portalModule.controller("newAccountController",
		['$scope','$log','$location','accountService','navModel',
			'orgService','orgModel','userService','loginModel','$window',
			'gapiService', '$routeParams', 'productService',
		function($scope,$log,$location,accountService,navModel,
				 orgService,orgModel,userService,loginModel, $window,
				 gapiService, $routeParams, productService){

	$scope.gapiService = gapiService;
	$scope.googleLoginEnabled = false;
	gapiService.enableGoogleLogin().then(function(status){
		$scope.googleLoginEnabled = status;
	}, function(){
		$scope.googleLoginEnabled = false;
	}).catch(function(error){
		$scope.googleLoginEnabled = false;
		$log.error("Error in 'Sign in with Google': ", error);
	});

	navModel.helpPage = false;
	navModel.showFooter = true;
	$scope.disableAfterSave = false;

	// read if input request is from external app
	if (!$scope.acode && !!$location.search() && $location.search().acode) {
		$scope.acode = $location.search().acode.toLowerCase();
	}

	$scope.appCode = $routeParams.appCode;
	if (!$scope.appCode && !!$location.search() && $location.search().app) {
		$scope.appCode = $location.search().app;
	}

	if($scope.appCode) {
		$scope.appCode = $scope.appCode.toLowerCase();
	}

	$scope.stateParam = $location.search().state;

	$scope.cancelRegistration = function(){
        $location.search({});
        if ($scope.appCode) {
            $location.path('/signin/staff').search('app', $scope.appCode);
        } else {
            $location.path('/signin/staff');
        }
	}
	
	$scope.registerUser={
            "basicProfile": {
                "title": "", // enumeration (hard code in HTML)
                "firstName": "", // 2-30 
                "lastName": "", // 2-30
                "email": ""
            },
            "credentials": {  
                "password": ""
            }
	}
	
	$scope.titleLabel = "Select a Title";
	$scope.errors = "";
	
	$scope.titles =  [
		                 {"value": "MR", "label": "MR"},
		                 {"value": "MRS", "label": "MRS"},
		                 {"value": "MISS", "label": "MISS"},
		                 {"value": "MS", "label": "MS"},
		                 {"value": "DR", "label": "DOCTOR"},
		                 {"value": "BR", "label": "BROTHER"},
		                 {"value": "CAPT", "label": "CAPTAIN"},
		                 {"value": "FR", "label": "FATHER"},
		                 {"value": "LT", "label": "LIEUTENANT"},
		                 {"value": "MSG", "label": "MONSIGNOR"},
		                 {"value": "PR", "label": "PASTOR"},
		                 {"value": "PROF", "label": "PROFESSOR"},
		                 {"value": "RAB", "label": "RABBI"},
		                 {"value": "REV", "label": "REVEREND"},
		                 {"value": "SGT", "label": "SERGEANT"},
		                 {"value": "SR", "label": "SISTER"},
		                 {"value": "NA", "label": "Not Applicable"}
	             ];
	
	$scope.changeTitle = function(titleValue,titleLabel){
		$scope.titleLabel = titleLabel;
		$scope.registerUser.basicProfile.title = titleValue;
		$scope.titleError = false;
	}
	
	$scope.registerAccount = function() {
        $log.debug("New user object", $scope.registerUser);
        $scope.termsPolicy = false;
        if ($scope.registerUser.basicProfile.title == '') {
            $scope.errorFlag = true;
            $scope.errors = 'Please select title';
            $window.scrollTo(0, 0);
            return;
        }
        if ($scope.termsOfUse != true || $scope.privacyPolicy != true) {
            $scope.errorFlag = true;
            $scope.errors = 'Please accept Terms of Use and Privacy Policy';
            $window.scrollTo(0, 0);
            return;
        }
        accountService.registerUser($scope.registerUser).then(registerUserSuccessHandler, registerUserErrorHandler);
        $scope.disableAfterSave = true;
    }
	
	$scope.closeAlert = function(){
		$scope.errors = "";
		$scope.errorFlag = false;
	}

	function registerUserSuccessHandler(data){
		$log.debug("Inside register user success handler",data);
		navModel.showNav= true;
		navModel.showFooter = true;
		//MB: Adding an extra check to setCurrentUser while registering the new user.
		//Was causing intermittent problems while registering new user
		userService.setCurrentUser(data);

		// if postLoginUrl exists: get it, clear the cookie, and redirect to the url
		var postLoginUrl = loginModel.getPostLoginUrl();
		if (postLoginUrl) {
			$log.debug('Post login url', postLoginUrl);
			loginModel.reset();
			$location.search({});
			$location.url(postLoginUrl);
			return;
		}

		// if we have an app code with no access code, attempt to get the alternate url and redirect
		// to that instead of the dashboard
		if (!!$scope.appCode && !$scope.acode) {
			productService.getAlternateUrl($scope.appCode)
				.then(function (response) {
					$log.debug('getAlternateUrl response', response);

					if (!!response && !!response.alternateUrl) {
						var url = response.alternateUrl;

						if (!!$scope.stateParam) {
							url += '?state=' + $scope.stateParam;
						}

						$window.location.href = url;
					} else {
						$location.path('/products');
					}
				})
				.catch(function (error) {
					$log.debug('getAlternateUrl error', error);
					$location.path('/products');
				});
		} else {
			$location.path('/products');
		}
	}

	function registerUserErrorHandler(error){
		$scope.disableAfterSave = false;
		$log.debug("Inside register user error handler",error);
		$scope.errorFlag = true;
		$scope.errors = error.message || error.code || "An error occurred. Please try again.";
		$window.scrollTo(0, 0);
	}
	
	
}]);