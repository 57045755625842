portalModule.service('loginModel', ['$log', '$cookies','cookieService', function($log, $cookies, cookieService) {

    function setPostLoginUrl(url){
        cookieService.saveCookie('postLoginUrl',url);
    }

    // getting cookie deletes it to prevent re-launching
    function getPostLoginUrl(){
        $log.debug(cookieService.getCookie('postLoginUrl'));
        var postLoginUrl = cookieService.getCookie('postLoginUrl');
        reset();
        return postLoginUrl;
    }
    
    function reset(){
        // remove from scholastic.com
        cookieService.removeCookie('postLoginUrl');

        var config = cookieService.config();
        var hostConfig = cookieService.config();

        // hard reset with expired time
        var cookieString =
          "postLoginUrl=;" +
          "expires=" + new Date(0).toUTCString() + ";" +
          "path=/;" +
          (config.secure ? "Secure;" : "");

        document.cookie = cookieString + "domain=" + config.domain + ";";
        document.cookie = cookieString + "domain=" + hostConfig.domain + ";";
    }
    
    return {
            setPostLoginUrl : setPostLoginUrl,
            getPostLoginUrl : getPostLoginUrl,
            reset : reset
          };

}]);