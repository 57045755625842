portalModule
    .controller('importStudentsController', [
        '$scope', '$log', '$location', '$routeParams', 'navModel',
        'userService', 'userModel', 'orgService', 'sharedConstants',
        '$filter', 'csvService', '$window', 'notificationModel', '$route',
        '$templateCache', 'fileReader', 'notificationsService', 'studentService',
        'sessionState', 'settingsService', 'classService', 'productModel', 'productService',
        function (
            $scope, $log, $location, $routeParams, navModel,
            userService, userModel, orgService, sharedConstants,
            $filter, csvService, $window, notificationModel, $route,
            $templateCache, fileReader, notificationsService, studentService,
            sessionState, settingsService, classService, productModel, productService
        ) {
            var $this = this;

            navModel.showNav = true;
            navModel.helpPage = false;
            $scope.spinner = true;
            $scope.disableUpload = false;
            $scope.initialOnboarding = $location.path().indexOf('firstclass') > -1;
            $scope.addImport = ($location.path().indexOf('addImport') > -1);
            $scope.alertMessage = '';
            $scope.errorFlag = false;

            $this.onInit = onInit;
            $this.reloadRedirect = reloadRedirect;
            $this.importRouting = importRouting;
            $this.validateCsvSuccessHandler = validateCsvSuccessHandler;
            $this.validateCsvErrorHandler = validateCsvErrorHandler;
            $this.getSelectedClass = getSelectedClass;
            $this.logSelectClassError = logSelectClassError;

            $scope.closeAlert = closeAlert;
            $scope.validateCsv = validateCsv;

            $this.searchPropertiesArray = ['firstName','lastName','externalStudentId','username'];
            $this.executeSearches = executeSearches;
            $this.failAndNotify = failAndNotify;
            $scope.getFile = getFile;
            $this.parseCsv = parseCsv;

            $scope.fileExtension = '.csv';
            $scope.fileName = undefined;

            $this.SUCCESS_MESSAGE_SEARCH_CSV_UPLOAD = $scope.addImport
                ? 'Successfully uploaded new student CSV file.'
                : 'Successfully uploaded student search CSV file.';
            $this.ERROR_SEARCH_CSV_UPLOAD_NO_STUDENT_ROWS =
                'It looks like there are no students in the file you uploaded. ' +
                'Please add student names to the rows and try uploading it again.';
            $this.ERROR_SEARCH_CSV_FAILED_TO_UPLOAD =
                'Your file failed to upload. Please check that your file is ' +
                'formatted correctly and includes all the required information ' +
                'and try uploading it again.';
            $this.ERROR_SEARCH_CSV_UPLOAD_NO_FIRSTNAME_LASTNAME =
                'It looks like your file is missing some information. Please ' +
                'check that every row has a first name and last name and try uploading your file again.';
            $this.ERROR_SEARCH_NO_CSV_FILE_EXTENSION = $scope.addImport
                ? 'New Student CSV File upload failed to parse; please confirm ' +
                    'the file format is comma separated values and has a .csv file ' +
                    'extension, then try again.'
                : 'Student Search CSV File upload failed to parse; please confirm ' +
                    'the file format is comma separated values and has a .csv file ' +
                    'extension, then try again.';
            $this.defaultErrorMessage =
                'Oops. We seem to be experiencing some technical difficulty. ' +
                'Sorry for the inconvenience. Please try again later.';

            function executeSearches(searchArray){
                csvService.setStudentsArray(searchArray);
                notificationModel.setMessage($this.SUCCESS_MESSAGE_SEARCH_CSV_UPLOAD);

                if($scope.initialOnboarding) {
                    $location.path('/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                    return;
                }

                $this.reloadRedirect('/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
            }

            function failAndNotify(message){
                notificationModel.setMessage(message);
                notificationsService.setMessage(true);
                $this.reloadRedirect($location.path());
            }

            function getFile(fileData) {
                fileReader.readAsText($scope, fileData)
                    .then(function(fileObject) {
                        $log.debug('fileObject',fileObject);
                        $this.parseCsv(fileObject);
                    })
                    .catch(function(error){
                        $log.info(error);
                        /** fail case 5: upload was not successful*/
                        $this.failAndNotify($this.ERROR_SEARCH_NO_CSV_FILE_EXTENSION);
                    });
            }

            function parseCsv (parsedFile){
                var searchObjectsArray = csvService.parseCsvFileObjReturnArrayOfObjects(parsedFile,
                    $this.searchPropertiesArray);
                if(csvService.fileNameHasNoCsvExtension(parsedFile,'.csv')){
                    /** fail case 5: file has no .csv extension*/
                    $this.failAndNotify($this.ERROR_SEARCH_NO_CSV_FILE_EXTENSION);
                } else if(csvService.noFileNameOrContents(parsedFile)){
                    /** fail case 4: failed to upload */
                    $this.failAndNotify($this.ERROR_SEARCH_CSV_FAILED_TO_UPLOAD);
                } else if (!searchObjectsArray || (!!searchObjectsArray && !searchObjectsArray[0])
                    || csvService.allFieldsInRowsAreEmpty(searchObjectsArray)) {
                    /** fail case 3: no student rows */
                    $this.failAndNotify($this.ERROR_SEARCH_CSV_UPLOAD_NO_STUDENT_ROWS);
                } else if (csvService.requiredColHeadersMissing(parsedFile, ['First Name','Last Name'])
                    || csvService.requiredFieldsAreMissing(parsedFile, ['First Name','Last Name'],
                        $this.searchPropertiesArray)){
                    /** fail case 2: firstName or lastName columns missing
                     * or any rows with required field values are missing */
                    $this.failAndNotify($this.ERROR_SEARCH_CSV_UPLOAD_NO_FIRSTNAME_LASTNAME);
                } else if (!!searchObjectsArray) {
                    /** success case 1: headers and student rows exist */
                    $this.executeSearches(searchObjectsArray);
                } else {
                    /** default case: standard Oops message */
                    $this.failAndNotify($this.defaultErrorMessage);
                }
            }

            $scope.backToManageRosters = function () {
                csvService.reset();
                $location.path('/students');
            };

            function reloadRedirect(route) {
                if (route.indexOf('/students/search') > -1) {
                    $location.path('/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                } else {
                    /** necessary to show success message when page reloaded */
                    var currentPageTemplate = $route.current.templateUrl;
                    $templateCache.remove(currentPageTemplate);
                    $route.reload();
                }
            }

            function closeAlert() {
                $scope.alertMessage = "";
                $scope.errorFlag = false;
                delete $scope.alertMessage;
                delete $scope.errorFlag;
            }

            function validateCsvSuccessHandler(data) {
                if(data.code === 'error.not.logged.in'){
                    $location.path('/signin/staff');
                    return;
                }

                csvService.setValidatedResults(data);

                notificationModel.setMessage($this.SUCCESS_MESSAGE_SEARCH_CSV_UPLOAD);
                $scope.alertMessage = $this.SUCCESS_MESSAGE_SEARCH_CSV_UPLOAD;
                notificationsService.setMessage(false);
                $scope.errorFlag = notificationsService.popMessage();

                $this.importRouting();
            }

            function validateCsvErrorHandler(error) {
                $scope.disableUpload = false;

                var errorMessage = error.message || $this.defaultErrorMessage;

                notificationModel.setMessage(errorMessage);
                $scope.alertMessage = errorMessage;

                notificationsService.setMessage(true);
                $scope.errorFlag = notificationsService.popMessage();
            }

            function validateCsv(value){
                $scope.closeAlert();

                if ($scope.showYOYFeatures && !productModel.isSRCTeacher) {
                    studentService.validateStudentsCsvAgainstOrg(value, $routeParams.sectionId)
                        .then($this.validateCsvSuccessHandler, $this.validateCsvErrorHandler);
                } else {
                    studentService.validateStudentsCSV(value, $routeParams.sectionId)
                        .then($this.validateCsvSuccessHandler, $this.validateCsvErrorHandler);
                }
            }

            function importRouting() {
                if ($scope.initialOnboarding) {
                    if ($scope.addImport) {
                        $location.path('/students/firstclass/searchAdd/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                    } else {
                        $location.path('/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                    }

                    return;
                }

                if ($scope.addImport) {
                    $location.path('/students/searchAdd/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                } else {
                    $location.path('/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                }
            }

            function getSelectedClass() {
                if($routeParams && $routeParams.sectionId){
                    classService.getClassSection($routeParams.sectionId).then(function (data) {
                        $scope.selectedClass = data;
                    }).catch(function (error) {
                        $this.logSelectClassError(error)
                    });
                }
            }

            function logSelectClassError(error){
                $log.debug('Error getting selected class details', error);
            }

            function onInit() {
                $log.debug("In importStudents.controller.onInit");
                $scope.alertMessage = notificationModel.popMessage();
                $scope.errorFlag = notificationsService.popMessage(); /** will replace notificationModel eventually */

                $scope.sampleImage = sharedConstants.SAMPLE_STUDENT_UPLOAD_IMAGE_WITH_ID_AND_USERNAME;
                $scope.sampleHref = sharedConstants.SAMPLE_STUDENT_UPLOAD_FILE_WITH_ID_AND_USERNAME;

                sessionState.store('$scope',$scope);
                settingsService.setSettings(sessionState).then(function(settings){
                    $log.debug('$scope',$scope);
                    $log.debug('settings',settings);
                });

                userService.getCurrentUser()
                    .then(function (user) {
                        $log.debug('getCurrentUser', user);
                        if (!!userModel && !!userModel.currentUser && !!userModel.currentUser.staff) {
                            $this.currentLoggedInUser = userModel.currentUser.staff.id;
                        }

                        orgService.getOrgData()
                            .then(function (orgRolesList) {
                                $log.debug('orgService.getOrgData', orgRolesList);

                                $scope.orgName = orgRolesList.filter(function (org) {
                                    return org.id === $routeParams.orgId;
                                })[0].name;

                                productService.getEntitlement().then(function() {
                                    if (!$scope.addImport && productModel.isSRCTeacher !== undefined && productModel.isSRCTeacher) {
                                        var path = $location.path().replace(/import/gi, 'addImport');
                                        $location.path(path);
                                    } else {
                                        $this.getSelectedClass();
                                        $scope.spinner = false;
                                    }
                                });
                            });
                    });

            }

            $this.onInit();

        }
    ]);