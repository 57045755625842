portalModule.controller("helpController", [
  "$window",
  function($window) {
    onInit();

    function onInit() {
      $window.location.href = 'https://help.digital.scholastic.com/';
    }
  }
]);
