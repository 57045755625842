angular.module('portalApp').directive('teacherDetailsDirective', function () {
    return {
        restrict: 'EA',
        scope: {
            school: "=",
            cleverSchool: "=cleverschool",
            ltiSchool: "=ltischool",
            classlinkSchool: "=classlinkschool",
            selectedClass: '=selectedclass',
            staffId: "=staffid",
            classList: '=classlist',
            googleUser: '=googleuser',
            initialized: '=initialized',
            readOnlyClass: '=readonlyclass',
            selectedSchoolYear: '=selectedschoolyear',
            showYOYFeatures: '=showyoyfeatures',
            removeCoTeacher: '&',
            setInitialized: '&setinitialized'
        },
        controller: 'teacherDetailsController',
        templateUrl: 'resources/js/Modules/teachers/teacherDetailsController/teacherDetail.html'
    };

});