(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule
        .controller('ClassSettingsController',
                    ['$scope', '$location', 'classService', '$routeParams', '$log', 'orgService', 'userModel', 'orgModel', 'classModel', 'userService', 'navModel', 'sharedConstants', 'SDMAnalyticsService', 'productModel', 'settingsService', 'productService', 'notificationModel', '$window',
                     function($scope, $location, classService, $routeParams, $log, orgService, userModel, orgModel, classModel, userService, navModel, sharedConstants, SDMAnalyticsService, productModel, settingsService, productService, notificationModel, $window) {
                        
                        var $this = this;
                        $this.onInit = onInit;
                        $this.loadClassDetails = loadClassDetails;
                        $this.generateEasyLoginUrl = generateEasyLoginUrl;
                        $this.getEasyLoginStatus = getEasyLoginStatus;
                        $this.getErrors = getErrors;
                        $this.checkInvalidGradesForCleverSchool = checkInvalidGradesForCleverSchool;
                        $scope.isCoTeacherForCurrentClass = false;
                        $scope.selectedClassId = $routeParams.sectionId;
                        $scope.grades = classModel.gradesArray;
                        $scope.error = '';
                        $scope.gradeLabelLow = "---Select Grade---";
                        $scope.gradeLabelHigh = "---Select Grade---";
                        $scope.showGradeDropdown = true;
                        $scope.isLtiClass = false;
                        $scope.isClasslinkClass = false;
                        $scope.lowGrade = true;
                        $scope.multipleGrades = {};
                        $scope.multipleGrades.value = false;
                        $scope.showEasyLoginUrl = false;
                        $scope.classEasyLoginToggle = false;
                        $scope.schoolEasyLoginAllowed = false;
                        $scope.initialized = false;

                        $scope.isActive = function(viewLocation){
                            return $window.location.href.indexOf(viewLocation)>-1;
                        };
                        
                        $scope.changeSettings = function(value){
                            $location.path('/students/settings/'+ $scope.selectedClassId + '/' + value);
                        }
                        
                        $scope.onMultipleGradesToggle = function () {
                            // if the teacher is saying that the class has multiple grades
                            // add the second upper limit grade to the classSection grades array
                            if ($scope.multipleGrades.value) {
                                $scope.classSection.highGrade = '---Select Grade---';
                                $scope.gradeLabelHigh = "---Select Grade---";
                            } else {
                                // we are saying this class doesn't have multiple grades
                                // so if the length is greater than 1 pop off the top item
                                // leaving the grades array with a single item
                                if ($scope.classSection.highGrade) {
                                    $scope.classSection.highGrade = $scope.classSection.lowGrade;
                                }
                            }
                        };
                        
                        $scope.changeGradeLow = function (gradeLow) {
                            $scope.classSection.lowGrade = gradeLow.value;
                            $scope.gradeLabelLow = gradeLow.label;
                            $scope.gradeSortLow = gradeLow.sort;
                            $scope.classSection.highGrade = '---Select Grade---';
                            $scope.gradeLabelHigh = "---Select Grade---";
                            //If lower grade value is maximum, disable multiple grade option
                            if($scope.gradeSortLow === 14){
                                $scope.multipleGrades.value = false;
                            }
                        };
                        
                        $scope.changeGradeHigh = function (gradeHigh) {
                            $scope.classSection.highGrade = gradeHigh.value;
                            $scope.gradeLabelHigh = gradeHigh.label;
                            $scope.gradeSortHigh = gradeHigh.sort;
                        };
                        

                        $scope.archiveClass = function(){
                            // copy class
                            var classCopy = angular.copy($scope.classSection);

                            // make the class inactive
                            classCopy.active = false;
                            // call update handler
                            classService.updateClassSection(classCopy).then(function(data){
                                $location.path('/students');
                            },function(error){
                                $scope.error = error.message || error.code;
                            });
                        };
                        
                        $scope.toggleEasyLogin = function(){
                            if($scope.classSection.easyLogin.enabled && !$scope.classEasyLoginToggle) {
                                $scope.classEasyLoginToggle = true;
                                $scope.classSection.easyLogin.showInEasyLoginSchool = false;
                            } else {
                                $scope.classSection.easyLogin.enabled = !$scope.classSection.easyLogin.enabled;
                                $scope.classEasyLoginToggle = $scope.classSection.easyLogin.enabled;
                            }
                            $scope.saveUpdates(true);
                        };
                        
                        $scope.toggleSchoolEasyLogin = function(){
                            $scope.classSection.easyLogin.showInEasyLoginSchool = !$scope.classSection.easyLogin.showInEasyLoginSchool;
                            if($scope.classSection.easyLogin.showInEasyLoginSchool) {
                                $scope.classSection.easyLogin.enabled = true;
                                $scope.classEasyLoginToggle = false;
                            } else {
                                $scope.classSection.easyLogin.enabled = false;
                            }
                            $scope.saveUpdates(true);
                        };
                        
                        $scope.saveUpdates = function(noRedirection){
                            $scope.error = getErrors();
                            
                            if (!$scope.error && $scope.classSection.nickname != undefined) {
                                // if not selecting multiple grades make sure high and low grade are the same
                                // low grade is always selected in the UI; so just copy the low grade value into the highGrade property
                                
                                if($scope.gradeSortLow > 4){
                                    if($scope.classSection.easyLogin){
                                        $scope.classSection.easyLogin.enabled = false;
                                        $scope.classSection.easyLogin.showInEasyLoginSchool = false;
                                    }
                                }
                                
                                if (!$scope.multipleGrades.value) {
                                    $scope.classSection.highGrade = $scope.classSection.lowGrade;
                                }
                                
                                classService.updateClassSection($scope.classSection).then(function(updatedSection){
                                    $log.debug('Updated section details', updatedSection);
                                    $scope.classSection = updatedSection;
                                    try{
                                        if (classModel.classList == undefined) {
                                            classModel.classList = [];
                                        }
                                        
                                        SDMAnalyticsService.addEventType(sharedConstants.UPDATE_CLASS_EVENT, 'updatedClass', 'existingClasses', 'orgId');
                                        SDMAnalyticsService.emit(sharedConstants.UPDATE_CLASS_EVENT, updatedSection, classModel.classList, updatedSection.organizationId);
                                    } catch (e) {
                                        $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
                                    }
                                    if(!noRedirection) {
                                        notificationModel.setMessage('Successfully updated class details for ' + updatedSection.nickname);
                                        $location.path('/students');
                                    } else {
                                        $this.generateEasyLoginUrl();
                                        $scope.error = 'Successfully updated class details for ' + updatedSection.nickname;
                                    }
                                },function(error){
                                    $scope.error = error.message || error.code || 'Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.';
                                });
                                
                            }
                            
                        };
                        
                        $scope.cancel = function(){
                            $location.path('/students');
                        }
                        
                        $scope.closeAlert = function(){
                            $scope.error = '';
                            $scope.warningMessage = '';
                        }
                        
                        $scope.printStudentLogins = function (){
                            $location.path('/students/easylogin/print/' + $scope.classSection.id);
                        }
                        
                        function getErrors() {
                            var errors = [];
                            
                            $log.debug("ClassSection nickname", $scope.classSection.nickname);
                            if ($scope.classSection.nickname === '') {
                                errors.push(sharedConstants.CLASS_ENTER_TITLE_ERROR);
                            }
                            
                            if ($scope.classSection.lowGrade == '---Select Grade---') {
                                if (!$scope.multipleGrades.value) {
                                    errors.push(sharedConstants.CLASS_SELECT_GRADE_ERROR);
                                } else {
                                    errors.push(sharedConstants.CLASS_SELECT_LOWER_GRADE_ERROR);
                                }
                            }
                            if ($scope.multipleGrades.value && $scope.classSection.highGrade == '---Select Grade---') {
                                errors.push(sharedConstants.CLASS_SELECT_HIGHER_GRADE_ERROR);
                            }
                            
                            return errors.length === 0 ? undefined : errors.join("<br>");
                        }
                        
                        function generateEasyLoginUrl() {
                            if($scope.classSection.easyLogin && $scope.classSection.easyLogin.enabled){
                                var host = $location.host();
                                var finalHostUrl;
                                
                                if (host === 'localhost' || host === 'l.scholastic-labs.io') {
                                    finalHostUrl = location.host + '/dp-portal';
                                } else {
                                    finalHostUrl = host;
                                }
                                
                                if ($scope.classSection.easyLogin.showInEasyLoginSchool) {
                                    classService.getSchoolEasyLoginCred($scope.classSection.organizationId).then(function(data){
                                        $scope.schoolEasyLoginCred = data;
                                        $scope.easyLoginUrl = finalHostUrl + '/#/sz/' + $scope.schoolEasyLoginCred.orgId + '/' + $scope.schoolEasyLoginCred.token;
                                    }, function(error){
                                        $scope.error = error.message || error.code || 'Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.';
                                        $scope.easyLoginUrl = finalHostUrl + '/#/cz/' + $scope.classSection.id + '/' + $scope.classSection.easyLogin.token;
                                    });
                                } else {
                                    $scope.easyLoginUrl = finalHostUrl + '/#/cz/' + $scope.classSection.id + '/' + $scope.classSection.easyLogin.token;
                                }
                                
                                $scope.showEasyLoginUrl = true;
                            }
                        }
                        
                        function getEasyLoginStatus() {
                            classService.getEasyLoginStatus($scope.classSection.organizationId).then(function(data){
                                if(data.status && (data.status.toLowerCase() === 'enabled' || data.status.toLowerCase() === 'disabled')) {
                                    $scope.schoolEasyLoginAllowed = true;
                                }
                            }, function(error){
                                if(error.code && error.code === 'error.easylogin.org.no.subs') {
                                    $scope.schoolEasyLoginAllowed = false;
                                }
                            });
                        }

                        $scope.getClassSource = function (classObj) {
                            return classObj.identifiers.source ? classObj.identifiers.source.toLowerCase() : null;
                        }
                        
                        function loadClassDetails(){
                            classService.getClassSection($scope.selectedClassId).then(function (sectionData) {
                                $log.debug("In class.loadSection(" + $scope.selectedClassId + "). sectionData=", sectionData);
                                $scope.classSection = sectionData;
                                // Verify if the teacher is a co-Teacher
                                if ((!!userModel && !!userModel.currentUser && !!userModel.currentUser.staff) &&
                                    (userModel.currentUser.staff.id !== sectionData.staff.primaryTeacherId)) {
                                    $scope.isCoTeacherForCurrentClass = true;
                                }
                                if ($scope.getClassSource($scope.classSection) === 'clever') {
                                    $log.debug('Section belongs to clever school');
                                    $scope.showGradeDropdown = false;
                                    $scope.cleverSchool = true;
                                }
                                $scope.isLtiClass = $scope.getClassSource($scope.classSection) === 'lti';
                                $scope.isClasslinkClass = $scope.getClassSource($scope.classSection) === 'classlink';
                                
                                // added multi-grade toggle for dual grade drop downs
                                if ($scope.classSection.highGrade == $scope.classSection.lowGrade) {
                                    $scope.multipleGrades.value = false;
                                    for (var i = 0; i < $scope.grades.length; i++) {
                                        if ($scope.classSection.lowGrade && $scope.grades[i].value.toLowerCase() == $scope.classSection.lowGrade.toLowerCase()) {
                                            $scope.gradeLabelLow = $scope.grades[i].label;
                                            $scope.gradeSortLow = $scope.grades[i].sort;
                                            break;
                                        }
                                    }
                                } else {
                                    $scope.multipleGrades.value = true;
                                    for (var i = 0; i < $scope.grades.length; i++) {
                                        if ($scope.grades[i].value.toLowerCase() == $scope.classSection.lowGrade.toLowerCase()) {
                                            $scope.gradeLabelLow = $scope.grades[i].label;
                                            $scope.gradeSortLow = $scope.grades[i].sort;
                                        }
                                        if ($scope.grades[i].value.toLowerCase() == $scope.classSection.highGrade.toLowerCase()) {
                                            $scope.gradeLabelHigh = $scope.grades[i].label;
                                            $scope.gradeSortHigh = $scope.grades[i].sort;
                                        }
                                        if ($scope.gradeLabelLow != '---Select Grade---' && $scope.gradeLabelHigh != '---Select Grade---') {
                                            break;
                                        }
                                    }
                                }
                                
                                if($scope.gradeSortLow < 5){
                                    $scope.lowGrade = true;
                                    $this.getEasyLoginStatus();
                                }
                                else{
                                    $scope.lowGrade = false;
                                }
                                
                                $scope.classEasyLoginToggle = $scope.classSection.easyLogin.enabled;
                                if($scope.classSection.easyLogin.showInEasyLoginSchool) {
                                    $scope.classEasyLoginToggle = false;
                                }
                                $this.generateEasyLoginUrl();
                                if ($scope.cleverSchool) {
                                    $this.checkInvalidGradesForCleverSchool($scope.classSection);
                                }
                                $scope.initialized = true;
                            });
                        }

                        function checkInvalidGradesForCleverSchool(selectedClass) {
                             if (!selectedClass.highGrade || !selectedClass.lowGrade) {
                                 $scope.warningMessage = selectedClass.name +  sharedConstants.WARNING_INCORRECT_GRADE;
                             } else {
                                 $scope.warningMessage = '';
                             }
                         }
                        
                        function onInit(){
                        
                            userService.getCurrentUser().then(function(currentUser){
                                $log.debug('User signed in',currentUser);
                                
                                settingsService.getSettings().then(function(settings) {
                                    $log.debug('Settings: ', settings);
                                    if(settings.showEasyLogin){
                                        $scope.showEasyLogin = true;
                                    }
                                });
                                
                                orgService.getSchoolsWithTeacherRole().then(function(data){
                                    $log.debug('Reload org list');
                                });
                                
                                productService.getSubscriptions().then(function(subscriptions){
                                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                                });
                                
                                productService.getEntitlement().then(function(data){
                                    $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                                });
                                
                                navModel.showNav = true;
                                navModel.helpPage = false;
                                $this.loadClassDetails();

                            },function(error){
                                $log.error('User not signed in',error);
                                $location.path('/signin/staff');
                            });
                        
                        };
                        $this.onInit();
                        
                    }
                    ]);
})();