(function () {

    angular.module('portalApp').service('csvService', [
        '$log', '$filter', csvServiceFunction
    ]);

    function csvServiceFunction($log, $filter) {
        var $this = this;

        function isObjectEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (obj[prop] && +obj[prop] !== 0) return false;
                }
            }
            return true;
        }

        $this.reset = function(){
            $this.studentsArray = undefined;
            delete $this.studentsArray;

            $this.validatedResults = undefined;
            delete $this.validatedResults;

            $this.importProgressStatus = undefined;
            delete $this.importProgressStatus;
        };

        $this.setStudentsArray = function (studentsArray) {
            $this.studentsArray = studentsArray;
            $this.setImportProgressStatus(studentsArray);
        };

        $this.getStudentsArray = function () {
            return $this.studentsArray;
        };

        $this.setValidatedResults = function (results) {
            $this.validatedResults = results;
        };

        $this.getValidatedResults = function () {
            return $this.validatedResults;
        };

        $this.shiftSearchEntry = function () {
            $this.studentsArray.shift();
            $this.incrementImportProgress();
            /** test for falsey*/
            return $this.studentsArray == false;
        };

        $this.setImportProgressStatus = function (studentsArray) {
            $this.importProgressStatus = {
                length: !!studentsArray && studentsArray.length ? studentsArray.length : 0,
                currentSearchCount: 1
            };
        };
        $this.getImportProgressStatus = function () {
            return $this.importProgressStatus;
        };
        $this.incrementImportProgress = function () {
            $this.importProgressStatus.currentSearchCount++;
        };

        $this.defaultLineDelimiter = '\n';

        $this.getAllLineDelimiters = function (text) {
            var LFs = (text.match(/\n/g) || []).length;
            var CRs = (text.match(/\r/g) || []).length;
            var CRLFs = (text.match(/\r\n/g) || []).length;
            var LFCRs = (text.match(/\n\r/g) || []).length;
            var lineDelimiters = {
                LFs: LFs,
                CRs: CRs,
                CRLFs: CRLFs,
                LFCRs: LFCRs
            };
            return lineDelimiters;
        };
        $this.setLineDelimiter = function (lineDelimiter) {
            $this.lineDelimiter = lineDelimiter;
        };
        $this.getLineDelimiter = function () {
            return $this.lineDelimiter || $this.defaultLineDelimiter;
        };

        $this.autoSelectLineDelimiter = function (lineDelimiters) {
            var selectedDelimiter = lineDelimiters.CRLFs ? '\r\n' :
                lineDelimiters.LFs ? '\n' :
                    lineDelimiters.CRs ? '\r' :
                        lineDelimiters.LFCRs ? '\n\r' : '\n';
            $this.setLineDelimiter(selectedDelimiter);
        };

        $this.noFileNameOrContents = function (fileObject) {
            return (!fileObject) || (!!fileObject && !fileObject.fileContents);
        };
        $this.fileNameHasNoCsvExtension = function (fileObject, fileExtension) {
            return !!fileObject
                && (!fileObject.fileName
                    || (!!fileObject.fileName && fileObject.fileName.lastIndexOf(fileExtension) < 0));
        };
        $this.requiredColHeadersMissing = function (fileObject, requiredColumnHeadersArray) {
            var fileHeadersStr = '';
            var fileHeadersArray = [];
            var fileContents = '';
            var lineDelimitersCounts = {};
            var missingHeaders = requiredColumnHeadersArray.slice();
            var noFileNameOrContents = $this.noFileNameOrContents(fileObject);
            var fileNameHasNoCsvExtension = $this.fileNameHasNoCsvExtension(fileObject, '.csv');

            if (!noFileNameOrContents && !fileNameHasNoCsvExtension) {
                fileContents = fileObject.fileContents;
                lineDelimitersCounts = $this.getAllLineDelimiters(fileContents);
                $this.autoSelectLineDelimiter(lineDelimitersCounts);
                fileHeadersStr = fileObject.fileContents.split($this.lineDelimiter)[0];
                fileHeadersArray = fileHeadersStr.split(',');
                for (var i = 0; i < requiredColumnHeadersArray.length; i++) {
                    for (var j = 0; j < fileHeadersArray.length; j++) {
                        if (fileHeadersArray[j].replace(/[\W_]+/g, "").toLowerCase()
                            .indexOf(requiredColumnHeadersArray[i].replace(/[\W_]+/g, "").toLowerCase()) > -1) {
                            missingHeaders.splice(missingHeaders.indexOf(requiredColumnHeadersArray[i]), 1);
                            break;
                        }
                    }
                }
            }
            return (!!missingHeaders && missingHeaders.length > 0);
        };
        $this.requiredFieldsAreMissing = function (fileObject, requiredColumnHeadersArray, propertiesArray) {
            var resultsArray = [];
            var noFileNameOrContents = $this.noFileNameOrContents(fileObject);
            var fileNameHasNoCsvExtension = $this.fileNameHasNoCsvExtension(fileObject, '.csv');
            var requiredPropertiesArrayOfObjects = $this.getRequiredPropertiesArray(requiredColumnHeadersArray, propertiesArray);
            if (!noFileNameOrContents && !fileNameHasNoCsvExtension && !!requiredPropertiesArrayOfObjects) {
                resultsArray = $this.parseCsvFileObjReturnArrayOfObjects(fileObject, propertiesArray);
                var firstRowMissingAProperty = [];
                for (var i = 0; i < requiredPropertiesArrayOfObjects.length; i++) {
                    for (var requiredProperty in requiredPropertiesArrayOfObjects[i]) {
                        if (requiredPropertiesArrayOfObjects[i].hasOwnProperty(requiredProperty)) {
                            firstRowMissingAProperty = resultsArray.filter(function (result) {
                                return !result[requiredProperty];
                            });
                            if (!!firstRowMissingAProperty && firstRowMissingAProperty.length > 0) {
                                return requiredProperty;
                            }
                        }
                    }
                }
            }
            return '';
        };
        $this.allFieldsInRowsAreEmpty = function (parsedArrayOfObjects) {
            for (var i = parsedArrayOfObjects.length - 1; i >= 0; i--) {
                if(!isObjectEmpty(parsedArrayOfObjects[i])) {
                    return false;
                }
            }
            return true;
        };
        $this.getRequiredPropertiesArray = function (requiredColumnHeadersArray, propertiesArray) {
            var requiredPropertiesArray = [];
            propertiesArray.forEach(function (prop) {
                requiredColumnHeadersArray.forEach(function (header) {
                    var headerStr = header.replace(/[\W_]+/g, "").toLowerCase();
                    prop = prop.replace(/[\W_]+/g, "");
                    if (headerStr.indexOf(prop.toLowerCase()) > -1
                        || prop.toLowerCase().indexOf(headerStr) > -1) {
                        var resultProp = {};
                        resultProp[prop] = header;
                        requiredPropertiesArray.push(resultProp);
                    }
                });
            });
            return requiredPropertiesArray;
        };

        $this.trimEndingBlankRows = function (parsedArrayOfObjects) {
            for (var i = parsedArrayOfObjects.length - 1; i >= 0; i--) {
                if (isObjectEmpty(parsedArrayOfObjects[i])) {
                    parsedArrayOfObjects.pop();
                } else {
                    return parsedArrayOfObjects;
                }
            }
        };

        $this.parseCsvFileObjReturnArrayOfObjects = function (fileObject, propertiesArray) {
            var fileContentsStr = fileObject.fileContents;
            var lineDelimitersCounts = $this.getAllLineDelimiters(fileContentsStr);
            $this.autoSelectLineDelimiter(lineDelimitersCounts);
            var fileRowsArray = $filter('csvToArrayOfObjects')(fileContentsStr, propertiesArray, $this.lineDelimiter);
            fileRowsArray = $this.trimEndingBlankRows(fileRowsArray);
            return fileRowsArray;
        }
    }

})();
