/**
 * Created by jhouser on 10/21/2015.
 */


portalModule.service("navModel", function() {

    var dataObject = {
        // value used to hide the footer until the main view is drawn; so it doesn't appear flush against head and then immediately move
        showFooter : false,
        // contains the user info of the user who currently signed in
        showNav : false,
        helpPage: false,
        licenseShowNav : false,
        reset : reset,
        showSubscriptionLink : false,
        showStudentsLink : false,
        showProductsLink : false,
        showReportsLink : false,

        // New UX
        activePage: undefined,
    };

    return dataObject;


    function reset(){
        dataObject.showNav = false;
        dataObject.licenseShowNav = false;
        dataObject.showSubscriptionLink = false;
        dataObject.showStudentsLink = false;
        dataObject.showProductsLink = false;
        dataObject.showReportsLink = false;
        dataObject.helpPage = false;

        dataObject.activePage = undefined;
    }

});
