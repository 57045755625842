portalModule.service('licenseAcceptService',['$http','$log','$q','apiResponse',function($http,$log,$q,apiResponse){
	return{
		licenseRequest : licenseRequest,
		checkValidUser : checkValidUser,
		checkValidToken : checkValidToken,
		licenseAccept : licenseAccept,
		userLicenseAccept : userLicenseAccept
	};
	
	function licenseRequest(token,method){
		 var request = $http({
	    	    method: method,
	    	    url: PORTAL_API_ENDPOINT +'/composite/staff/license-agreement/institutional?token=' + token
	    	});

		 return  apiResponse.makeApiCall(request);
	}
	
	function checkValidToken(token){
		return licenseRequest(token,'GET');
	}
	
	function licenseAccept(token){
		return licenseRequest(token,'POST');
	}

	function userLicenseRequest(method){
		// get for checking if user agreed to license
		// put for accepting license agreement
		var request = $http({
			method: method,
			url: PORTAL_API_ENDPOINT +'/composite/staff/individual-license-agreement/'
		});

		return  apiResponse.makeApiCall(request);
	}


	function checkValidUser(){
		return userLicenseRequest('GET');
	}

	function userLicenseAccept(){
		return userLicenseRequest('PUT');
	}

	
}]);