portalModule.controller("showMessageController",[
    '$scope','$uibModalInstance','$log','$sce',
    function($scope,$uibModalInstance, $log, $sce){
        $log.debug("showMessageController ");

        $scope.messageModalText = $sce.trustAsHtml($scope.messageModalText);

        $scope.closePopup = function(response){
            $uibModalInstance.close(response);
        };

        $scope.continueSave = function(){
            $scope.closePopup('CONTINUE');
        };
    }]);