angular.module('portalApp')
    .controller('SubMenuBarController', [
        '$scope', '$log', '$location', 'productService', 'orgService', 'subscriptionModel',
        'userModel', 'orgModel', 'navModel', '$uibModal', '$timeout', 'SDMAnalyticsService',
        'sharedConstants',  'notificationModel', 'settingsService',  'notificationsService', 'productModel',
        function ($scope, $log, $location, productService, orgService, subscriptionModel,
                  userModel, orgModel, navModel, $uibModal, $timeout, SDMAnalyticsService,
                  sharedConstants,  notificationModel, settingsService, notificationsService, productModel) {

            var $this = this;
            $this.onInit = onInit;
            $scope.closeBanner = closeBanner;

            $scope.isActive = function(viewLocation){
                return $location.path().indexOf(viewLocation) > -1;
            };

            // rewrite of changeOrg to accommodate for differences in using UI Select vs using a drop down
            $scope.changeOrg = function ($item, $model) {
                $scope.$emit('setInitialized', false);

                // for some reason subscriptionsForOrg is not updating automatically; perhaps the on-select method bypasses the automatic change of model?
                $scope.subscriptionsForOrg = $item;
                $scope.$emit('subscriptionsForOrg', $scope.subscriptionsForOrg);

                // setting up user model
                userModel.currentOrg = $scope.subscriptionsForOrg;

                subscriptionModel.setCookieForOrgAsAdmin($scope.subscriptionsForOrg.id);
                subscriptionModel.selectedSubscriptionIndex = $scope.orgAndSubscriptions.indexOf($item);

                try {
                  SDMAnalyticsService.addEventType(sharedConstants.SUBSCRIPTION_HOME_TAB, 'orgId');
                  SDMAnalyticsService.emit(sharedConstants.SUBSCRIPTION_HOME_TAB, $scope.subscriptionsForOrg.id);
                } catch (error) {
                  $log.error('Error while emitting event: ' + sharedConstants.SUBSCRIPTION_HOME_TAB, e);
                } finally {
                  $scope.$emit('setInitialized', true);
                }
            };

            function closeBanner() {
                $scope.showBanner = false;
                productModel.setBannerNotificationCloseCookie(false);
            }

            // init function
          function onInit() {
            $scope.errorRetrievingData = notificationModel.popMessage();
            $scope.errorFlag = notificationsService.popMessage();
            /** will replace notificationModel eventually */
          }

            onInit();
        }]);