//MB: Referenced from https://blindcoder.wordpress.com/2015/09/08/bootstrap-keyboard-navigable-dropdown-list-with-angularjs/
// Keyboard navigation directive for Bootstrap dropdown
portalModule.directive("focusOnFirstDropdownElement", function() {
    return {
        restrict: 'A',
        link: function (scope, element) {
        element.bind('keypress', 
            function() {
                var list = element.next();
                setTimeout(function() {
                    list.find('a:first').focus();
                }, 100);
            });
        }
    };
});

//Remove focus from all list elements in dropdown on mouserover
portalModule.directive("loseFocus", function() {
    return {
        restrict: 'A',
        link: function (scope, element) {
        element.bind('mouseover', 
            function() {
        		var list = element.parent();
        		var listChildren = list.children();
        		listChildren.find('a').blur();
            });
        }
    };
});