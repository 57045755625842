/**
 * Created by jhouser on 10/21/2015.
 */


portalModule.service("subscriptionModel", function(cookieService,sharedConstants,userModel,orgModel) {

    var dataObject = {
        organizationList : [],
        reset : reset,
        getOrgWithSub : getOrgWithSub,
        lacksSubsAsAdmin : lacksSubsAsAdmin,
        hasSubsAsAdmin : hasSubsAsAdmin,
        isAdminOfOrg : isAdminOfOrg,
        setCookieForOrgAsAdmin : setCookieForOrgAsAdmin,
        getCookieForOrgAsAdmin : getCookieForOrgAsAdmin,
        checkIfUserHasAccessAsAdmin : checkIfUserHasAccessAsAdmin,
        checkIfSubPresentInsideOrg : checkIfSubPresentInsideOrg,
        checkIfHasAccessInAnyOrg : checkIfHasAccessInAnyOrg,
        filterSchoolAdminOrgs : filterSchoolAdminOrgs,
        getSchoolAdminOrgWithSub : getSchoolAdminOrgWithSub,
        filterDistrictAdminOrgs : filterDistrictAdminOrgs,
        filterOrgs : filterOrgs,
        getDistrictAdminOrgWithSub : getDistrictAdminOrgWithSub
    };

    return dataObject;

    function reset(){
        dataObject.organizationList = [];
        delete dataObject.orgAndSubscriptions;
        delete dataObject.selectedSubscriptionIndex;
    };
    
    function getSchoolAdminOrgWithSub(orgId){
    	return dataObject.schoolAdminOrgAndSubscriptions.filter(function(org,index){
    		return org.id == orgId;
    	});
    }
    
    function getDistrictAdminOrgWithSub(orgId){
        return dataObject.districtAdminOrgAndSubscriptions.filter(function(org,index){
            return org.id == orgId;
        });
    }
    
    function getOrgWithSub(orgId){
        return dataObject.orgAndSubscriptions.filter(function(org,index){
            return org.id == orgId;
        });
    }
    
    function checkIfSubPresentInsideOrg(selectedOrgAndSubs,appCode){
    	var subs = selectedOrgAndSubs.subscription;
    	for(var i=0; i<subs.length; i++){
    		if(subs[i].application.applicationCode === appCode){
    			return true;
    		}
    	}
    	return false;
    }
    
    function checkIfHasAccessInAnyOrg(appCode){
    	for(var i=0; i<dataObject.orgAndSubscriptions.length; i++){
			var appExistsInAnyOrg = checkIfSubPresentInsideOrg(dataObject.orgAndSubscriptions[i],appCode);
			if(appExistsInAnyOrg){
				return dataObject.orgAndSubscriptions[i].id;
			}
		}
		return false;
    }
    
    function checkIfUserHasAccessAsAdmin(appCode){
    	if(getCookieForOrgAsAdmin() != undefined){
    		var selectedOrgAndSubs = getOrgWithSub(getCookieForOrgAsAdmin().orgId)[0];
    		var appExistsInCurrentOrg = checkIfSubPresentInsideOrg(selectedOrgAndSubs,appCode);
    		if(appExistsInCurrentOrg){
    			return getCookieForOrgAsAdmin().orgId;
    		}
    		else{
    			return checkIfHasAccessInAnyOrg(appCode);
    		}	
    	}
    	else{
    		return checkIfHasAccessInAnyOrg(appCode);
    	}
    }
    
    function filterOrgs(userType){
        dataObject.schoolAdminOrgAndSubscriptions = [];
        dataObject.districtAdminOrgAndSubscriptions = [];
        for(var i=0; i<dataObject.orgAndSubscriptions.length; i++){
            if(!dataObject.orgAndSubscriptions[i].district){
                dataObject.schoolAdminOrgAndSubscriptions.push(dataObject.orgAndSubscriptions[i]); 
            }
            else{
                dataObject.districtAdminOrgAndSubscriptions.push(dataObject.orgAndSubscriptions[i]);
            }
        }
        if(userType === 'schoolAdmin'){
            return dataObject.schoolAdminOrgAndSubscriptions; 
        }
        else{
            return dataObject.districtAdminOrgAndSubscriptions;
        }  
    }
    
    function filterSchoolAdminOrgs(){
        var filteredSchoolOrgs = filterOrgs('schoolAdmin');
        return filteredSchoolOrgs;
    }
    
    function filterDistrictAdminOrgs(){
        var filteredDistrictOrgs = filterOrgs('districtAdmin');
        return filteredDistrictOrgs;
    }
    
    
    function lacksSubsAsAdmin(){
    	
    	if(dataObject.orgAndSubscriptions){
    		return dataObject.orgAndSubscriptions.length == 0;
    	}
    	return false;
    }
    
    function hasSubsAsAdmin(){
    	
    	if(dataObject.orgAndSubscriptions){
    		return dataObject.orgAndSubscriptions.length > 0;
    	}
    	return false;
    	
    }

    function isAdminOfOrg(orgId){
        if (dataObject.orgAndSubscriptions){
            if (dataObject.orgAndSubscriptions.length <= 0) {
                return false;
            }

            return dataObject.orgAndSubscriptions.filter(function(org) {
                return org.id === orgId;
            }).length > 0;
        }

        return false;
    }
    
    function setCookieForOrgAsAdmin(orgId) {
      var orgAsAdmin = {};

      orgAsAdmin.teacherId = userModel.teacherId;
      orgAsAdmin.orgId = orgId;

      cookieService.saveCookie("orgAsAdmin", orgAsAdmin);

      if (orgId) {
        var currentCalendar = orgModel
          .getCalendarsForOrg(orgId)
          .filter(function(calendar) {
            return calendar.currentCalendar;
          })[0];

        if (!!currentCalendar) {
          window.dumbleDataHelpers.updateSchoolYear(currentCalendar);
          window.dumbleDataHelpers.updateCurrentSchoolYear(currentCalendar);
        }
      }

      var storedId = Volume.getStoreItem("dpOrgId");
      if (storedId !== orgId) {
        Volume.setStoreItem("dpOrgId", orgId);
      }
    }
    
    function getCookieForOrgAsAdmin(){
        var orgAsAdminCookie = cookieService.getCookie('orgAsAdmin');

    	if(orgAsAdminCookie){
        	if(userModel.teacherId == orgAsAdminCookie.teacherId){
        		return orgAsAdminCookie;
        	}
        	else{
        		return undefined;
        	}
    	}
    	return undefined;
    }
    
});
