/**
 * Created by jhouser on 9/18/2015.
 */

function clone(obj) {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());

    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];

    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }

    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};

    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }

    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

portalModule.controller("StudentHomeController", [
    '$scope', 'classService', 'userService', 'classModel', '$uibModal',
    'studentModel', '$location', '$timeout', 'studentService', 'userModel',
    'orgService', '$log', 'orgModel', '$window', 'navModel', 'enrollmentService',
    '$rootScope', 'sharedConstants', '$filter', 'SDMAnalyticsService', 'productService',
    'productModel','settingsService', 'notificationModel', 'gapiService', 'filterFilter',
    '$uibModalStack','$routeParams', 'configService', 'easyLoginModel',
    function (
        $scope, classService, userService, classModel, $uibModal,
        studentModel, $location, $timeout, studentService, userModel,
        orgService, $log, orgModel, $window, navModel, enrollmentService,
        $rootScope, sharedConstants, $filter, SDMAnalyticsService, productService,
        productModel,settingsService, notificationModel, gapiService, filterFilter,
        $uibModalStack, $routeParams, configService, easyLoginModel
    ) {
        $scope.studentsAddedMsg = "";
        $scope.errorMessage = "";
        $scope.errorMessageName = "";
        $scope.errorMessageGrade = "";
        $scope.initialized = false;
        $scope.dataInitialized = true;
        // for the in-line new student group directive
        $scope.newStudentGroupControl = {};
        $scope.editMode = true;
        $scope.showEasyLogin = false;
        $scope.showCoTeacherFeatures = false;
        $scope.showYOYFeatures = false;
        $scope.pageContext = 'STUDENTHOME';

        $scope.isReactTeacherClasses = configService.getCurrentConfigOption("isReactTeacherClasses");
        $scope.volShouldShowClassesPage = '/students' === $location.path();
        $scope.volShouldShowShowDetailsPage = '/students/show-details' === $location.path();
        $scope.volShouldShowEasyLoginPage = '/students/show-details/easy-login' === $location.path();
        $scope.volShouldShowAppsPage = '/students/show-details/apps' === $location.path();

        $scope.volShouldShowStudentDetailsPage = new RegExp("/student/[0-9]+/show", "i").test($location.path());
        $scope.volShouldShowEditStudentPage = new RegExp("/student/[0-9]+/edit", "i").test($location.path());

        function verifyEditStudentsRoute() {
          $scope.volEditStudentId = $routeParams.studentId;
          $scope.volShouldShowAddEditStudentsPage = new RegExp(
            "/students/([0-9]+/)?edit",
            "i"
          ).test($location.path());

          if (
            $scope.volShouldShowStudentDetailsPage &&
            !configService.getCurrentConfigOption("isReactStudentDetails")
          ) {
            $location.path('/students');
          }
        }

        verifyEditStudentsRoute();

        // we need to run the route checker for the "edit students"
        // page, because there'll be local route changes (without
        // redirecting to other pages)
        $scope.$on('$locationChangeSuccess', verifyEditStudentsRoute);

        var $this = this;
        $this.onInit = onInit;
        $this.preInitialize = preInitialize;
        $this.readAllSettings  = readAllSettings;
        $this.loadClassListAsync = loadClassListAsync;
        $this.initClassList = initClassList;
        $this.determineClassInPresentSchoolYear = determineClassInPresentSchoolYear;
        $this.loadSectionStudentsAndEntitlements = loadSectionStudentsAndEntitlements;
        $this.getStudentEnrollmentSuccessHandler = getStudentEnrollmentSuccessHandler;
        $this.getStudentsInClassHandler = getStudentsInClassHandler;
        $this.getStudentEnrollmentErrorHandler = getStudentEnrollmentErrorHandler;
        $this.showOnlyPrimaryTeacherClasses = showOnlyPrimaryTeacherClasses;
        $this.setCoTeacherPermissions = setCoTeacherPermissions;
        $this.findInactiveStudents  = findInactiveStudents;
        $this.errorGettingStudentsInClassHandler = errorGettingStudentsInClassHandler;
        $this.getYOYClassSectionData = getYOYClassSectionData;
        $this.getNonYOYClassSectionData = getNonYOYClassSectionData;
        $this.initScopeFunctions = initScopeFunctions;
        $this.checkIfSchoolYearGreaterThanCurrentYear = checkIfSchoolYearGreaterThanCurrentYear;
        $this.checkIfSchoolYearLessThanCurrentYear = checkIfSchoolYearLessThanCurrentYear;
        $this.calculateDifferenceBetweenYears = calculateDifferenceBetweenYears;
        $this.determineClassInCleverSchool = determineClassInCleverSchool;
        $this.activateStudentSuccessHandler = activateStudentSuccessHandler;
        $this.activateStudentErrorHandler = activateStudentErrorHandler;
        $this.matchGoogleCourses = matchGoogleCourses;
        $this.handleClassList = handleClassList;
        $this.checkInvalidGradesForCleverSchool = checkInvalidGradesForCleverSchool;
        $this.removeCPDCardIfAccessibleFromProducts = removeCPDCardIfAccessibleFromProducts;
        $this.gapiPromise = {};

        $this.currentLoggedInUser = '';
        $this.currentSchoolYear = {};
        // this code is used to verify if the logged in teacher is a Co-Teacher
        // If it is , then make the View invisible
        $scope.isCoTeacherForCurrentClass = false;
        // toggle used by the view to disable functionality if this is a clever school
        // We start out by assuming that the school is not a clever school
        // and when the class changes; we re-evaluate this value
        $scope.cleverSchool = false;
        $scope.classlinkSchool = false;

        // created for DP-2395 to show / hide the add class link
        $scope.allowAddClass = false;

        // added for the modifications in how active/inactive students are shown
        $scope.showingActiveStudents = true;
        $scope.ssoNoClass = false;

        $scope.onlyDeactivatedStudents = false;

        $scope.googleUser = false;
        $scope.googleCourses;
        $scope.importbuttonpresscounter = 0;
        $scope.courses = undefined;
        $scope.importstaffid  = undefined;
        $scope.importsource = undefined;
        $scope.selectedGoogleClasses = [];
        $scope.gradelevels = classModel.gradesArray;
        $scope.schools = undefined;
        $scope.entitlementsLoaded = false;

        $scope.studentList = [];
        $scope.selectedStudents = function () {
            return filterFilter($scope.studentList, {selected: true});
        };

        $scope.$watch('studentList|filter:{selected:true}',function(newSelections, oldSelections, scope){
            if (newSelections !== oldSelections) {
                $scope.bulkActionAllStudentsSelected = (newSelections.length === scope.studentList.length);
            }
        }, true);

        $scope.$on('$locationChangeStart', handleLocationChange);
        function handleLocationChange() {
            $uibModalStack.dismissAll();
        }

        $scope.bulkActionSelectAllToggle = function () {
            $scope.bulkActionAllStudentsSelected = !$scope.bulkActionAllStudentsSelected;
            $scope.studentList.forEach(function(student){
                student.selected = $scope.bulkActionAllStudentsSelected;
            });
        };

        $scope.$on("orgChanged", function($event, orgId) {
          if ($scope.$$phase) {
            Volume.setStoreItem('dpOrgId', orgId);
          } else {
            // force refresh classes page when org is changed
            $scope.$apply(function() {
              Volume.setStoreItem('dpOrgId', orgId);
            });
          }
        });

        function loadClasses() {
          return orgService.getSchoolsWithTeacherRole().then(function(data) {
            $log.debug("Orgs with teacher role", data);
            if (data.length === 0) {
              $log.debug("No schools on user profile");
              $scope.schools = [];
            } else {
              $log.debug("Orgs with teacher role (schools)", data);
              $scope.schools = data;
            }

            if ($scope.showYOYFeatures) {
              // Org id is calculated because, the new GET API expects
              // organization id
              var orgProdCookie;
              var orgId;

              orgProdCookie = productModel.getCookieForOrgAsTeacher();
              if (!!orgProdCookie) {
                orgId = orgProdCookie.orgId;
              } else {
                orgId = $scope.schools[0].id;
              }

              // this is calculated because if there is no class for
              // school year then the name becomes undefined and blank
              $scope.school = {};
              $scope.school.name = orgModel.getOrgName($scope.schools, orgId);

              // below service is now modified to call the new GET API for school calendars
              return orgService
                .getSchoolYearsYOYForTeacher(orgId)
                .then(function(data) {
                  $log.debug("School years associated to the teacher", data);
                  $scope.schoolYears = data;
                  if ($scope.schoolYears.length > 0) {
                    // Set base year to a variable which
                    // is the current calendar year
                    $this.currentSchoolYear = orgModel.setBaseCalendarYear(
                      $scope.schoolYears
                    );

                    if ($this.isRedirectFromCreateClass) {
                      var selectedYear = $scope.schoolYears.filter(function(
                        item
                      ) {
                        if (item.id === $this.redirectCalendarId) {
                          return item;
                        }
                      });
                      $scope.selectedSchoolYear = selectedYear[0];
                    } else if (
                      !!orgProdCookie &&
                      orgProdCookie.schoolCalendarId
                    ) {
                      $scope.selectedSchoolYear = $scope.schoolYears.find(
                        function(schoolYear) {
                          return (
                            schoolYear.id === orgProdCookie.schoolCalendarId
                          );
                        }
                      );

                      if (!$scope.selectedSchoolYear) {
                        $scope.selectedSchoolYear = $this.currentSchoolYear;
                      }
                    } else {
                      // selected School year will be set as the
                      // current calendar year
                      $scope.selectedSchoolYear = $this.currentSchoolYear;
                    }

                    // Remove future school years for GC, CLEVER and LTI users
                    if (
                      $scope.cleverSchool ||
                      $scope.ltiSchool ||
                      $scope.googleUser ||
                      $scope.classlinkSchool
                    ) {
                      $scope.schoolYears = data.filter(function(schoolObject) {
                        if (
                          !$this.checkIfSchoolYearGreaterThanCurrentYear(
                            schoolObject,
                            $this.currentSchoolYear
                          )
                        ) {
                          return schoolObject;
                        }
                      });
                    }
                    return $this.loadClassListAsync($scope.selectedSchoolYear);
                  } else {
                    return $this.loadClassListAsync();
                  }
                });
            } else {
              return orgService.getSchoolYearsForTeacher().then(function(data) {
                $log.debug("School years associated to the teacher", data);
                $scope.schoolYears = data;
                if ($scope.schoolYears.length > 0) {
                  $scope.selectedSchoolYear = $scope.schoolYears[0];
                  return $this.loadClassListAsync(
                    $scope.selectedSchoolYear.code
                  );
                } else {
                  return $this.loadClassListAsync();
                }
              });
            }
          });
        }

        $scope.volSaveStudent = function(selectedClassId, student) {
          return studentService
            .updateStudentToOrg(selectedClassId, student.id, student)
            .then(function(data) {
              $log.debug("Student updated", data);

              return data;
            });
        };

        $scope.volGetOptionValue = function (key) {
          return configService.getCurrentConfigOption(key)
        };

        $scope.volLoadClasses = function() {
          return orgService.getSchoolsWithTeacherRole().then(function(data) {
            $scope.schools = data;

            var orgId = Volume.getStoreItem("dpOrgId");
            if (!orgId) {
              orgId = $scope.schools[0];
              Volume.setStoreItem("dpOrgId", orgId);
            }

            // this is calculated because if there is no class for
            // school year then the name becomes undefined and blank
            $scope.school = {};
            $scope.school.name = orgModel.getOrgName($scope.schools, orgId);

            // below service is now modified to call the new GET API for school calendars
            return orgService
              .getSchoolYearsYOYForTeacher(orgId)
              .then(function(schoolYears) {
                $scope.schoolYears = schoolYears;

                if ($scope.schoolYears.length > 0) {
                  volHandleSchoolYears();

                  return $this.loadClassListAsync($scope.selectedSchoolYear);
                } else {
                  return $this.loadClassListAsync();
                }
              });
          });
        };

        $scope.volLoadSchoolCalendars = function(orgId) {
          return orgService
            .getSchoolYearsYOYForTeacher(orgId)
            .then(function(schoolYears) {
              $log.debug("School years associated to the teacher", schoolYears);
              $scope.schoolYears = schoolYears;

              if ($scope.schoolYears.length > 0) {
                volHandleSchoolYears();
              }

              return schoolYears;
            });
        };

        function volHandleSchoolYears() {
          // Set base year to a variable which
          // is the current calendar year
          $this.currentSchoolYear = orgModel.setBaseCalendarYear(
            $scope.schoolYears
          );

          var calendarId = Volume.getStoreItem("dpCalendarId");
          if (calendarId) {
            $scope.selectedSchoolYear = $scope.schoolYears.find(function(
              schoolYear
            ) {
              return schoolYear.id === calendarId;
            });
          }

          if (!$scope.selectedSchoolYear) {
            $scope.selectedSchoolYear = $this.currentSchoolYear;
            Volume.setStoreItem("dpCalendarId", $this.currentSchoolYear.id);
          }
        }

      function searchForStudent(searchParams) {
        var userId = userModel.currentUser.userIdentifiers.staffId;
        var orgId = productModel.getCookieForOrgAsTeacher().orgId;

        $log.debug(
          "getStudentsSearchResults: userId",
          userId,
          "orgId",
          orgId,
          "params",
          searchParams
        );

        return studentService
          .getStudentsSearchResults(orgId, searchParams)
          .catch(function(error) { return []; });
      }

      $scope.volSearchForStudent = searchForStudent;

      function addExistingStudent(selectedStudent, sectionId) {
        var orgId = productModel.getCookieForOrgAsTeacher().orgId;

        delete selectedStudent.selected;

        if (!selectedStudent.active) {
          selectedStudent.active = true;
        }

        $log.debug(
          "assignStudents sectionId",
          sectionId,
          "selection.id",
          selectedStudent.id,
          "student",
          selectedStudent
        );

        return studentService
          .assignStudents([sectionId], orgId, [selectedStudent.id])
          .then(function(student) {
            $log.debug("added student", student);

            $scope.volLoadClasses();

            return student;
          });
      }

      $scope.volAddExistingStudent = addExistingStudent;

      $scope.volToggleEasyLogin = function(params) {
        if (params.isEasyLoginEnabled !== undefined) {
          $scope.selectedClass.easyLogin.enabled = params.isEasyLoginEnabled;
        }

        if (params.showInEasyLoginSchool !== undefined) {
          $scope.selectedClass.easyLogin.showInEasyLoginSchool = params.showInEasyLoginSchool;
        }

        return classService.updateClassSection($scope.selectedClass).then(
          function(updatedClass) {
            $log.debug("Updated section details", updatedClass);

            var copiedObject = clone($scope.selectedClass);
            copiedObject.easyLogin = updatedClass.easyLogin;
            $scope.selectedClass = copiedObject;

            try {
              if (!classModel.classList) {
                classModel.classList = [];
              }

              SDMAnalyticsService.addEventType(
                sharedConstants.UPDATE_CLASS_EVENT,
                "updatedClass",
                "existingClasses",
                "orgId"
              );

              SDMAnalyticsService.emit(
                sharedConstants.UPDATE_CLASS_EVENT,
                updatedClass,
                classModel.classList,
                updatedClass.organizationId
              );
            } catch (e) {
              $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
            }

            return updatedClass;
          }
        );
      };

      function onInit() {
            $log.debug("In StudentHomeController.onInit");

            $scope.isReactTeacherClasses = configService.getCurrentConfigOption("isReactTeacherClasses");
            navModel.showNav = true;
            navModel.helpPage = false;

            delete $scope.selectedClass;

            if ($scope.showYOYFeatures) {
                // read the route params
                // verify if the values are not
                if (!!$routeParams.classSectionId && !!$routeParams.schoolCalendarId) {
                    $this.isRedirectFromCreateClass = true;
                    $this.redirectCalendarId = $routeParams.schoolCalendarId;
                    $this.newlyCreatedClassId = $routeParams.classSectionId;
                }
            }

            $this.initScopeFunctions();

            userService
              .getCurrentUser()
              .then(function() {
                loadClasses();
                $this.determineClassInCleverSchool();

                navModel.activePage = "classes";

                $scope.isReactTeacherClasses = configService.getCurrentConfigOption(
                  "isReactTeacherClasses"
                );

                Volume.setStoreItem("dpCurrentRole", "teacher");

                $scope.allowAddClass = !userModel.isUserSSO();

                if (
                  !!userModel &&
                  !!userModel.currentUser &&
                  !!userModel.currentUser.staff
                ) {
                  $this.currentLoggedInUser = userModel.currentUser.staff.id;
                } else {
                  $log.debug("Invalid user", userModel.currentUser);
                  userModel.reset();
                  $location.path("/signin/staff");
                  $scope.studentsAddedMsg =
                    "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                }

                if (!!gapiService.isGoogleUser(userModel)) {
                  $scope.allowAddClass = false;
                  // this will also drop a gapiToken cookie
                  $log.debug("Is Google User");
                  $scope.googleUser = true;
                  $scope.importstaffid = userModel.currentUser.userIdentifiers.staffId;
                  $scope.importsource = userModel.currentUser.userIdentifiers.source;
                } else {
                  $log.debug("Is not Google User");
                }

                productService.getSubscriptions().then(function (subscriptions) {
                  $log.debug('Get subscriptions', subscriptions);
                });

                productService.getEntitlement()
                  .then(function (data) {
                    $log.debug('Product entitlements', data);

                    if (productModel.lacksAppsAsTeacher() && !userModel.isUserSSO()) {
                      $location.path('/products');
                    }
                  })
                  .catch(function (error) {
                    var message = sharedConstants.ERROR_PROBLEM_UNKNOWN;

                    if (error && error.message) {
                      message = error.message;
                    }

                    $scope.warningMessage = message;
                  })
                  .finally(function() {
                    $scope.entitlementsLoaded = true;
                  });
              })
              .catch(function(error) {
                $log.debug("Error getting sections for teacher", error);
                if (error.code === "error.not.logged.in") {
                  userModel.reset();
                  $location.path("/signin/staff");
                }
                $scope.studentsAddedMsg =
                  error.message ||
                  "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
              });

            if (!!notificationModel.errorFlag) {
                if (!$scope.warningMessage) {
                    $scope.warningMessage = notificationModel.popMessage();
                }
            } else {
                if (!$scope.studentsAddedMsg){
                    $scope.studentsAddedMsg = notificationModel.popMessage();
                }
            }
        }

        function loadClassListAsync(schoolYear) {
            if ($scope.showYOYFeatures) {
                return $this.getYOYClassSectionData(schoolYear);
            } else {
                return $this.getNonYOYClassSectionData(schoolYear);
            }
        }

        function getNonYOYClassSectionData(schoolYear) {
            return classService.getClassesForTeacher(schoolYear).then(function (classList) {
                if (!classList || classList.length === 0) {
                    if ($scope.cleverSchool || $scope.ltiSchool || $scope.classlinkSchool) {
                        $scope.initialized = false;
                        $scope.ssoNoClass = true;
                        $scope.ssoNoClassMessage = sharedConstants.CLEVER_NO_CLASS_MESSAGE;
                    } else {
                        $scope.initialized = true;
                        if ($scope.googleUser) {
                            gapiService.refreshPage('onboarding');
                        } else {
                            $scope.createUpdateClass('create');
                        }
                    }

                    return [];
                } else {
                    $scope.ssoNoClass = false;
                    $this.initClassList(classList);

                    return classList;
                }
            }, function (error) {
                $log.debug('Error getting sections for teacher', error);
                if (error.code === 'error.not.logged.in') {
                    userModel.reset();
                    $location.path('/signin/staff');
                }
                $scope.studentsAddedMsg = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
            });
        }

        function getYOYClassSectionData(schoolYear) {
            return classService.loadClassSectionsForACalendar(schoolYear, $scope.isReactTeacherClasses).then(function (classList) {
                $log.debug('classList', classList);
                if (!classList || classList.length === 0) {
                    if ($scope.cleverSchool || $scope.ltiSchool || $scope.classlinkSchool) {
                        $scope.initialized = false;
                        $scope.ssoNoClass = true;
                        $scope.ssoNoClassMessage = sharedConstants.CLEVER_NO_CLASS_MESSAGE;
                    } else {
                        $scope.initialized = true;
                        if ($scope.googleUser) {
                            $scope.noClassForCurrentSchool = true;
                            if (!!schoolYear && schoolYear.currentCalendar) {
                                $scope.showAddClassPanel = true;
                            } else {
                                $scope.isSchoolYearGreater = $this.checkIfSchoolYearGreaterThanCurrentYear(schoolYear,$this.currentSchoolYear);
                                $scope.isSchoolYearLess = $this.checkIfSchoolYearLessThanCurrentYear(schoolYear,$this.currentSchoolYear);

                                $scope.showAddClassPanel = !!$scope.isSchoolYearGreater;
                            }
                        } else {
                            if (schoolYear.currentCalendar) {
                                $scope.noClassForCurrentSchool = true;
                                $scope.showAddClassPanel = true;
                            } else {
                                $scope.isSchoolYearGreater = $this.checkIfSchoolYearGreaterThanCurrentYear(schoolYear,$this.currentSchoolYear);
                                $scope.isSchoolYearLess = $this.checkIfSchoolYearLessThanCurrentYear(schoolYear,$this.currentSchoolYear);

                                if ($scope.isSchoolYearGreater) {
                                    $scope.noClassForCurrentSchool = true;
                                    $scope.showAddClassPanel = true;
                                } else {
                                    $scope.noClassForCurrentSchool = true;
                                    $scope.showAddClassPanel = false;
                                }
                            }
                        }
                    }

                    return {
                      classesData: [],
                      schoolYearsData: $scope.schoolYears
                    };
                } else {
                    if ($scope.googleUser) {
                        $scope.initialized = true;
                        $scope.noClassForCurrentSchool = true;
                        $scope.showAddClassPanel = true;
                    } else {
                        $scope.noClassForCurrentSchool = false;
                        $scope.showAddClassPanel = false;
                    }
                    $scope.ssoNoClass = false;
                    $scope.isSchoolYearGreater = $this.checkIfSchoolYearGreaterThanCurrentYear(schoolYear, $this.currentSchoolYear);
                    $scope.isSchoolYearLess = $this.checkIfSchoolYearLessThanCurrentYear(schoolYear,$this.currentSchoolYear);

                    return $this.initClassList(classList);
                }
            }, function (error) {
                $log.debug('Error getting sections for teacher', error);
                if (error.code === 'error.not.logged.in') {
                    userModel.reset();
                    $location.path('/signin/staff');
                }
                $scope.studentsAddedMsg = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
            });
        }

        function  checkIfSchoolYearGreaterThanCurrentYear(newSchoolYear,currentSchoolYear) {
            return $this.calculateDifferenceBetweenYears(newSchoolYear,currentSchoolYear) > 0;
        }

        function checkIfSchoolYearLessThanCurrentYear(newSchoolYear,currentSchoolYear) {
            return $this.calculateDifferenceBetweenYears(newSchoolYear, currentSchoolYear) < 0;
        }

        function calculateDifferenceBetweenYears(newSchoolYear,currentSchoolYear){
           return (moment(newSchoolYear.endDate).year() - moment(currentSchoolYear.endDate).year());
        }

        //MB: Load the subscriptions and students
        function loadSectionStudentsAndEntitlements() {
          if (!$scope.selectedClass) {
            return false;
          }

          return enrollmentService
            .getStudentEnrollment($scope.selectedClass.id)
            .then(
              getStudentEnrollmentSuccessHandler,
              getStudentEnrollmentErrorHandler
            );
        }

        function loadEntitlements(classId) {
          if (!classId) return false;

          return enrollmentService.getStudentEnrollment(classId)
        }

        $scope.volLoadEntitlements = loadEntitlements;

        function getStudentsInClassWithAvatars(selectedClass) {
            if (!selectedClass) return Promise.resolve([]);

            return studentService.getStudentsInClass(selectedClass.id, true).then(function(studentList){
                $log.debug('Students belonging to the class', studentList);

                var filteredStudentList = $filter('filter')(studentList, { active: true });

                if (selectedClass.easyLogin.enabled){
                    var easyLoginAvatars = easyLoginModel.getEasyLoginAvatars();
                    var easyLoginSecrets = easyLoginModel.getEasyLoginSecrets();

                    for (var student in filteredStudentList) {
                        for(var avatar in easyLoginAvatars) {
                            if (filteredStudentList[student].credentials.easyLoginAvatarId == easyLoginAvatars[avatar].id) {
                                filteredStudentList[student].avatar = easyLoginAvatars[avatar];
                                break;
                            }
                        }

                        for (var secret in easyLoginSecrets) {
                            if (filteredStudentList[student].credentials.easyLoginSecret == easyLoginSecrets[secret].id) {
                                filteredStudentList[student].secret = easyLoginSecrets[secret];
                                break;
                            }
                        }
                    }
                }

                return filteredStudentList;
            }, function(error){
                $log.debug('Error getting students in class', error);
            });
        }

        $scope.volGetStudentsInClassWithAvatars = getStudentsInClassWithAvatars;

        function getAvatar (selectedClass) {
            var schoolEasyLoginAvatars = easyLoginModel.getSchoolEasyLoginAvatars();

            if (!(selectedClass && selectedClass.easyLogin && selectedClass.easyLogin.enabled)) {
                return {};
            }

            let avatar;
            for (var index in schoolEasyLoginAvatars) {
                if (selectedClass.easyLogin.iconIndex == schoolEasyLoginAvatars[index].id) {
                    avatar = schoolEasyLoginAvatars[index];
                    break;
                }
            }

            return avatar || {};
        }

        function getEasyLoginInfo(selectedClass) {
            var host = $location.host();
            var finalHostUrl = host === "localhost" || host === "l.scholastic-labs.io"
                ? location.host + "/dp-portal"
                : host;

            var info = {
                finalHostUrl: finalHostUrl,
                // teacherDisplayName: userModel.currentUser.staff.lastName,
                teacherDisplayName: 'userModel.currentUser.staff.lastName',
                avatar: getAvatar(selectedClass),
                easyLoginEnabled: false,
            };

            if(!(selectedClass && selectedClass.easyLogin && selectedClass.easyLogin.enabled)) {
                return Promise.resolve(info);
            }

            info.easyLoginEnabled = true;
            info.easyLoginUrl = finalHostUrl + '/#/cz/' + selectedClass.id + '/' + selectedClass.easyLogin.token;
            info.schoolEasyLoginEnabled = false;
            info.displayName = selectedClass.displayName || '';
            info.nickname = selectedClass.nickname || '';

            if (selectedClass.easyLogin.showInEasyLoginSchool) {
                return classService.getSchoolEasyLoginCred(selectedClass.organizationId).then(function(data) {
                    info.schoolEasyLoginCred = data;
                    info.easyLoginUrl = finalHostUrl + '/#/sz/' + data.orgId + '/' + data.token;
                    info.schoolEasyLoginEnabled = true;

                    return info;
                }, function(error) {
                    return info;
                });
            } else {
                return Promise.resolve(info);
            }
        }

        $scope.volGetEasyLoginInfo = getEasyLoginInfo;

        function upsertStudentsSuccessHandler(students) {
          $log.debug("upsertStudentsSuccess", students);

          $scope.volLoadClasses();

          return students;
        }

        function upsertStudent(student) {
          var students = [student];

          $log.debug("upsertStudents students", students);

          if (productModel.isSRCTeacher) {
            return studentService
              .upsertStudents(students, $scope.selectedClass.id)
              .then(upsertStudentsSuccessHandler);
          }

          return studentService
            .upsertStudentsToOrg(students, $scope.selectedClass.id)
            .then(upsertStudentsSuccessHandler);
        }

        $scope.volAddNewStudent = upsertStudent;

        function getShowEasyLogin () {
            return userService.getCurrentUser().then(function() {
                return settingsService.getSettings().then(function(settings) {
                    return settings.showEasyLogin;
                });
            });
        }

        $scope.volGetShowEasyLogin = getShowEasyLogin;

        function getStudentsInClassHandler(students) {
            $log.debug("In getStudentsInClassHandler. students: ", students);
            studentModel.studentList = students;
            //		$scope.studentList = students;
            // remove inactive users from this list
            $scope.studentList = studentModel.filterActiveStudents(true);
            $scope.showingActiveStudents = true;
            $this.findInactiveStudents();
            if ($scope.studentList.length === 0 && studentModel.studentList.length !== 0) {
                $scope.onlyDeactivatedStudents = true;
                $scope.toggleActiveStudents();
            } else {
                $scope.onlyDeactivatedStudents = false;
            }
            $scope.studentArray = [{
                student: {
                    grade: 'Select Grade'
                }
            }];
            $scope.dataInitialized = true;
        }

        $scope.volSetActive = function(isActive, classSection) {
          var classCopy = classSection || angular.copy($scope.selectedClass);

          // make the class inactive
          classCopy.active = isActive;

          // call update handler
          return classService
            .updateClassSection(classCopy)
            .then(function(data) {
              // refetch classes only if the `classSection` is NOT provided. Otherwise, if it is provided,
              // don't reload classes from the AngularJS code. They'll be reloaded from the React code instead
              if (!classSection) {
                $scope.volLoadClasses();
              }

              return data;
            });
        };

        // helper function for determining if the current class has inactive students
        function findInactiveStudents() {
            // If the local student list is equal to the full student list then assume there are no inactive students
            // technically we could have all inactive students; but that seems unlikely and the page isn't built for that use case
            if ($scope.studentList.length === studentModel.studentList.length) {
                $scope.hasInactiveStudents = false;
            } else {
                $scope.hasInactiveStudents = true;
            }
        }

        function errorGettingStudentsInClassHandler(data) {
            $log.error("In errorGettingStudentsInClassHandler. data: ", data);
            $scope.dataInitialized = true;
            $scope.initialized = true;
        }

        function initScopeFunctions() {

            $scope.activateClass = function () {
                // make the class inactive
                // make a copy of the class because we don't want to modify the UI until we get a resopnse back from the server
                var classToChange = angular.copy($scope.selectedClass);
                classToChange.active = true;
                // call update handler
                classService.updateClassSection(classToChange).then(function (data) {
                    $log.error('In updateClassSectionSuccessHandler', data);

                    $scope.selectedClass.active = true;
                    $scope.studentsAddedMsg = 'Class has been restored.';


                }, function (error) {
                    $log.error('In updateClassSectionErrorHandler', error);
                    $scope.studentsAddedMsg = error.message || 'An error occurred. Please try again.';
                });

            };


            $scope.checkEntitlement = function (studentId, subId) {
                var subsEntitlement = $scope.entitlements[subId];

                if (!subsEntitlement) {
                    return false;
                }

                for (var i = 0; i < subsEntitlement.length; i++) {
                    if (subsEntitlement[i] === studentId) {
                        return true;
                    }
                }

                return false;
            };

            //MB: Check if all students are already enrolled for a particular subscription
            $scope.checkAllEntitlements = function (subId) {
                var subsEntitlement = $scope.entitlements[subId];

                if (!subsEntitlement) {
                    return false;
                }

                return subsEntitlement.length === $scope.studentList.length;
            };

            //MB: Check if there are any licenses left for per student subscription and if not then disable the individual checkboxes
            $scope.checkQuantity = function (quantity, licenseType, studentId, subscriptionId) {
                //TODO: Figure out a way to find out if checkbox is checked or not instead of calling the function
                var checkboxValue = $scope.checkEntitlement(studentId, subscriptionId);
                return (licenseType === 'student' && quantity === 0 && !checkboxValue);
            };

            //MB: Check if licenses are sufficient for entire class and if not then disable the checkAll checkbox
            $scope.checkAllQuantity = function (quantity, licenseType, subscriptionId) {
                //TODO: Figure out a way to find out if checkbox is checked or not instead of calling the function
                var checkboxValue = $scope.checkAllEntitlements(subscriptionId);
                return (licenseType === 'student' && (quantity === 0 || quantity < $scope.studentList.length) && !checkboxValue);
            };

            $scope.volToggleAllEntitlements = function(subscriptionId, areToggled) {
              var promise;

              if (areToggled) {
                promise = enrollmentService
                  .addAllStudentsSubscription($scope.selectedClass.id, subscriptionId)
                  .then(function(data) {
                    loadClasses();

                    return data;
                  });

                // MB: Analytics code for adding event of providing access to all students for particular product
                try {
                  SDMAnalyticsService.addEventType(
                    sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT,
                    "selectedClass",
                    "subscriptionId",
                    "subscriptions"
                  );

                  SDMAnalyticsService.emit(
                    sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT,
                    $scope.selectedClass,
                    subscriptionId,
                    $scope.subscriptions
                  );
                } catch (e) {
                  $log.debug(sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR);
                }
              } else {
                promise = enrollmentService
                  .validateStudentSubscription($scope.selectedClass.id, subscriptionId)
                  .then(function() {
                    var response = enrollmentService.deleteAllStudentsSubscription(
                      $scope.selectedClass.id,
                      subscriptionId
                    );

                    loadClasses();

                    return response;
                  });

                // MB: Analytics code for adding event of removing access of all students for particular product
                try {
                  SDMAnalyticsService.addEventType(
                    sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT,
                    "selectedClass",
                    "subscriptionId",
                    "subscriptions"
                  );

                  SDMAnalyticsService.emit(
                    sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT,
                    $scope.selectedClass,
                    subscriptionId,
                    $scope.subscriptions
                  );
                } catch (e) {
                  $log.debug(sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR);
                }
              }

              return promise;
            };

          $scope.volToggleStudentEnrollment = function(studentId, subscriptionId, isToggled) {
            var promise;

            var subscriptionsCopy = clone($scope.subscriptions);
            var entitlementsCopy = clone($scope.entitlements);

            if (isToggled) {
              promise = enrollmentService
                .addStudentSubscription($scope.selectedClass.id, subscriptionId, studentId)
                .then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);

              for (var i = 0; i < subscriptionsCopy.length; i++) {
                if (subscriptionsCopy[i].subscription.id === subscriptionId) {
                  subscriptionsCopy[i].subscription.license.remaining--;

                  break;
                }
              }

              entitlementsCopy[subscriptionId].push(studentId);
            } else {
              var index = $scope.entitlements[subscriptionId].indexOf(studentId);

              promise = enrollmentService
                .deleteStudentSubscription($scope.selectedClass.id, subscriptionId, studentId)
                .then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);

              if (index > -1) {
                entitlementsCopy[subscriptionId].splice(index, 1);

                for (var i = 0; i < subscriptionsCopy.length; i++) {
                  if (subscriptionsCopy[i].subscription.id === subscriptionId) {
                    subscriptionsCopy[i].subscription.license.remaining++;

                    break;
                  }
                }
              }
            }

            $scope.subscriptions = subscriptionsCopy;
            $scope.entitlements = entitlementsCopy;

            return promise;
          };

            $scope.checkAll = function (subscriptionId, $event) {
                $this.currentEvent = $event;
                if ($event.target.checked === true) {
                    var subCount = 0;
                    enrollmentService.addAllStudentsSubscription($scope.selectedClass.id, subscriptionId).then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);
                    for (var i = 0; i < $scope.studentList.length; i++) {
                        if ($scope.entitlements[subscriptionId].indexOf($scope.studentList[i].id) === -1) {
                            $scope.entitlements[subscriptionId].push($scope.studentList[i].id);
                            subCount++;
                        }
                    }
                    for (var i = 0; i < $scope.subscriptions.length; i++) {
                        if ($scope.subscriptions[i].subscription.id === subscriptionId) {
                            //MB: Subtracting the number of licenses remaining with the number of students and adding the count of number of students
                            //which were already there before the checkAll checkbox was clicked. Sort of workaround to match the numbers in DB as
                            //well as UI.
                            $scope.subscriptions[i].subscription.license.remaining -=  subCount;
                            $scope.studentsAddedMsg = 'Congratulations! Your class is now set up to use ' + $scope.subscriptions[i].application.name;
                            break;
                        }
                    }

                    //MB: Analytics code for adding event of providing access to all students for particular product
                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT, 'selectedClass', 'subscriptionId', 'subscriptions');
                        SDMAnalyticsService.emit(sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT, $scope.selectedClass, subscriptionId, $scope.subscriptions);
                    } catch (e) {
                        $log.debug(sharedConstants.PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR);
                    }

                } else {
                    enrollmentService.validateStudentSubscription($scope.selectedClass.id, subscriptionId).then(verifyStudentSubscriptionsHandler,updateStudentSubscriptionErrorHandler);
                    $this.subscriptionId = subscriptionId;
                    //MB: Analytics code for adding event of removing access of all students for particular product
                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT, 'selectedClass', 'subscriptionId', 'subscriptions');
                        SDMAnalyticsService.emit(sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT, $scope.selectedClass, subscriptionId, $scope.subscriptions);
                    } catch (e) {
                        $log.debug(sharedConstants.REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR);
                    }
                }
            };

            //MB: Update individual checkbox by calling the api passing the studentId and subscriptionId
            $scope.updateEnrollment = function (id, subId, $event) {
                if ($event.target.checked === true) {
                    enrollmentService.addStudentSubscription($scope.selectedClass.id, subId, id).then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);
                    for (var i = 0; i < $scope.subscriptions.length; i++) {
                        if ($scope.subscriptions[i].subscription.id === subId) {
                            $scope.subscriptions[i].subscription.license.remaining--;
                            break;
                        }
                    }
                    $scope.entitlements[subId].push(id);
                } else {
                    var index = $scope.entitlements[subId].indexOf(id);
                    enrollmentService.deleteStudentSubscription($scope.selectedClass.id, subId, id).then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);

                    if (index > -1) {
                        $scope.entitlements[subId].splice(index, 1);

                        for (var i = 0; i < $scope.subscriptions.length; i++) {
                            if ($scope.subscriptions[i].subscription.id === subId) {
                                $scope.subscriptions[i].subscription.license.remaining++;
                                break;
                            }
                        }
                    }
                }
            };

            $scope.importCSVWindow = function () {
                $uibModal.open({
                    templateUrl: 'resources/js/Modules/students/importCSVPopup/importCSVPopup.html',
                    controller: 'ImportCSVPopupController',
                    resolve: {
                        selectedClass: function () {
                            return $scope.selectedClass;
                        }
                    }
                }).result.then(function (data) {
                    $this.loadSectionStudentsAndEntitlements();
                    var successMessage = data;
                    if ($scope.subscriptions && $scope.subscriptions.length > 0 && successMessage !== '') {
                        successMessage += ". You can manage their access to Scholastic products using the checkboxes below.";
                    }
                    $scope.studentsAddedMsg = successMessage;
                });
            };

            $scope.openNewStudentWindow = function () {
                $uibModal.open({
                    templateUrl: 'resources/js/Modules/students/newStudentGroupPopup/NewStudentGroupPopup.html',
                    controller: 'newStudentGroupPopupController',
                    resolve: {
                        selectedClass: function () {
                            return $scope.selectedClass;
                        }
                    },
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (data) {
                    var successMessage = data.message;

                    if (successMessage === 'csv') {
                        $scope.importCSVWindow();
                        return;
                    }

                    if ($scope.subscriptions && $scope.subscriptions.length > 0 && successMessage !== '') {
                        successMessage += ". You can manage their access to Scholastic products using the checkboxes below.";
                    }

                    $scope.studentsAddedMsg = successMessage;

                    // if data.students is null / blank then the box was just cancelled w/o saving so don't try to add new students
                    if (data.students) {
                        //$scope.studentList = $scope.studentList.concat(data.students);
                        //MB: Reloading the students over here as it was breaking the edit student functionality
                        $this.loadSectionStudentsAndEntitlements();
                    }
                });
            };

            $scope.onViewEditStudent = function (student) {
                // if this is a clever school do not allow editing
                if ($scope.cleverSchool) {
                    return false;
                }

                $location.path('/students/editStudent/' + $scope.selectedClass.id + '/' + student.id  );
            };

            $scope.onPrintRequest = function () {
                $location.path('/students/print/' + $scope.selectedClass.id);
            };

            $scope.onExportStudentRequest = function () {
                $log.debug('Export csv request for class:', $scope.selectedClass);

                userService.getCurrentUserWithoutRedirect().then(function (data) {
                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.EXPORT_STUDENT_CSV_EVENT, 'selectedClass');
                        SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.EXPORT_STUDENT_CSV_EVENT, $scope.selectedClass);
                    } catch (e) {
                        $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.EXPORT_STUDENT_CSV_EVENT, e);
                    }

                    $log.debug('User is signed in. Exporting list of students', data);
                    $window.location = PORTAL_API_ENDPOINT + "/my/roster/sections/" + $scope.selectedClass.id + "/students/export?format=csv";
                }, function (error) {
                    $log.debug('User is not signed in. Redirecting to staff login page', error);
                    userModel.reset();
                    $location.path('/signin/staff');
                });
            };

            $scope.createUpdateClass = function(sectionId) {
              if ($scope.showYOYFeatures) {
                var calendarId = $scope.selectedSchoolYear.id;
                Volume.setStoreItem("dpCalendarId", calendarId);

                if (sectionId) {
                  $location.path(
                    "/students/createclass/" + calendarId + "/edit/" + sectionId
                  );
                } else {
                  $location.path("/students/createclass/" + calendarId);
                }
              } else {
                $location.path("/students/createclass");
              }
            };

            $scope.volCreateUpdateClass = function(sectionId) {
              // $scope.apply(...) is needed to immediately invoke the redirect
              // when the method is called from outside the angularjs
              $scope.$apply(function() {
                $scope.createUpdateClass(sectionId);
              });
            };

            $scope.volEditStudents = function(studentId, toAddEdit) {
              $scope.$apply(function() {
                if (studentId === undefined) {
                  $location.path("/students/edit");
                } else {
                  var prefix = !!toAddEdit ? "/students/" : "/student/";
                  $location.path(prefix + studentId + "/edit");
                }

                $location.replace();
              });
            }

            $scope.volNavigateToEditStudents = function(selectedClass) {
              $scope.selectedClass = selectedClass;
              $scope.buttonname = $scope.selectedClass.displayName;
              $scope.classSectionId = $scope.selectedClass.id;

              classModel.selectedClassId = $scope.selectedClass.id;
              if (!$scope.selectedClass.gradeArray) {
                  $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
              }

              $scope.school = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
              userModel.currentOrg = $scope.school;

              productModel.setCookieForOrgAsTeacher($scope.school.id, $scope.classSectionId, $scope.selectedClass.schoolCalendarId);
              $scope.volLoadEntitlements(selectedClass.id);
              $location.path('/students/edit');
            };

            $scope.volNavigateTo = function(path, shouldReplace = false) {
              $scope.$apply(function() {
                $location.path(path);

                if (shouldReplace) $location.replace();
              });
            };

            function saveStudentToSection (classId, studentId, student) {
              return studentService.updateStudent(classId, studentId, student)
            }

            function saveStudentToOrg (classId, studentId, student) {
              return studentService.updateStudentToOrg(classId, studentId, student);
            }

            $scope.volUpdateStudent = function(student, classId, studentId) {
              const promise =
                $scope.showYOYFeatures && !productModel.isSRCTeacher
                  ? saveStudentToOrg(classId, studentId, student)
                  : saveStudentToSection(classId, studentId, student);

              return promise.then(data => {
                $scope.volLoadClasses();

                return data;
              });
            };

            $scope.volGetSchoolEasyLoginCred = function(orgId) {
              return classService.getSchoolEasyLoginCred(orgId);
            };

            $scope.changeClass = function (selectedClass) {
                $log.debug("Selected Class value:", selectedClass);

                if (!$scope.isReactTeacherClasses && (selectedClass && selectedClass.id === $scope.selectedClass.id)) {
                    return;
                }

                $scope.dataInitialized = false;
                $scope.selectedClass = selectedClass;
                $scope.buttonname = $scope.selectedClass.displayName;
                $scope.classSectionId = $scope.selectedClass.id;

                // setup teacher access
                $this.setCoTeacherPermissions(selectedClass);

                $this.determineClassInCleverSchool();
                $scope.subsAndStudentData = false;
                $scope.showingActiveStudents = true;
                delete $scope.errorMsg;
                $scope.errorMessageName = "";
                $scope.errorMessageGrade = "";
                classModel.selectedClassId = $scope.selectedClass.id;
                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }
                $scope.studentsAddedMsg = "";
                $this.loadSectionStudentsAndEntitlements();
                $scope.school = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
                productModel.setCookieForOrgAsTeacher($scope.school.id, $scope.classSectionId, $scope.selectedClass.schoolCalendarId);
                userModel.currentOrg = $scope.school;

                // in case this was a class in in-line edit mode validation screen; be sure to switch it back to 'enter' mode.
                $scope.editMode = true;
                $this.determineClassInPresentSchoolYear();

                // validations for clever school
                if ($scope.cleverSchool) {
                    $this.checkInvalidGradesForCleverSchool($scope.selectedClass);
                }

                try {
                    SDMAnalyticsService.addEventType(sharedConstants.CLASS_CHANGED_STUDENT_TAB, 'orgId');
                    SDMAnalyticsService.emit(sharedConstants.CLASS_CHANGED_STUDENT_TAB, $scope.school.id);
                } catch (e) {
                    $log.error('Error while emitting event: ' + sharedConstants.CLASS_CHANGED_STUDENT_TAB, e);
                }

                if (!!$scope.isReactTeacherClasses) {
                  Volume.setStoreItem('dpClassId', selectedClass.id);
                  $scope.volLoadEntitlements(selectedClass.id);
                  $location.path('/students/show-details');
                }
            };

            $scope.closeAlert = function (alert) {
                if (!alert) {
                    $scope.studentsAddedMsg = '';
                    $scope.warningMessage = '';
                    return ;
                }

                if (alert === 'success') {
                    $scope.studentsAddedMsg = '';
                } else if (alert === 'error') {
                    $scope.warningMessage = '';
                } else {
                    return false;
                }
            };

            //JH: Copied from NewStudentGroupPopup.js
            $scope.validateStudents = function () {
                $scope.newStudentGroupControl.validate();
            };

            $scope.addStudents = function () {
                $scope.newStudentGroupControl.addStudents();
            };

            $scope.backToEditMode = function () {
                $scope.editMode = true;
            };

            $scope.upsertStudentsSuccessHandler = function (students) {
                $this.loadSectionStudentsAndEntitlements();

                var num = 'student';
                if ($scope.studentArray.length > 1) {
                    num += 's';
                }

                var successMessage = "Successfully added " + $scope.studentArray.length + " " + num;
                if ($scope.subscriptions && $scope.subscriptions.length > 0) {
                    successMessage += ". You can manage their access to Scholastic products using the checkboxes below.";
                }

                $scope.studentsAddedMsg = successMessage;
            };

            $scope.toggleActiveStudents = function () {
                $scope.showingActiveStudents = !$scope.showingActiveStudents;
                $scope.studentList = studentModel.filterActiveStudents($scope.showingActiveStudents);
            };

            $scope.onActivateStudent = function (student) {
                var newStudent = studentModel.copyStudent(student);
                newStudent.active = true;

                studentService.updateStudent($scope.selectedClass.id, newStudent.id, newStudent)
                    .then($this.activateStudentSuccessHandler, $this.activateStudentErrorHandler);
            };

            $scope.viewExistingClasses = function() {
                $scope.noClassForCurrentSchool = false;
                $scope.selectedClass = $scope.classList[0];
                // If there are no classes for a school and teacher decides to view existing classes
                // initialize the teacher section
                $scope.teacherInitialized = true;
                $scope.initialized = false;
                $this.setCoTeacherPermissions($scope.selectedClass);

                $scope.buttonname = $scope.classList[0].displayName;
                $scope.classSectionId = $scope.classList[0].id;
                $log.debug("In enrollmentHome. Value of $scope.selectedClass", $scope.selectedClass);
                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }
                $this.loadSectionStudentsAndEntitlements();
                $scope.school = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
                productModel.setCookieForOrgAsTeacher($scope.school.id, $scope.classSectionId, $scope.selectedClass.schoolCalendarId);
                userModel.currentOrg = $scope.school;
                $this.determineClassInPresentSchoolYear();

                try {
                    SDMAnalyticsService.addEventType(sharedConstants.STUDENT_HOME_TAB, 'orgId');
                    SDMAnalyticsService.emit(sharedConstants.STUDENT_HOME_TAB, $scope.school.id);
                } catch (e) {
                    $log.error('Error while emitting event: ' + sharedConstants.STUDENT_HOME_TAB, e);
                }
            };

            $scope.changeSchoolYear = function (year) {
                $log.debug('Selected school year', year);

                if (!year ||  (year.id === $scope.selectedSchoolYear.id)) {
                    return;
                }

                $scope.selectedSchoolYear = year;

                if ($scope.showYOYFeatures) {
                    $scope.initialized = false;
                    $scope.noClassForCurrentSchool = false;
                    $this.isRedirectFromCreateClass = false;
                    $this.loadClassListAsync($scope.selectedSchoolYear);
                } else {
                    $this.loadClassListAsync($scope.selectedSchoolYear.code);
                }

                $scope.volChangeSchoolYear(year);
            };

            $scope.volChangeSchoolYear = function (year) {
              if (!year ||  (year.id === $scope.selectedSchoolYear.id)) {
                return;
              }

              var orgId = Volume.getStoreItem('dpOrgId');
              var classId = Volume.getStoreItem('dpClassId');

              $scope.selectedSchoolYear = year;
              productModel.setCookieForOrgAsTeacher(orgId, classId, year.id);
            };

            $scope.changeSettings = function (value) {
                $location.path('/students/settings/' + $scope.selectedClass.id + '/' + value  );
            };

            $scope.goBackToDashboard = function () {
                $location.path('/products');
            };

            $scope.showBulkActionsDropdown = function() {
                return $scope.showYOYFeatures &&        // YOY feature
                    !$scope.readOnlyClass &&            // Class is in current or future year
                    !$scope.cleverSchool &&             // Clever classes manage their own rosters
                    !$scope.classlinkSchool &&          // Classlink classes manage their own rosters
                    !$scope.ltiSchool &&                // LTI classes manage their own rosters
                    !$scope.googleUser &&               // Google Classrooms manage their own rosters
                    !$scope.isCoTeacherForCurrentClass; // Only primary teachers can remove students
            };

            $scope.disableBulkActionsDropdown = function() {
                return $scope.selectedStudents().length <= 0;
            };

            $scope.confirmBulkRemoveStudents = function () {
                var students = $scope.selectedStudents();

                if (!students.length) {
                    return false;
                }

                $uibModal.open({
                        templateUrl: 'resources/js/Modules/students/confirmRemoveStudentPopup/confirmRemoveStudentPopup.html',
                        controller: 'confirmRemoveStudentPopupController',
                        scope: $scope,
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function (data) {
                        if (data && data.message && data.message === 'remove') {
                            $scope.closeAlert();

                            var studentIds = students.map(function (student) {
                                return student.id;
                            });

                            var orgId = productModel.getCookieForOrgAsTeacher().orgId;

                            if (!orgId) {
                                orgId = $scope.schools[0].id;
                            }

                            studentService.unassignStudents($scope.selectedClass.id, orgId, studentIds)
                                .then(function () {
                                    $this.loadSectionStudentsAndEntitlements();
                                    $scope.studentsAddedMsg = 'Successfully removed ' + students.length + ' student(s) from ' + $scope.selectedClass.displayName + '.';
                                })
                                .catch(function (error) {
                                    $log.error('error removing students', students, error);
                                    var message = 'Error removing student(s). Please try again.';

                                    if (error.message) {
                                        message = error.message;
                                    }

                                    $scope.warningMessage = message;
                                });
                        }
                    });

                return students;
            };

            $scope.volRemoveStudentFromClass = function(studentId) {
              var orgId = productModel.getCookieForOrgAsTeacher().orgId;

              if (!orgId) {
                orgId = $scope.schools[0].id;
              }

              studentService
                .unassignStudents($scope.selectedClass.id, orgId, [studentId])
                .then(function(data) {
                  $this.loadSectionStudentsAndEntitlements();

                  return data;
                });
            };

            $scope.confirmAssignGradeLevel = function () {
                var students = $scope.selectedStudents();

                if (!students.length) {
                    return false;
                }

                $uibModal.open({
                    templateUrl: 'resources/js/Modules/students/assignGradeLevelPopup/assignGradeLevelPopup.html',
                    controller: 'assignGradeLevelPopupController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        grades: function () {
                            return $scope.selectedClass.gradeArray;
                        }
                    }
                }).result.then(function (data) {
                    if (data && data.message && data.message === 'assign') {
                        $scope.closeAlert();

                        var studentsToUpdate = [];

                        students.forEach(function(student){
                            var studentToUpdate = studentModel.copyStudent(student);
                            studentToUpdate.grade = data.grade.value;
                            studentsToUpdate.push(studentToUpdate);
                        });

                        studentService.updateStudents($scope.selectedClass.id, studentsToUpdate)
                            .then(function () {
                                $this.loadSectionStudentsAndEntitlements();
                                $scope.studentsAddedMsg = 'Successfully assigned ' + students.length + ' student(s) to ' + data.grade.label + '.';
                            })
                            .catch(function (error) {
                                $log.error('error assigning students grade level', students, error);
                                var message = 'Error assigning grade level for student(s). Please try again.';

                                if (error.message) {
                                    message = error.message;
                                }

                                $scope.warningMessage = message;
                            });
                    }
                });

                return students;
            };

            $scope.setInitialized = function() {
                $scope.initialized = true;
            };
        } // End initScopeFunctions

        function activateStudentSuccessHandler(student) {
            $log.debug("In activateStudentSuccessHandler. Student: ", student);

            studentModel.replaceStudent(student);

            $scope.studentsAddedMsg = '';
            // in theory the student.active should always be true here; as we only allow for the option to
            // activate de-activated students in this UI.
            if (student.active === true) {
                $scope.studentsAddedMsg = student.firstName + ' ' + student.lastName + ' successfully activated';
            } else {
                $scope.studentsAddedMsg = student.firstName + ' ' + student.lastName + ' successfully deactivated';
            }

            // refresh the student list;
            // since we are in this method they must be viewing inacative students
            $scope.studentList = studentModel.filterActiveStudents(false);
            $this.loadSectionStudentsAndEntitlements();
            // switch back to active
            if ($scope.studentList.length === 0) {
                $scope.studentList = studentModel.filterActiveStudents(true);
                $scope.showingActiveStudents = true;
                $scope.hasInactiveStudents = false;
            }
        }

        function activateStudentErrorHandler(data) {
            $log.error("In activateStudentErrorHandler. data: ", data);
            $scope.studentsAddedMsg = data.message || data.code || "An error occurred activating the student. Please try again.";
            $log.error("errorMessage: ", $scope.studentsAddedMsg);
        }

        function determineClassInCleverSchool() {
            $log.debug('Inside determineClassInCleverSchool.UserModel object:', userModel.currentUser);

            userService.getCurrentUser().then(function (data) {
                $scope.cleverSchool = userModel.isUserClever() ? true : false;
                $scope.ssoSchool = userModel.isUserSSO() ? true : false;
                $scope.ltiSchool = userModel.isUserLTI() ? true : false;
                $scope.classlinkSchool = userModel.isUserClasslink() ? true :false;
            });
        }

        function determineClassInPresentSchoolYear() {
            $log.debug('Selected Class', $scope.selectedClass, 'School', $scope.school);
            if (Number($scope.selectedClass.schoolYear) >= Number($scope.school.currentSchoolYear)) {
                $scope.readOnlyClass = false;
                // Removing this reset, clears valid messages on route reload
                // $scope.warningMessage = '';
            } else {
                $scope.readOnlyClass = true;
                if($scope.schoolYears.length < 2){
                    if(!$scope.cleverSchool){
                        $scope.warningMessage = sharedConstants.SCHOOL_YEAR_CUTOVER_MESSAGE;
                    }
                    else{
                        $scope.warningMessage = sharedConstants.CLEVER_SCHOOL_YEAR_CUTOVER_MESSAGE;
                    }
                }
            }
        }

        var updateStudentSubscriptionSuccessHandler = function (data) {
            $log.debug('In updateStudentSubscriptionSuccessHandler');

            return data;
        };

        var verifyStudentSubscriptionsHandler = function (data) {
            $log.debug('In verifyStudentSubscriptionsHandler');
            var originalCount = $scope.entitlements[$this.subscriptionId].length;

            if (!!data && data.length > 0) {
                var nameDetails = '';
                data.forEach(function (item) {
                    $scope.studentList.forEach(function (student) {
                       if (student.id == item) {
                           nameDetails += student.firstName + ' ' +  student.lastName + ' ,';
                       }
                    });
                });

                var subResult = '';
                $scope.subscriptions.forEach(function (sub) {
                   if (sub.subscription.id === $this.subscriptionId) {
                       if (!!sub.product) {
                           subResult = sub.product.name;
                       }
                   }
                });

                nameDetails = nameDetails.substring(0,nameDetails.length - 1);
                $scope.studentsWithMultipleEntitlements = nameDetails;
                $scope.selectedSubscriptionToRemove = subResult;
                $uibModal.open({
                    templateUrl: 'resources/js/Modules/students/confirmRemoveEntitlementStudentsPopup/confirmRemoveEntitlements.html',
                    controller: 'confirmRemoveEntitlements',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (response) {
                    if (response.message === 'remove') {
                        enrollmentService.deleteAllStudentsSubscription($scope.selectedClass.id, $this.subscriptionId).then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);
                        $scope.entitlements[$this.subscriptionId] = [];
                        for (var i = 0; i < $scope.subscriptions.length; i++) {
                            if ($scope.subscriptions[i].subscription.id === $this.subscriptionId) {

                                $scope.subscriptions[i].subscription.license.remaining += originalCount;
                                $scope.studentsAddedMsg = 'Your students have been removed from ' + $scope.subscriptions[i].application.name + '. If you change your mind, you can add them again at any time.';
                                break;
                            }
                        }
                    } else  {
                        $this.currentEvent.target.checked = true;
                    }
                });
            } else {
                enrollmentService.deleteAllStudentsSubscription($scope.selectedClass.id, $this.subscriptionId).then(updateStudentSubscriptionSuccessHandler, updateStudentSubscriptionErrorHandler);
                $scope.entitlements[$this.subscriptionId] = [];
                for (var i = 0; i < $scope.subscriptions.length; i++) {
                    if ($scope.subscriptions[i].subscription.id === $this.subscriptionId) {
                        $scope.subscriptions[i].subscription.license.remaining += originalCount;
                        $scope.studentsAddedMsg = 'Your students have been removed from ' + $scope.subscriptions[i].application.name + '. If you change your mind, you can add them again at any time.';
                        break;
                    }
                }
            }
        }

        var updateStudentSubscriptionErrorHandler = function (error) {
            $log.error('In updateStudentSubscriptionErrorHandler', error);
            if (error.code == 'error.not.logged.in') {
                userModel.reset();
                $location.path('/signin/staff');
            }
            $scope.studentsAddedMsg = error.message || 'An error occurred. Please try again.';

        };

        //MB: Bind the elements in scope and also check if the subscriptions or students are empty
        function getStudentEnrollmentSuccessHandler(data) {
          $log.debug("In getStudentEnrollmentSuccessHandler", data);
          getStudentsInClassHandler(data.students);
          $scope.subsAndStudentData = true;

          if ($scope.showYOYFeatures && $scope.isSchoolYearGreater) {
            $scope.subscriptions = [];
            $scope.entitlements = [];
          } else {
            if (
              !!userModel.currentUser &&
              !!userModel.currentUser.staff &&
              !!userModel.currentUser.staff.id &&
              !!userModel.currentOrg &&
              !!userModel.currentOrg.id
            ) {
              configService
                .getConfig(
                  userModel.currentUser.staff.id,
                  userModel.currentOrg.id
                )
                .then(function(config) {
                  $this.currentConfig = config;

                  $scope.isReactTeacherClasses = configService.getCurrentConfigOption("isReactTeacherClasses");

                  $timeout(
                    $this.removeCPDCardIfAccessibleFromProducts(data),
                    1
                  );
                });
            } else {
              $scope.subscriptions = data.subscriptions;
              $scope.entitlements = data.entitlements;

              delete $scope.errorMsg;

              $scope.dataInitialized = true;
              $scope.initialized = true;
            }
          }

          return data;
        }

        function removeCPDCardIfAccessibleFromProducts(data) {
            if (!!$this.currentConfig && !!$this.currentConfig["isReactGlobalNav"]
                && $this.currentConfig["isReactGlobalNav"] === "true") {
                var subs = data.subscriptions.filter(function (subscription) {
                    if (subscription.application.applicationCode !== 'literacydash') {
                        return subscription;
                    }
                });
                $scope.subscriptions = subs;
            } else {
                $scope.subscriptions = data.subscriptions;
            }
            $scope.entitlements = data.entitlements;
            delete $scope.errorMsg;
            $scope.dataInitialized = true;
            $scope.initialized = true;
        }

        function getStudentEnrollmentErrorHandler (data) {
            if (data.code == 'error.not.logged.in') {
                userModel.reset();
                $location.path('/signin/staff');
            }
            $scope.studentsAddedMsg = data.message || data.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
            var errorMessage = data.message || data.code || "An error occurred. Please try again.";
            $log.error("errorMessage: ", errorMessage);
            $scope.dataInitialized = true;
            $scope.initialized = true;
        }

        function initClassList(classList) {
            // if this is a Clever school; we want to remove deactivated classes from school list display
            if ($scope.cleverSchool || $scope.ltiSchool || $scope.classlinkSchool) {
                classList = $filter('filter')(classList, function (value, index, array) {
                    return (value.active === true);
                });

                if (classList.length === 0) {
                    $scope.initialized = false;
                    $scope.ssoNoClass = true;
                    $scope.ssoNoClassMessage = sharedConstants.CLEVER_NO_CLASS_MESSAGE;
                    return;
                }

                return $this.handleClassList(classList);
            } else if ($scope.googleUser) {
                classList = $filter('filter')(classList, function (value, index, array) {
                    return (value.active === true);
                });

                if (classList.length === 0) {
                    // initialized true turns of the spinner
                    $scope.initialized = true;
                    // noClassForCurrentSchool true for a google user should show 'Import My Classes'
                    $scope.noClassForCurrentSchool = true;
                    // ssoNoClass below is a remnant of behavior
                    // which should be inside a clever parent
                    $scope.ssoNoClass = false;
                    return;
                }

                // Keep the spinner going up while we fetch the google courses
                $scope.initialized = false;

                function refreshGapiCourseList() {
                  gapiService.refreshGapiCourseListPromise(userModel.currentUser.staff.identifiers.sourceId)
                    .then(function(res) {
                      $scope.googleCourses = res;
                      $this.matchGoogleCourses(classList, res);
                      return $this.handleClassList(classList);
                    })
                    .catch(function() {
                      if ($scope.googleCourses) {
                        $this.matchGoogleCourses(classList, $scope.googleCourses);
                      }

                      return $this.handleClassList(classList);
                    });
                }

                if (!!window.gapi && window.gapi.client && window.gapi.client.init) {
                  refreshGapiCourseList();
                } else {
                  $this.gapiPromise = new Promise(function(resolve, reject) {
                    gapiService.initGapi(resolve, reject);
                  });

                  $this.gapiPromise
                    .then(function() {
                      refreshGapiCourseList();
                    })
                    .catch(function() {
                      if ($scope.googleCourses) {
                        $this.matchGoogleCourses(classList, $scope.googleCourses);
                      }

                      return $this.handleClassList(classList);
                    });
                }
            } else {
                // if this is not a Clever school the sort the class list so active classes are displayed first and inactive classes are displayed second
                classList = $filter('orderBy')(classList, 'active', true);
                return $this.handleClassList(classList);
            }
        }

        function matchGoogleCourses(classList, res) {
            if (res && res.result && res.result.courses) {
                var courses = res.result.courses;
                classList.forEach(function (classListItem) {
                    courses.forEach(function (course) {
                        if (classListItem.identifiers.sourceId === course.id) {
                            classListItem.googleCourse = course;
                        }
                    });
                });
            }
        }

        function handleClassList(classList) {
            // the api returns all classes where the teacher is primary and co-teacher
            if (!$scope.showCoTeacherFeatures && !$scope.cleverSchool) {
                // run the filter when co-teacher is not clever and co-teacher feature is false
                // TODO: Review Clever exception case above with Kate prior to BTS 2019
                classList = $this.showOnlyPrimaryTeacherClasses(classList);
            }

            $scope.classList = classModel.classList = classList;
            var selectedClass;

            if (productModel.getCookieForOrgAsTeacher()) {
                var currentOrgId = productModel.getCookieForOrgAsTeacher().orgId;
                if (productModel.getCookieForOrgAsTeacher().classId === undefined) {
                    //MB: If classId is not present in orgAsTeacher cookie, then default to the first class of the current org
                    selectedClass = classList.filter(function (section, index) {
                        return section.organizationId == currentOrgId;
                    })[0];
                } else {
                    //MB: If classId is present in orgAsTeacher cookie, then default to that class
                    var currentClassId = productModel.getCookieForOrgAsTeacher().classId;
                    selectedClass = classList.filter(function (section, index) {
                        return section.id == currentClassId;
                    })[0];
                    if (!selectedClass) {
                        selectedClass = classList.filter(function (section, index) {
                            return section.organizationId == currentOrgId;
                        })[0];
                    }
                }
            } else {
                //MB: If orgAsTeacher cookie is not set or returns undefined, default to the first class in the list
                selectedClass = classList[0];
            }

            if (!selectedClass) {
                //MB: This code executes when there are no classes in current school but there are some existing classes belonging to other school
                if (!$scope.cleverSchool) {
                    //MB: This code is only for manual users to create new class in current school
                    $scope.noClassForCurrentSchool = true;
                    $scope.initialized = true;
                    if (!currentOrgId) {
                        var currentOrgId = productModel.getCookieForOrgAsTeacher().orgId;
                    }
                    var currentSchoolWithNoClasses = orgModel.organizationList.filter(function (org, index) {
                        return org.id == currentOrgId;
                    })[0];
                    $scope.school = currentSchoolWithNoClasses;
                } else {
                    //MB: Code executes when no classes found in current school for clever users. This code defaults to the first class in the list disregarding the current school.
                    selectedClass = classList[0];
                    $scope.noClassForCurrentSchool = false;
                    $scope.selectedClass = selectedClass;
                    $scope.buttonname = selectedClass.displayName;
                    $scope.classSectionId = selectedClass.id;
                    $log.debug("In enrollmentHome. Value of $scope.selectedClass", $scope.selectedClass);
                    if (!$scope.selectedClass.gradeArray) {
                        $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                    }
                    $this.loadSectionStudentsAndEntitlements();
                    $scope.school = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
                    productModel.setCookieForOrgAsTeacher($scope.school.id, $scope.classSectionId, $scope.selectedClass.schoolCalendarId);
                    userModel.currentOrg = $scope.school;
                    $this.determineClassInPresentSchoolYear();
                }
            } else {
                $scope.noClassForCurrentSchool = false;
                $scope.selectedClass = selectedClass;
                $scope.buttonname = selectedClass.displayName;
                $scope.classSectionId = selectedClass.id;
                $log.debug("In enrollmentHome. Value of $scope.selectedClass", $scope.selectedClass);
                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }
                $this.loadSectionStudentsAndEntitlements();
                $scope.school = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
                productModel.setCookieForOrgAsTeacher($scope.school.id, $scope.classSectionId, $scope.selectedClass.schoolCalendarId);
                userModel.currentOrg = $scope.school;
                $this.determineClassInPresentSchoolYear();
            }

            if ($scope.volShouldShowAddEditStudentsPage && !$scope.selectedClass.active) {
              $location.path('/students/show-details');

              return;
            }

            if ($this.isRedirectFromCreateClass) {
                var newRedirectClass = classList.filter(function (classItem) {
                    return classItem.id === $this.newlyCreatedClassId;
                });
                $scope.changeClass(newRedirectClass[0]);
            }

            $scope.teacherInitialized = false;
            if (!!selectedClass) {
                $scope.teacherInitialized = true;
                $scope.initialized = false;
                if ($scope.cleverSchool) {
                    $this.checkInvalidGradesForCleverSchool(selectedClass);
                }
                $this.setCoTeacherPermissions(selectedClass);
            }

            $scope.initialized = true;
            SDMAnalyticsService.addEventType(sharedConstants.STUDENT_HOME_TAB, 'orgId');
            SDMAnalyticsService.emit(sharedConstants.STUDENT_HOME_TAB, $scope.school.id);

            return {
              classesData: classList,
              schoolYearsData: $scope.schoolYears
            };
        }

        function checkInvalidGradesForCleverSchool(selectedClass) {
            if ((!$scope.readOnlyClass) && (!selectedClass.highGrade || !selectedClass.lowGrade)) {
                $scope.warningMessage = selectedClass.displayName +  sharedConstants.WARNING_INCORRECT_GRADE;
                $scope.errorFlag = true;
            } else {
                $scope.warningMessage = '';
                $scope.errorFlag = false;
            }
        }

        function  preInitialize() {
            settingsService.getSettings().then(function (settings) {
                // setting up all the settings values
                $this.readAllSettings(settings);
                $this.onInit();
            });
        }

        function readAllSettings(settingsObject) {
            var tempObj = Object.keys(settingsObject);
            tempObj.forEach(function (item) {
                $scope[item] = settingsObject[item];
            });
        }

        $this.preInitialize();

        function setCoTeacherPermissions(selectedClass) {
            // selectedClass.staff.primaryTeacherId
            if (!!selectedClass.staff) {
                $scope.isCoTeacherForCurrentClass = selectedClass.staff.primaryTeacherId !== $this.currentLoggedInUser;
            }
        }

        function showOnlyPrimaryTeacherClasses(classList) {
            return classList.filter(function (classItem) {
                return classItem.staff.primaryTeacherId === $this.currentLoggedInUser;
            }).slice();
        }
    }
]);
