portalModule.controller('ProductSchoolController', [
  '$scope', '$log', '$routeParams', '$location', 'orgModel',
  'productService', 'orgService', 'userService', 'navModel', 'SDMAnalyticsService',
  'productModel', 'notificationModel', 'sharedConstants', '$cookies', 'settingsService',
  'userModel', 'classService', 'classModel', 'licenseAcceptService', 'gapiService',
  function ($scope, $log, $routeParams, $location, orgModel,
            productService, orgService, userService, navModel, SDMAnalyticsService,
            productModel, notificationModel, sharedConstants, $cookies, settingsService,
            userModel, classService, classModel, licenseAcceptService, gapiService) {
        var $this = this;
        $this.onInit = onInit;
        $this.getOrgsForAccessCode = getOrgsForAccessCode;
        $this.addOptionsToSchoolDropdown = addOptionsToSchoolDropdown;
        $this.resetSearchByZip = resetSearchByZip;

        $scope.confirmSubscription = false;
        $scope.acceptTerms = false;
        $scope.accessCode = $routeParams.accesscode;
        $scope.showOrgsForZipDropdown = false;
        $scope.error = '';
        $scope.showConfirmChkBox = false;
        $scope.alreadyHasHomeSchool = false;
        $scope.schoolNotInTheListMessage = '';
        $scope.zipSearched = false;
        $scope.showAddHomeSchoolLink = false;
        $scope.noSchoolsFoundMsg = false;
        $scope.homeSchool = false;
        $scope.cleverUser = false;
        $scope.googleUser = false;
        $scope.showZipSearchForm = false;
        $scope.schoolAlreadyInList = false;
        $scope.showOtherSchoolLink = true;
        $scope.showLicenseAgreement = false;
        $scope.showSrcTerms = false;
        $scope.selectedOtherOrg = {
            'id': '0',
            'name': '---Choose your school---'
        };

        $scope.appCode = $location.search().app;

        if ($scope.appCode) {
          $scope.appCode = $scope.appCode.toLowerCase();
        }

        $this.onInit();

        //Initial check for populating school dropdown if user is already associated with some schools
        function getOrgsForAccessCode() {

            if (angular.equals(orgModel.orgsForAccessCode, {})) {
                //Load schools from api
                var firstEntitlement = productModel.entitlementList.length < 1;

                productService.redeemAccessCode($scope.accessCode, false, firstEntitlement, false).then(function (subscriptionArray) {
                    // new changes due to MPC, the response returned is Array.
                    var subscription = {};
                    if (!!subscriptionArray && subscriptionArray.length > 0) {
                        subscription = subscriptionArray[0];
                    } else  {
                        subscription = subscriptionArray;
                    }
                    $log.debug('Orgs for the entered access code through api: ', subscription);
                    orgModel.orgsForAccessCode = subscription;
                    $scope.subscriptionWithOrgs = angular.copy(subscription);
                    if ($scope.subscriptionWithOrgs.promptToAcceptLicense) {
                        $scope.showLicenseAgreement = true;
                    } else {
                        $scope.acceptTerms = true;
                    }
                    if($scope.subscriptionWithOrgs.application && $scope.subscriptionWithOrgs.application.applicationCode === 'src') {
                        $scope.showSrcTerms = true;
                    }
                    $scope.orgList = $scope.subscriptionWithOrgs.schools;
                    $this.addOptionsToSchoolDropdown();
                }, function (error) {
                    $log.debug('Error in getting orgs for access code', error);
                    $location.path('/products');
                });
            } else {
                //Load schools from model object
                $log.debug('Orgs for the entered access code through model: ', orgModel.orgsForAccessCode);
                $scope.subscriptionWithOrgs = orgModel.orgsForAccessCode;
                if ($scope.subscriptionWithOrgs.promptToAcceptLicense) {
                    $scope.showLicenseAgreement = true;
                } else {
                    $scope.acceptTerms = true;
                }
                if($scope.subscriptionWithOrgs.application && $scope.subscriptionWithOrgs.application.applicationCode === 'src') {
                    $scope.showSrcTerms = true;
                }
                if (!!orgModel.orgsForAccessCode && orgModel.orgsForAccessCode.length > 0) {
                    $scope.orgList = orgModel.orgsForAccessCode[0].schools;
                } else {
                    $scope.orgList = orgModel.orgsForAccessCode.schools;
                }
                $this.addOptionsToSchoolDropdown();
            }
        }


        //Add additional other option to school dropdown for searching using zip code
        function addOptionsToSchoolDropdown() {
            if ($scope.featureSettings.homeSchoolUCN) {
                //If feature settings include homeSchoolUCN then show add home school link and initialize home school object
                $scope.homeSchoolObject = {
                    'id': '3317116',
                    'name': 'Home School',
                    'identifiers': {
                        'ucn': $scope.featureSettings.homeSchoolUCN
                    }
                };
                $scope.showAddHomeSchoolLink = true;
                for (var school in $scope.orgList) {
                    //check if school list already contains home school
                    if ($scope.orgList[school].identifiers && $scope.orgList[school].identifiers.ucn === $scope.featureSettings.homeSchoolUCN) {
                        $scope.alreadyHasHomeSchool = true;
                        break;
                    }
                }
            }
            if ($scope.cleverUser) {
                $scope.showOtherSchoolLink = false;
            }

            if ($scope.orgList.length === 0) {
                $scope.showZipSearchForm = true;
            } else {
                $scope.selectedOrg = $scope.orgList[0];
                if ($scope.selectedOrg.identifiers && $scope.selectedOrg.identifiers.ucn === $scope.featureSettings.homeSchoolUCN) {
                    $scope.homeSchool = true;
                }
            }
        }

        //Reset search by zip input and dropdown whenever we search new zip or change values in school dropdown
        function resetSearchByZip() {
            $scope.showOrgsForZipDropdown = false;
            $scope.orgsForZip = [];
            $scope.selectedOtherOrg = {
                'id': '0',
                'name': '---Choose your school---'
            };
            $scope.zipSearched = false;
            $scope.showConfirmChkBox = false;
            $scope.noSchoolsFoundMsg = false;
            $scope.expand = false;
        }

        //Change org function for existing school dropdown
        $scope.changeOrg = function (org) {
            $log.debug('Selected org from existing org dropdown', org);
            $scope.selectedOrg = org;
            $scope.confirmSubscription = false;
            if ($scope.showLicenseAgreement) {
                $scope.acceptTerms = false;
            }

            if ($scope.selectedOrg.identifiers && $scope.selectedOrg.identifiers.ucn === $scope.featureSettings.homeSchoolUCN) {
                $scope.homeSchool = true;
            } else {
                $scope.homeSchool = false;
            }
        };

        //Change org function for schools belonging to zip dropdown
        $scope.changeOrgForZip = function (org) {
            $log.debug('Selected org from search by zip dropdown', org);
            $scope.schoolAlreadyInList = false;
            for (var school in $scope.orgList) {
                if ($scope.orgList[school].identifiers && org.identifiers && $scope.orgList[school].identifiers.ucn === org.identifiers.ucn) {
                    $scope.changeOrg($scope.orgList[school]);
                    $scope.schoolAlreadyInList = true;
                    break;
                }
            }

            if (!$scope.schoolAlreadyInList) {
                $scope.selectedSchoolFromZip = org.identifiers ? org.identifiers.ucn : undefined;
                $scope.orgList.push(org);
                $scope.changeOrg(org);
            }

            $scope.showZipSearchForm = false;
        };

        //Function to finally redeem access code once user has confirmed that they have print subscription for a particular school
        $scope.confirmSubscriptionForAccessCode = function () {
            var ucn;
            var schoolSelected;
            ucn = $scope.selectedOrg.identifiers.ucn;
            try {
                if ($scope.homeSchool) {
                    schoolSelected = 'Home School';
                } else {
                    if ($scope.selectedSchoolFromZip && $scope.selectedSchoolFromZip === ucn) {
                        schoolSelected = 'School from zip search';
                    } else if ($scope.orgList[0].identifiers && ucn === $scope.orgList[0].identifiers.ucn) {
                        schoolSelected = 'Default school from profile';
                    } else {
                        schoolSelected = 'Another school(not default) from profile';
                    }
                }
            } catch (e) {
                $log.debug('Error getting selected school type for analytics');
            }

            $log.debug('Selected school ucn', ucn);
            if (!ucn || ucn === '') {
                $scope.error = 'We are not able to locate the school in our system. Please choose another school or try again later.';
                return;
            }

            if ($scope.showLicenseAgreement) {
                licenseAcceptService.userLicenseAccept().then(function (data) {
                    $log.debug('Successfully accepted EULA', data);
                }, function (error) {
                    $log.debug('Error in accepting EULA', error);
                });
            }

            var firstEntitlement = productModel.entitlementList.length < 1;

            productService.redeemAccessCode($scope.accessCode, ucn, firstEntitlement, $scope.selectedOrg.id, true).then(function (subscriptionArray) {
                // new changes due to MPC, the response returned is Array.
                var subscription = {};
                if (!!subscriptionArray && subscriptionArray.length > 0) {
                    subscription = subscriptionArray[0];
                } else  {
                    subscription = subscriptionArray;
                }

                $log.debug('Successfully redeemed the access code', subscription);
                $scope.schoolId = subscription.schools[0].id;

                orgService.reloadIfMissingTeacherRoleForSchool($scope.schoolId).then(function (data) {
                    $log.debug('Reload org list', data);
                });

                productModel.setCookieForOrgAsTeacher($scope.schoolId);
                userModel.currentOrg = subscription.schools[0];

                try {
                    SDMAnalyticsService.addEventType(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT, 'subscription', 'schoolsOnProfile', 'schoolSelected', 'schoolUCN');
                    SDMAnalyticsService.emit(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT, subscription, $scope.orgList, schoolSelected, ucn);
                } catch (e) {
                    $log.debug(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT_ERROR);
                }

                notificationModel.setMessage(sharedConstants.PRODUCT_ADD_CONFIRMATION_MESSAGE);
                var isCM = $scope.subscriptionWithOrgs.application.rosterSupport.toLowerCase() === 'cm';

                if (firstEntitlement && isCM && $scope.googleUser) {
                  $location.path('/students/google/import');
                  return;
                }

                if (isCM || $scope.subscriptionWithOrgs.application.rosterSupport.toLowerCase() === 'none' || $scope.cleverUser) {
                    $location.path('/products');
                } else{
                    if (classModel.classList === undefined || classModel.classList.length === 0) {
                        $location.path('/students/createfirstclass');
                    } else {
                        $location.path('/products');
                    }
                }
            }, function (error) {
                $log.debug('Error redeeming the access code', error);
                var message = error.message || error.code || sharedConstants.ERROR_PROBLEM_UNKNOWN;
                var notificationError;
                notificationError = {};
                notificationError['message'] = message;
                notificationModel.setMessage(JSON.stringify(notificationError));
                $location.path('/products');
            });
        };

        //Function to search schools based on zip
        $scope.searchByZip = function () {
            //Copy zip from input model object to another scope object for messaging purpose
            $scope.schoolZipForMessage = $scope.schoolZipCode;
            $this.resetSearchByZip();

            if (!$scope.schoolZipCode) {
                return;
            }
            orgService.searchOrgsByZip($scope.schoolZipCode).then(function (data) {
                $log.debug('List of orgs with zip ', $scope.schoolZipCode, data);
                $scope.orgsForZip = data;
                $scope.zipSearched = true;
                if ($scope.orgsForZip.length > 0) {
                    $scope.showOrgsForZipDropdown = true;
                } else {
                    //Show no school message if no schools returned from searching by zip code
                    $scope.noSchoolsFoundMsg = true;
                    $scope.expand = true;
                }
                try {
                    SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.SEARCH_ZIPCODE_EVENT, 'searchedZipCode', 'schoolsReturned', 'totalSchoolsReturned');
                    SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.SEARCH_ZIPCODE_EVENT, $scope.schoolZipCode, $scope.orgsForZip, $scope.orgsForZip.length);
                } catch (e) {
                    $log.debug(sharedConstants.SEARCH_ZIPCODE_EVENT_ERROR);
                }
            }, function (error) {
                $log.debug('Error fetching org with zip ', $scope.schoolZipCode, error);
            });
        };

        //Function to add/select home school if user is an educator for Home School
        $scope.addHomeSchool = function () {
            //Add home school value in the existing school dropdown if it doesn't already contain 'Home School'
            if (!$scope.alreadyHasHomeSchool) {
                $scope.orgList.push($scope.homeSchoolObject);
                $scope.alreadyHasHomeSchool = true;
                $scope.changeOrg($scope.homeSchoolObject);
            } else {
                //Search for 'Home School' in existing school dropdown and select it
                for (var school in $scope.orgList) {
                    if ($scope.orgList[school].identifiers && $scope.orgList[school].identifiers.ucn === $scope.featureSettings.homeSchoolUCN) {
                        $scope.changeOrg($scope.orgList[school]);
                        break;
                    }
                }
            }
            $scope.showZipSearchForm = false;
        };

        $scope.displayZipSearchForm = function () {
            $this.resetSearchByZip();
            $scope.schoolZipCode = undefined;
            $scope.showZipSearchForm = true;
        };

        $scope.cancel = function () {
            try {
                SDMAnalyticsService.addEventType(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_CANCEL_EVENT, 'subscriptionDetailsAlongWithSchools');
                SDMAnalyticsService.emit(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_CANCEL_EVENT, $scope.subscriptionWithOrgs);
            } catch (e) {
                $log.debug(sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_CANCEL_EVENT_ERROR);
            }
            $location.path('/products');
        };

        $scope.cancelZipSearch = function () {
            if ($scope.orgList.length === 0) {
                $location.path('/products');
            } else {
                $scope.showZipSearchForm = false;
            }
        };

        $scope.closeAlert = function () {
            $scope.error = '';
        };

        function onInit() {

            userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                $log.debug('User signed in', currentUser);

                if (userModel.currentUser.userIdentifiers.source === 'Clever') {
                    $scope.cleverUser = true;
                }

                if (gapiService.isGoogleUser(userModel)) {
                    $scope.googleUser = true;
                }

                productService.getSubscriptions().then(function (subscriptions) {
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh', subscriptions);
                });

                productService.getEntitlement().then(function (data) {
                    $log.debug('Product entitlements', data);
                });

                if (!classModel.classList) {
                    classService.getClassesForTeacher().then(function (classList) {
                        $log.debug('Class list', classList);
                    });
                } else {
                    $log.debug('Class list', classModel.classList);
                }

                settingsService.getSettings().then(function (settings) {
                    $log.debug('Feature settings', settings);
                    $scope.featureSettings = settings;
                    //Load schools for the access code only after we have received the feature settings. Primarily for getting feature settings
                    //related to 'Home School'
                    $this.getOrgsForAccessCode();
                });

            }, function (error) {
                $log.error('User not signed in', error);
                $location.path('/signin/staff');
            });

            navModel.showNav = true;
            navModel.helpPage = false;
        }
    }
]);