portalModule.
controller("HomeController", ['$scope', '$location', 'userService', 'orgService', 'userModel', '$log', 'navModel', '$routeParams', 'loginModel', 'cookieService', 'sharedConstants', 'appModel', 'orgModel', 'classModel', 'subscriptionModel', 'studentModel', 'sessionModel', 'productModel', 'productService', 'notificationModel','easyLoginModel',
    function ($scope, $location, userService, orgService, userModel, $log, navModel, $routeParams, loginModel, cookieService, sharedConstants, appModel, orgModel, classModel, subscriptionModel, studentModel, sessionModel, productModel, productService, notificationModel, easyLoginModel) {

        var $this = this;
        this.ssoLaunch = $location.search().ssoLaunch;
        this.cleverToken = $location.search().cleverToken;
        this.gapiToken = $location.search().gapiToken;
        this.userType = $location.search().userType;

        $this.redirectToStudentDashboard = function() {
            Volume.setStoreItem('dpCurrentRole', 'student');
            $location.path('/studentportal');
        };

        $this.redirectToTeacherDashboard = function() {
            Volume.setStoreItem('dpCurrentRole', 'teacher');
            $location.path('/products');
        };

        $this.redirectToAdminDashboard = function() {
            Volume.setStoreItem('dpCurrentRole', 'admin');
            $location.path('/subscriptions');
        };

        $this.redirectToHomePage = function (userContext) {
            //MB: Post login url
            var postLoginUrl = loginModel.getPostLoginUrl();

            // Remove open sign in param since user has now logged in
            $location.search('openSignIn', null);

            $log.debug('Post login url', postLoginUrl);
            if (postLoginUrl !== '' && postLoginUrl !== undefined) {
                // switching this to URL because path() is escaping the question mark in the query string
                loginModel.reset();
                $location.url(postLoginUrl);
            } else {
                //MB: User is student, redirect to student portal
                if (userContext.student) {
                    $this.redirectToStudentDashboard();
                } else {
                    if(userContext.staff.promptToAcceptLicense){
                        //MB: If teacher has been added to teacher directed entitlement and has not accepted license agreement
                        userModel.fromPromptToAcceptLicense = true;
                        Volume.setStoreItem('dpCurrentRole', 'teacher');
                        $location.path('/userlicenseagreement');
                    } else{
                        productService.getSubscriptions().then(function() {
                            productService.getEntitlement().then(function() {
                                // Final sanity checks:
                                // - if admin + teacher -> admin dashboard
                                // - if admin -> admin dashboard
                                // - if teacher -> teacher dashboard
                                if (subscriptionModel.hasSubsAsAdmin()) {
                                    $this.redirectToAdminDashboard();
                                    return;
                                }

                                $this.redirectToTeacherDashboard();
                            }, function(error) {
                                $log.debug('Error getting entitlements for teacher', error);

                                // Not a teacher but is an admin, redirect to admin dashboard
                                $this.redirectToAdminDashboard();
                            });
                        }, function(error) {
                            $log.debug('Error getting subscriptions for admin', error);

                            // Not a student and not an admin, redirect to teacher dashboard
                            $this.redirectToTeacherDashboard()
                        });
                    }
                }
            }
        };

        $this.redirectToLoginPage = function() {
            appModel.reset();
            classModel.reset();
            navModel.reset();
            orgModel.reset();
            subscriptionModel.reset();
            studentModel.reset();
            userModel.reset();
            sessionModel.reset();
            productModel.reset();
            easyLoginModel.reset();
            $log.debug('User cookie', cookieService.getCookie('user'));
            userModel.checkUser = 1;
            if (cookieService.getCookie('user') == 'staff') {
                $location.path('/signin/staff');
            } else {
                $location.path('/signin');
            }
        }

        function activateNavBar(value) {
            navModel.showNav = value;
            navModel.helpPage = false;
        }

        function setBypassCookie(value) {
            cookieService.saveCookie('bypassShingle', value);
        }

        $this.init = function() {
            $log.debug('Inside home controller');
            activateNavBar(false);

            if (!!$location.search() && $location.search().bypassShingle != undefined) {
                setBypassCookie($location.search().bypassShingle);
            }

            userService.getCurrentUserWithoutRedirect(
                $this.ssoLaunch, $this.cleverToken, $this.gapiToken, $this.userType)
                .then(function (currentUser) {
                $log.debug("User already logged in", currentUser);

                $location.search('ssoLaunch', null);
                $location.search('cleverToken', null);
                $location.search('gapiToken', null);
                $location.search('userType', null);

                $this.redirectToHomePage(currentUser);
            }, function (error) {
                $log.error("User not signed in", error);
                var notificationError;

                notificationError = {};
                if ($this.ssoLaunch && $this.gapiToken) {
                    if (!!error
                        && (!!error.code &&!!error.code.indexOf('error.google') > -1)
                        && !!error.detailedMessage)
                     {
                            notificationError['message'] = error.detailedMessage;
                     }
                    else {
                        notificationError['message'] = sharedConstants.GOOGLE_LOGIN_ERROR;
                    }
                } else if ($this.ssoLaunch) {
                    notificationError['message'] = sharedConstants.SSO_FAILURE_MESSAGE;
                }
                notificationModel.setMessage(JSON.stringify(notificationError));
                $location.search('ssoLaunch', null);
                $location.search('cleverToken', null);
                $location.search('gapiToken', null);
                $location.search('userType', null);
                $this.redirectToLoginPage();
            });
        };
        $this.init();
    }
]);
