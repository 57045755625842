portalModule
    .controller(
        "SignInController", [
            '$scope', '$http', '$location', '$rootScope', '$sce',
            'orgService', 'userModel', 'userService', '$log',
            'navModel', 'orgModel', '$routeParams', 'loginModel', 'sharedConstants',
            'SDMAnalyticsService', 'appModel', 'classModel', 'subscriptionModel', 'studentModel',
            'sessionModel', 'productModel', 'notificationModel', 'easyLoginModel', 'gapiService',
            '$window', 'cookieService', 'configService', '$uibModal', 'settingsService',
            function (
                $scope, $http, $location, $rootScope, $sce,
                orgService, userModel, userService, $log,
                navModel, orgModel, $routeParams, loginModel,
                sharedConstants, SDMAnalyticsService, appModel,
                classModel, subscriptionModel, studentModel, sessionModel,
                productModel, notificationModel, easyLoginModel, gapiService, $window,
                cookieService, configService, $uibModal, settingsService) {

                var $this = this;
                $this.init = init;

                // exposing private functions for testings
                $this._submit = submit;
                $this._signInSuccessHandler = signInSuccessHandler;
                $this._signInErrorHandler = signInErrorHandler;

                $scope.appCode = '';
                $scope.openSignIn = false;

                /**
                 * make gapiService available on the template thru the scope
                 * this improves performance by reducing the watchers on scope
                 */
                $scope.gapiService = gapiService;

                /**
                 * initialize, hold state in gapiService for reuse, and
                 * request the backend for the ConsentURL, if google is enabled
                 */
                $scope.googleLoginEnabled = false;

                $scope.staff = false;
                $scope.errorFlag = false;
                $scope.errorMessage = '';

                // We are suppressing Clever link in Pilot environments
                //$scope.showCleverLink = $location.host().indexOf("pilot") === -1;
                $scope.showCleverLink = true;

                $scope.googleRecaptchaEnabled = false;

                var activateNavBar = function (value) {
                    navModel.showNav = value;
                    navModel.helpPage = false;
                };

                // Included in scope to test the function.
                //$scope._activateNavBar = activateNavBar;

                $scope.initialized = false;

                // OAuth loading states
                $scope.googleOAuthLoading = false;
                $scope.cleverOAuthLoading = false;

                $scope.loginPageRedirects = function (loginAs) {
                    if (loginAs == 'staff') {
                        $location.path('/signin/staff');
                    } else {
                        $location.path('/signin');
                    }
                };

                function signInSuccessHandler(userContext) {
                    $log.debug("In signinSuccessHandler. userContext=", userContext, " userModel=", userModel);

                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.LOGIN_EVENT, 'userContext');
                        SDMAnalyticsService.emit(sharedConstants.LOGIN_EVENT, userContext);
                    } catch (e) {
                        $log.debug(sharedConstants.LOGIN_EVENT_ERROR);
                    }

                    $location.path('/');
                }

                function signInErrorHandler(error) {
                  if (error.code === "error.staff.login.with.student.context") {
                    notificationModel.setMessage(JSON.stringify({ message: error.code }), true);
                    $scope.loginPageRedirects('staff');
                    return;
                  }

                  var message =
                    "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";

                  if (error && error.message) {
                    message = error.message;
                  }

                  $scope.errorMessage = message;
                  $scope.errorFlag = true;
                }

                $this.setHTMLMessage = function setHTMLMessage(message) {
                    if (
                      message === 'error.google.teacher.logged.in.student.context' ||
                      message === 'Google Classroom teacher logged in under student context'
                    ) {
                        if ($location.path().indexOf('/signin/staff') === -1) {
                            notificationModel.setMessage(JSON.stringify({
                                code: 'error.google.teacher.logged.in.student.context',
                                message: 'Google Classroom teacher logged in under student context'
                            }), true);
                            $scope.loginPageRedirects('staff');
                        }
                        return 'Use the Google Classroom button below to sign-in as an Educator or Librarian.';
                    } else if (
                      message === 'error.google.student.logged.in.teacher.context' ||
                      message === 'Google Classroom student logged in under teacher context'
                    ) {
                        return 'Use the Google Classroom button below to sign-in as a Student.';
                    } else if (message === 'error.google.teacher.not.found') {
                        return $sce.trustAsHtml(
                          'Looks like your account hasn\'t been registered yet. Go to the ' +
                          '<a href="./#/createaccount"><strong style="color:black">Registration</strong></a>' +
                          ' page and select Sign Up with Google Classroom to register your account as an educator.'
                        );
                    } else if (message === "error.staff.login.with.student.context") {
                        return $sce.trustAsHtml('Looks like you tried to sign in with an educator email. You can sign in using the <strong>Sign In / Register</strong> option below.');
                    } else {
                        return message;
                    }
                };

                $scope.validate = function() {
                  // DP-2153 if a student login form; strip whitespace from username
                  // regex borrowed from http://stackoverflow.com/a/5963202/133840
                  if (!$scope.staff) {
                    $scope.user.username = $scope.user.username.replace(
                      /\s+/g,
                      ""
                    );
                  }

                  if ($scope.user.username === "" && $scope.user.pass === "") {
                    $scope.errorMessage = "Please enter username/password";
                    $scope.errorFlag = true;
                  } else if ($scope.user.username === "") {
                    $scope.errorMessage = "Please enter username/email";
                    $scope.errorFlag = true;
                  } else if ($scope.user.pass === "") {
                    $scope.errorMessage = "Please enter password";
                    $scope.errorFlag = true;
                  } else {
                    delete $scope.errorMessage;
                    delete $scope.errorFlag;

                    if ($scope.googleRecaptchaEnabled) {
                      grecaptcha.ready(function() {
                        grecaptcha
                          .execute("6LcWqkgaAAAAAL8IQnmcS8z65iJYy5FYvXgcfInM", {
                            action: "submit"
                          })
                          .then(function(token) {
                            $this._submit(token);
                          });
                      });
                    } else {
                      $this._submit();
                    }
                  }
                };

                function submit(token) {
                  userService
                    .signinUser($scope.user.username, $scope.user.pass, token)
                    .then($this._signInSuccessHandler, $this._signInErrorHandler)
                    .catch($this._signInErrorHandler);
                }


                $scope.openMyScholasticModal = function() {
                    window.addEventListener(
                      "message",
                      myScholasticHandler,
                      true
                    );

                    $scope.myScholasticLoginModal = $uibModal.open({
                        templateUrl: "resources/js/Modules/login/myScholasticModal/myScholasticModal.html",
                        scope: $scope,
                        size: 'my-scholastic'
                    });
                };

                $scope.onMyScholasticClose = function() {
                    $scope.myScholasticLoginModal.close();

                    window.removeEventListener(
                      "message",
                      myScholasticHandler,
                      true
                    );
                };

                $scope.oAuthRedirect = function(url) {
                    window.location.assign(url);
                };

                $scope.googleOAuth = function() {
                  if ($scope.googleOAuthLoading === true) {
                    return;
                  }

                  $scope.googleOAuthLoading = true;

                  gapiService
                    .getConsentUrl()
                    .then(function(url) {
                      gapiService.gapiUrl = url;
                      gapiService.gapiEnabled = url !== "";

                      if (gapiService.gapiEnabled) {
                          $scope.oAuthRedirect(url);
                        return;
                      }

                      gapiService.gapiError(
                        new Error(
                          "Could not get Google authentication redirect url."
                        )
                      );
                    })
                    .catch(gapiService.gapiError)
                    .finally(function() {
                      $scope.googleOAuthLoading = false;
                    });
                };

                $scope.cleverOAuth = function() {
                  if ($scope.cleverOAuthLoading === true) {
                    return;
                  }

                  $scope.cleverOAuthLoading = true;

                  userService
                    .loginWithCleverUrl()
                    .then(function(url) {
                      if (url && url !== "") {
                        $scope.cleverLoginLocation = url;
                          $scope.oAuthRedirect(url);
                        return;
                      }

                      $scope.errorFlag = true;
                      $scope.errorMessage =
                        "Could not get Clever authentication redirect url.";
                    })
                    .catch(function(error) {
                      $scope.errorFlag = true;
                      $scope.errorMessage =
                        error && error.message
                          ? error.message
                          : "Could not get Clever authentication redirect url.";
                    })
                    .finally(function() {
                      $scope.cleverOAuthLoading = false;
                    });
                };

                function init() {
                    var search = $location.search();

                    if (!!search) {
                        if (!!search.app) {
                            $scope.appCode = search.app.toUpperCase();
                        }

                        if (!!search.openSignIn) {
                            $scope.openSignIn = true;
                        }
                    }

                    if ($scope.appCode === 'ECTEACHER') {
                        $scope.loginPageRedirects('staff');
                        $scope.staff = true;
                        $scope.openSignIn = true;
                    }

                    // clear notification cookie
                    cookieService.removeCookie('notificationClosed');
                    activateNavBar(false);
                    setupStaffLogin();
                    setupScope();

                    var notificationMessage = notificationModel.popMessage();
                    try {
                        if (!!notificationMessage) {
                            var message;
                            notificationMessage = JSON.parse(notificationMessage);
                            if (typeof notificationMessage === 'object') {
                                message = notificationMessage.message;
                            }
                            if (message) {
                                $log.debug('message', message);
                                $scope.errorFlag = notificationModel.errorFlag;
                                $scope.errorMessage = $this.setHTMLMessage(message);
                            }
                        }
                    } catch (error) {
                        if (typeof notificationMessage === 'string') {
                            $scope.errorMessage = notificationMessage;
                        }
                    }

                    gapiService.enableGoogleLogin().then(function(status){
                      $scope.googleLoginEnabled = status;
                    }, function(){
                      $scope.googleLoginEnabled = false;
                    }).catch(function(error){
                      $scope.googleLoginEnabled = false;
                      $log.error("Error in 'Sign in with Google': ", error);
                    });

                    settingsService
                      .getSettings()
                      .then(function(settings) {
                        $scope.googleRecaptchaEnabled = !!settings.googleRecaptchaEnabled;


                        if (settings.myScholasticLoginUrl) {
                            $scope.myScholasticLoginUrl = settings.myScholasticLoginUrl;
                            $scope.myScholasticLoginIFrameUrl = $sce.trustAsResourceUrl(settings.myScholasticLoginUrl += '?ref=sdm');
                        }

                        $scope.myScholasticLoginEnabled =
                          !!$scope.myScholasticLoginUrl &&
                          !!settings.myScholasticLoginEnabled;
                      })
                      .catch(function() {
                        $scope.googleRecaptchaEnabled = false;
                        $scope.myScholasticLoginEnabled = false;
                      })
                      .finally(function() {
                        if ($scope.googleRecaptchaEnabled) {
                          var recaptcha = document.createElement('script');
                          recaptcha.src = 'https://www.google.com/recaptcha/api.js?render=6LcWqkgaAAAAAL8IQnmcS8z65iJYy5FYvXgcfInM';
                          recaptcha.type = 'text/javascript';
                          document.getElementsByTagName('head')[0].appendChild(recaptcha);
                        }

                        if ($scope.myScholasticLoginEnabled && $scope.openSignIn && $scope.staff) {
                            $scope.openMyScholasticModal();
                        }
                      });

                    if (userModel.checkUser == 0) {
                        userService.getCurrentUser().then(function (currentUser) {
                            $log.debug("User already logged in", currentUser);
                            $location.path('/');
                        }, function (error) {
                            appModel.reset();
                            classModel.reset();
                            navModel.reset();
                            orgModel.reset();
                            subscriptionModel.reset();
                            studentModel.reset();
                            userModel.reset();
                            sessionModel.reset();
                            productModel.reset();
                            easyLoginModel.reset();
                            gapiService.reset();
                            configService.reset();

                            Volume.deleteStoreItem('dpUserContext');
                            Volume.deleteStoreItem('dpCurrentRole');
                            Volume.deleteStoreItem('dpOrgId');

                            $log.debug("User not signed in", error);
                            if ($routeParams.staff == 'staff') {
                                $location.path('/signin/staff');
                            } else {
                                $location.path('/signin');
                            }
                        });
                    }

                    // code added to hide the footer bar until the view is initialized
                    navModel.showFooter = true;

                    // capture the passed access code and set it to the cookie for GC users, since we will lose the
                    // url query parameter after the oAuth redirect
                    var accessCode = $location.search().acode;
                    if (accessCode) {
                        productModel.setAccessCodeCookie(accessCode);
                    }
                } // end init

                function setupStaffLogin() {
                    if (($routeParams.staff == 'staff') || (!!$routeParams.role && $routeParams.role === 'staff')) {
                        $log.debug("Staff member");
                        $scope.staff = true;
                    }
                }

                function setupScope() {
                    $scope.user = {
                        username: "",
                        pass: ""
                    };

                    $scope.registerAccount = function(){
                        if ($scope.appCode) {
                            $location.path('/createaccount').search('app', $scope.appCode);
                        } else {
                            $location.path('/createaccount');
                        }
                    };

                    $scope.closeAlert = function () {
                        $scope.errorMessage = "";
                        $scope.errorFlag = false;
                        delete $scope.errorMessage;
                        delete $scope.errorFlag;
                    };

                } // end setupScope

                // MyScholastic Event handler
                function myScholasticHandler(event) {
                    // safely checking origin
                    if (event && event.origin === $scope.myScholasticLoginUrl) {
                        try {
                            // safely checking response data to avoid conflicts with browser
                            // extensions posting message events
                            var response, status, data;

                            if (event && event.data) {
                                try {
                                    response = JSON.parse(event.data);
                                } catch (e) {
                                    response = event.data;
                                }

                                if (response) {
                                    status = response.status;
                                    data = response.data;
                                }
                            }

                            // No valid status to take an action on, could be an event from a
                            // browser extension or another source, ignore it
                            if (!status) {
                                return;
                            }

                            //user clicked close icon
                            if (status === "CLOSE") {
                                $scope.onMyScholasticClose();
                            } else if (status === "SUCCESS" && data !== null) {
                                setTimeout(function() {
                                    userService.getCurrentUser().then(function (currentUser) {
                                        try {
                                            SDMAnalyticsService.addEventType(sharedConstants.LOGIN_EVENT, 'userContext');
                                            SDMAnalyticsService.emit(sharedConstants.LOGIN_EVENT, currentUser);
                                        } catch (e) {
                                            $log.debug(sharedConstants.LOGIN_EVENT_ERROR);
                                        }

                                        $location.path('/');
                                    }).catch(function () {
                                        appModel.reset();
                                        classModel.reset();
                                        navModel.reset();
                                        orgModel.reset();
                                        subscriptionModel.reset();
                                        studentModel.reset();
                                        userModel.reset();
                                        sessionModel.reset();
                                        productModel.reset();
                                        easyLoginModel.reset();
                                        gapiService.reset();
                                        configService.reset();

                                        Volume.deleteStoreItem('dpUserContext');
                                        Volume.deleteStoreItem('dpCurrentRole');
                                        Volume.deleteStoreItem('dpOrgId');

                                        $location.path('/signin/staff');
                                    }).finally(function() {
                                        $scope.onMyScholasticClose();
                                    });
                                }, 100);
                            } else if (status === "REDIRECT") {
                                // redirect to register page using URL from data field preserving
                                // app code similar to legacy behavior for launch flows

                                $scope.onMyScholasticClose();
                                if (data && data.sdm) {
                                    var url = data.sdm;

                                    if ($scope.appCode) {
                                        url += '?app=' + $scope.appCode;
                                    }

                                    $window.location.href = url;
                                }
                            } else if (status === "RESET") {
                                // No action taken right now, leave modal open so user can take
                                // further actions inside of it
                            }
                        } catch(e) {
                            // Don't do anything with caught errors to avoid unintended message
                            // handling with browser extensions
                            $log.error('Error handling MyScholastic event', e);
                        }
                    }
                }

                $this.init();
            }
        ]
    );
