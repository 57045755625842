/**
 * Created by jhouser on 11/7/2015.
 */

portalModule.controller("ProductHomeController", [
    '$scope', '$rootScope', '$log', '$timeout', '$uibModal', 'classService', 'classModel',
    'userModel', '$location', 'orgModel', 'productService', 'orgService',
    'appModel', 'userService', 'navModel', 'notificationModel', 'ltiDataModel', 'sharedConstants',
    'SDMAnalyticsService', 'productModel', 'subscriptionModel', 'gapiService', 'settingsService',
    'sessionState', '$window', 'cookieService' ,'configService', 'licenseAcceptService',
    function (
        $scope, $rootScope, $log, $timeout, $uibModal, classService, classModel,
        userModel, $location, orgModel, productService, orgService,
        appModel, userService, navModel, notificationModel, ltiDataModel, sharedConstants,
        SDMAnalyticsService, productModel, subscriptionModel, gapiService, settingsService,
        sessionState, $window, cookieService, configService, licenseAcceptService
    ) {

        $log.debug("In ProductHomeController init");
        var $this = this;

        // Cookie for DNA
        $this.dnaConfigCookie = {};

        // by default show the access code form; it only gets hidden if all schools are clever
        $scope.cleverUser = false;
        $scope.ltiUser = false;
        $scope.googleUser = false;
        $scope.classlinkUser = false;
        $scope.initialized = false;
        $scope.loadingProducts = false;
        $scope.manageRostering = false;
        $scope.userIsAdmin = false;
        $scope.editClassPasscode = false;
        $scope.errorFlag = notificationModel.errorFlag;

        productModel.redeemedSubscription = undefined;
        $scope.curriculumResourcesText = {
                headerText: '',
                contentText: '',
                buttonText: ''
        };

        // capture the access code passed from signin/register. If we can't find an access code check to see if one
        // is set in the cookie for GC users (or any user coming from a redirect)
        $scope.accessCode = $location.search().acode;
        $scope.appCode = $location.search().app;

        if ($scope.appCode) {
            $scope.appCode = $scope.appCode.toLowerCase();
        }

        $this.lbaOnlyApps = false;
        $this.checkForGCorLTICMOnlyFlag = false;
        $this.showAnnouncementBanner = false;
        $this.filterNonCMApplications = filterNonCMApplications;
        $this.filterApplicationsBasedOnRosterSupport = filterApplicationsBasedOnRosterSupport;
        $this.getClassPasscode = getClassPasscode;
        $this.loadEntitlements = loadEntitlements;
        $this.verifyIfAllAppsAreCm = verifyIfAllAppsAreCm;
        $this.onInit = onInit;
        $this.processAppsBasedOnRoster = processAppsBasedOnRoster;
        $this.removeCPDCardIfAccessibleFromProducts= removeCPDCardIfAccessibleFromProducts;
        $this.setCookieForDNA = setCookieForDNA;
        $this.onInit();

        $scope.$on('orgChanged', function($event, orgId) {
            if (orgId) {
                $scope.selectedOrgAndApps = productModel.getIndexOfOrg(orgId)[0];
                if ($scope.selectedOrgAndApps) {
                    $scope.orgId = $scope.selectedOrgAndApps.id;
                    $scope.appsForOrg = $scope.selectedOrgAndApps;
                    userModel.currentOrg = $scope.selectedOrgAndApps;
                } else if ($scope.entitlements && $scope.entitlements[0]) {
                    $scope.orgId = $scope.entitlements[0].id;
                    $scope.appsForOrg = $scope.entitlements[0];
                    userModel.currentOrg = $scope.entitlements[0];
                }
            } else if ($scope.entitlements && $scope.entitlements[0]) {
                $scope.orgId = $scope.entitlements[0].id;
                $scope.appsForOrg = $scope.entitlements[0];
                userModel.currentOrg = $scope.entitlements[0];
            }

            try {
                SDMAnalyticsService.addEventType(sharedConstants.PRODUCT_HOME_TAB, 'orgId');
                SDMAnalyticsService.emit(sharedConstants.PRODUCT_HOME_TAB, $scope.orgId);
            } catch (e) {
                $log.error('Error while emitting event: ' + sharedConstants.PRODUCT_HOME_TAB, e);
            }

            $this.filterApplicationsBasedOnRosterSupport();
        });

        function verifyIfAllAppsAreCm() {
            var allApplications;
            var checkIfAllAPPCMOnly;
            var filterRetVal;

            checkIfAllAPPCMOnly = false;
            if (!!$scope.appsForOrg && !!$scope.appsForOrg.applications && $scope.appsForOrg.applications.length > 0) {
                allApplications = $scope.appsForOrg.applications;
                filterRetVal = allApplications.filter(function (item) {
                    if (!!item && !!item.rosterSupport) {
                        return item.rosterSupport.toLowerCase() === 'cm';
                    }
                });

                if (!!filterRetVal && filterRetVal.length === allApplications.length) {
                    checkIfAllAPPCMOnly = true;
                } else if (!!filterRetVal && filterRetVal.length === 0) {
                    checkIfAllAPPCMOnly = false;
                }
            }
            return checkIfAllAPPCMOnly;
        }

        // entitlement loading code
        function loadEntitlements() {
            $log.debug("In loadEntitlements");
            delete $scope.entitlements;

            $log.debug("User is a teacher in " + userModel.teacherId + ". Retrieving entitlements (applications)");

            productService.loadEntitlements().then(function (applications) {
                navModel.showNav = true;
                $scope.entitlements = applications;
                if ($scope.cleverUser && !productModel.hasAppsAsTeacher()) {
                    $scope.manageRostering = true;
                }
                if ($scope.entitlements.length >= 1) {
                    if ($scope.schoolIdForSub) {
                        /** path for AccessCode which has newly redeemed a subscription*/
                        $scope.selectedOrgAndApps = productModel.getIndexOfOrg($scope.schoolIdForSub)[0];
                        $scope.orgId = $scope.selectedOrgAndApps.id;
                        $scope.appsForOrg = $scope.selectedOrgAndApps;
                        productModel.setCookieForOrgAsTeacher($scope.orgId);
                        userModel.currentOrg = $scope.selectedOrgAndApps;

                        var isAppHasRosterSupport = productModel.checkIfApplicationHasRosterSupport(productModel.redeemedSubscription);

                        if ((classModel.classList == undefined || classModel.classList.length === 0) && !($scope.cleverUser || $scope.ltiUser || $scope.classlinkUser) && isAppHasRosterSupport) {
                            if(!gapiService.isGoogleUser(userModel)){
                                $location.path('/students/createfirstclass');
                            } else { // DO NOT CHANGE GOOGLE USER ONBOARDING: '/students/google/import'
                                $location.path('/students/google/import');
                            }
                        } else {
                            productModel.redeemedSubscription = undefined;
                            $scope.accessCode = '';
                            $scope.error = sharedConstants.PRODUCT_ADD_CONFIRMATION_MESSAGE;
                            $scope.errorFlag = false;
                        }
                    } else {
                        if (productModel.getCookieForOrgAsTeacher() != undefined) {
                            $scope.selectedOrgAndApps = productModel.getIndexOfOrg(productModel.getCookieForOrgAsTeacher().orgId)[0];
                            if ($scope.selectedOrgAndApps) {
                                $scope.orgId = $scope.selectedOrgAndApps.id;
                                $scope.appsForOrg = $scope.selectedOrgAndApps;
                                userModel.currentOrg = $scope.selectedOrgAndApps;
                            } else {
                                $scope.orgId = $scope.entitlements[0].id;
                                $scope.appsForOrg = $scope.entitlements[0];
                                productModel.setCookieForOrgAsTeacher($scope.orgId);
                                userModel.currentOrg = $scope.entitlements[0];
                            }
                        } else {
                            $scope.orgId = $scope.entitlements[0].id;
                            $scope.appsForOrg = $scope.entitlements[0];
                            productModel.setCookieForOrgAsTeacher($scope.orgId);
                            userModel.currentOrg = $scope.entitlements[0];
                        }
                    }

                    if (
                      userModel.currentUser &&
                      userModel.currentUser.staff &&
                      userModel.currentUser.staff.id &&
                      $scope.orgId
                    ) {
                      configService
                        .getConfig(userModel.currentUser.staff.id, $scope.orgId)
                        .catch(function(error) {
                          $scope.error =
                            error.message ||
                            error.code ||
                            "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                          $scope.errorFlag = true;
                        });
                    }

                    var checkIfCMOnly = $this.verifyIfAllAppsAreCm();
                    if (!checkIfCMOnly && $this.showAnnouncementBanner) {
                        $this.showBanner();
                    }
                    $this.filterApplicationsBasedOnRosterSupport();

                } else if ($scope.entitlements.length === 0 && $this.showAnnouncementBanner) {
                    $this.showBanner();
                } else if ($scope.entitlements.length === 0) {
                    $scope.loadingProducts = true;
                }

                $scope.initialized = true;
                $log.debug("Entitlements for teacher ", userModel.teacherId, " : ", applications);

                $this.dnaPromise = $this.setCookieForDNA();

                $this.dnaPromise.then(function () {
                    $log.debug("DNA cookie", $this.dnaConfigCookie);
                    cookieService.saveCookie('dnaConfigCookie',$this.dnaConfigCookie)

                });

            }, function (error) {
                $log.error('error loading entitlements for teacher in school', userModel.teacherId);
                if (error.code == 'error.not.logged.in') {
                    userModel.reset();
                    $location.path('/signin/staff');
                }
                $scope.error = error.message || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                $scope.errorFlag = true;
                $scope.initialized = true;

            }).then(function (data) {
                try {
                    SDMAnalyticsService.addEventType(sharedConstants.PRODUCT_HOME_TAB, 'orgId');
                    SDMAnalyticsService.emit(sharedConstants.PRODUCT_HOME_TAB, $scope.orgId);
                } catch (e) {
                    $log.error('Error while emitting event: ' + sharedConstants.PRODUCT_HOME_TAB, e);
                }
            });
        }

        function setCookieForDNA() {
            var userHasClassroomMagazine = false;
            var userHasRosteredProduct = false;
            var userHasClass;

            return Promise.all(
                $scope.entitlements.map(function (entitlement) {
                    return new Promise(function(resolve, reject){
                        entitlement.applications.forEach(function (application) {
                            if (application.rosterSupport.toUpperCase() === "FULL" && !userHasRosteredProduct) {
                                userHasRosteredProduct = true
                                $this.dnaConfigCookie.userHasRosteredProduct = userHasRosteredProduct;
                            }


                            if (application.rosterSupport.toUpperCase() === "CM" && !userHasClassroomMagazine) {
                                userHasClassroomMagazine = true;
                                $this.dnaConfigCookie.userHasClassroomMagazine =userHasClassroomMagazine;
                            }
                        });

                        orgService.getSchoolYearsYOYForTeacher(entitlement.id)
                            .then(function (data) {
                                if (data.length > 0) {
                                    // Set base year to a variable which
                                    // is the current calendar year
                                    $this.currentSchoolYear = orgModel.setBaseCalendarYear(data);

                                    classService.loadClassSectionsForACalendar($this.currentSchoolYear).then(function (classList) {
                                        if (classList.length > 0) {
                                            userHasClass = true;
                                            $this.dnaConfigCookie.userHasClass = userHasClass;
                                        }
                                        resolve('done');
                                    });

                                } else{
                                    resolve('done');
                                }
                            })
                            .catch(function() {
                                resolve('done');
                            });
                    });
                })
            );
        }

        $scope.getIsAddNewProgramDisabled = function (orgId) {
            return configService
                .getConfig(userModel.currentUser.staff.id, orgId)
                .then(config => config.isAddNewProgramDisabled);
        };

        function filterApplicationsBasedOnRosterSupport() {
            if (configService.getCurrentConfigOption("isReactGlobalNav")) {
                $this.removeCPDCardIfAccessibleFromProducts();
            }

            $timeout($this.processAppsBasedOnRoster(), 1);
        }

        function removeCPDCardIfAccessibleFromProducts() {
            if (!!$scope.appsForOrg && !!$scope.appsForOrg.applications) {
                var applications = $scope.appsForOrg.applications.filter(function (application) {
                    if (!!application && !!application.applicationCode && application.applicationCode !== 'literacydash') {
                        return application;
                    }
                });
                $scope.appsForOrg.applications = [];
                $scope.appsForOrg.applications = applications;
            }
        }

        function processAppsBasedOnRoster() {
            $scope.cmApplications = [];
            $scope.noncmApplications = [];
            var allApplications = [];

            if ($scope.appsForOrg && $scope.appsForOrg.applications) {
                $log.debug('Applications before filter', $scope.appsForOrg.applications);
                allApplications = $scope.appsForOrg.applications;
            }

            for (var i in allApplications) {
                if ($scope.showYOYFeatures) {
                    if (!($scope.googleUser || $scope.ltiUser)) {
                        if (allApplications[i].rosterSupport.toLowerCase() === 'cm') {
                            $scope.cmApplications.push(allApplications[i]);
                        } else {
                            $scope.noncmApplications.push(allApplications[i]);
                        }
                    } else {
                        $scope.noncmApplications.push(allApplications[i]);
                    }
                }
                else {
                    if (allApplications[i].rosterSupport.toLowerCase() === 'cm') {
                        $scope.cmApplications.push(allApplications[i]);
                    } else {
                        $scope.noncmApplications.push(allApplications[i]);
                    }
                }
            }

            $log.debug('Classroom magazine applications', $scope.cmApplications);
            $log.debug('Non cm applications', $scope.noncmApplications);

            if ($scope.cmApplications.length > 0 && !$scope.cleverUser) {
                $this.getClassPasscode();
            }

            if($scope.noncmApplications.length > 0){
                $this.filterNonCMApplications();
            }
            $scope.loadingProducts = true;
        }

        function filterNonCMApplications(){

            $scope.atleastOneFullRosterApp = false;
            $scope.atleastOneRosterOnlyApp = false;
            $scope.atleastOneNoRosterApp = false;
            $scope.mixedRosterApps = false;
            $scope.onlyFullRosterApps = false;
            $scope.onlyRosterApps = false;
            $scope.onlyNoRosterApps = false;
            $scope.showManageAccess = false;

            var noLbaForManualGC;
            var googleCMDisplay;

            noLbaForManualGC = false;
            googleCMDisplay = false;
            $this.lbaOnlyApps = false;

            if (!!$scope.cmApplications && $scope.cmApplications.length === 0 ) {
                var lbaFilter = $scope.noncmApplications.filter(function (item) {
                    return item.applicationCode === 'lba';
                });

                if (!!lbaFilter && lbaFilter.length === $scope.noncmApplications.length) {
                    $this.lbaOnlyApps = true;
                }
            }

            for(var i in $scope.noncmApplications){
                if($scope.noncmApplications[i].rosterSupport.toLowerCase() === 'full'){
                    $scope.atleastOneFullRosterApp = true;
                }
                else if($scope.noncmApplications[i].rosterSupport.toLowerCase() === 'roster_only'){
                    $scope.atleastOneRosterOnlyApp = true;
                }
                else {
                    $scope.atleastOneNoRosterApp = true;
                }

                if ($scope.showYOYFeatures) {
                    if ($scope.noncmApplications[i].rosterSupport.toLowerCase() === 'cm' && ($scope.googleUser || $scope.ltiUser)) {
                        $this.checkForGCorLTICMOnlyFlag = true;
                    }
                }
            }
            if($scope.atleastOneFullRosterApp){
                setFullRosterText();
            }
            else if(!$scope.atleastOneFullRosterApp && $scope.atleastOneRosterOnlyApp){
                $scope.onlyRosterApps = true;
                if($scope.cleverUser){
                    setRosterOnlyTextForClever();
                }
                else{
                    setRosterOnlyTextForManual();
                }
            }
            else{
                $scope.onlyNoRosterApps = true;
                if($scope.cleverUser){
                    setRosterOnlyTextForClever();
                }

                if ($this.checkForGCorLTICMOnlyFlag || $this.lbaOnlyApps) {
                    setFullRosterText();
                }

                if($this.lbaOnlyApps && $scope.googleUser && !($scope.cleverUser || $scope.ltiUser || $scope.classlinkUser)) {
                    noLbaForManualGC = false;
                }

                if ($this.checkForGCorLTICMOnlyFlag && $scope.googleUser && !$this.lbaOnlyApps) {
                    googleCMDisplay = true;
                }
            }

            $scope.showManageAccess = $scope.showYOYFeatures &&
                (
                    ($scope.onlyNoRosterApps && ($scope.cleverUser || $scope.ltiUser || $scope.classlinkUser || noLbaForManualGC || googleCMDisplay)) ||
                    (!$scope.onlyNoRosterApps && ($scope.atleastOneFullRosterApp || $scope.atleastOneRosterOnlyApp))
                );
        }

        function setFullRosterText(){
            $scope.curriculumResourcesText.headerText = sharedConstants.TEACHER_DASHBOARD_TEXT.FULL_ROSTER_HEADER_TEXT;
            $scope.curriculumResourcesText.contentText = sharedConstants.TEACHER_DASHBOARD_TEXT.FULL_ROSTER_TEXT;
            $scope.curriculumResourcesText.buttonText = sharedConstants.TEACHER_DASHBOARD_TEXT.FULL_ROSTER_BTN_TEXT;
        }

        function setRosterOnlyTextForManual(){
            $scope.curriculumResourcesText.headerText = sharedConstants.TEACHER_DASHBOARD_TEXT.ROSTER_ONLY_HEADER_TEXT;
            $scope.curriculumResourcesText.contentText = sharedConstants.TEACHER_DASHBOARD_TEXT.ROSTER_ONLY_TEXT;
            $scope.curriculumResourcesText.buttonText = sharedConstants.TEACHER_DASHBOARD_TEXT.ROSTER_ONLY_BTN_TEXT;
        }

        function setRosterOnlyTextForClever(){
            $scope.curriculumResourcesText.headerText = sharedConstants.TEACHER_DASHBOARD_TEXT.ROSTER_ONLY_HEADER_TEXT;
            $scope.curriculumResourcesText.contentText = sharedConstants.TEACHER_DASHBOARD_TEXT.CLEVER_ROSTER_ONLY_TEXT;
            $scope.curriculumResourcesText.buttonText = sharedConstants.TEACHER_DASHBOARD_TEXT.CLEVER_ROSTER_ONLY_BTN_TEXT;
        }

        function getClassPasscode(dpOrgId) {
            $scope.classPasscode = undefined;
            $scope.editClassPasscode = false;

            return classService.getClassPasscode(dpOrgId || $scope.appsForOrg.id).then(function (data) {
                $log.debug('Classroom magazine passcode', data);

                $scope.classPasscode = data;

                return data;
            }, function (error) {
                $log.debug('Error getting classroom magazine passcode', error);
                $scope.error = error.message || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                $scope.errorFlag = true;
            });
        }

        $scope.saveClassPasscode = function () {
            classService.updateClassPasscode($scope.classPasscode).then(function (data) {
                $log.debug('Updated classroom magazine passcode', data);
                $scope.classPasscode = data;
                $scope.editClassPasscode = false;
                $scope.error = 'Successfully updated class passcode';
                $scope.errorFlag = false;

                SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT);
                SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT);
            }, function (error) {
                $log.debug('Error updating classroom magazine passcode', error);
                $scope.error = error.message || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                $scope.errorFlag = true;
            });
        };

        // rewrite of changeOrg to use ui-select instead of drop down
        $scope.changeOrg = function ($item, $model) {
            // find index
            // todo why is selectedOrgIndex stored in the productModel? Is this tech debt?
            productModel.selectedOrgIndex = $scope.entitlements.indexOf($item);
            $scope.orgId = $item.id;
            $scope.appsForOrg = $scope.entitlements[productModel.selectedOrgIndex];
            productModel.setCookieForOrgAsTeacher($scope.orgId);
            userModel.currentOrg = $scope.entitlements[productModel.selectedOrgIndex];
            // ENABLE loading when the app is recalculating
            $scope.loadingProducts = false;

            if (
              userModel.currentUser &&
              userModel.currentUser.staff &&
              userModel.currentUser.staff.id &&
              $scope.orgId
            ) {
                configService.getConfig(userModel.currentUser.staff.id, $scope.orgId)
                  .finally($this.filterApplicationsBasedOnRosterSupport);
            }

            SDMAnalyticsService.addEventType(sharedConstants.ORG_CHANGED_PRODUCT_TAB, 'orgId');
            SDMAnalyticsService.emit(sharedConstants.ORG_CHANGED_PRODUCT_TAB, $item.id);
        };

        $scope.promptForAccessCode = function () {
            $log.debug('User clicked the add access code button');
            $location.path('/products/add');
        };

        $scope.closeAlert = function () {
            $scope.error = '';
            $scope.errorFlag = false;
        };

        $scope.manageRoster = function () {
            SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.MANAGE_ACCESS_LINK_EVENT);
            SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.MANAGE_ACCESS_LINK_EVENT);
                $location.path('/students');
        };

        $scope.showLtiInformation = function (app, orgId) {
            $uibModal.open({
                controllerAs: '$ctrl',
                controller: 'LtiInformationModalController',
                bindToController: true,
                resolve: {
                    modalData: function () {
                        return {
                            app: app,
                            orgId: orgId
                        };
                    }
                },
                templateUrl: 'resources/js/Modules/lti/modal/ltiInformationModal.html'
            });
        };

        $this.showBanner = function () {
            var modal = $uibModal.open({
                templateUrl: 'resources/js/Modules/showAnnouncement/modal/banner.template.html',
                controller: 'bannerController',
                scope: $scope,
                backdrop: 'static',
                windowClass: 'app-modal-window',
                keyboard: false
            }).result.then(function (response) {
                if (response === 'close') {
                    $this.showAnnouncementBanner = false;
                    productModel.setCookieForAnnouncements(false);
                }
            });
        };

        $scope.viewLtiInformation = function () {
            $location.path('/lti/' + $scope.orgId + '/info').search({ dashboard: 'products'});
        };

        $scope.showGradeSettingModal = function (classes) {
            $uibModal.open({
                controller: 'LtiSetGradeForClassModalController',
                controllerAs: '$ctrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    classes: function () {
                        return classes;
                    }
                },
                templateUrl: 'resources/js/Modules/lti/modal/ltiSetGradeForClassModal.html'
            })
            .result
            .then(function () {
                $scope.error = notificationModel.popMessage();
                $scope.errorFlag = false;
            })
            .catch(function (err) {
                $log.error(err);
            });
        };

        $scope.checkIfGradeSet = function (classes) {
            if (userModel.isUserLTI()) {
                var classesWithoutGrades = classes.filter(function (classObj) {
                    var retVal;

                    retVal = (angular.isUndefined(classObj.lowGrade)) ||
                        (!classObj.lowGrade && (classObj.lowGrade === null)) ||
                         (classObj.lowGrade === "null");
                    return retVal;
                });
                if (classesWithoutGrades.length) {
                    $scope.showGradeSettingModal(classesWithoutGrades);
                }
            }
        };

        $scope.getClasses = function () {
            classService.getClassesForTeacher().then(function (classList) {
                $log.debug('Class list', classList);
                $scope.checkIfGradeSet(classList);
            });
        };

        $scope.redeemAccessCode = function (accessCode) {
            $scope.expand = false;
            var firstEntitlement = productModel.entitlementList.length < 1;

            productService.redeemAccessCode(accessCode, false, firstEntitlement, true).then(function (subscriptionArray) {
                // new changes due to MPC, the response returned is Array.
                var subscription = {};
                if (!!subscriptionArray && subscriptionArray.length > 0) {
                    subscription = subscriptionArray[0];
                } else  {
                    subscription = subscriptionArray;
                }

                $log.debug('Redeem access code: ', subscriptionArray);
                productModel.redeemedSubscription = subscriptionArray;

                Volume.globalNavRefreshTrigger();
                Volume.organizationSelectRefreshTrigger();

                // subscription is being changed as an Array
                if (subscription.universalAccessCode) {
                    $log.debug('Universal access code for classroom magazine');
                    orgModel.orgsForAccessCode = subscription;

                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.REDEEM_UNIVERSAL_ACCESS_CODE_EVENT, 'subscriptionDetailsAlongWithSchools', 'totalSchoolsOnProfile', 'prompToAcceptEULA');
                        SDMAnalyticsService.emit(sharedConstants.REDEEM_UNIVERSAL_ACCESS_CODE_EVENT, subscription, subscription.schools.length, subscription.promptToAcceptLicense);
                    } catch (e) {
                        $log.debug(sharedConstants.REDEEM_UNIVERSAL_ACCESS_CODE_EVENT_ERROR);
                    }

                    $location.path('/products/selectschool/' + accessCode);
                    if ($scope.appCode) {
                        $location.search({ app: $scope.appCode });
                    }

                    return;
                }

                $scope.schoolIdForSub = subscription.schools[0].id;
                $this.loadEntitlements();

                //$scope.error = sharedConstants.PRODUCT_ADD_CONFIRMATION_MESSAGE;
                orgService.reloadIfMissingTeacherRoleForSchool($scope.schoolIdForSub).then(function (data) {
                    $log.debug('Reload school list', data);

                    $scope.getClasses();
                });

                //MB: Analytics code to emit an event of redeeming the access code
                try {
                  setTimeout(function() {
                    SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.REDEEM_ACCESS_CODE_EVENT, "subscriptionDetails", "entitlements");
                    SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.REDEEM_ACCESS_CODE_EVENT, subscriptionArray, $scope.entitlements);
                  }, 2000);
                } catch (e) {
                  $log.debug(sharedConstants.REDEEM_ACCESS_CODE_EVENT_ERROR_MESSAGE);
                }
            }, function (error) {
                if (error.code == 'error.not.logged.in') {
                    userModel.reset();
                    $location.path('/signin/staff');
                }
                try {
                    SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.REDEEM_ACCESS_CODE_EVENT_ERROR, 'accessCode');
                    SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.REDEEM_ACCESS_CODE_EVENT_ERROR, accessCode);
                } catch (error) {
                    $log.debug('Error while emitting analytics event: ', error);
                }
                $log.error('Error redeeming access code', error);
                $scope.error = error.message || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                $scope.errorFlag = true;
                $scope.initialized = true;
                $scope.accessCode = "";
            });
        };

        $scope.launchApp = function(appId, launchType) {
            //MB: Analytics code for sending teacher product launch event
            try {
                SDMAnalyticsService.addEventType(sharedConstants.PRODUCT_LAUNCH_TEACHER_EVENT, 'applicationId', 'schoolId', 'entitlements');
                SDMAnalyticsService.emit(sharedConstants.PRODUCT_LAUNCH_TEACHER_EVENT, appId, $scope.orgId, $scope.entitlements);
            } catch (e) {
                $log.debug(sharedConstants.PRODUCT_LAUNCH_TEACHER_EVENT_ERROR);
            }

            appModel.setCurrentApp(appId);

            productService.launchData(appId, $scope.orgId, 'staff', false, launchType)
              .then(function(data) {
                  $log.debug('Launch data for app #', appId, ' : ', data);
                  $scope.outboundLaunch = data;
                  $scope.launchParams = data.params;

                  $timeout(function() {
                      if (launchType === 'oauth') {
                          $window.location.href = $scope.outboundLaunch.launchUrl;
                          return;
                      }

                      var form = $('#launchForm');
                      form.attr('action', $scope.outboundLaunch.launchUrl);
                      form.submit();
                      $scope.loading = false;
                  }, 0);
              }, function(error) {
                  if (error.code === 'error.not.logged.in') {
                      userModel.reset();
                      $location.path('/signin/staff');
                  }
                  $log.error('In error handler', error);
                  $scope.error = error.message || error.code || 'An error occurred. Please try again later.';
                  $scope.errorFlag = true;
              });
        };

        $scope.onCardClick = function($event, application) {
            $scope.launchApp(application.id, application.launchType);
            $scope.$apply();
        };

        function updatePasscode(classPasscode) {
          classService
            .updateClassPasscode(classPasscode)
            .then(function(data) {
              $log.debug("Updated classroom magazine passcode", data);
              $scope.classPasscode = data;
              $scope.editClassPasscode = false;
              $scope.error = "Successfully updated class passcode";
              $scope.errorFlag = false;

              SDMAnalyticsService.addEventType(
                sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT
              );

              SDMAnalyticsService.emit(
                sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT
              );
            })
            .catch(function(error) {
              $log.debug("Error updating classroom magazine passcode", error);
              $scope.error =
                error.message ||
                error.code ||
                "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
              $scope.errorFlag = true;
            });
        }

        $scope.onSavePassCode = function() {
            SDMAnalyticsService.addEventType(
              sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT
            );

            SDMAnalyticsService.emit(
              sharedConstants.SDM_ANALYTICS_EVENTS.CLASS_PASSCODE_UPDATE_EVENT
            );
        };

        $scope.getSettings = function() {
          return settingsService.getSettings();
        };

        $scope.searchOrgsByZip = function(zipCode) {
          return orgService.searchOrgsByZip(zipCode).then(
            function(organizations) {
              $log.debug("List of orgs with zip ", zipCode, organizations);

              try {
                SDMAnalyticsService.addEventType(
                  sharedConstants.SDM_ANALYTICS_EVENTS.SEARCH_ZIPCODE_EVENT,
                  "searchedZipCode",
                  "schoolsReturned",
                  "totalSchoolsReturned"
                );

                SDMAnalyticsService.emit(
                  sharedConstants.SDM_ANALYTICS_EVENTS.SEARCH_ZIPCODE_EVENT,
                  zipCode,
                  organizations,
                  organizations.length
                );
              } catch (e) {
                $log.debug(sharedConstants.SEARCH_ZIPCODE_EVENT_ERROR);
              }

              return organizations;
            },
            function(error) {
              $log.debug("Error fetching org with zip ", zipCode, error);
            }
          );
        };

        $scope.confirmSubscription = function(params, meta) {
          var schoolSelected = '';
          try {
            if (meta.homeSchoolIsSelected) {
              schoolSelected = "Home School";
            } else {
              if (meta.schoolFromZipSearchIsSelected) {
                schoolSelected = "School from zip search";
              } else if (meta.defaultSchoolFromProfileIsSelected) {
                schoolSelected = "Default school from profile";
              } else {
                schoolSelected = "Another school(not default) from profile";
              }
            }
          } catch (e) {
            $log.debug("Error getting selected school type for analytics");
          }

          $log.debug("Selected school ucn", params.school.ucn);
          if (!params.school.ucn) {
            $scope.error =
              "We are not able to locate the school in our system. Please choose another school or try again later.";
            return;
          }

          if (meta.promptToAcceptLicense) {
            licenseAcceptService.userLicenseAccept().then(
              function(data) {
                $log.debug("Successfully accepted EULA", data);
              },
              function(error) {
                $log.debug("Error in accepting EULA", error);
              }
            );
          }

          var firstEntitlement = productModel.entitlementList.length < 1;

          productService.redeemAccessCode(params.accessCode, params.school.ucn, firstEntitlement, true).then(
            function(subscriptionArray) {
              // new changes due to MPC, the response returned is Array.
              var subscription = {};
              if (!!subscriptionArray && subscriptionArray.length > 0) {
                subscription = subscriptionArray[0];
              } else {
                subscription = subscriptionArray;
              }

              $log.debug("Successfully redeemed the access code", subscription);
              $scope.schoolId = subscription.schools[0].id;

              orgService
                .reloadIfMissingTeacherRoleForSchool($scope.schoolId)
                .then(function(data) {
                  $log.debug("Reload org list", data);
                });

              productModel.setCookieForOrgAsTeacher($scope.schoolId);
              userModel.currentOrg = subscription.schools[0];

              try {
                SDMAnalyticsService.addEventType(
                  sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT,
                  "subscription",
                  "schoolsOnProfile",
                  "schoolSelected",
                  "schoolUCN"
                );

                SDMAnalyticsService.emit(
                  sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT,
                  subscription,
                  meta.schools,
                  schoolSelected,
                  params.school.ucn
                );
              } catch (e) {
                $log.debug(
                  sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT_ERROR
                );
              }

              notificationModel.setMessage(sharedConstants.PRODUCT_ADD_CONFIRMATION_MESSAGE);

              var subscriptionWithOrgs = angular.copy(subscription);

              var isCM = subscriptionWithOrgs.application.rosterSupport.toLowerCase() === "cm";

              if (firstEntitlement && isCM && $scope.googleUser) {
                $location.path("/students/google/import");

                return;
              }

              if (
                isCM ||
                subscriptionWithOrgs.application.rosterSupport.toLowerCase() === "none" ||
                $scope.cleverUser
              ) {
                $location.path("/products");
              } else {
                if (classModel.classList === undefined || classModel.classList.length === 0) {
                  $location.path("/students/createfirstclass");
                } else {
                  $location.path("/products");
                }
              }
            },
            function(error) {
              $log.debug("Error redeeming the access code", error);
              var message =
                error.message ||
                error.code ||
                "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
              var notificationError;
              notificationError = {};
              notificationError["message"] = message;
              notificationModel.setMessage(JSON.stringify(notificationError));
              $location.path("/products");
            }
          );
        };
        
        $scope.volConfirmSubscription = function(params, meta) {
          return new Promise(function(resolve, reject) {
            var schoolSelected = "";

            try {
              if (meta.homeSchoolIsSelected) {
                schoolSelected = "Home School";
              } else {
                if (meta.schoolFromZipSearchIsSelected) {
                  schoolSelected = "School from zip search";
                } else if (meta.defaultSchoolFromProfileIsSelected) {
                  schoolSelected = "Default school from profile";
                } else {
                  schoolSelected = "Another school(not default) from profile";
                }
              }
            } catch (e) {
              $log.debug("Error getting selected school type for analytics");
            }

            $log.debug("Selected school ucn", params.school.ucn);

            if (!params.school.ucn) {
              throw new Error(
                "We are not able to locate the school in our system. Please choose another school or try again later."
              );
            }

            if (meta.promptToAcceptLicense) {
              licenseAcceptService.userLicenseAccept().then(
                function(data) {
                  $log.debug("Successfully accepted EULA", data);
                },
                function(error) {
                  $log.debug("Error in accepting EULA", error);
                }
              );
            }

            var firstEntitlement = productModel.entitlementList.length < 1;

            productService
              .redeemAccessCode(params.accessCode, params.school.ucn, firstEntitlement, true)
              .then(function(subscriptionArray) {
                // new changes due to MPC, the response returned is Array.
                var subscription = {};

                if (!!subscriptionArray && subscriptionArray.length > 0) {
                  subscription = subscriptionArray[0];
                } else {
                  subscription = subscriptionArray;
                }

                $log.debug(
                  "Successfully redeemed the access code",
                  subscription
                );

                userModel.currentOrg = subscription.schools[0];

                try {
                  SDMAnalyticsService.addEventType(
                    sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT,
                    "subscription",
                    "schoolsOnProfile",
                    "schoolSelected",
                    "schoolUCN"
                  );

                  SDMAnalyticsService.emit(
                    sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT,
                    subscription,
                    meta.schools,
                    schoolSelected,
                    params.school.ucn
                  );
                } catch (e) {
                  $log.debug(
                    sharedConstants.CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT_ERROR
                  );
                }

                resolve(subscriptionArray);
                return subscriptionArray;
              })
              .catch(function(error) {
                $log.debug("Error confirming subscription", error);
                reject(error);
                throw error;
              });
          });
        };

        $scope.redeemAccess = function(accessCode, ucn) {
            var firstEntitlement = productModel.entitlementList.length < 1;

            return new Promise(function(resolve, reject) {
            productService
              .redeemAccessCode(accessCode, ucn, firstEntitlement, true)
              .then(function(subscriptionArray) {
                var subscription = {};
                if (!!subscriptionArray && subscriptionArray.length > 0) {
                  subscription = subscriptionArray[0];
                } else {
                  subscription = subscriptionArray;
                }

                productModel.redeemedSubscription = subscriptionArray;

                Volume.globalNavRefreshTrigger();
                Volume.organizationSelectRefreshTrigger();

                resolve(subscription);

                //MB: Analytics code to emit an event of redeeming the access code
                try {
                  setTimeout(function() {
                    SDMAnalyticsService.addEventType(
                      sharedConstants.SDM_ANALYTICS_EVENTS
                        .REDEEM_ACCESS_CODE_EVENT,
                      "subscriptionDetails",
                      "entitlements"
                    );
                    SDMAnalyticsService.emit(
                      sharedConstants.SDM_ANALYTICS_EVENTS
                        .REDEEM_ACCESS_CODE_EVENT,
                      subscriptionArray,
                      $scope.entitlements
                    );
                  }, 2 * 1000);
                } catch (e) {
                  $log.debug(
                    sharedConstants.REDEEM_ACCESS_CODE_EVENT_ERROR_MESSAGE
                  );
                }
              })
              .catch(function(error) {
                if (error.code === "error.not.logged.in") {
                  userModel.reset();
                  $location.path("/signin/staff");
                }

                try {
                  SDMAnalyticsService.addEventType(
                    sharedConstants.SDM_ANALYTICS_EVENTS
                      .REDEEM_ACCESS_CODE_EVENT_ERROR,
                    "accessCode"
                  );
                  SDMAnalyticsService.emit(
                    sharedConstants.SDM_ANALYTICS_EVENTS
                      .REDEEM_ACCESS_CODE_EVENT_ERROR,
                    accessCode
                  );
                } catch (error) {
                  $log.debug("Error while emitting analytics event: ", error);
                }

                $log.error("Error redeeming access code", error);

                reject(error)
              });
          });
        };

        $scope.setInitialized = function(initialized) {
            $scope.initialized = initialized;
        };

        // init function
        function onInit() {
            sessionState.store('$scope',$scope);
            // $this.showAnnouncementBanner = productModel.getCookieForAnnouncements();
            $log.debug('$this.showAnnouncementBanner', $this.showAnnouncementBanner);

            navModel.activePage = 'programs';

            Volume.setStoreItem('dpCurrentRole', 'teacher');

            $this.dnaConfigCookie.userHasRosteredProduct = false
            $this.dnaConfigCookie.userHasClassroomMagazine = false
            $this.dnaConfigCookie.userHasClass = false;
            $this.dnaConfigCookie.userType = '';
            $this.dnaConfigCookie.userRosterSource = '';

            userService.getCurrentUser().then(function (currentUser) {
                $log.debug('User signed in', currentUser);

                if (!!currentUser) {
                    $this.dnaConfigCookie.userType = !!currentUser.staff ? 'staff' : 'student';

                    $this.dnaConfigCookie.userRosterSource =  !!currentUser.userIdentifiers &&
                        !!currentUser.userIdentifiers.source ? currentUser.userIdentifiers.source : 'manual';
                }

                //MB: Have to reload org list as on refresh orgModel is getting reset
                orgService.getOrgData().then(function (data) {
                    $log.debug('Reload org list in order to set the orgModel again on refresh', data);
                });

                productService.getSubscriptions().then(function (subscriptions) {
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh', subscriptions);
                    $scope.userIsAdmin = subscriptionModel.hasSubsAsAdmin();
                });

                settingsService.setSettings(sessionState).then(function(settings){
                    $log.debug('$scope',$scope);
                    $log.debug('settings',settings);

                    if (!classModel.classList) {
                        $scope.getClasses();
                    } else {
                        $log.debug('Class list', classModel.classList);
                        if (userModel.isUserLTI() && $scope.showLtiInfo) {
                            $scope.checkIfGradeSet(classModel.classList);
                        }
                    }
                });

                if (userModel.currentUser.userIdentifiers.source == 'Clever') {
                    $scope.cleverUser = true;
                }

                if (userModel.isUserLTI()) {
                    $scope.ltiUser = true;
                }

                if (userModel.isUserClasslink()) {
                    $scope.classlinkUser = true;
                }

                if (!!gapiService.isGoogleUser(userModel)) {
                    // this will also drop a gapiToken cookie
                    $log.debug('Is Google User');
                    $scope.googleUser = true;
                } else {
                    $log.debug('Is not Google User');
                }

                if (!$scope.accessCode) {
                    $scope.accessCode = productModel.getAccessCodeCookie();
                }

                if (!!$scope.accessCode) {
                    $scope.redeemAccessCode($scope.accessCode);
                    $location.search({});
                } else {
                    $this.loadEntitlements();
                }

                // clear the cookie for the access code so we do not try to redeem it again
                cookieService.removeCookie('accessCode');

                navModel.helpPage = false;
                var notificationMessage = notificationModel.popMessage();
                $scope.errorFlag = notificationModel.errorFlag;

                try {
                    if (!!notificationMessage) {
                        var message;
                        notificationMessage = JSON.parse(notificationMessage);
                        if (typeof notificationMessage === 'object') {
                            message = notificationMessage.message;
                        }
                        if (message) {
                            $scope.error = message;
                        }
                    }
                } catch (error) {
                    if (typeof notificationMessage === 'string') {
                        $scope.error = notificationMessage;
                    }
                }
            }, function (error) {
                $log.error('User not signed in', error);
                $location.path('/signin/staff');
            });
        }
    }
]);
