angular.module('portalApp').directive('addNewClassDirective', function () {
    return {
        restrict: 'E',
        scope: {
            selectedSchoolYear: '=selectedschoolyear',
            showYOYFeatures: '=showyoyfeatures'
        },
        templateUrl: 'resources/js/Modules/classes/AddNewClass/addNewClass.template.html',
        controller: 'addNewClassController' // NOTE: controller needs to be loaded first
    }
});
