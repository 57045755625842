angular.module('portalApp')
    .controller('addNewClassController',[
        '$scope','$log', '$location',
        function ($scope,$log, $location) {

            $scope.redirectToAddNewClass = function (selectedClass) {
                if ($scope.showYOYFeatures) {
                    $location.path('/students/createclass/' + $scope.selectedSchoolYear.id);
                } else {
                    $location.path('/students/createclass');
                }
            };
        }
    ]);
