portalModule.controller("updateCoteacherController",[
    '$scope','$uibModalInstance','$log',
    function($scope,$uibModalInstance, $log){
    $log.debug("updateCoteacherController ");
    $scope.errorRemovingTeacher = '';
    $scope.errorFlag = false;

    $scope.closePopup = function(response){
        $uibModalInstance.close(response);
    };

    $scope.revokeAccess = function(){
        $scope.closePopup('UNASSIGN CO-TEACHER');
    };

    $scope.makePrimaryTeacher = function(){
        $scope.closePopup('makePrimaryTeacher');
    };
}]);