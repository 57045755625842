portalModule.directive('copyToClipboard', function () {
    return {
        scope: {
            copyText: '=copyToClipboard'
        },
        restrict: 'A',
        controller: [
            '$scope',
            function ($scope) {
                $scope.supportCopy = !!document.execCommand && !!document.getSelection;

                $scope.copyToClipboard = function (str) {
                    var el = document.createElement('textarea');
                    el.value = str;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    var selected = document.getSelection().rangeCount > 0
                        ? document.getSelection().getRangeAt(0)
                        : false;
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                    if (selected) {
                        document.getSelection().removeAllRanges();
                        document.getSelection().addRange(selected);
                    }
                };
            }
        ],
        link: function (scope, element) {
            element.bind('click', function () {
                scope.copyToClipboard(scope.copyText);
            });

            if (!scope.supportCopy) {
                element.css({'display': 'none'});
            }
        }
    };
});