portalModule.directive('ngFile', [function () {
    return {
        link: function ($scope,element) {
            $scope.fileChange = function($event) {
                var fileData = { file: ($event.srcElement || $event.target).files[0] };
                $scope.getFile(fileData);
            };

            element.bind('change', $scope.fileChange);
        }
    }
}]);
