portalModule.directive('getCsvFiles', ['$parse', function ($parse) {
    /** For '.csv' file upload use the input below in HTML template:
     <input type="file" accept=".csv" ng-model="csvfiles"
     get-csv-files="parseFile($event,files)" />
     */
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ngModel) {
            var parseAttrs = $parse(attrs['getCsvFiles']);
            element.bind('change', function (event) {
                parseAttrs($scope, {$event: event, files: event.target.files});
            });
        }
    }
}]);
