(function() {
  angular
    .module("configServiceModule", [
      "apiResponseServiceModule",
      "globalConfigModule"
    ])
    .service("configService", [
      "$http",
      "apiResponse",
      "globalConfigModel",
      "$q",
      "$rootScope",
      function($http, apiResponse, globalConfigModel, $q, $rootScope) {
        var $this = this;
        $this.configMap = {};
        $this.currentConfig = {};

        return {
          getConfig: getConfig,
          fetchConfig: fetchConfig,
          mapConfig: mapConfig,
          getCurrentConfigOption: getCurrentConfigOption,
          reset: reset
        };

        function getConfig(userId, orgId) {
          var userMap = $this.configMap[userId];
          if (!userMap) {
            return fetchConfig(userId, orgId);
          }

          var orgMap = userMap[orgId];
          if (!orgMap) {
            return fetchConfig(userId, orgId);
          }

          $this.currentConfig = orgMap;

          var deferred = $q.defer();
          deferred.resolve(orgMap);

          return deferred.promise;
        }

        function mapConfig(userId, orgId, config) {
          var userMap = $this.configMap[userId];
          if (!userMap) {
            userMap = $this.configMap[userId] = {};
          }

          userMap[orgId] = config;
          $this.currentConfig = config;
        }

        function fetchConfig(userId, orgId) {
          var url =
            globalConfigModel.PORTAL_API_ENDPOINT +
            "/user/" +
            userId +
            "/configs?orgId=" +
            orgId;

          var request = $http({
            method: "get",
            url: url,
            cache: false
          });

          var deferred = $q.defer();

          apiResponse
            .makeApiCall(request)
            .then(
              function(config) {
                mapConfig(userId, orgId, config);

                if (
                  getCurrentConfigOption("isReactStudentPrograms") ||
                  getCurrentConfigOption("isReactTeacherClasses") ||
                  getCurrentConfigOption("isReactTeacherPrograms") ||
                  getCurrentConfigOption("isReactAdminPrograms")
                ) {
                  $rootScope.isReactBody = true;
                }

                deferred.resolve(config);
              },
              function(err) {
                deferred.reject(err);
              }
            )
            .catch(function(err) {
              deferred.reject(err);
            });

          return deferred.promise;
        }

        function getCurrentConfigOption(option) {
          return $this.currentConfig[option] === 'true';
        }

        function reset() {
          $this.configMap = {};
          $this.currentConfig = {};
        }
      }
    ]);
})();
