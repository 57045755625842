portalAppServices.service("teacherService", [
  "$http",
  "apiResponse",
  function($http, apiResponse) {
    return {
      loadTeachersBySchool: loadTeachersBySchool,
      loadTeachersForAClass: loadTeachersForAClass,
      matchTeacherEmail: matchTeacherEmail,
      addCoTeacherToClass: addCoTeacherToClass,
      removeCoTeacher: removeCoTeacher,
      makePrimaryTeacher: makePrimaryTeacher,
      loadTeachersYOYForAClass: loadTeachersYOYForAClass
    };

    function loadTeachersBySchool(staffId, schoolId) {
      // https://dp-api-dev1.scholastic-labs.io/composite/staff/2/organizations/1/teachers
      //  + staffId
      var request = $http({
        method: "get",
        url:
          PORTAL_API_ENDPOINT +
          "/composite/staff/" +
          "organizations/" +
          schoolId +
          "/teachers"
      });
      return apiResponse.makeApiCall(request);
    }

    function loadTeachersForAClass(staffId, startYear) {
      var params = {
        includePrimaryTeacher: true,
        includeTeachers: true,
        staffId: staffId,
        startYear: startYear
      };

      var request = $http({
        method: "GET",
        url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections",
        params: params
      });

      return apiResponse.makeApiCall(request);
    }

    function loadTeachersYOYForAClass(calendarId) {
      var params = {
        includePrimaryTeacher: true,
        includeTeachers: true,
        schoolCalendarId: calendarId
      };

      var request = $http({
        method: "GET",
        url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections",
        params: params
      });

      return apiResponse.makeApiCall(request);
    }

    function matchTeacherEmail(staffId, orgId, email) {
      var params = { email: email };

      var request = $http({
        method: "GET",
        url:
          PORTAL_API_ENDPOINT +
          "/composite/staff/organization/" +
          orgId +
          "/roster/teacher",
        params: params
      });

      return apiResponse.makeApiCall(request);
    }

    function addCoTeacherToClass(classSectionId, orgId, teacherId) {
      var params = {
        classSectionId: classSectionId,
        teacherId: teacherId
      };

      var request = $http({
        method: "POST",
        url:
          PORTAL_API_ENDPOINT +
          "/composite/staff/organization/" +
          orgId +
          "/roster/teacher/class/assign",
        params: params
      });

      return apiResponse.makeApiCall(request);
    }

    function removeCoTeacher(teacherObject) {
      // /composite/staff/organization/{orgId}/roster/teachers/classes/unassign

      var orgId = teacherObject.organizationId;
      var params = {
        classSectionIdList: [teacherObject.classSectionId],
        teachersIdList: [teacherObject.id]
      };

      var request = $http({
        method: "POST",
        url:
          PORTAL_API_ENDPOINT +
          "/composite/staff/organization/" +
          orgId +
          "/roster/teachers/classes/unassign",
        params: params
      });

      return apiResponse.makeApiCall(request);
    }

    function makePrimaryTeacher(teacherObject) {
      /**
        teacher-composite-controller
        Teacher Composite Controller
        PUT
        /composite/staff/{staffId}/roster/sections/{sectionId}
        Update a class section as Staff
      */
      var sectionId = teacherObject.classSectionId;
      var data = teacherObject.classSection;
      var request = $http({
        method: "PUT",
        url:
          PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId,
        data: JSON.stringify(data)
      });

      return apiResponse.makeApiCall(request);
    }
  }
]);
