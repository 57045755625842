angular.module('portalApp')
    .controller('subscriptionNavBarController',[
        '$scope','$location',
    function ($scope,$location) {
        $scope.redirectTo = redirectTo;

        function redirectTo(message) {
            if (message == 'subscriptions') {
                $location.path('/subscriptions');
            } else if (message == 'schoolyear') {
                $location.path('/schoolyear');
            }
        }

        $scope.isActive = function(viewLocation){
            return $location.path().indexOf(viewLocation) > -1;
        };

    }]);