portalModule.service("ltiDataModel", ["$log", function ($log) {

    var initialState = {
        organizationLtiInfoList: [],
        school: null,
        url: null
    };

    function updateDataModel (data) {
        return angular.merge(dataObject.ltiData, data);
    }

    function reset () {
        dataObject.ltiData = angular.copy(initialState);
    }

    function getLtiInfo () {
        var info = dataObject.ltiData.organizationLtiInfoList.filter(function (orgInfo) {
            return !orgInfo.expiryDate;
        });
        return angular.merge({}, dataObject.ltiData, {
            activeLtiKey: info[0].ltiKey,
            activeLtiSecret: info[0].ltiSecret
        });
    }

    function getLtiUrlForApp (appId) {
        return [dataObject.ltiData.url, 'app', appId].join('/');
    }

    var dataObject = {
        reset: reset,
        updateDataModel: updateDataModel,
        getLtiUrlForApp: getLtiUrlForApp,
        getLtiInfo: getLtiInfo,
        ltiData: angular.copy(initialState)
    };

    return dataObject;
}]);