portalModule.service('ltiService',
    ['$http', '$q', '$log', 'apiResponse', 'globalConfigModel', 'ltiDataModel',
        function ($http, $q, $log, apiResponse, globalConfigModel, ltiDataModel) {

            return {
                getInfo: getInfo,
                generateKey: generateKey,
                deactivateKey: deactivateKey
            };

            function getInfo(orgId) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/staff/organization/' + orgId + '/lti/info';
                var request = $http({
                    method: 'get',
                    url: url,
                    cache: false
                });
                return apiResponse.makeApiCall(request).then(function (data) {
                    ltiDataModel.reset();
                    ltiDataModel.updateDataModel(data);
                    return data;
                });
            }

            function generateKey(orgId) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/staff/organization/' + orgId + '/lti/generate';
                var request = $http({
                    method: 'POST',
                    url: url
                });
                return apiResponse.makeApiCall(request);
            }

            function deactivateKey(orgId, key) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/staff/organization/' + orgId + '/lti/deactivate';
                var request = $http({
                    method: 'PUT',
                    url: url,
                    params: {
                        consumerKey: key
                    }
                });
                return apiResponse.makeApiCall(request);
            }

        }
    ]
);