/**
 * Created by jhouser on 2/12/2016.
 */

portalModule.controller("SubscriptionHomeController", [
    '$scope', '$rootScope', '$log', '$location', 'productService', 'orgService',
    'subscriptionModel', 'userModel', 'orgModel', 'navModel', '$uibModal',
    '$timeout', 'SDMAnalyticsService', 'sharedConstants', 'notificationModel', 'appModel',
    'settingsService', 'userService', '$window', 'configService', 'calendarService',
    function ($scope, $rootScope, $log, $location, productService, orgService,
              subscriptionModel, userModel, orgModel, navModel, $uibModal,
              $timeout, SDMAnalyticsService, sharedConstants, notificationModel, appModel,
              settingsService, userService, $window, configService, calendarService) {
        $log.debug("In SubscriptionController init");

        var $this = this;
        $this.sortAppCode = "lba";

        $this.onInit = onInit;
        $this.loadSubscriptions = loadSubscriptions;
        $this.sortSubscriptions = sortSubscriptions;

        $scope.showYOYFeatures = false;
        //MB: Function which loads subscriptions for school admin/district admin
        $scope.initialized = false;
        $scope.showDownloadAccessCodeButton = false;
        $scope.showLtiInfo = false;
        $scope.hideDropDown = true;
        $scope.showSiteAccessCode = false;
        $scope.cleverSchool = false;
        $scope.errorRetrievingData = '';
        $scope.errorFlag = false;

        $this.onInit();

        $scope.$on('setInitialized', function($event, initialized) {
            $scope.setInitialized(initialized);
        });

        $scope.$on('subscriptionsForOrg', function ($event, newValue) {
            if (!!newValue) {
                $scope.subscriptionsForOrg = newValue;

                navModel.showNav = true;
                navModel.helpPage = false;
                navModel.isInSubscription = true;
                $scope.cleverSchool = orgModel.isOrgClever(newValue);

                Volume.setStoreItem('dpOrgId', newValue.id);

                if (
                  userModel.currentUser &&
                  userModel.currentUser.staff &&
                  userModel.currentUser.staff.id &&
                  newValue.id
                ) {
                    configService.getConfig(userModel.currentUser.staff.id, newValue.id);
                }

                if ($scope.cleverSchool) {
                    var subs = newValue.subscription;

                    if (subs && subs.length) {
                        for (var i = 0; i < subs.length; i++) {
                            if (newValue.subscription[i].rosterSource === "Manual") {
                                $scope.cleverSchool = false;
                                break;
                            }
                        }
                    }
                }
                $this.sortSubscriptions();
            }
        });

        $scope.$on('orgChanged', function($event, orgId) {
            if (subscriptionModel.orgAndSubscriptions) {
                $scope.subscriptionsForOrg = subscriptionModel.getOrgWithSub(orgId)[0];

                // setting up user model
                userModel.currentOrg = $scope.subscriptionsForOrg;
                $this.sortSubscriptions();

                SDMAnalyticsService.addEventType(
                  sharedConstants.SUBSCRIPTION_HOME_TAB,
                  "orgId"
                );
                SDMAnalyticsService.emit(
                  sharedConstants.SUBSCRIPTION_HOME_TAB,
                  orgId
                );
            }
        });

        $scope.closeAlert = function () {
            $scope.errorRetrievingData = '';
            $scope.errorFlag = false;
        };

        $scope.viewLtiInformation = function (orgId) {
            $location.path('/lti/' + orgId + '/info');
        };

        $scope.viewSubscription = function (orgId, subId) {
            $log.debug('in view subscription', orgId, subId);
            $location.path('/subscriptions/' + orgId + '/' + subId);
        };

        $scope.launchApp = function(orgId, appId, launchType) {
            //MB: Analytics code for sending admin product launch event
            try {
                SDMAnalyticsService.addEventType(sharedConstants.PRODUCT_LAUNCH_ADMIN_EVENT, 'applicationId', 'schoolId', 'entitlements');
                SDMAnalyticsService.emit(sharedConstants.PRODUCT_LAUNCH_ADMIN_EVENT, appId, orgId, subscriptionModel.orgAndSubscriptions);
            } catch (e) {
                $log.debug(sharedConstants.PRODUCT_LAUNCH_ADMIN_EVENT_ERROR);
            }

            appModel.setCurrentApp(appId);

            productService.launchData(appId, orgId, 'staff', true, launchType)
              .then(function(data) {
                  $log.debug('Launch data for app #', appId, ' : ', data);
                  $scope.outboundLaunch = data;
                  $scope.launchParams = data.params;

                  $timeout(function() {
                      if (launchType === 'oauth') {
                          $window.location.href = $scope.outboundLaunch.launchUrl;
                          return;
                      }

                      var form = $('#launchForm');
                      form.attr('action', $scope.outboundLaunch.launchUrl);
                      form.submit();
                      $scope.loading = false;
                  }, 0);

              }, function(error) {
                  if (error.code === 'error.not.logged.in') {
                      userModel.reset();
                      $location.path('/signin/staff');
                  }
                  $log.error('In error handler', error);
                  $scope.errorRetrievingData = error.message || 'An error occurred. Please try again later.';
                  $scope.errorFlag = true;
              });
        };

        $scope.downloadSubscriptionAccessCodes = function () {
            userService.getCurrentUserWithoutRedirect().then(function (data) {

                try {
                    SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.DOWNLOAD_ORGANIZATION_SUBSCRIPTIONS_ACCESS_CODES_EVENT, 'orgAndSubscriptions');
                    SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.DOWNLOAD_ORGANIZATION_SUBSCRIPTIONS_ACCESS_CODES_EVENT, $scope.subscriptionsForOrg);
                } catch (e) {
                    $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.DOWNLOAD_ORGANIZATION_SUBSCRIPTIONS_ACCESS_CODES_EVENT, e);
                }

                $log.debug('User is signed in. Downloading subscription access codes', data);
                $window.location.href = PORTAL_API_ENDPOINT + "/composite/staff/organization/" + $scope.subscriptionsForOrg.id + "/access_codes?format=csv";
            }, function (error) {
                $log.debug('User is not signed in. Redirecting to staff login page', error);
                userModel.reset();
                $location.path('/signin/staff');
            });
        };

        function sortSubscriptions() {
            if(
              !!$scope.subscriptionsForOrg &&
              !!$scope.subscriptionsForOrg.subscription &&
              !!$scope.subscriptionsForOrg.subscription[0] &&
              !!$scope.subscriptionsForOrg.subscription[0].application &&
              !!$scope.subscriptionsForOrg.subscription[0].application.applicationCode){
                if($scope.subscriptionsForOrg.subscription.some(function(elem){
                    return elem.application.applicationCode === $this.sortAppCode;
                })){
                    $scope.subscriptionsForOrg.subscriptionsMap = $scope.subscriptionsForOrg.subscription.reduce(function(hashMap, sub){
                        var key = sub.application.applicationCode;
                        if (!hashMap[key]) {
                            hashMap[key] = [];
                        }
                        hashMap[key].push(sub);
                        return hashMap;
                    }, {});
                    if($scope.subscriptionsForOrg.subscriptionsMap[$this.sortAppCode].length > 1){
                        $scope.subscriptionsForOrg.sortedSubscriptions = $scope.subscriptionsForOrg.subscription.filter(function(sub){
                            return sub.application.applicationCode !== $this.sortAppCode;
                        });
                        $scope.subscriptionsForOrg.sortAppCode = $this.sortAppCode;
                        var repeatedSubscriptions = $scope.subscriptionsForOrg.subscription.filter(function(sub){
                            return sub.application.applicationCode === $this.sortAppCode;
                        });
                        var singleEntry = JSON.parse(JSON.stringify(repeatedSubscriptions[0]));
                        $scope.subscriptionsForOrg.sortedSubscriptions.push(singleEntry);
                    } else {
                        $scope.subscriptionsForOrg.sortedSubscriptions = $scope.subscriptionsForOrg.subscription;
                    }
                } else {
                    $scope.subscriptionsForOrg.sortedSubscriptions = $scope.subscriptionsForOrg.subscription;
                }
            }

            $scope.setInitialized(true);
        }

        function loadSubscriptions() {
          navModel.showNav = false;
          navModel.helpPage = false;

          productService
            .getSubscriptions()
            .then(function(subscriptions) {
              $log.debug("Load Subscriptions success handler", subscriptions);
              if (subscriptionModel.lacksSubsAsAdmin()) {
                $location.path("/products");
              }
              $scope.orgAndSubscriptions = subscriptions;

              if ($scope.orgAndSubscriptions.length >= 1) {
                var cookie = subscriptionModel.getCookieForOrgAsAdmin();

                if (cookie && cookie.orgId) {
                  $scope.subscriptionsForOrg = subscriptionModel.getOrgWithSub(
                    cookie.orgId
                  )[0];
                }

                if ($scope.subscriptionsForOrg === undefined) {
                  $scope.subscriptionsForOrg = $scope.orgAndSubscriptions[0];
                  subscriptionModel.setCookieForOrgAsAdmin(
                    $scope.orgAndSubscriptions[0].id
                  );
                }
              }

              // setting up user model
              userModel.currentOrg = $scope.subscriptionsForOrg;

              if (
                userModel.currentUser &&
                userModel.currentUser.staff &&
                userModel.currentUser.staff.id &&
                userModel.currentOrg &&
                userModel.currentOrg.id
              ) {
                calendarService.getSchoolYearInformation(
                  userModel.currentOrg.id
                );

                configService
                  .getConfig(
                    userModel.currentUser.staff.id,
                    $scope.subscriptionsForOrg.id
                  )
                  .catch(function(error) {
                    $scope.errorRetrievingData =
                      error.message ||
                      "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                  })
                  .finally($this.sortSubscriptions);
              } else {
                $scope.setInitialized(true);
              }

              navModel.showNav = true;

              var defaultOrg = $scope.subscriptionsForOrg
                ? $scope.subscriptionsForOrg.id
                : $scope.orgAndSubscriptions
                ? $scope.orgAndSubscriptions[0].id
                : undefined;

              if (defaultOrg) {
                SDMAnalyticsService.addEventType(
                  sharedConstants.SUBSCRIPTION_HOME_TAB,
                  "orgId"
                );

                SDMAnalyticsService.emit(
                  sharedConstants.SUBSCRIPTION_HOME_TAB,
                  defaultOrg
                );
              }
            })
            .catch(function(error) {
              if (error.code == "error.not.logged.in") {
                userModel.reset();
                $location.path("/signin/staff");
              }
              $log.error("Error loading Subscriptions", error);
              $scope.errorRetrievingData =
                error.message ||
                "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
            });
        }

        $scope.onCardClick = function($event, application) {
            var orgId = Volume.getStoreItem('dpOrgId');
            $scope.launchApp(orgId, application.id, application.launchType);
            $scope.$apply();
        };

        $scope.onManageClick = function($event, subscription) {
            var orgId = Volume.getStoreItem('dpOrgId');
            $location.path('/subscriptions/' + orgId + '/' + subscription.id);
            $scope.$apply();
        };

        $scope.setInitialized = function(initialized) {
            $scope.initialized = initialized;
        };

        function onInit() {
            userService.getCurrentUser()
              .then(function() {
                  navModel.activePage = 'programs';
                  Volume.setStoreItem('dpCurrentRole', 'admin');

                  //MB: Although not necessary to get org data. However on refresh I am losing context which determines the user is school admin or not.
                  orgService.getOrgData().then(function(data) {
                      $log.debug('getOrgData', data);

                      settingsService.getSettings().then(function(settings) {
                          $log.debug('Settings: ', settings);

                          $scope.showSiteAccessCode = !!settings.showSiteAccessCode;
                          $scope.showLtiInfo = !!settings.showLtiInfo;
                          $scope.showYOYFeatures = !!settings.showYOYFeatures;
                          $scope.showDownloadAccessCodeButton = !!settings.showDownloadAccessCodeButton;
                      });

                      productService.getEntitlement().then(function(data) {
                          $log.debug('Product entitlements', data);
                          $this.loadSubscriptions();
                      });
                  });
              })
              .catch(function(error) {
                  $log.error('User not signed in', error);
                  $location.path('/signin/staff');
              });
        }
    }
]);
