angular.module('portalApp').directive('loginViewContentdirective', function () {
    return {
        restrict: 'E',
        scope: {
            appCode: '=',
            staff: '=',
            user: '=',
            errorMessage :'=',
            errorFlag : '=',
            validate: '='
        },
        controller: 'loginViewContentController',
        templateUrl: 'resources/js/Modules/login/loginViews/loginView.template.html',
    }
});
