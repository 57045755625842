(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule.controller("EasyLoginPasswordController",
                            ['$scope',
                             '$http',
                             '$location',
                             '$rootScope',
                             'userService',
                             'orgService',
                             'userModel',
                             '$log',
                             'navModel',
                             'orgModel',
                             '$routeParams',
                             'loginModel',
                             '$cookies',
                             'sharedConstants',
                             'SDMAnalyticsService',
                             'appModel',
                             'classModel',
                             'subscriptionModel',
                             'studentModel',
                             'sessionModel',
                             'productModel',
                             'notificationModel',
                             'easyLoginModel',
                             '$uibModal',
                             function($scope, $http, $location, $rootScope, userService, 
                                      orgService, userModel, $log, navModel, orgModel, 
                                      $routeParams, loginModel, $cookies, sharedConstants, 
                                      SDMAnalyticsService, appModel, classModel, subscriptionModel, 
                                      studentModel, sessionModel, productModel, notificationModel, easyLoginModel, $uibModal){
                                
                                var $this = this;
                                $this.onInit = onInit;
                                $this.getEasyLoginDetails = getEasyLoginDetails;
                                $this.getSelectedStudent = getSelectedStudent;
                                $this.showAccountLockPopup = showAccountLockPopup;
                                $this.isLocked = false;
                                $scope.classId = $routeParams.classId;
                                $scope.classSecret = $routeParams.classSecret;
                                $scope.studentId = $routeParams.studentId;
                                $scope.accountSecretArray = easyLoginModel.getEasyLoginSecrets();
                                $scope.error = '';
                                $scope.secretIndex = undefined;
                                $scope.initialized = false;
                                $this.onInit();
                                
                                $scope.easyLoginSubmit = function(secret,index){
                                    $scope.secretIndex = undefined;
                                    userService.easyLoginSignInUser($scope.classId, $scope.classSecret, $scope.studentId, secret.id.toString()).then(function(data){
                                        $log.debug('User successfully signed in', data);
                                        try {
                                            SDMAnalyticsService.addEventType(sharedConstants.EASY_LOGIN_EVENT, 'userContext');
                                            SDMAnalyticsService.emit(sharedConstants.EASY_LOGIN_EVENT, data);
                                        } catch (e) {
                                            $log.debug(sharedConstants.EASY_LOGIN_EVENT_ERROR);
                                        }
                                        easyLoginModel.setEasyLoginCookie($scope.classId, $scope.classSecret);
                                        $location.path('/');
                                    }, function(error){
                                        $log.debug('Error signing in using easy login: ', error);
                                        if(error.code && error.code === 'error.easylogin.incorrect.credentials' && error.numAttempts && error.numAttempts === 5) {
                                            $this.isLocked = false;
                                            $this.showAccountLockPopup();
                                            return;
                                        }
                                        if(error.code && error.code === 'error.easylogin.account.locked') {
                                            $this.isLocked = true;
                                            $this.showAccountLockPopup();
                                            return;
                                        }
                                        $scope.error = "You did not select the correct picture password. Please try again or contact your teacher if you don't remember your picture password.";
                                        $scope.secretIndex = index;
                                    });
                                };
                                
                                $scope.goBack = function(){
                                    $location.path('/cz/' + $scope.classId + '/' + $scope.classSecret);
                                };
                                
                                $scope.closeAlert = function(){
                                    $scope.error = '';
                                }
                                
                                function getEasyLoginDetails(){
                                    if(easyLoginModel.easyLoginClassDetails){
                                        $this.getSelectedStudent(easyLoginModel.easyLoginClassDetails.students);
                                    }
                                    else{
                                        userService.getEasyLoginClassDetails($scope.classId, $scope.classSecret).then(function(data){
                                            $log.debug('Easy login class details', data);
                                            $scope.easyLoginDetails = data;
                                            $this.getSelectedStudent($scope.easyLoginDetails.students);
                                        },function(error){
                                            $log.debug('Error getting easy login class details', error);
                                            $scope.goBack();
                                        });
                                    }
                                }
                                
                                function getSelectedStudent(students){
                                    for(var student in students){
                                        if(students[student].id == $scope.studentId){
                                            $scope.selectedStudent = students[student];
                                            $log.debug('Selected student details: ', $scope.selectedStudent);
                                            break;
                                        }
                                    }
                                    $scope.initialized = true;
                                }
                                
                                function showAccountLockPopup() {
                                    var chooseUserIconUrl = '/cz/' + $scope.classId + '/' + $scope.classSecret;
                                    var modalInstance = $uibModal.open({
                                        templateUrl: 'resources/js/Modules/login/easyLogin/easyLoginPasswordLockPopup/easyLoginPasswordLockPopup.html',
                                        controller: 'EasyLoginPasswordLockPopupController',
                                        resolve : {
                                            chooseUserIconUrl : function() { return chooseUserIconUrl; },
                                            isLocked: function() { return $this.isLocked; }
                                        },
                                        backdrop: 'static'
                                    })
                                    .result.then(function(data){
                                        $log.debug('Modal closed');
                                    });
                                }
                                
                                function onInit() {
                                    
                                    userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                                        $log.debug("User already logged in", currentUser);
                                        $location.path('/');
                                    }, function (error) {
                                        appModel.reset();
                                        classModel.reset();
                                        navModel.reset();
                                        orgModel.reset();
                                        subscriptionModel.reset();
                                        studentModel.reset();
                                        userModel.reset();
                                        sessionModel.reset();
                                        productModel.reset();
                                        $log.debug("User not signed in", error);
                                        $this.getEasyLoginDetails();
                                    });
                                    
                                    navModel.showFooter = true;
                                }
                                
                            }]);
})();