portalModule.service("enrollmentService",['$http', '$log', 'apiResponse', 'userService', '$q', function($http, $log, apiResponse, userService, $q) {
	return{
		getStudentEnrollment: getStudentEnrollment,
		updateStudentSubscription: updateStudentSubscription,
		addStudentSubscription: addStudentSubscription,
		deleteStudentSubscription: deleteStudentSubscription,
		updateAllStudentsSubscription: updateAllStudentsSubscription,
		addAllStudentsSubscription: addAllStudentsSubscription,
		deleteAllStudentsSubscription: deleteAllStudentsSubscription,
        validateStudentSubscription: validateStudentSubscription
	};
	
	function httpAction(method, uri, requestData, preprocessor) {
		var deferred = $q.defer();
		userService.ensureIsStaffMember().then(function(currentUser) {
			var request = $http({
				method: method,
				url: uri,
				data: requestData
	        });
			return apiResponse.makeApiCall(request).then(function(responseData) {
				$log.debug("Got response for ", method, " ", uri, " responseData: ", responseData);
				deferred.resolve(responseData);
			},function(error){
				deferred.reject(error);
			});
		}, function(error) {
			deferred.reject(error);
		}); 
		return deferred.promise;
	}
	
	function getStudentEnrollment(sectionId){
		return httpAction("get", PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/entitlements"+"?withPasswords=true");
	}
	
	function updateStudentSubscription(action,sectionId,subscriptionId,studentId){
		var uri = PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/entitlements/" + subscriptionId + "/" + studentId;
		return httpAction(action, uri);		
	}
	
	function updateAllStudentsSubscription(action,sectionId,subscriptionId){
		var uri = PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/entitlements/" + subscriptionId;
		return httpAction(action, uri);
	}
	
	function addStudentSubscription(sectionId,subscriptionId,studentId){
		return updateStudentSubscription("put",sectionId,subscriptionId,studentId);
	}
	
	function deleteStudentSubscription(sectionId,subscriptionId,studentId){
		return updateStudentSubscription("delete",sectionId,subscriptionId,studentId);
	}
	
	function addAllStudentsSubscription(sectionId,subscriptionId){
		return updateAllStudentsSubscription("put",sectionId,subscriptionId);
	}
	
	function deleteAllStudentsSubscription(sectionId,subscriptionId){
		return updateAllStudentsSubscription("delete",sectionId,subscriptionId);
	}

	function validateStudentSubscription(sectionId, subscriptionId) {
        var uri = PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/entitlements/" + subscriptionId + '/validate';
        return httpAction('POST', uri);

    }
}]);