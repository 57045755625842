angular.module('portalApp').directive('addStudentContentdirective', function () {
    return {
        restrict: 'E',
        scope: {
            orgId: '=orgid',
            sectionId: '=sectionid',
            sectionName :'=sectionname',
            coTeacher: '=?coteacher'
        },
        templateUrl: 'resources/js/Modules/students/addStudents/addStudentHomeTemplate/addStudentContent.template.html',
        controller: 'addStudentContentController' // NOTE: controller needs to be loaded first
    }
});
