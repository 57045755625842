(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule.controller("SchoolEasyLoginController",
                            ['$scope',
                             '$http',
                             '$location',
                             '$rootScope',
                             'userService',
                             'orgService',
                             'userModel',
                             '$log',
                             'navModel',
                             'orgModel',
                             '$routeParams',
                             'loginModel',
                             '$cookies',
                             'sharedConstants',
                             'SDMAnalyticsService',
                             'appModel',
                             'classModel',
                             'subscriptionModel',
                             'studentModel',
                             'sessionModel',
                             'productModel',
                             'notificationModel',
                             'easyLoginModel',
                             '$document',
                             function($scope, $http, $location, $rootScope, userService, 
                                      orgService, userModel, $log, navModel, orgModel, 
                                      $routeParams, loginModel, $cookies, sharedConstants, 
                                      SDMAnalyticsService, appModel, classModel, subscriptionModel, 
                                      studentModel, sessionModel, productModel, notificationModel, 
                                      easyLoginModel, $document){
                                
                                var $this = this;
                                $this.onInit = onInit;
                                $this.getSchoolEasyLoginDetails = getSchoolEasyLoginDetails;
                                $scope.orgId = $routeParams.orgId;
                                $scope.orgSecret = $routeParams.orgSecret;
                                $scope.error = false;
                                $scope.errorMessage = '';
                                $scope.initialized = false;
                                $this.onInit();
                                
                                $scope.chooseClass = function(selectedClass){
                                    $log.debug('Selected Easy Login Class: ', selectedClass);
                                    $location.path('/cz/' + selectedClass.id + '/' + selectedClass.secret);
                                }
                                
                                $scope.redirectToLoginPage = function(){
                                    $location.path('/signin');
                                };
                                
                                $scope.closeAlert = function(){
                                    $scope.errorMessage = '';
                                }
                                
                                function getSchoolEasyLoginDetails(){
                                    userService.getSchoolEasyLoginDetails($scope.orgId, $scope.orgSecret).then(function(data){
                                        $log.debug('School Easy login class details', data);
                                        $scope.schoolEasyLoginDetails = data;
                                        $scope.initialized = true;
                                    },function(error){
                                        $log.debug('Error getting school easy login class details', error);
                                        $scope.error = true;
                                        $scope.initialized = true;
                                    });
                                }
                                
                                function onInit() {
                                    
                                    userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                                        $log.debug("User already logged in", currentUser);
                                        $location.path('/');
                                    }, function (error) {
                                        appModel.reset();
                                        classModel.reset();
                                        navModel.reset();
                                        orgModel.reset();
                                        subscriptionModel.reset();
                                        studentModel.reset();
                                        userModel.reset();
                                        sessionModel.reset();
                                        productModel.reset();
                                        easyLoginModel.reset();
                                        $log.debug("User not signed in", error);
                                        $this.getSchoolEasyLoginDetails();
                                        $scope.errorMessage = notificationModel.popMessage();
                                    });
                                    navModel.showFooter = true;
                                }
                                
                            }]);
})();