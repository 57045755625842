var PORTAL_API_ENDPOINT = ".";

angular.module("globalConfigModule",[]).service("globalConfigModel",function() {

    var dataObject = {
        PORTAL_API_ENDPOINT : '.'
    };

    return dataObject;
});



