portalModule.controller("StudentPortalController", [
  '$scope', '$log', '$location', '$routeParams', '$timeout',
  'userService', 'productService', 'studentPortalAppService', 'navModel', '$window',
  'sharedConstants', 'SDMAnalyticsService', 'notificationModel', 'easyLoginModel', '$uibModal',
  'userModel', 'configService',
  function ($scope, $log, $location, $routeParams, $timeout,
            userService, productService, studentPortalAppService, navModel, $window,
            sharedConstants, SDMAnalyticsService, notificationModel, easyLoginModel, $uibModal,
            userModel, configService) {
        var $this = this;
        $this.onInit = onInit;
        $this.getSchoolInfo = getSchoolInfo;
        $this.getAppsforStudent = getAppsforStudent;

        navModel.showNav = false;
        navModel.helpPage = false;

        $scope.entitlements = undefined;
        $scope.errorRetrievingData = "";
        $scope.initialized = false;

        $scope.shouldChangePassword = false;

        function getSchoolInfo() {
            studentPortalAppService.getSchoolInfo().then(function(data){
                $log.debug("Schools for student:", data);

                $scope.orgs = data;

                if ($scope.orgs && $scope.orgs.length > 0) {
                  $scope.currentOrg = $scope.orgs[0];
                  userModel.currentOrg = $scope.currentOrg;

                  Volume.setStoreItem('dpOrgId', $scope.currentOrg.id);
                  window.dumbleDataHelpers.updateOrgData($scope.currentOrg);
                  $this.getAppsforStudent($scope.currentOrg.id);

                  studentPortalAppService.getClassSections();

                  // We are not getting the config consistently for students after logging in, so
                  // after we have their current org and we know they are a current user, let's make
                  // sure to get it if we have already fetched it, or fetch it now.
                  if (
                    userModel.currentUser &&
                    userModel.currentUser.student &&
                    userModel.currentUser.student.id &&
                    userModel.currentOrg &&
                    userModel.currentOrg.id
                  ) {
                    configService.getConfig(
                      userModel.currentUser.student.id,
                      userModel.currentOrg.id
                    );
                  }
                }
            }, function(error){
                $log.debug("Getting schools for student failed:", error);
            });
        }

        function getAppsforStudent(orgId) {
            studentPortalAppService.getAppsforStudent(orgId).then(function(data){
                $log.debug("Applications student has access to:", data);
                $scope.entitlements = data;
                $scope.initialized = true;
                var fromNavWidget = $location.search().navWidget;
                if (data.length === 1 && fromNavWidget !== "true" && $scope.orgs.length < 2 && !$scope.shouldChangePassword) {
                    var appId = data[0].id;
                    $scope.launchApp(appId, $scope.currentOrg.id);
                } else if (fromNavWidget === "true") {
                    $location.search({});
                }
                else{
                    $log.debug('Not directly launching the app');
                }
            }, function(error){
                $log.debug("Getting applications for student failed", error);
            });
        }

        function onInit() {
            userService.getCurrentUser().then(function (currentUser) {
                if (!currentUser.student) {
                    $location.path("/signin");
                    return;
                }

                navModel.activePage = 'programs';
                Volume.setStoreItem('dpCurrentRole', 'student');

                if (currentUser.sessionContext.easyLogin) {
                    $scope.easyLoginSession = true;
                    var easyLoginCred = easyLoginModel.getEasyLoginCookie();
                    if (easyLoginCred) {
                        if (easyLoginModel.easyLoginClassDetails) {
                            $log.debug('Easy login class details', easyLoginModel.easyLoginClassDetails);
                        } else {
                            userService.getEasyLoginClassDetails(easyLoginCred.classId, easyLoginCred.classSecret).then(function (data) {
                                $log.debug('Easy login class details', data);
                            }, function (error) {
                                $log.debug('Error getting easy login class details', error);
                            });
                        }
                    }
                }
                navModel.showNav = true;
                navModel.helpPage = false;
                var notificationMessage = notificationModel.popMessage();
                try {
                    if (!!notificationMessage) {
                        var message;
                        notificationMessage = JSON.parse(notificationMessage);
                        if (typeof notificationMessage === 'object') {
                            message = notificationMessage.message;
                        }
                        if (message) {
                            $scope.errorFlag = true;
                            $scope.error = message;
                        }
                    }
                } catch (error) {
                    if (typeof notificationMessage === 'string') {
                        $scope.error = notificationMessage;
                    }
                }

                $this.getSchoolInfo();

                $scope.shouldChangePassword = !!$location.search().changePassword;
                if ($scope.shouldChangePassword) {
                  $scope.changePassword();
                }
            }, function (error) {
                $location.path("/signin");
            });
        }

        $scope.closeAlert = function () {
            $scope.error = '';
        };

        $scope.changeOrg = function (org) {
            $scope.currentOrg = org;
            userModel.currentOrg = $scope.currentOrg;
            $this.getAppsforStudent($scope.currentOrg.id);
            Volume.setStoreItem('dpOrgId', $scope.currentOrg.id);
            window.dumbleDataHelpers.updateOrgData($scope.currentOrg);
        };

        $scope.launchApp = function(appId, orgId, launchType) {
            try {
                SDMAnalyticsService.addEventType(sharedConstants.PRODUCT_LAUNCH_STUDENT_EVENT, 'applicationId', 'entitlements');
                SDMAnalyticsService.emit(sharedConstants.PRODUCT_LAUNCH_STUDENT_EVENT, appId, $scope.entitlements);
            } catch (e) {
                $log.debug(sharedConstants.PRODUCT_LAUNCH_STUDENT_EVENT_ERROR);
            }

            productService.launchData(appId, orgId, 'student', false, launchType)
                .then(function(data) {
                    $log.debug('Launch data for app #', appId, ' : ', data);
                    $scope.outboundLaunch = data;
                    $scope.launchParams = data.params;
                    $timeout(function() {
                        if (launchType === 'oauth') {
                          $window.location.href = $scope.outboundLaunch.launchUrl;
                          return;
                        }

                        var form = $('#launchForm');
                        form.attr('action', $scope.outboundLaunch.launchUrl);
                        form.submit();
                        $scope.loading = false;
                    }, 0);
                }, function(error) {
                    $log.error('In error handler', error);
                    $scope.error = error.message || error.code || sharedConstants.ERROR_PROBLEM_UNKNOWN;
                    $scope.errorFlag = true;
                });
        };

        $scope.changePassword = function() {
          $uibModal
            .open({
              templateUrl:
                "resources/js/Modules/changePassword/changePassword.html",
              controller: "ChangePasswordPopupController"
            })
            .result.then(function(data) {
              if (data.msg) {
                $scope.error = data.msg;
                $scope.errorFlag = false;
              }

              $location.search('changePassword', null);
            });
        };

        // New UX functions

        $scope.onCardClick = function($event, application) {
          $scope.launchApp(
            application.id,
            $scope.currentOrg.id,
            application.launchType
          );
          $scope.$apply();
        };

        $scope.setInitialized = function(initialized) {
          $scope.initialized = initialized;
        };

        $this.onInit();
    }
]);
