/**
 * Created by jhouser on 9/14/2015.
 *
 * This is an angular component created to allow for addition of a single student
 * within the manual 'add student' popup
 */

var newStudent = {};
newStudent.scripts = document.getElementsByTagName("script");
newStudent.currentScriptPath = newStudent.scripts[newStudent.scripts.length - 1].src;

angular.module("newStudent", ['ui.bootstrap']).directive('sdpNewstudent',
    function() {
        return {

            restrict: 'AE',
            templateUrl: 'resources/js/Modules/students/newStudent/NewStudent.html',
            transclude: true,
            scope: {
                options: "=?",
                grades: "=?",
                onfirstnamechange: "&",
                onlastnamechange: "&",
                ongradechange: "&",
                onfocus: "&",
                setfocus: "=?",
                removestudent: '&',
                totalrows: '=?'
            },
            controller: ['$scope', '$rootScope', function($scope, $rootScope) {
                $scope.showGrade = false;

                $scope.changeGrade = function(gradeValue, gradeLabel) {
                    $scope.options.student.grade = gradeValue;
                    $scope.gradeLabel = gradeLabel;
                    $scope.ongradechange($scope.options.student);
                };


                $scope.onFirstNameChangeRequest = function() {
                    $scope.onfirstnamechange({
                        student: $scope.options.student
                    });
                };

                $scope.onLastNameChangeRequest = function() {
                    $scope.onlastnamechange({
                        student: $scope.options.student
                    });
                };
                
                this.onInit = function() {
                    $scope.gradeLabel = "Select Grade";

                    if (!$scope.options) {
                        $scope.options = {};
                    }

                    if (!$scope.options.student) {
                        // not a complete student object; just what is needed in the template
                        $scope.options.student = {};
                        $scope.options.student.firstName = "";
                        $scope.options.student.lastName = "";
                        $scope.options.student.grade = "Select Grade";
                    }


                    if (!$scope.grades) {
                        $scope.grades = [];
                    }
                    if ($scope.grades.length > 1) {
                        $scope.showGrade = true;
                        $rootScope.$broadcast("showGrade", {
                            'show': $scope.showGrade
                        });
                        // set default style for grade drop down as we are changing it in relation to grade selection errors
                        $scope.options.student.gradeInvalid = 'dropdownGeneric';
                    }

                };
                this.onInit();
            }]

        }
    }
).directive('focusInput', function($timeout, $parse) {
    return {
        link: function(scope, element, attrs) {
            var model = $parse(attrs.focusInput);
            scope.$watch(model, function(value) {
                if (value === true) {
                    element[0].focus();
                }
            });
            element.bind('blur', function() {
                scope.$apply(model.assign(scope, false));
            })
        }
    };
});
