portalModule.controller(
    'LtiInformationModalController',
    [
        'modalData',
        'ltiService',
        'ltiDataModel',
        'classlinkService',
        'classlinkDataModel',
        function (modalData, ltiService, ltiDataModel, classlinkService, classlinkDataModel) {

            var ctrl = this;
            ctrl.data = modalData;

            ctrl.setUrl = function () {
                ctrl.url = ltiDataModel.getLtiUrlForApp(ctrl.data.app.id);
            };

            ctrl.$onInit = function () {
                if (!ltiDataModel.ltiData.url) {
                    ltiService.getInfo(ctrl.data.orgId).then(function (data) {
                        ctrl.setUrl();
                    });
                } else {
                    ctrl.setUrl();
                };
                classlinkService.clGetInfo(ctrl.data.orgId);
            };

        }
    ]
);
