angular.module('portalApp')
    .controller('addStudentsButtonController',[
        '$scope','$log', '$location',
        function ($scope,$log, $location) {

            $scope.redirectToStudentHomeTemplate = redirectToStudentHomeTemplate;

            function redirectToStudentHomeTemplate(selectedClass) {
                $location.path('/students/add/' + selectedClass.organizationId + "/" + selectedClass.id);
            };
        }]);
