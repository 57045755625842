angular.module('portalApp').directive('addTeacherButtonDirective', function () {
    return {
        restrict: 'E',
        scope: {
            school: "=school",
            cleverSchool: "=cleverschool",
            ltiSchool: "=ltischool",
            googleUser: "=googleuser",
            selectedClass: "=selectedclass",
            staffId: "=staffid",
            addButtonText: "=addbuttontext"
        },
        templateUrl: 'resources/js/Modules/teachers/addTeacherButton/addTeacherButton.template.html',
        controller: 'addTeacherButtonController' // NOTE: controller needs to be loaded first
    }
});
