/**
 * Created by jhouser on 12/18/2015.
 */

portalModule.constant("sharedConstants", {

    "KINDERGARTEN": "kindergarten",
    "PREKINDERGARTEN": "pre-kindergarten",
    "SAMPLE_STUDENT_UPLOAD_IMAGE_WITH_ID_AND_USERNAME": "resources/images/import-student-upload-csv-template.png",
    "SAMPLE_STUDENT_UPLOAD_FILE_NO_GRADE": "resources/assets/studentImportSamples/SampleStudentUpload_NoGrade.csv",
    "SAMPLE_STUDENT_UPLOAD_FILE_WITH_ID_AND_USERNAME": "resources/assets/studentImportSamples/SampleStudentUpload-Id-And-Username.csv",
    "SAMPLE_STUDENT_UPLOAD_FILE_WITH_GRADE": "resources/assets/studentImportSamples/SampleStudentUpload_WithGrade.csv",
    "SAMPLE_STUDENT_UPLOAD_SCREENSHOT_NO_GRADE": "resources/images/studentImportSamples/SampleStudentUpload_NoGrade.png",
    "SAMPLE_STUDENT_UPLOAD_SCREENSHOT_WITH_GRADE": "resources/images/studentImportSamples/SampleStudentUpload_WithGrade.png",
    // moved from productsHome.js
    "CLEVER_NO_ENTITLEMENTS_MESSAGE": "Sorry, it looks like you do not have any active subscriptions to Scholastic's educational tools. Either your school has not purchased a subscription or has not added you to it. However you can still redeem an access code if your school has provided you one.",
    "MANUAL_SCHOOL_NO_ENTITLEMENTS_MESSAGE": "Welcome to Scholastic Digital Manager. If you have a product access code, enter it below.",
    "PRODUCT_ADD_CONFIRMATION_MESSAGE": "You have successfully redeemed your access code. You can launch the product from your dashboard.",
    "CURRENT_USER_TIMEOUT": 60000,
    "CURRENT_USER_COOKIE_TIMEOUT": (30 * 24 * 60 * 60 * 1000),
    "CLEVER_NO_CLASS_MESSAGE": "We have not received any classes for you yet. It's possible your school or district has not shared the data with us yet. Please check with your school or district IT administrator or contact our customer service representative at <span class='schNumber'>1-866-826-8834</span> for assistance.",
    "NON_CLEVER_NO_CLASS_MESSAGE": "This area will show how much time your students are spending using the Scholastic tools you provide them. To get started, setup your class roster.",
    "GOOGLE_LOGIN_ERROR": 'Oops. We seem to be having momentary technical difficulties. Please try again by clicking the "Sign in with Google" button below or logging in a different way.',
    "LOGIN_EVENT": "login",
    "LOGIN_EVENT_ERROR": "Error adding event for login",
    "EASY_LOGIN_EVENT": "easyLogin",
    "EASY_LOGIN_EVENT_ERROR": "Error adding event for easy login",
    "CHANGE_PASSWORD_EVENT": "changePassword",
    "CHANGE_PASSWORD_EVENT_ERROR": "Error adding event for change password",
    "REDEEM_ACCESS_CODE_EVENT_ERROR_MESSAGE": "Error adding event for redeeming access code",
    "REDEEM_UNIVERSAL_ACCESS_CODE_EVENT": "redeemUniversalAccessCode",
    "REDEEM_UNIVERSAL_ACCESS_CODE_EVENT_ERROR": "Error adding event for redeem universal access code event",
    "UNIVERSAL_ACCESS_CODE_EULA_PAGE_EVENT": "universalAccessCodeEulaPage",
    "UNIVERSAL_ACCESS_CODE_EULA_PAGE_EVENT_ERROR": "Error adding event for universal access code eula page event",
    "UNIVERSAL_ACCESS_CODE_ACCEPT_EULA_EVENT": "universalAccessCodeAcceptEula",
    "UNIVERSAL_ACCESS_CODE_ACCEPT_EULA_EVENT_ERROR": "Error adding event for universal access code eula accept event",
    "UNIVERSAL_ACCESS_CODE_CANCEL_EULA_EVENT": "universalAccessCodeCancelEula",
    "UNIVERSAL_ACCESS_CODE_CANCEL_EULA_EVENT_ERROR": "Error adding event for universal access code eula cancel event",
    "PRODUCT_LAUNCH_TEACHER_EVENT": "productLaunchTeacher",
    "PRODUCT_LAUNCH_TEACHER_EVENT_ERROR": "Error adding event for teacher launch",
    "PRODUCT_LAUNCH_STUDENT_EVENT": "productLaunchStudent",
    "PRODUCT_LAUNCH_STUDENT_EVENT_ERROR": "Error adding event for student launch",
    "PRODUCT_LAUNCH_ADMIN_EVENT": "productLaunchAdmin",
    "PRODUCT_LAUNCH_ADMIN_EVENT_ERROR": "Error adding event for admin launch",
    "UPDATE_CLASS_EVENT": "updateClass",
    "CREATE_UPDATE_CLASS_EVENT_ERROR": "Error adding event(create/update) class",
    "PROVIDE_ACCESS_TO_ALL_EVENT": "provideAllAccess",
    "PROVIDE_ACCESS_TO_ALL_EVENT_ERROR": "Error adding event for provide access to all students",
    "PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT": "provideAccessToOneProduct",
    "PROVIDE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR": "Error adding event for provide all access to one product",
    "REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT": "removeAccessToOneProduct",
    "REMOVE_ALL_ACCESS_TO_ONE_PRODUCT_EVENT_ERROR": "Error adding event for removing all access to one product",
    "QEF_ADD_STUDENTS_EVENT": "qefAddStudents",
    "QEF_ADD_STUDENTS_EVENT_ERROR": "Error adding event for adding students through QEF",
    "TAB_SWITCHED": "tabSwitched",
    "TAB_SWITCHED_ERROR": "Error switching tab",
    "ORG_CHANGED_SUBSCRIPTION_TAB": "orgChangedSubscriptionTab",
    "ORG_CHANGED_SUBSCRIPTION_TAB_ERROR": "Error changing org on subscription tab",
    "ORG_CHANGED_PRODUCT_TAB": "orgChangedProductTab",
    "ORG_CHANGED_PRODUCT_TAB_ERROR": "Error changing org on product tab",
    "CLASS_CHANGED_STUDENT_TAB": "classChangedStudentTab",
    "CLASS_CHANGED_STUDENT_TAB_ERROR": "Error changing class on student tab",
    "SUBSCRIPTION_HOME_TAB": "OnSubcriptionTab",
    "PRODUCT_HOME_TAB": "OnProductTab",
    "STUDENT_HOME_TAB": "OnStudentTab",
    "SSO_FAILURE_MESSAGE": 'Oops. We seem to be having momentary technical difficulties. Please try logging in again by clicking the "Login With Clever" button below.',
    "INDIVIDUAL_EULA_THANK_YOU_MESSAGE": 'Thank you for accepting Product Terms and Individual Customer Agreement. The agreement applies to your current product as well as any future products.',
    "INDIVIDUAL_EULA_ALREADY_ACCEPTED_MESSAGE": 'You have already accepted the Scholastic Product Terms and Individual Customer Agreement.',
    "CLASS_ENTER_TITLE_ERROR": "Please enter class title",
    "CLASS_CHOOSE_SCHOOL_ERROR": "Please choose the school",
    "CLASS_SELECT_GRADE_ERROR": "Please select grade",
    "CLASS_SELECT_LOWER_GRADE_ERROR": "Please select minimum grade",
    "CLASS_SELECT_HIGHER_GRADE_ERROR": "Please select maximum grade",
    "SELECTED_OTHER_SCHOOL_EVENT": "selectedOtherSchool",
    "SELECTED_OTHER_SCHOOL_EVENT_ERROR": "Error adding event for selected other school event",
    "SEARCH_ZIPCODE_EVENT_ERROR": "Error adding event for search school by zip code event",
    "CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT": "confirmPrintSubscriptionSubmit",
    "CONFIRM_PRINT_SUBSCRIPTION_SUBMIT_EVENT_ERROR": "Error adding event for CM confirm print subscription submit event",
    "CONFIRM_PRINT_SUBSCRIPTION_CANCEL_EVENT": "confirmPrintSubscriptionCancel",
    "CONFIRM_PRINT_SUBSCRIPTION_CANCEL_EVENT_ERROR": "Error adding event for CM confirm print subscription cancel event",
    "STUDENT_NO_ACCESS_MESSAGE": "Wait! You do not have access to that resource. Please ask your Teacher for assistance",
    "STAFF_NO_ACCESS_MESSAGE": "Wait! You need to activate your resource! Use the “Activate a new resource” form on your dashboard to obtain access.",
    "STAFF_SELECT_MAGAZINE_MANUALLY": "We're experiencing technical difficulties. Please select your magazine.",
    "SCHOOL_YEAR_CUTOVER_MESSAGE": "WARNING! The class you are viewing is no longer active as it belongs to the previous school year. Please create new classes for the current school year.",
    "CLEVER_SCHOOL_YEAR_CUTOVER_MESSAGE": "WARNING! The class you are viewing is no longer active as it belongs to the previous school year. Please check with your school or district IT administrator or contact our customer service representative at 1-866-826-8834 for assistance.",
    "SDM_ANALYTICS_EVENTS": {
        "EXPORT_STUDENT_CSV_EVENT": "exportStudentCSVEvent",
        "CLASS_PASSCODE_UPDATE_EVENT": "classPasscodeUpdateEvent",
        "CREATE_CLASS_EVENT": "createClassEvent",
        "CREATE_CLASS_ABORT_EVENT": "createClassAbortEvent",
        "ADD_NEW_STUDENT_EVENT":"addNewStudentEvent",
        "ADD_NEW_STUDENT_ABORT_EVENT":"addNewStudentAbortEvent",
        "PRINT_INSTRUCTIONS_EVENT": "printInstructionsEvent",
        "REDEEM_ACCESS_CODE_EVENT": "redeemAccessCode",
        "REDEEM_ACCESS_CODE_EVENT_ERROR": "redeemAccessCodeFailed",
        "MANAGE_ACCESS_LINK_EVENT": "manageAccessLink",
        "SEARCH_ZIPCODE_EVENT": "searchZipCode",
        "DOWNLOAD_ORGANIZATION_SUBSCRIPTIONS_ACCESS_CODES_EVENT" : "downloadSubscriptionAccessCodesEvent"
    },
    "TEACHER_DASHBOARD_TEXT": {
        "FULL_ROSTER_HEADER_TEXT": "Student Access",
        "FULL_ROSTER_TEXT": "Students use an individual username and password to access curriculum resources. You can edit their access to resources and sign-in information.",
        "FULL_ROSTER_BTN_TEXT": "MANAGE ACCESS",
        "ROSTER_ONLY_HEADER_TEXT": "Students",
        "ROSTER_ONLY_TEXT": "Manage your students and class information.",
        "ROSTER_ONLY_BTN_TEXT": "MANAGE",
        "CLEVER_ROSTER_ONLY_TEXT": "View your students and class information",
        "CLEVER_ROSTER_ONLY_BTN_TEXT": "VIEW"
    },
    "VALIDATION_GAP_MESSAGE" : 'There is a gap between the school year being added and the previous school year. To ensure students have access to appropriate applications, edit the start date of the school year to the day after the end date of the previous school year. ',
    "VALIDATION_GAP_PREVIOUS_EDIT_MESSAGE": 'Scholastic Digital Manager does not allow gaps between school years. By updating the start date of the <strong>#CURRENTMODIFIEDYEAR#</strong>, you will also be updating the end date of the <strong>#PREVIOUSMODIFIEDYEAR#</strong> to end 1 day before the new start date. Are you sure you want to proceed?  ',
    "VALIDATION_GAP_FOLLOWING_EDIT_MESSAGE" : "Scholastic Digital Manager does not allow gaps between school years. By updating the end date of the <strong>#CURRENTMODIFIEDYEAR#</strong>, you will also be updating the start date of the <strong>#NEXTMODIFIEDYEAR#</strong> to start 1 day after the end date. Are you sure you want to proceed?",
    "OVERRIDE_DISTRICT_SCHOOL_CALENDAR_MESSAGE" : "By opting out of your district’s calendar settings, you will be responsible for managing all current and future calendars for your school. If the calendars for your school are out of sync with your district, you may not be able to opt back in to this setting. Are you sure you want to continue?",
    "OVERRIDE_DISTRICT_SCHOOL_CALENDAR_SUCCESS_MESSAGE": "Success! You are no longer using your district calendar settings. You can now add and edit school year calendars for your organization.",
    "VALIDATE_OVERLAP_MESSAGE" : 'The school year could not be saved because it overlaps with another school year. Please adjust the start and end date and try saving your school year again.',
    "VALIDATE_SYNC_CHILD_TO_PARENT_SCHOOL_CALENDAR_MESSAGE": "By opting to use your district's calendar settings, you will no longer be able to manage current and future calendars for your school. The calendars for your school will be automatically updated to match the calendars from your district. Are you sure you want to continue? ",
    "VALIDATION_UNABLE_TO_SYNC_TO_DISTRICT_CALENDAR" : "We are unable to use your district’s calendar setting for your school. Please contact customer support for more information.",
    "VALIDATION_SUCCESS_SYNC_TO_DISTRICT_CALENDAR" : "Your school year calendars have been updated to match the calendar settings from your district.",
    "WARNING_INCORRECT_GRADE" : "'s assigned grade level is not supported by Scholastic Digital Manager. You and your students may experience difficulties accessing some applications. Please contact your administrator and ask them to update your class in Clever to a supported grade level, Pre-K to Grade 12."

});