portalModule.controller(
    'LtiSignedOutController',
    ['navModel',
        function (navModel) {
            var onInit = function () {
                navModel.showFooter = true;
            };
            onInit();
        }
    ]);
