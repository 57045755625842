portalModule.service("productModel", function(cookieService, sharedConstants, userModel, orgModel) {

    var dataObject = {
        reset : reset,
        lacksAppsAsTeacher : lacksAppsAsTeacher,
        hasAppsAsTeacher : hasAppsAsTeacher,
        getIndexOfOrg : getIndexOfOrg,
        setCookieForOrgAsTeacher : setCookieForOrgAsTeacher,
        getCookieForOrgAsTeacher : getCookieForOrgAsTeacher,
        checkIfUserHasAccessAsTeacher : checkIfUserHasAccessAsTeacher,
        checkIfAppPresentInsideOrg : checkIfAppPresentInsideOrg,
        checkIfHasAccessInAnyOrg : checkIfHasAccessInAnyOrg,
        checkIfApplicationHasRosterSupport: checkIfApplicationHasRosterSupport,
        checkIfNoStudentAccess: checkIfNoStudentAccess,
        getRedeemedApplicationsName: getRedeemedApplicationsName,
        getCookieForAnnouncements: getCookieForAnnouncements,
        setCookieForAnnouncements: setCookieForAnnouncements,
        setBannerNotificationCloseCookie: setBannerNotificationCloseCookie,
        getBannerNotificationCloseCookie: getBannerNotificationCloseCookie,
        setAccessCodeCookie: setAccessCodeCookie,
        getAccessCodeCookie: getAccessCodeCookie,
        setSRCTeacher: setSRCTeacher
    };

    return dataObject;

    function reset(){
       delete dataObject.entitlementList;
       delete dataObject.selectedOrgIndex;
       delete dataObject.redeemedSubscription;
       delete dataObject.isSRCTeacher;
    }
    
    function lacksAppsAsTeacher(){
    	if(dataObject.entitlementList){
    		return dataObject.entitlementList.length == 0;
    	}
    	return false;
    }
    
    function hasAppsAsTeacher(){
    	if(dataObject.entitlementList){
    		return dataObject.entitlementList.length > 0;
    	}
    	return false;
    }
    
    function getIndexOfOrg(orgId){
      if (!dataObject.entitlementList) {
        dataObject.entitlementList = [];
        return dataObject.entitlementList;
      }

    	return dataObject.entitlementList.filter(function(org,index){
    		if(org.id == orgId){
    			dataObject.selectedOrgIndex = index;
    			return org.id == orgId;
    		}
    	});
    }
    
    function checkIfAppPresentInsideOrg(selectedOrgAndApps, appCode) {
      if (selectedOrgAndApps && selectedOrgAndApps.applications) {
        var apps = selectedOrgAndApps.applications;
        for (var i = 0; i < apps.length; i++) {
          if (apps[i].applicationCode === appCode) {
            return true;
          }
        }
      }

      return false;
    }
    
    function checkIfHasAccessInAnyOrg(appCode) {
      if (!dataObject.entitlementList) {
        return false;
      }

      for (var i = 0; i < dataObject.entitlementList.length; i++) {
        var appExistsInAnyOrg = checkIfAppPresentInsideOrg(dataObject.entitlementList[i], appCode);
        if (appExistsInAnyOrg) {
          return dataObject.entitlementList[i].id;
        }
      }
      return false;
    }
    
    function checkIfUserHasAccessAsTeacher(appCode){
    	if(getCookieForOrgAsTeacher() !== undefined){
    		var selectedOrgAndApps = getIndexOfOrg(getCookieForOrgAsTeacher().orgId)[0];
    		var appExistsInCurrentOrg = checkIfAppPresentInsideOrg(selectedOrgAndApps,appCode);
    		if(appExistsInCurrentOrg){
    			return getCookieForOrgAsTeacher().orgId;
    		}
    		else{
    			return checkIfHasAccessInAnyOrg(appCode);
    		}	
    	}
    	else{
    		return checkIfHasAccessInAnyOrg(appCode);
    	}
    }

    function setCookieForOrgAsTeacher(orgId, classId, schoolCalendarId) {
      var orgAsTeacher = {};

      orgAsTeacher.teacherId = userModel.teacherId;
      orgAsTeacher.orgId = orgId;

      orgAsTeacher.schoolCalendarId = schoolCalendarId
        ? schoolCalendarId
        : undefined;

      orgAsTeacher.classId = classId;

      cookieService.saveCookie("orgAsTeacher", orgAsTeacher);

      if (orgId) {
        var calendar = orgModel.getCalendarById(orgId, schoolCalendarId);
        var currentCalendar = orgModel
          .getCalendarsForOrg(orgId)
          .filter(function(calendar) {
            return calendar.currentCalendar;
          })[0];

        if (!!calendar) {
          window.dumbleDataHelpers.updateSchoolYear(calendar);
        }

        if (currentCalendar) {
          window.dumbleDataHelpers.updateCurrentSchoolYear(currentCalendar);

          if (!calendar) {
            window.dumbleDataHelpers.updateSchoolYear(currentCalendar);
          }
        }
      }

      var storedOrgId = Volume.getStoreItem("dpOrgId");
      if (storedOrgId !== orgId) {
        Volume.setStoreItem("dpOrgId", orgId);
      }

      var storedClassId = Volume.getStoreItem("dpClassId");
      if (storedClassId !== classId) {
        Volume.setStoreItem("dpClassId", classId);
      }

      var storedCalendarId = Volume.getStoreItem("dpCalendarId");
      if (storedCalendarId !== schoolCalendarId) {
        Volume.setStoreItem("dpCalendarId", schoolCalendarId);
      }
    }

    function setCookieForAnnouncements(displayBanner) {
        var showAnnouncement = {};
        showAnnouncement.displayBanner = displayBanner;
        cookieService.saveCookie('showAnnouncements', showAnnouncement);
    }

    function setBannerNotificationCloseCookie(isNotificationBannerClosed) {
        var isNotificationClosed = {};
        isNotificationClosed.isNotificationBannerClosed = isNotificationBannerClosed;
        cookieService.saveCookie('notificationClosed', isNotificationClosed);
    }

    function getBannerNotificationCloseCookie() {
        var retValue;
        var cookieValue;
        retValue = true;

        cookieValue = cookieService.getCookie('notificationClosed');
        if(cookieValue){
            retValue = cookieValue.isNotificationBannerClosed;
        }
        return retValue;

    }

    function setAccessCodeCookie(accessCode) {
        cookieService.saveCookie('accessCode', { toRedeem: accessCode });
    }

    function getAccessCodeCookie() {
        var cookie = cookieService.getCookie('accessCode');

        if (cookie && cookie.toRedeem) {
            return cookie.toRedeem;
        }
    }

    function getCookieForAnnouncements() {
        var retValue;
        var showAnnouncement = cookieService.getCookie('showAnnouncements');

        retValue = true;
        if(showAnnouncement){
            retValue = showAnnouncement.displayBanner;
        }
        return retValue;
    }
    
    function getCookieForOrgAsTeacher(){
        var orgAsTeacherCookie = cookieService.getCookie('orgAsTeacher');

    	if(orgAsTeacherCookie){
        	if(userModel.teacherId == orgAsTeacherCookie.teacherId){
        		return orgAsTeacherCookie;
        	}
        	else{
        		return undefined;
        	}
    	}
    	return undefined;
    }

    function checkIfApplicationHasRosterSupport(redeemedSubscriptionArray) {
        var isAppHasRosterSupport = false;

        if (!!redeemedSubscriptionArray) {
            redeemedSubscriptionArray.forEach(function (applicationItem) {
                if (!isAppHasRosterSupport && applicationItem.application.rosterSupport.toLowerCase() !== 'none') {
                    isAppHasRosterSupport = true;
                }
            });
        }

        return isAppHasRosterSupport;
    }
    
    function checkIfNoStudentAccess(redeemedSubscriptionArray) {
        var isNoStudentAccess = false;
        if (!!redeemedSubscriptionArray) {
            redeemedSubscriptionArray.forEach(function (subscription) {
                if (!isNoStudentAccess && subscription.subscriptionProduct.includesStudentAccess) {
                    isNoStudentAccess = true;
                }
            });
        }

        return isNoStudentAccess;
    }

    function  getRedeemedApplicationsName(redeemedSubscriptionArray) {
        var applicationName = '';

        if (!!redeemedSubscriptionArray) {
            redeemedSubscriptionArray.forEach(function (appItem) {
                if (!!appItem && !!appItem.application) {
                    applicationName += appItem.application.name.trim() + ', ';
                }
            });
            applicationName = applicationName.substring(0, applicationName.length - 2);
        }
        return applicationName;
    }

    function setSRCTeacher() {
      var cookie = getCookieForOrgAsTeacher();

      if (cookie !== undefined) {
        var orgId = cookie.orgId;

        if (orgId !== undefined){
          var orgEntitlements = dataObject.entitlementList.filter(function(org){
            if (org.id === orgId){
              return org;
            }
          });

          if (orgEntitlements.length) {
            var org = orgEntitlements[0];
            if (org.applications.length === 1 && org.applications[0].applicationCode === 'src') {
                dataObject.isSRCTeacher = true;
            } else {
                dataObject.isSRCTeacher = false;
            }
          }
        }
      } else {
          dataObject.isSRCTeacher = false;
      }

      return dataObject.isSRCTeacher;
    }
});
