/**
 * Created by jhouser on 7/6/2016.
 */

portalModule.controller('userLicenseAcceptController', ['$scope', '$location', '$log', 'userService', 'notificationModel', 'licenseAcceptService', 'navModel', 'loginModel', '$rootScope', 'userModel', '$routeParams', 'sharedConstants', 'SDMAnalyticsService', 'orgModel',
    function ($scope, $location, $log, userService, notificationModel, licenseAcceptService, navModel, loginModel, $rootScope, userModel, $routeParams, sharedConstants, SDMAnalyticsService, orgModel) {

        function checkUser() {
            // call service to see if user already accepted an agreement or not
            // PUT /composite/staff/{staffId}/individual-license-agreement/`

            licenseAcceptService.checkValidUser().then(function (data) {
                // if teacher accepted redirect to portal home page
                // show message You have already accepted the Scholastic Product Terms & Conditions
                
                if (data.licenseAccepted) {
                    // reset post login URL so we don't go into an infinite loop
                    loginModel.setPostLoginUrl('');
                    
                  //Only show already accepted license message if it did not come through promptToAccepetLicense flag
                    if(!userModel.fromPromptToAcceptLicense){
                        // if user accepted; set the message to display as part of the main app
                        notificationModel.setMessage(sharedConstants.INDIVIDUAL_EULA_ALREADY_ACCEPTED_MESSAGE);
                        $rootScope.$broadcast('globalMessageSet');
                    }
                    userModel.overrideUserLicenseAgreement = true;
                    userModel.currentUser.staff.promptToAcceptLicense = false;
                 // redirect to home page
                    $location.path('/');
                } else {
                    // if not accepted; show this page
                    $scope.initialized = true;
                }
                $log.debug(data);

            }, function (error) {
                // if teacher not accepted; show them userLicenseAccept agreement for them to accept
                $log.error(error);
                $scope.error = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";

            });

        }


        $scope.acceptLicense = function () {
            licenseAcceptService.userLicenseAccept().then(userLicenseAcceptSuccessHandler, userLicenseAcceptErrorHandler);
        };
        
        $scope.cancelLicense = function(){
            try{
                if($scope.accessCode){
                    SDMAnalyticsService.addEventType(sharedConstants.UNIVERSAL_ACCESS_CODE_CANCEL_EULA_EVENT, 'subscriptionDetailsAlongWithSchools', 'userProfile');
                    SDMAnalyticsService.emit(sharedConstants.UNIVERSAL_ACCESS_CODE_CANCEL_EULA_EVENT, orgModel.orgsForAccessCode, userModel.currentUser.staff);
                }
            } catch(e) {
                $log.debug(sharedConstants.UNIVERSAL_ACCESS_CODE_CANCEL_EULA_EVENT_ERROR);
            }
            
            $location.path('/products');
        }

        function userLicenseAcceptSuccessHandler(data) {
            $log.debug('License accepted', data);

            // reset post login URL to make sure we don't go into a loop
            loginModel.setPostLoginUrl('');

            // set message to display on home page after redirecting away from here
            notificationModel.setMessage(sharedConstants.INDIVIDUAL_EULA_THANK_YOU_MESSAGE);
            $rootScope.$broadcast('globalMessageSet');
            navModel.licenseShowNav = false;
            userModel.overrideUserLicenseAgreement = true;
            userModel.currentUser.staff.promptToAcceptLicense = false;
            //MB: Send user to choose school page if accepted EULA while trying to redeem CM access code
            if($scope.accessCode){
                try{
                    SDMAnalyticsService.addEventType(sharedConstants.UNIVERSAL_ACCESS_CODE_ACCEPT_EULA_EVENT, 'subscriptionDetailsAlongWithSchools', 'userProfile');
                    SDMAnalyticsService.emit(sharedConstants.UNIVERSAL_ACCESS_CODE_ACCEPT_EULA_EVENT, orgModel.orgsForAccessCode, userModel.currentUser.staff);
                } catch(e) {
                    $log.debug(sharedConstants.UNIVERSAL_ACCESS_CODE_ACCEPT_EULA_EVENT_ERROR);
                }
                
                $location.path('/products/selectschool/' + $scope.accessCode);
            }
            else{
                // redirect to home page
                $location.path('/');
            }
            
        }

        function userLicenseAcceptErrorHandler(error) {
            $log.error('User License accept error handler', error);
            $scope.error = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
        }

        function init() {
            $scope.error = '';
            $scope.initialized = false;
            $scope.showOrgInfo = false;
            userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                $log.debug('User signed in ', currentUser);
                if (currentUser.student) {
                    $location.path('/studentportal');
                } else {
                    navModel.licenseShowNav = true;
                    navModel.showNav = false;
                    navModel.helpPage = false;
                    $scope.firstName = currentUser.staff.firstName;
                    $scope.lastName = currentUser.staff.lastName;
                    if($routeParams.accesscode){
                        $scope.accessCode = $routeParams.accesscode;
                        try{
                            SDMAnalyticsService.addEventType(sharedConstants.UNIVERSAL_ACCESS_CODE_EULA_PAGE_EVENT, 'subscriptionDetailsAlongWithSchools', 'userProfile');
                            SDMAnalyticsService.emit(sharedConstants.UNIVERSAL_ACCESS_CODE_EULA_PAGE_EVENT, orgModel.orgsForAccessCode, userModel.currentUser.staff);
                        } catch(e) {
                            $log.debug(sharedConstants.UNIVERSAL_ACCESS_CODE_EULA_PAGE_EVENT_ERROR);
                        } 
                    }
                    checkUser();
                }
            }, function (error) {
                loginModel.setPostLoginUrl($location.path());
                $log.error('User not signed in ', error);
                $location.path('/signin/staff');
                navModel.licenseShowNav = false;
            });
        }
        init();

    }
]);