/**
 * Created by jhouser on 3/18/2016.
 */

portalModule.controller("TeachersEditController",['$scope', '$log', '$routeParams','$location','userService','teacherService','userModel','navModel','subscriptionModel','orgModel','productService','teacherModel',

function($scope, $log, $routeParams,$location,userService,teacherService,userModel,navModel,subscriptionModel,orgModel,productService,teacherModel ){

    $scope.errorRetrievingData = '';

    $scope.filterText = '';
    $scope.initialized = false;

    $scope.closeAlert = function(){
        $scope.addRemoveMessage = "";
        delete $scope.errorMessage;
    }

    $scope.cancel = function(){
        $location.path('/subscriptions/' + $routeParams.subscriptionId );
    };

    $scope.onFilterChange = function(){

        $scope.teachers = $scope.teachersMasterList.filter(function(sub, index){
            // compare $scope.filterText to
            // sub.firstName
            // sub.lastName
            // sub.email
            // if it matches any of them; return true
            // else return false

            var regexName = new RegExp('.*' + $scope.filterText + '.*', "i");
            return regexName.test(sub.firstName + " " + sub.lastName) || regexName.test(sub.email); 
        });

    };


    function resetTeacherSubscriptions(){
        if(!$scope.teachersMasterList){
            return;
        }

        $scope.teachersMasterList.forEach(function(element, index, array){
            element.inSubscription = false;
        });
    }

    function loadActiveTeachers(){

        resetTeacherSubscriptions();

        productService.loadTeachersForSubscription($scope.selectedSubscription.subscription.id).then(function(teachersInSubscription) {


            /* loop over all teachers $scope.teachersMasterList | and if they are in teachersInSubscription mark them as such somehow
            basically conflate the two lists and make them show up in the checkbox UI
            */

            teachersInSubscription.forEach(function(element, index, array){
                for (var counter = 0; counter < $scope.teachersMasterList.length; counter++){
                    if(element.id == $scope.teachersMasterList[counter].id){
                        $scope.teachersMasterList[counter].inSubscription = true;
                        break;
                    }
                }
            });

        });

    }

    $scope.onSubscriptionChange = function(subscription){
    	$scope.selectedSubscription = subscription;
    	$scope.buttonname = $scope.selectedSubscription.displayName;
        loadActiveTeachers();
    };

    $scope.processTeacher = function(teacher){
        if(teacher.inSubscription){
            removeTeacher(teacher)
        } else if ($scope.selectedSubscription.subscription.license.remaining > 0){
            addTeacher(teacher)
        }
        // else do nothing
    };

    function removeTeacher(teacher){
        // prompt user to make sure they are sure
        teacherModel.createRemoveTeacherDialog(teacher,$scope.selectedSubscription.subscription.id ).then(function(data){
            // if the data is an empty string then popup was closed w/o saving
            // otherwise we have a result from the service call
            if(data != ''){
                // Increase number of remaining licenses
                // doing this in the UI instead of reloading data for performance purposes
                $scope.selectedSubscription.subscription.license.remaining++;

                // toggle teacher's subscription info for UI; also doing this w/o reloading data for performance reasons
                teacher.inSubscription = false;

                $scope.addRemoveMessage = 'Teacher successfully removed.';
            }
        });
    }

    function addTeacher(teacher){
        // todo implement this add teacher method
        productService.addTeacherAccess($scope.selectedSubscription.subscription.id, teacher.id).then(function (data){

            /*
            if(data == null){
                $log.debug('was not able to succesfully add a teacher' + data)
                $scope.addRemoveMessage = 'Unknown error adding teacher.';
                return;
            }
            */

            // decrease the number of remaining licenses
            // doing this in the UI instead of reloading data for performance purposes
            $scope.selectedSubscription.subscription.license.remaining--;

            // toggle teacher's subscription info for UI; also doing this w/o reloading data for performance reasons
            teacher.inSubscription = true;

            $scope.addRemoveMessage = 'Teacher successfully added.';
        },
        function addTeacherAccessErrorHandler(data){
            $log.debug('was not able to succesfully add a teacher' + data)
            $scope.addRemoveMessage = 'We were not able to add the teacher.';

        });
    }

    var onInit =function () {

        userService.getCurrentUser().then(function (currentUser) {

            // weird things happen if we reload directly to this page because some of the required data isn't loaded and cached
            // so if they reload here; redirect back to main subscription page
            if(!subscriptionModel.subscriptions){
                $location.path("/subscriptions");
            }

            navModel.showNav = true;
            navModel.helpPage = false;
            $scope.subscriptions = [];
            // all subscriptions for the drop down
            //$scope.subscriptions = subscriptionModel.subscriptions;
            //Set the display name for subscriptions based on the values of application name and product name
            for(var i=0;i<subscriptionModel.subscriptions.length;i++)
        	{
        			for(var j=0;j<subscriptionModel.subscriptions[i].subscriptionAndProduct.length;j++)
        			{
        				$scope.subscriptions.push(subscriptionModel.subscriptions[i].subscriptionAndProduct[j]);
        				if($scope.subscriptions[j].application.name == $scope.subscriptions[j].product.name)
                    	{
                    		$scope.subscriptions[j].displayName = $scope.subscriptions[j].application.name;
                    	}
                    	else if($scope.subscriptions[j].product.name.indexOf($scope.subscriptions[j].application.name) > -1)
                    	{
                    		$scope.subscriptions[j].displayName = $scope.subscriptions[j].product.name;
                    	}
                    	else
                    	{
                    		$scope.subscriptions[j].displayName = $scope.subscriptions[j].application.name + ' - ' + $scope.subscriptions[j].product.name;
                    	}
        			}
        		
        	}
            
            $log.debug('Subscription List with display names ',$scope.subscriptions);
            
            if ($routeParams.subscriptionId) {
                // load all product info (?)
                var id = $routeParams.subscriptionId;
//                $scope.selectedSubscription = $scope.subscriptions.filter(function (sub, index) {
//                    $log.debug("In filter of subscriptions. index: ", index, " sub: ", sub);
//                    return sub.id == id;
//                })[0];
                
                for(var i=0;i<$scope.subscriptions.length;i++)
	        	{
	        		if($scope.subscriptions[i].subscription.id == id)
	        		{
	        			$scope.selectedSubscription = $scope.subscriptions[i];
	        			break;
	        		}		
	        	}
            }
            
            //Initialize the dropdown display value
            $scope.buttonname = $scope.selectedSubscription.displayName;

            //var school = orgModel.getSchoolsByID($scope.selectedSubscription.assignment.schoolId)[0];
          
            var school = orgModel.getSchoolsByID($scope.selectedSubscription.subscription.assignment.schoolId)[0]; 
            $scope.schoolName = school.name;

            teacherService.loadTeachersBySchool(userModel.currentUser.staff.id, school.id ).then(function(data){
                $scope.teachersMasterList = data;
                // load teachers who are active based on the subscription
                loadActiveTeachers();
                $scope.teachers = data;
                $scope.initialized = true;

            }, function(error){
                $log.error('error loading teacher Data');
                $scope.initialized = true;
                $scope.errorRetrievingData = error.message;
            });

            $log.debug("In SubscriptionController. $scope.subscription: ", $scope.selectedSubscription, "  $routeParams.subscriptionId: ", $routeParams.subscriptionId, "  subscriptionModel.subscriptions: ", subscriptionModel.subscriptions);

            // to do load teacher list

        }, function () {
            userModel.reset();
            $location.path("/signin/staff");
        });
    };

    onInit();

}]);
