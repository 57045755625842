portalModule.directive('validationApplyTo',['globalValidation',function(globalValidation) {
	  return {
	    require: 'ngModel',
	    link: function(scope, element, attrs, ctrl) {
	      element.bind('keyup keydown',function(){
	    	  scope.$apply(function(){
	    		var elemValue = attrs.value;
	    		if(attrs.name == "firstName" || attrs.name == "lastName")
	    		{	
					var validateName = globalValidation.validateName(elemValue);
		        	if(validateName == true)
		        	{
		        	    element.addClass("validField");
		        	}
		        	else
		        	{
		        	    element.addClass("error");
                        element.removeClass("validField");
		        	}
	    		}
	    		else
	    		{
	    			var regexId = /^[a-zA-Z0-9]*$/;
	    			var validateId = regexId.test(elemValue);
	    			if(validateId == true)
		        	{
	    			    element.addClass("validField");
		        	}
		        	else
		        	{
		        	    element.addClass("error");
                        element.removeClass("validField");
		        	}
	    		}
	    	  });
	      });
	    }
	  };
	}]);

portalModule.directive('gradeSelectValidationApplyTo',function() {
	return {
		require: 'ngModel',
		link: function (scope, element, attrs, ctrl) {
			element.bind('change', function () {
				var elemValue = attrs.value;
				var regexGrade = /^\b(Pre-Kindergarten|Kindergarten|Grade 1|Grade 2|Grade 3|Grade 4|Grade 5|Grade 6|Grade 7|Grade 8|Grade 9|Grade 10|Grade 11|Grade 12|pk|k|1|2|3|4|5|6|7|8|9|10|11|12)\b$/i;
				var validateGrade = regexGrade.test(elemValue);
				if (validateGrade == true) {
				    element.addClass("validField");
				}
				else {
				    element.addClass("error");
                    element.removeClass("validField");
				}

			})
		}
	}
});
