portalModule.controller("headTagController", [ '$scope', '$rootScope', '$log', '$location', 'easyLoginModel', '$document', 'userModel', function($scope, $rootScope, $log, $location, easyLoginModel, $document, userModel){
    
    $scope.favicon = 'favicon.ico';
    
    if($location.path().match( /(easylogin|cz|sz)/)){
        $scope.favicon = 'easylogin.ico';
    }
    
    $rootScope.$watch(function() {
        return userModel.currentUser;
    },
    function watchCallback(newValue, oldValue) {
        if(newValue.staff && oldValue.staff){
            return;
        }
        
        if(newValue.student){
            if(userModel.currentUser.sessionContext.easyLogin){
                $scope.favicon = 'easylogin.ico';
            }
        }
    });
    
    $rootScope.$watch(function() {
        return easyLoginModel.easyLoginClassDetails;
    },
    function watchCallback(newValue, oldValue) {
        if(easyLoginModel.easyLoginClassDetails){
            $document[0].title = easyLoginModel.easyLoginClassDetails.title + ' - Easy Login';
        }
    });
    
    $rootScope.$watch(function() {
        return easyLoginModel.schoolEasyLoginClassDetails;
    },
    function watchCallback(newValue, oldValue) {
        if(easyLoginModel.schoolEasyLoginClassDetails){
            $document[0].title = easyLoginModel.schoolEasyLoginClassDetails.easyLoginSchoolSettings.orgName + ' - Easy Login';
        }
    });
    
}]);