portalModule.service("classlinkDataModel", ["$log", function ($log) {

    var initialState = {
        orgId: null,
        orgKey: null,
        orgSecret: null,
        orgUrl: null,
        orgSourceId: null
    };

    function updateDataModel (data) {
        return angular.merge(dataObject.classlinkData, data);
    }

    function reset () {
        dataObject.classlinkData = angular.copy(initialState);
    }

    function getClInfo () {
        return dataObject.classlinkData;
    }

    var dataObject = {
        reset: reset,
        updateDataModel: updateDataModel,
        getClInfo: getClInfo,
        classlinkData: angular.copy(initialState)
    };

    return dataObject;
}]);