angular.module('portalApp').controller('teacherDetailsController',
    ['$scope', 'teacherService', '$log', 'userModel', 'notificationModel',
        '$location', '$uibModal', '$uibModalStack', '$templateCache', '$route',
        function ($scope, teacherService, $log, userModel, notificationModel,
                  $location, $uibModal, $uibModalStack, $templateCache, $route) {

        $scope.teachersList = [];
        $scope.teacherDetails = [];
        $scope.teacherDataSetup = false;
        $scope.teachersPresentForClass = false;
        $scope.initialized = false;
        $scope.setupCurrentClass = setupCurrentClass;
        $scope.loadTeacherInformation = loadTeacherInformation;
        $scope.updateTeacher = updateTeacher;
        $scope.isLoggedInUserCoTeacher = false;
        $scope.classIsActive = true;

        var $this = this;
        $this.init = init;
        $this.detectIfClassIsActive = detectIfClassIsActive;
        $this.detectCurrentYear = detectCurrentYear;
        $this.revokeTeacherAccessSuccessHandler = revokeTeacherAccessSuccessHandler;
        $this.revokeTeacherAccessErrorHandler = revokeTeacherAccessErrorHandler;
        $this.fillTeacherList = fillTeacherList;
        $this.switchCoTeacherAndPrimaryTeacher = switchCoTeacherAndPrimaryTeacher;
        $scope.coTeacherInstance = {};

        $scope.$on('$locationChangeStart', handleLocationChange);
        /** handleLocationChange()
         * Dismisses all modals on location change start,
         * such as when the back button is pressed.
         */
        function handleLocationChange() {
            $uibModalStack.dismissAll();
        }

        $scope.$watch('selectedClass', function (newValue, oldValue) {
            $log.debug('selected Class Value ' , newValue);

            if (!!newValue && !!oldValue && newValue.id !== oldValue.id) {
                if (newValue.schoolYear !== oldValue.schoolYear) {
                    $scope.loadTeacherInformation();
                } else {
                    $scope.setupCurrentClass();
                }
                $scope.selectedClass = newValue;
                $scope.setInitialized();
            } else if (!!newValue && !!oldValue) {
                $scope.classIsActive = $this.detectIfClassIsActive(newValue);
            }

        }, true);

        function teacherObject(id, name, teacherRole, primaryTeacherId, organizationId,
                               classSectionId, classDisplayName, selectedClass, teacherIds) {
            this.id = id;
            this.name = name;
            this.teacherRole = teacherRole;
            this.primaryTeacherId = primaryTeacherId;
            this.organizationId = organizationId;
            this.classSectionId = classSectionId;
            this.classDisplayName = classDisplayName;
            this.selectedClass = angular.copy(selectedClass);
            this.classSection = {
                id: selectedClass.id,
                uuid: selectedClass.uuid,
                organizationId: selectedClass.organizationId,
                staff: {
                    primaryTeacherId: primaryTeacherId,
                    teacherIds: teacherIds
                },
                // staff: angular.copy(selectedClass.staff),
                nickname: selectedClass.nickname,
                lowGrade: selectedClass.lowGrade,
                highGrade: selectedClass.highGrade,
                active: selectedClass.active,
                schoolYear: selectedClass.schoolYear,
                schoolCalendarId: selectedClass.schoolCalendarId,
                easyLogin: angular.copy(selectedClass.easyLogin),
                identifiers: selectedClass.identifiers,
                displayName: selectedClass.displayName
            };
        }

        // This code will get the Classes for the logged in teacher
        // It will fetch the Primary teacher for each class and make the rest to get all the teachers.
        function loadTeacherInformation() {
            var schoolYear;
            var classList = [];

            if (!!$scope.classList) {
                $scope.classList.forEach(function(classElement){
                    classList.push(classElement);
                });
            }
            if (classList && classList.length > 0) {
                schoolYear = classList[0].schoolYear;
                var uniqueTeach = [];
                var primaryTeachers = [];

                primaryTeachers = classList.map(function (item) {
                    return item.staff.primaryTeacherId;
                });

                uniqueTeach = primaryTeachers.filter(function (item, index) {
                    return primaryTeachers.indexOf(item) === index;
                });

                uniqueTeach.forEach(function (item) {
                    if ($scope.showYOYFeatures) {
                        teacherService.loadTeachersYOYForAClass($scope.selectedSchoolYear.id)
                            .then(function (response) {
                                if (response.length > 0) {
                                    $this.fillTeacherList(response);
                                }
                            });
                    } else {
                        teacherService.loadTeachersForAClass(item, schoolYear)
                            .then(function (response) {
                                if (response.length > 0) {
                                    $this.fillTeacherList(response);
                                }
                            });
                    }
                });
            }
        }

        function fillTeacherList(response) {
            var teacherList = [];

            if (!!teacherList) {
                teacherList = response;
            } else {
                // necessary if primaryteacher is a coteacher of a class
                teacherList.concat(response);
            }
            $scope.teachersList = teacherList;
            $scope.setupCurrentClass();
        }

        function switchCoTeacherAndPrimaryTeacher(coTeacherInstance){
            coTeacherInstance.classSection.staff.primaryTeacherId = coTeacherInstance.id;
            return coTeacherInstance;
        }

        function updateTeacher(coTeacherInstance,action) {
            $log.debug('Remove Co-Teacher From class', coTeacherInstance, action);
            $scope.coTeacherInstance = coTeacherInstance;
            $scope.coteacherModalAction = action;
            if (!!coTeacherInstance) {
                var modal = $uibModal.open({
                    templateUrl: 'resources/js/Modules/teachers/updateCoteacherController/updateCoteacherController.html',
                    controller: 'updateCoteacherController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (response) {
                    $log.debug(response);
                    if (response === 'UNASSIGN CO-TEACHER') {
                        teacherService.removeCoTeacher(coTeacherInstance)
                            .then(revokeTeacherAccessSuccessHandler, revokeTeacherAccessErrorHandler);
                    } else if (response === 'makePrimaryTeacher') {
                        teacherService.makePrimaryTeacher($this.switchCoTeacherAndPrimaryTeacher(coTeacherInstance))
                            .then(revokeTeacherAccessSuccessHandler, revokeTeacherAccessErrorHandler);
                    } else if (response === '') {
                    }
                });
            }
        }

        function revokeTeacherAccessSuccessHandler(data){
            $log.debug("In revoke teacher success handler",data);
            $scope.errorRemovingTeacher = '';
            var className = (!!$scope.selectedClass && !!$scope.selectedClass.displayName) ? $scope.selectedClass.displayName : '';
            if ($scope.coteacherModalAction === 'RemoveTeacher') {
                notificationModel.setMessage($scope.coTeacherInstance.name + ' was successfully removed as a co-teacher from ' + className + '.');
            } else if ($scope.coteacherModalAction === 'makePrimaryTeacher') {
                notificationModel.setMessage('Success! ' + $scope.coTeacherInstance.name + ' is now the primary teacher for ' + className + '.');
            }
            var currentPageTemplate = !! $route.current && !!$route.current.templateUrl ? $route.current.templateUrl : '';
            $templateCache.remove(currentPageTemplate);
            $route.reload();
        }

        function revokeTeacherAccessErrorHandler(error){
            $log.debug("In revoke teacher error handler",error);
            notificationModel.setMessage(error.message || error.code || "An error occurred. Please try again.");
            var currentPageTemplate = !! $route.current && !!$route.current.templateUrl ? $route.current.templateUrl : '';
            $templateCache.remove(currentPageTemplate);
            $route.reload();
        }

        /// This code will populate the array that displays the teacher and Co-Teachers
        function setupCurrentClass() {
            $log.debug('Setup the Teacher display table');

            var teacherInfo = {};
            $scope.teacherDetails = [];
            $scope.isLoggedInUserCoTeacher = false;
            $scope.classIsActive = $this.detectIfClassIsActive($scope.selectedClass);
            $scope.teachersList.forEach(function (item) {
                    var organizationId;
                    var classSectionId;
                    var classDisplayName;
                    var primaryTeacherId;
                    var teacherIds;

                    if (!!$scope.selectedClass) {
                        if (item.id == $scope.selectedClass.id) {
                            if (item.staff !== undefined) {
                                organizationId = item.organizationId;
                                classSectionId = item.id;
                                primaryTeacherId = item.staff.primaryTeacher.id;
                                classDisplayName = $scope.selectedClass.displayName;
                                teacherIds = angular.copy(item.staff.teacherIds);
                                teacherInfo = new teacherObject(
                                    item.staff.primaryTeacher.id,
                                    item.staff.primaryTeacher.firstName + ' ' + item.staff.primaryTeacher.lastName,
                                    'Primary Teacher',
                                    primaryTeacherId,
                                    organizationId,
                                    classSectionId,
                                    classDisplayName,
                                    $scope.selectedClass,
                                    teacherIds
                                );
                                $scope.teacherDetails.push(teacherInfo);
                                item.staff.teachers.forEach(function (data) {
                                    if (!!data) {
                                        if (item.staff.primaryTeacher.id !== data.id) {
                                            teacherInfo = new teacherObject(
                                                data.id,
                                                data.firstName + ' ' + data.lastName,
                                                'Co-Teacher',
                                                primaryTeacherId,
                                                organizationId,
                                                classSectionId,
                                                classDisplayName,
                                                $scope.selectedClass,
                                                teacherIds
                                            );
                                            $scope.teacherDetails.push(teacherInfo);

                                            // Verify if logged in user is Co-Teacher
                                            if (!$scope.isLoggedInUserCoTeacher) {
                                                if (!!$scope.staffId) {
                                                    if ($scope.staffId == data.id) {
                                                        $scope.isLoggedInUserCoTeacher = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                                $scope.teachersPresentForClass = true;
                            }
                            $scope.teacherDataSetup = true;
                            $scope.initialized = true;
                        }
                    }
                }
            );
        }

        function detectIfClassIsActive(selectedClass){
            if(!selectedClass) return false;
            if(!selectedClass.schoolYear) return false;
            if(selectedClass.schoolYear < $this.detectCurrentYear() - 1) return false;

            $scope.setInitialized();

            return !!selectedClass.active;
        }
        
        function detectCurrentYear() {
            return new Date().getUTCFullYear();
        }

        function init() {
            if (!!userModel
                    && !!userModel.currentUser
                    && !!userModel.currentUser.staff
                    && !!userModel.currentUser.staff.identifiers
                    && !!userModel.currentUser.staff.identifiers.staffId) {
                    $scope.staffId = userModel.currentUser.staff.identifiers.staffId;
            }
            $scope.loadTeacherInformation();
        }

        init();
}]);