// idea stolen from http://stackoverflow.com/questions/21103724/angular-directive-templateurl-relative-to-js-file
var studentGroup = {};
studentGroup.scripts = document.getElementsByTagName("script");
studentGroup.currentScriptPath = studentGroup.scripts[studentGroup.scripts.length - 1].src;

angular.module("newStudentGroup", ['ui.bootstrap', 'newStudent', 'portalAppServices', 'globalValidationApp'])
    .directive('sdpNewstudentgroup', ['globalValidation','userModel','$uibModalStack','$location',
    function(globalValidation,userModel,$uibModalStack,$location) {
        return {
            restrict: 'AE',
            templateUrl: 'resources/js/Modules/students/newStudentGroup/NewStudentGroup.html',
            transclude: true,
            scope: {
                options: "=?",
                selectedclass: "=?",
                studentarray: "=?",
                control: '=?',
                editmode: '=?',
                onfirstnamechange: "&",
                onlastnamechange: "&",
                ongradechange: "&",
                savestudentsuccesshandler: '&',
                setfocus: "=?",
                totalrows: "=?",
                confirmcancel: "=?",
                uploadusingcsv: "=?"
            },
            controller: ['$scope', '$log', '$filter', 'studentService', 'sharedConstants', 'SDMAnalyticsService', 'studentModel', function($scope, $log, $filter, studentService, sharedConstants, SDMAnalyticsService, studentModel) {

                $scope.errorValidating = '';
                $scope.errorFlag = false;
                $scope.showGrade = false;

                $scope.$on('showGrade', function(event, showGrade) {
                    $scope.showGrade = showGrade.show;
                });

                $scope.$on('editmode', function(event, editmode) {
                    $scope.editmode = editmode;
                });

                $scope.onAddNewStudent = function() {
                    // The StudentCU subcomponent will populate the default options for the student object
                    // all this have to do is add a new item to the array
                    $scope.studentarray.push({});
                    setManualFocus();
                };

                function setManualFocus() {
                    for (var i = 0; i < $scope.studentarray.length; i++) {
                        if (($scope.studentarray[i].student == undefined) ||
                            (
                                ($scope.studentarray[i].student.firstName == undefined || $scope.studentarray[i].student.firstName == '') &&
                                ($scope.studentarray[i].student.lastName == undefined || $scope.studentarray[i].student.lastName == '')
                            ) ||
                            (
                                ($scope.studentarray[i].student.firstName != '' && ($scope.studentarray[i].student.lastName == undefined || $scope.studentarray[i].student.lastName == ''))
                            )
                        ) {
                            $scope.studentarray[i].manualfocus = true;
                            return;
                        } else {
                            $scope.studentarray[i].manualfocus = false;
                        }
                    }
                }

                $scope.onAddNewStudentWithoutManualFocus = function() {
                    $scope.studentarray.push({});
                };

                $scope.onAddNewStudentViaFocus = function(student) {
                    if (student.student.lastName == undefined || student.student.lastName == '') {
                        var emptyRowPresent = false;
                        for (var i = 0; i < $scope.studentarray.length; i++) {
                            if ($scope.studentarray[i].student && ($scope.studentarray[i].student.firstName == undefined || $scope.studentarray[i].student.firstName == '')) {
                                emptyRowPresent = true;
                                break;
                            }
                        }
                        if (emptyRowPresent == false) {
                            $scope.onAddNewStudentWithoutManualFocus();
                        }
                    }

                }

                $scope.onFirstNameChangeRequest = function(student) {
                    if ($scope.onfirstnamechange) {
                        $scope.onfirstnamechange({
                            student: student
                        });
                    }
                    validateFirstName(student.student);
                };

                $scope.onLastNameChangeRequest = function(student) {
                    if ($scope.onlastnamechange) {
                        $scope.onlastnamechange({
                            student: student
                        });
                    }
                    validateLastName(student.student);
                };

                $scope.ongradechangeRequest = function(student) {
                    if ($scope.ongradechange) {
                        $scope.ongradechange(student);
                    }
                    validateGradeChange(student.student);
                };

                $scope.removeStudent = function(index) {
                    $log.debug('Removing student at index: ', index);
                    $scope.studentarray.splice(index, 1);
                }

                $scope.closeAlert = function() {
                    $scope.errorValidating = '';
                    $scope.errorFlag = false;

                };

                // borrowed approach from here; so the popup can call the validation method in here
                // http://stackoverflow.com/questions/16881478/how-to-call-a-method-defined-in-an-angularjs-directive
                $scope.internalControl = $scope.control || {};
                $scope.internalControl.validate = function validate() {
                    // moving from NewStudentGroupPopup.js
                    $log.debug('validate Students: ', $scope.studentarray);

                    // since we are starting the validation process again; go ahead and clear out the error alert for duplicate names
                    $scope.errorValidating = '';
                    $scope.errorFlag = false;

                    // loop over array
                    var errorMessage = "";

                    // remove white space
                    for (var index = 0; index < $scope.studentarray.length; ++index) {
                        if ($scope.studentarray[index].student.firstName) {
                            $scope.studentarray[index].student.firstName = removeDuplicateSpaces($scope.studentarray[index].student.firstName);
                        }
                        if ($scope.studentarray[index].student.lastName) {
                            $scope.studentarray[index].student.lastName = removeDuplicateSpaces($scope.studentarray[index].student.lastName);
                        }

                    }


                    // filter out blank items
                    var newArray = $filter('filter')($scope.studentarray, function(value, index, array) {
                        return (value.student.firstName || value.student.lastName);
                    });
                    // if nothing in here; add a blank empty row
                    if (newArray.length == 0) {
                        newArray.push({});
                    }
                    // replace student array w/ blank items removed.. this will also remove them from the UI
                    $scope.studentarray = newArray;

                    var foundAtLeastOne = false;
                    var foundError = false;

                    // an array to send to the service
                    // this array won't have the embedded student object, like the main studentarray does
                    var arrayForService = [];

                    for (index = 0; index < $scope.studentarray.length; ++index) {
                        // ignore student row if no first name and last name
                        // if we do have at least a first name or a last name then perform validation on the full row
                        var student = $scope.studentarray[index];
                        if (student.student == undefined) {
                            break;
                        }
                        if (student.student.firstName || student.student.lastName) {
                            foundAtLeastOne = true;
                            // if only one grade, then user didn't have option to select the grade in UI
                            // so force it to the only grade available
                            if ($scope.selectedclass.lowGrade == $scope.selectedclass.highGrade) {
                                student.student.grade = $scope.selectedclass.lowGrade;
                            }
                            // make sure all items are required

                            validateFirstName(student.student);
                            if (student.student.firstNameInvalid == 'error') {
                                foundError = true;
                            }
                            else{
                                delete student.student.firstNameInvalid;
                            }

                            validateLastName(student.student);
                            if (student.student.lastNameInvalid == 'error') {
                                foundError = true;
                            }
                            else{
                                delete student.student.lastNameInvalid;
                            }

                            validateGradeChange(student.student);
                            if (student.student.gradeInvalid == 'dropdownError') {
                                foundError = true;
                            }
                            else{
                                student.student.gradeInvalid = 'dropdownGeneric';
                            }

                            arrayForService.push(student.student);
                        }
                    }
                    if (!foundAtLeastOne) {
                        errorMessage += "You must enter at least one student\n";
                    }
                    if (errorMessage) {
                        //alert(errorMessage);
                        $scope.errorValidating = errorMessage;
                        $scope.errorFlag = true;
                        return;
                    }
                    // form changes colors / inputs if an error is found; so no need to display explicit errors
                    if (foundError) {
                        return;
                    }
                    // if we get here; no errors that the UI could detect; call validation method

                    studentService.validateStudents(arrayForService, $scope.selectedclass.id).then(validateStudentsSuccessHandler, validateStudentsErrorHandler);
                };

                var validateStudentsSuccessHandler = function(data) {

                    $log.debug("In validate Success Handler", data);
                    
                    if(data.code == 'error.not.logged.in'){
        				userModel.reset();
        				$uibModalStack.dismissAll();
        				$location.path('/signin/staff');
        				return;
        			}

                    // what I want to do in this method is to create a new students array which will replace
                    // remember for some reason the student object is embedded in a students property; probably due to
                    // scope inheritance within the directives

                    // if errors; set using the relevant validation properties

                    // if duplicate name errors; set an error using relevant firstname property
                    // and set an alert using $scope.errorValidating to clarify the error type

                    // if no errors; switch mode by toggling $scope.editMode to false

                    var students = data.records;

                    // loop over all violations
                    for (var vi = 0; vi < (data.violations || []).length; vi++) {
                        // get current violation
                        var violation = data.violations[vi];

                        // get student record for this violation
                        // the server only returns records w/ errors; not records w/ no errors so
                        // pull student record out of the $scope.studentarray; comparing based on grade, firstname, and lastname
                        // since we have no unique ID for students; duplicate name errors may give two false positives for this check; so do so in an array
                        var studentsWithErrors = [];
                        for (var si = 0; si < $scope.studentarray.length; si++) {
                            if (($scope.studentarray[si].student.firstName == students[violation.index].firstName) &&
                                ($scope.studentarray[si].student.lastName == students[violation.index].lastName) &&
                                ($scope.studentarray[si].student.grade == students[violation.index].grade)
                            ) {
                                studentsWithErrors.push($scope.studentarray[si].student);
                            }
                        }

                        // loop over the violation errors
                        // in theory in this form we'll only have duplicate user errors; because all other errors are handled by UI validation before calling service
                        for (var fei = 0; fei < (violation.fieldErrors || []).length; fei++) {
                            var fieldError = violation.fieldErrors[fei];
                            var fieldName = fieldError.field;
                            if (fieldName == 'grade') {
                                for (si = 0; si < studentsWithErrors.length; si++) {
                                    studentsWithErrors[si].gradeInvalid = 'dropdownError';
                                }
                            } else {
                                // firstName or lastName
                                for (si = 0; si < studentsWithErrors.length; si++) {
                                    if (fieldName == 'firstName') {
                                        studentsWithErrors[si].firstNameInvalid = "error";
                                    } else {
                                        studentsWithErrors[si].lastNameInvalid = "error";
                                    }
                                }
                            }

                            $log.debug("violation: ", violation);
                            $log.debug("violation[", fieldName, "]: ", violation[fieldName]);
                        }
                        // loop over record errors; these are the ones for duplication user names
                        if ((violation.recordErrors || []).length > 0) {
                            $log.debug("Record violation for index :", violation.index, ": ", violation.recordErrors[0]);
                            $scope.errorValidating = "You entered at least one student twice.  Please change or delete the duplicate student.";
                            $scope.errorFlag = true;
                            // trigger only first name as in error since that is how its done in the CVS Import
                            for (si = 0; si < studentsWithErrors.length; si++) {
                                studentsWithErrors[si].firstNameInvalid = "error";
                            }
                        }
                    }

                    if (!data.violations.length) {
                        // if no errors; change the screen to verify mode
                        $scope.editmode = false;
                    }
                };


                var validateStudentsErrorHandler = function(error) {
                    $log.error("In import error handler", error);
                    $scope.errorValidating = error.message || error.code || "An error occurred. Please try again.";
                    $scope.errorFlag = true;

                };

                $scope.internalControl.addStudents = function addStudents() {
                    // loop over all students and create an array with the students as top level objects instead of embedded objects
                    var arrayForService = [];
                    for (var index = 0; index < $scope.studentarray.length; ++index) {
                        arrayForService.push($scope.studentarray[index].student);
                    }
                    studentService.upsertStudents(arrayForService, $scope.selectedclass.id).then(saveStudentSuccessHandlerRequest, validateStudentsErrorHandler);
                };

                function saveStudentSuccessHandlerRequest(data) {
                	if(data.code == 'error.not.logged.in'){
        				userModel.reset();
        				$uibModalStack.dismissAll();
        				$location.path('/signin/staff');
        				return;
        			}
                    $scope.savestudentsuccesshandler({
                        students: data
                    });

                    //MB: Analytics code for tracking event of adding new students through QEF
                    try {
                        SDMAnalyticsService.addEventType(sharedConstants.QEF_ADD_STUDENTS_EVENT, 'selectedClass', 'newStudents', 'existingStudents');
                        SDMAnalyticsService.emit(sharedConstants.QEF_ADD_STUDENTS_EVENT, $scope.selectedclass, data, studentModel.studentList);
                    } catch (e) {
                        $log.debug(sharedConstants.QEF_ADD_STUDENTS_EVENT_ERROR);
                    }

                }

                function removeDuplicateSpaces(str) {
                    if (!str) {
                        return '';
                    }

                    // Initial formula borrowed from http://stackoverflow.com/a/1981366/133840 and tweaked
                    return str.replace(/  +/g, ' ').trim();
                }

                function validateFirstName(student) {
                    // trim all extra spaces
                    var localFirstName = removeDuplicateSpaces(student.firstName);

                    if ((!student.firstName) || !globalValidation.validateName(localFirstName) || (student.firstName == '')) {
                        student.firstNameInvalid = 'error';
                    } else if (student.firstNameInvalid == 'error') {
                        // if previous error was set that no longer applies; then reapply
                        student.firstNameInvalid = 'error validField';
                    }
                }

                function validateLastName(student) {
                    // trim all extra spaces
                    var localLastName = removeDuplicateSpaces(student.lastName);

                    if ((!student.lastName) || !globalValidation.validateName(localLastName) || (student.lastName == '')) {
                        student.lastNameInvalid = 'error';
                    } else if (student.lastNameInvalid == 'error') {
                        student.lastNameInvalid = 'error validField';
                    }
                }

                function validateGradeChange(student) {
                    if (student.grade == "Select Grade") {
                        student.gradeInvalid = 'dropdownError';
                    } else if (student.gradeInvalid == 'dropdownError') {
                        student.gradeInvalid = 'dropdownSuccess';
                    }
                }


                // initialization routine
                this.onInit = function() {
                    if (!$scope.options) {
                        $scope.options = {};
                    }

                    if (!$scope.studentarray) {
                        $scope.studentarray = [{
                            student: {
                                grade: 'Select Grade'
                            }
                        }];
                    }

                    if (!$scope.selectedclass) {
                        $scope.selectedclass = {
                            grades: []
                        };
                    }

                };
                this.onInit();
            }]
        }
    }
]);
