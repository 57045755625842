portalModule.directive('csvReader', function () {
    return {
        scope: {
            selectedFile: "&method",
            disableUpload: "=disableUpload"
        },
        template:'<input type="file" name="File Upload" id="csvUpload" accept=".csv" />',
        link: function (scope, element) {
            $(element).on('change', function (changeEvent) {
            	scope.disableUpload = true;
                var files = changeEvent.target.files;
                scope.selectedFile({value:files[0]});
            });
        }
    };
});
