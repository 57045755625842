/**
 * Created by jhouser on 9/18/2015.
 */


portalModule.service("teacherModel", ['$uibModal',function($uibModal) {

    var dataObject = {

        createRemoveTeacherDialog : createRemoveTeacherDialog

    };


    return dataObject;



    // encapsulated this method to share the remove teacher dialog code between the editing a subscription page (productEdit.js) and editing Clever Teachers Subscription associations (teacherEdit.js )
    function createRemoveTeacherDialog(teacher, subscriptionID){
        return $uibModal.open({
            templateUrl: 'resources/js/Modules/products/revokeEntitlement/revokeEntitlement.html',
            controller: 'revokeEntitlementPopupController',
            resolve : {
                teacher : function() { return teacher },
                subscriptionId : function() { return subscriptionID }
            }
        }).result;

    }


}]);
