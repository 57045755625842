(function () {

    angular.module('portalApp').service('formValidationService', [
        '$log','$q','sessionState', formValidationsFunction
    ]);

    function formValidationsFunction($log,$q,sessionState) {
        var $this = this;

        $this.reset = function () {
            $this.formRowsArray = undefined;
            delete $this.formRowsArray;
        };
        $this.setFormRowsArray = function (arr) {
            $log.debug('formRowsArray',arr);
            $this.formRowsArray = arr;
        };
        $this.getFormRowsArray = function () {
            return $this.formRowsArray;
        };
        $this.markGradeInvalid = function(student){
            var gradeLookup = { "pre-kindergarten":"pk", "kindergarten":"k", "grade 1":"1", "grade 2":"2",
                "grade 3":"3", "grade 4":"4", "grade 5":"5", "grade 6":"6", "grade 7":"7", "grade 8":"8",
                "grade 9":"9", "grade 10":"10", "grade 11":"11", "grade 12":"12", 
                "pk":"pk", "k":"k", "1":"1", "2":"2", "3":"3", "4":"4", "5":"5", "6":"6", "7":"7",
                "8":"8", "9":"9", "10":"10", "11":"11", "12":"12"
            };
            var gradeLevels = sessionState.get('$scope').gradelevels;
            $log.debug('gradelevels = ',JSON.stringify(gradeLevels,null,2));
            var sectionGradeLevels = {};
            gradeLevels.forEach(function(grade){
                sectionGradeLevels[grade.label.toLowerCase()]
                    = sectionGradeLevels[grade.value.toLowerCase()] = grade.value.toLowerCase();
            });
            return !(gradeLookup[student.grade.toLowerCase()] && sectionGradeLevels[student.grade.toLowerCase()]);
        };
        $this.duplicatesCount = function () {
            return $q(function (resolve, reject) {
                var newArray = $this.formRowsArray;
                var sectionDuplicate = {};
                var emptyCase = "||";
                var duplicatesObject = {
                    duplicatesCount: 0
                };
                for (var i = 0; i < newArray.length; i++) {
                    $log.debug('student',JSON.stringify(newArray[i],null,2));
                    /** Names */
                    if (sectionDuplicate.hasOwnProperty(newArray[i].lowerCaseNames)
                        && (newArray[i].lowerCaseNames !== emptyCase)) {
                        /** Duplicate found, set sectionDuplicate = true */
                        newArray.map(function (student) {
                            if (newArray[i].lowerCaseNames === student.lowerCaseNames) {
                                student.sectionDuplicate = true;
                                student.tooltipMessage = "Duplicate students cannot be added to the same class. " +
                                    "Ensure the student name and id fields are unique.";
                            }
                            return student;
                        });
                        duplicatesObject.duplicatesCount++;
                    } else if (newArray[i].lowerCaseNames !== emptyCase) {
                        /** No Duplicate */
                        sectionDuplicate[newArray[i].lowerCaseNames] = newArray[i].sectionDuplicate = false;
                        newArray[i].tooltipMessage = "";
                    }

                    if (i + 1 === newArray.length) {
                        $log.debug('duplicatesObject',duplicatesObject);
                        return resolve(duplicatesObject);
                    }
                }
                return resolve(duplicatesObject);
            });
        };
        $this.markMissingInfo = function(studentsArray){
            studentsArray.map(function (student) {
                $log.debug('markMissingInfo student',student);
                /** Blank First or Last for Uploaded Students */
                if(student.entryType === 'upload' && !student.firstName){
                    student.blankFirstName = true;
                    student.blankFirstNameTooltip = "Students cannot be added without a first name.";
                } else {
                    student.blankFirstName = false;
                    student.blankFirstNameTooltip  = "";
                }
                if(student.entryType === 'upload' && !student.lastName){
                    student.blankLastName = true;
                    student.blankLastNameTooltip  = "Students cannot be added without a last name.";
                } else {
                    student.blankLastName = false;
                    student.blankLastNameTooltip  = "";
                }
                /** Invalid Grades for Uploaded Students */
                if((student.entryType === 'upload' && !student.grade)
                    || (!!student.grade && $this.markGradeInvalid(student))){
                    student.gradeInvalid = true;
                    student.gradeTooltip = "Students cannot be added without a valid grade.";
                } else {
                    student.gradeInvalid = false;
                    student.gradeTooltip = "";
                }
                return student;
            });
        };
        $this.parseValidations = function parseValidations(newStudentsArray, violationsArray, response) {
            return $q(function (resolve, reject){
                violationsArray = violationsArray || [];
                $log.debug('parseValidations newStudentsArray = ',JSON.stringify(newStudentsArray,null,2));
                $log.debug('parseValidations violationsArray = ',JSON.stringify(violationsArray,null,2));
                $log.debug('parseValidations response = ',JSON.stringify(response,null,2));
                $this.setFormRowsArray(newStudentsArray);
                $this.duplicatesCount()
                    .then(function(duplicatesObject){
                        $log.debug('parseValidations newStudentsArray',JSON.stringify(newStudentsArray,null,2));
                        /** violationsArray should be a unique list of students which already exist */
                        var newViolations = [];
                        if (!!response && !!response.violations) {
                            newViolations = response.violations.map(function (violation) {
                                return {
                                    index: violation.index,
                                    recordErrors: violation.recordErrors,
                                    firstName: response.records[violation.index].firstName,
                                    lastName: response.records[violation.index].lastName,
                                    grade: response.records[violation.index].grade,
                                    studentId: response.records[violation.index].studentId,
                                    lowerCaseNames: (response.records[violation.index].firstName || '').toLowerCase()
                                        + "|" + (response.records[violation.index].lastName || '').toLowerCase()
                                        + "|" + (response.records[violation.index].studentId || '')
                                };
                            });
                        }
                        /** if each violation has been cleared prior to clicking the save then
                         * concat will always be new.
                         * */
                        violationsArray = newViolations.length ? violationsArray.concat(newViolations)
                            : violationsArray;
                        $this.markViolations($this.formRowsArray, violationsArray);
                        $this.markMissingInfo($this.formRowsArray);
                        return resolve(violationsArray);
                    })
                    .catch(function(error){
                        $log.info('error', error);
                        return reject(error);
                    });
            });
        };
        $this.recordErrorHasOrgDuplicate = function(recordErrors){
            $log.debug('recordErrors',JSON.stringify(recordErrors,null,2));
            return recordErrors.some(function(err){
                    return err.code === 'error.duplicate.student.name';
                });
        };
        $this.markViolations = function (newStudentsArray, violationsArray) {
            /** check for duplicates in Names and StudentIds against violations and against formRows */
            /** mark violations on the formRows for display */
            var emptyCase = "||";
            var uniqueViolations
                = $this.listUnique('lowerCaseNames',violationsArray,'' + '|' + '' + '|' + '');
            newStudentsArray.map(function (student) {
                student.orgDuplicate = uniqueViolations.some(function(violation){
                    return student.lowerCaseNames !== emptyCase
                        && !!violation && violation.lowerCaseNames !== emptyCase
                        && student.lowerCaseNames === violation.lowerCaseNames
                        && !!violation.recordErrors && $this.recordErrorHasOrgDuplicate(violation.recordErrors);
                });
                student.tooltipMessage = student.sectionDuplicate ? "Duplicate students cannot be added " +
                    "to the same class. Ensure the student name and id fields are unique." :
                    student.orgDuplicate ? "This student exists in your organization.  Ensure the student name " +
                        "and id fields are unique, or add the existing student account to your class via search." : "";
                return student;
            });
            $log.debug('markViolations newStudentsArray',JSON.stringify(newStudentsArray,null,2));
        };
        $this.listUnique = function listUnique(propToMatch,list,exception) {
            $log.debug('listUnique',propToMatch,list,exception);
            var register = {};
            return list.filter(function (item) {
                if (register.hasOwnProperty(item[propToMatch])
                    && item[propToMatch] !== exception) {
                    // Current property value is already in register
                    return false;
                }
                // Current property value is not in register and must be added
                return register[item[propToMatch]] = true;
            });
        };
    }

})();
