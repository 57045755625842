angular.module('portalApp')
    .controller('loginViewContentController',[
        '$scope','$log', '$location','$uibModal','$uibModalStack',
        function ($scope,$log, $location,$uibModal,$uibModalStack) {

            var $this = this;

            $this.templateUrl = 'resources/js/Modules/login/messageModal/showForgotPassword.html'
            $scope.$on('$locationChangeStart', handleLocationChange);
            /** handleLocationChange()
             * Dismisses all modals on location change start,
             * such as when the back button is pressed.
             */
            function handleLocationChange() {
                $uibModalStack.dismissAll();
            }
            
            $scope.showForgotPasswordPopup = function () {
                var modal = $uibModal.open({
                    templateUrl: $this.templateUrl,
                    controller: 'showForgotPasswordController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (response) {
                });

            }

        }]);
