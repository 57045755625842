//MB: Referenced from http://stackoverflow.com/questions/24820381/disable-submit-when-validate-confirm-password-in-angularjs
//Although directive used just for matching fields. Could be reused to match any condition passed in directive
portalModule.directive('matchFields', function () {
	 return {
	        require: 'ngModel',
	        link: function (scope, elm, attrs, ctrl) {
	                attrs.$observe('matchFields', function (newVal) {
	                    if (newVal === 'true') {
	                        ctrl.$setValidity('noMatch', true);
	                    } else {
	                        ctrl.$setValidity('noMatch', false);
	                    }
	                });
	        }
	    }
});