portalModule.directive('customValidatorForbiddenInput', ['globalValidation', function (globalValidation) {
    // custom-validator-forbidden-input
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$validators.forbiddenInput = function (elemValue) {
                var valid = true;
                var alphaNumbericRegex = /^[a-zA-Z0-9]*$/;
                var gradeRegex = /^\b(Pre-Kindergarten|Kindergarten|Grade 1|Grade 2|Grade 3|Grade 4|Grade 5|Grade 6|Grade 7|Grade 8|Grade 9|Grade 10|Grade 11|Grade 12|pk|k|1|2|3|4|5|6|7|8|9|10|11|12)\b$/i;

                if(!elemValue) {
                    return true;
                } else if (attrs.name === "firstName"
                        || attrs.name === "lastName") {
                    valid = globalValidation.validateName(elemValue);
                    scope.$emit('invalidCharactersInName',valid);
                    return valid;
                } else if (attrs.name === "studentId") {
                    valid = alphaNumbericRegex.test(elemValue);
                    scope.$emit('invalidCharactersInStudentId',valid);
                    return valid;
                } else if (attrs.name === "username") {
                    valid = alphaNumbericRegex.test(elemValue);
                    return valid;
                } else if (attrs.name === "gradeLevel") {
                    valid = gradeRegex.test(elemValue);
                    return valid;
                }
            };
        }
    }
}]);