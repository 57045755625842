portalModule
    .controller('addStudentContentController', [
        '$scope', '$log', '$location', 'csvService', 'productModel', 'productService',
        function ($scope, $log, $location, csvService, productModel, productService) {

        var $this  = this;
        $this.onInit = onInit;

        $scope.spinner = true;
        $scope.initialOnboarding = ($location.path().indexOf('firstclass') > -1);

        $scope.showSearch = false;

        $scope.onButtonPress = function (message) {
            $log.debug('message', message);
            var prefix = '/students/';

            if ($scope.initialOnboarding) {
                prefix += 'firstclass/';
            }

            if (message === 'searchStudents' && !$scope.srcTeacher) {
                csvService.reset();
                $location.path(prefix + 'search/' + $scope.orgId + "/" + $scope.sectionId);
            } else if (message === 'createNewStudents' || $scope.srcTeacher) {
                $log.debug('Create New Student');
                csvService.reset();
                $location.path(prefix + 'searchAdd/' + $scope.orgId + "/" + $scope.sectionId);
            }
        };

        $scope.goToDashboard = function() {
            $location.path('/products');
        };

        function  onInit() {
            productService.getEntitlement().then(function() {
                $scope.showSearch = productModel.isSRCTeacher !== undefined && !productModel.isSRCTeacher;
            }).finally(function () {
                $scope.spinner = false;
            });

            if ($scope.sectionName === 'STUDENTHOME') {
                $scope.sectionName = '';
                $scope.addStudentText = 'ADD A NEW STUDENT';
                $scope.searchStudentText = 'ADD EXISTING STUDENT';
            } else {
                $scope.addStudentText = 'CREATE NEW ACCOUNT';
                $scope.searchStudentText = 'SEARCH STUDENTS';
            }
        }

        $this.onInit();
    }]);