portalModule.controller('ChangePasswordPopupController',
		['$scope', '$log', 'userModel', 'userService', '$uibModalInstance', 'SDMAnalyticsService', 'sharedConstants',
		 function($scope, $log, userModel, userService, $uibModalInstance, SDMAnalyticsService, sharedConstants) {


    function initScopeFunctions() {

        $scope.error = '';
        $scope.errorFlag = false;

        $scope.cancel = function(message) {
            $log.debug('ChangePassword Cancel');
            $uibModalInstance.close({
                msg: message
            });
        };

        $scope.onChangePassword = function() {
          // check to make sure that the passwords match [or not]
          // if not make sure that form is set to invalid
          //

          var isOldPassValid = $scope.existingPassword.length > 0;
          $scope.noExistingEntered = !isOldPassValid;

          // for some reason sometimes the newPassword / ConfirmPassword fields are undefined in this method; which satisfies the regular expression
          // I think it means this field is in the process of updating when this method is run
          // the extra undefined check is added to invalidate the password for undefined password inputs
          var isNewPassValid =
            $scope.newPassword === undefined
              ? false
              : $scope.selectedPattern.test($scope.newPassword);

          var isConfirmPassValid =
            $scope.confirmPassword === undefined
              ? false
              : $scope.selectedPattern.test($scope.confirmPassword);

          $scope.passwordsMisMatched =
            $scope.newPassword.trim() !== $scope.confirmPassword.trim();

          $scope.formValid =
            isOldPassValid &&
            isNewPassValid &&
            isConfirmPassValid &&
            !$scope.passwordsMisMatched;
        };

        $scope.updatePassword = function() {
            var credentials = {
                currentPassword: $scope.existingPassword,
                newPassword: $scope.newPassword
            };

            // call userService.updateCredentials
            if (userModel.isTeacher()) {
                userService.updateCredentialsTeacher(credentials, userModel.currentUser.userIdentifiers.staffId).then(updatePasswordSuccess, updatePasswordFail);
            } else {
                userService.updateCredentialsStudent(credentials, userModel.currentUser.userIdentifiers.studentId).then(updatePasswordSuccess, updatePasswordFail);
            }

        };

        $scope.closeAlert = function() {
            $scope.error = '';
            $scope.errorFlag = false;
        };

    }

    function updatePasswordSuccess(data) {
        $log.debug('Change Password Success handler',data);
        //MB: Analytics code to send change password event
        try{
        	 SDMAnalyticsService.addEventType(sharedConstants.CHANGE_PASSWORD_EVENT, 'userContext');
             SDMAnalyticsService.emit(sharedConstants.CHANGE_PASSWORD_EVENT, userModel.currentUser);
        }
        catch(e){
        	$log.debug(sharedConstants.CHANGE_PASSWORD_EVENT_ERROR);
        }
       
        //MB: Commenting this code for future use in case we need to do callouts directly for analytics
//        try{
//        	_satellite.track('password_update');
//        }
//        catch(e){
//        	console.log('Error in analytics');
//        }
        // according to Swagger; a 200 response with no body is a success.  Weird but we'll go with it.
        $scope.cancel('Password Successfully Changed');
    }

    function updatePasswordFail(data) {
        $log.debug(data);
        $scope.errorFlag = true;
        $scope.error = data.message || data.code || "There was a problem updating your password.";
    }

    function initScopeVariables() {
        $scope.existingPassword = '';
        $scope.newPassword = '';
        $scope.confirmPassword = '';
        $scope.noExistingEntered = false;
        $scope.passwordsMisMatched = false;
        $scope.formValid = false;
        $scope.error = '';
        $scope.errorFlag = false;
        // borrowed from accountCreate
        $scope.teacherPattern = /^(?=.*\d)(?=.*[a-zA-Z]).{7,30}$/;
        $scope.studentPattern = /.{4,30}$/;

        $scope.teacherPasswordError = 'Password must be at least 7 characters, no more than 30 characters, and must include at least one letter and one digit.';
        $scope.studentPasswordError = 'Password must be at least 4 characters.';

        // if user is a teacher; set the input pattern
        if (userModel.isTeacher()) {
            $scope.selectedPattern = $scope.teacherPattern;
            $scope.selectedPasswordError = $scope.teacherPasswordError;
        } else {
            $scope.selectedPattern = $scope.studentPattern;
            $scope.selectedPasswordError = $scope.studentPasswordError;
        }

    }

    var onInit = function() {
        initScopeVariables();
        initScopeFunctions();
        $log.debug('Current User',userModel.currentUser);
    };
    onInit();

}]);
