portalModule.service("notificationsService", ["$log", function ($log) {
    var $this = this;
    $this.errorFlag = [];

    $this.setMessage = function (errorFlag) {
        $this.errorFlag.push(errorFlag);
    };

    $this.popMessage = function () {
        return $this.errorFlag.pop();
    }
}]);