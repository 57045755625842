(function () {

    /**
     *
     * @author Manit Banker
     * */

    portalModule.controller('launchAppController',
        ['$scope', '$location', '$log', '$timeout', '$window',
        'userService', 'notificationModel', 'navModel', 'loginModel', 'orgService',
        'productService', 'productModel', 'subscriptionModel', 'userModel', 'studentPortalAppService',
        '$cookies','sharedConstants', 'SessionService', 'appModel', 'classModel',
        'orgModel', 'studentModel', 'sessionModel', 'easyLoginModel', 'gapiService',
        'csvService','cookieService',
        function ($scope, $location, $log, $timeout, $window,
                  userService, notificationModel, navModel, loginModel, orgService,
                  productService, productModel, subscriptionModel, userModel, studentPortalAppService,
                  $cookies, sharedConstants, SessionService, appModel, classModel,
                  orgModel, studentModel, sessionModel, easyLoginModel, gapiService,
                  csvService, cookieService) {

            var $this = this;
            $this.newUser = false;
            $this.redirectUrl = $window.location.hash.substr(1, $window.location.hash.length);
            $this.reloadOnError = reloadOnError;
            $this.redirectPath = redirectPath;
            $this.maxRetryCount = 0;

            $this.errorMessages = {
                fallback: sharedConstants.ERROR_PROBLEM_UNKNOWN,
                noAccess: 'You do not have access to the application you are attempting to launch. Please contact your administrator.'
            };

            function redirectPath(path, search) {
                $timeout(function () {
                    $location.path(path);

                    if (!!search) {
                        $location.search(search);
                    }
                },1000);
            }

            function reloadOnError() {
                var notificationError;

                $this.maxRetryCount--;

                // this code redirect will only happen when all retries have been done and still there is a timeout
                if (userModel.currentUser.student) {
                    // this value is set if the Student is not getting redirected to the MAgs page

                    $this.urlToRedirect = '/studentportal';
                } else {
                    // this value is set if the Teacher is not getting redirected to the MAgs page
                    $this.urlToRedirect = '/products';
                }

                if ($this.maxRetryCount >  0) {
                    $this.urlToRedirect = '';
                    $this.urlToRedirect = $this.redirectUrl + '&maxRetryCount=' + $this.maxRetryCount;
                } else {
                    notificationError = {};
                    notificationError['message'] = sharedConstants.STAFF_SELECT_MAGAZINE_MANUALLY;
                    notificationModel.setMessage(JSON.stringify(notificationError));
                }
                $this.redirectPath($this.urlToRedirect);
            }

            $this.getParameters = function () {
                //Putting it in scope for unit test purpose
                $scope.appCode = $location.search().app;
                $scope.stateParam = $location.search().state;
                $scope.urlParam = $location.search().url;
                $scope.acode = $location.search().acode;
                $scope.launchType = $location.search().launchType;
                $scope.role = $location.search().role;

                if (userModel.currentUser.student) {
                    $this.checkIfStudentHasAccess();
                } else {
                    if (!!$scope.appCode) {
                        $this.checkIfTeacherHasAccess();
                    } else {
                        $this.checkIfUserHasAccess();
                    }
                }
            };

            $this.appendState = function(url) {
                var newUrl = url;
                if (!!$scope.stateParam) {
                    var pattern = new RegExp(/\?.+=.*/g);
                    var prefix = pattern.test(newUrl) ? '&' : '?';
                    newUrl += prefix + 'state=' + $scope.stateParam;
                }
                return newUrl;
            };

            $this.getProductsSearchParams = function() {
                var search = {};

                if (!!$scope.appCode) {
                    search.app = $scope.appCode;
                }

                if (!!$scope.acode) {
                    search.acode = $scope.acode;
                }

                if (!!$scope.role) {
                    search.role = $scope.role;
                }

                return search;
            };

            $this.staffRedirect = function() {
                var search = $this.getProductsSearchParams();

                if (subscriptionModel.hasSubsAsAdmin() && !search.acode) {
                    $this.redirectPath( '/subscriptions');
                } else {
                    $this.redirectPath('/products', search);
                }
            };

            $this.launchApp = function(orgId, userType, isAdmin, teacherDoesNotHaveAccess) {
                var params = {
                    'byAppCode': true,
                };

                if (isAdmin) {
                    params.isAdmin = true;
                }

                if ($scope.stateParam !== undefined) {
                    params.state = $scope.stateParam;
                }

                if ($scope.urlParam !== undefined) {
                    params.url = $scope.urlParam;
                }

                /// this below code is written considering situations when the student is part of more than 1
                // org , then the list of org will be pushed to an Array.
                // the Verification of app code against all the org for the student will be till we
                // get the successfull one

                if (userType === 'student') {
                    if ($scope.studentOrgId.length !== 0) {
                        orgId = $scope.studentOrgId.pop();
                    } else {
                        $this.redirectPath('/studentportal');
                    }
                }

                productService.launchDataByAppCode($scope.appCode, orgId, userType, params, $scope.launchType)
                  .then(function(data) {
                      $log.debug('Launch data', data);

                      $scope.outboundLaunch = data;
                      $scope.launchParams = data.params;

                      $log.debug('outboundLaunch', $scope.outboundLaunch);
                      $log.debug('launchParams', $scope.launchParams);

                      if (teacherDoesNotHaveAccess) {
                          if ($scope.acode) {
                              $this.staffRedirect();
                              return;
                          }

                          var altUrl;
                          if (!!$scope.launchParams.custom_dp_launch) {
                              $this.customObj = JSON.parse($scope.launchParams.custom_dp_launch);

                              if (
                                !!$this.customObj.launchContext &&
                                !!$this.customObj.launchContext.application &&
                                !!$this.customObj.launchContext.application.alternateUrl
                              ) {
                                  altUrl = $this.appendState($this.customObj.launchContext.application.alternateUrl);
                                  $window.location.href = altUrl;
                              } else {
                                  $this.staffRedirect();
                              }
                          }
                      } else {
                          $timeout(function() {
                              if ($scope.launchType && $scope.launchType === 'oauth') {
                                  $window.location.href = $scope.outboundLaunch.launchUrl;
                                  return;
                              }

                              var form = $('#launchForm');
                              form.attr('action', $scope.outboundLaunch.launchUrl);
                              form.submit();
                          }, 0);
                      }
                  })
                  .catch(function(error) {
                    $log.error('$this.launchApp productService.launchDataByAppCode error', error);

                    // reset the post login URL in case the redirect is not allowed
                    // this prevents an infinite loop
                    loginModel.reset();

                    if (!!error && error.code === 'error.student.does.not.have.access') {
                        $this.launchApp(-1, 'student', false);
                    } else if (
                      !!error &&
                      (
                        (error.code === 'error.staff.member.does.not.have.access' && teacherDoesNotHaveAccess) ||
                        error.code === 'error.org.notexists')
                    ) {
                        if ($scope.acode) {
                            $this.staffRedirect();
                            return;
                        }

                        // todo get all the staff Apps
                        // todo get the Alternate URL from all apps
                        // todo this code will be re-written once proxy is ready to get /Apps
                        if ($scope.appCode) {
                            productService.getAlternateUrl($scope.appCode)
                              .then(function (response) {
                                  $log.debug('getAlternateUrl response', response);

                                  if (!!response && !!response.alternateUrl) {
                                      var url = $this.appendState(response.alternateUrl);
                                      $window.location.href = url;
                                  } else {
                                      $this.staffRedirect();
                                  }
                              })
                              .catch(function (err) {
                                  $log.debug('getAlternateUrl error', err);
                                  $this.staffRedirect();
                              });
                        } else {
                            $this.staffRedirect();
                        }
                    } else {
                        $this.staffRedirect();
                    }
                });
            };

            $this.checkIfTeacherHasAccess = function() {
                var orgIdForTeacherEntitlement = productModel.checkIfUserHasAccessAsTeacher($scope.appCode);
                var teacherDoesNotHaveAccess;
                if (!orgIdForTeacherEntitlement) {
                    teacherDoesNotHaveAccess = true;
                    var org = productModel.getCookieForOrgAsTeacher();
                    if (!!org) {
                        orgIdForTeacherEntitlement = org.orgId;
                    }
                }

                $log.debug('Current user is entitled to requested app as teacher');
                $this.launchApp(orgIdForTeacherEntitlement, 'staff', false, teacherDoesNotHaveAccess);
            };

            $this.checkIfUserHasAccess = function () {
                var orgIdForTeacherEntitlement = productModel.checkIfUserHasAccessAsTeacher($scope.appCode);
                if (orgIdForTeacherEntitlement) {
                    $log.debug('Current user is entitled to requested app as teacher');
                    $this.launchApp(orgIdForTeacherEntitlement, 'staff', false);
                } else {
                    var orgIdForAdminSubscription = subscriptionModel.checkIfUserHasAccessAsAdmin($scope.appCode);
                    if (orgIdForAdminSubscription) {
                        $log.debug('Current user has subscription as admin for requested app');
                        $this.launchApp(orgIdForAdminSubscription, 'staff', true);
                    } else {
                        $log.debug('Current user does not have requested app');
                        loginModel.reset();
                        $this.staffRedirect();
                        navModel.showNav = true;
                    }
                }
            };

            $this.checkIfStudentHasAccess = function () {
                if (!!$scope.studentEntitlements && $scope.studentEntitlements.length > 0 ) {
                    for (var i = 0; i < $scope.studentEntitlements.length ; i++) {
                        if ($scope.appCode === $scope.studentEntitlements[i].applicationCode) {
                            $this.launchApp(-1, 'student', false);
                            return;
                        }
                    }

                    $log.debug('Current student does not have requested app');
                    loginModel.reset();

                    var path;
                    var notificationError;

                    notificationError = {};
                    notificationError['message'] = sharedConstants.STUDENT_NO_ACCESS_MESSAGE;
                    notificationModel.setMessage(JSON.stringify(notificationError));
                    $location.search({});
                    path = '/studentportal';
                } else {
                    $location.search({});
                    path = '/studentportal';
                }

                $this.redirectPath(path);
            };

            $this.resetServicesOnSignout = function(){
                appModel.reset();
                classModel.reset();
                navModel.reset();
                orgModel.reset();
                subscriptionModel.reset();
                studentModel.reset();
                userModel.reset();
                sessionModel.reset();
                productModel.reset();
                easyLoginModel.reset();
                gapiService.reset();
                csvService.reset();
            };

            $this.redirectOnSignout = function(){
                var path;

                path = '/signin';

                if (($scope.role === 'staff') || (cookieService.getCookie('userType') === 'staff')) {
                    path += '/staff';
                }

                $this.redirectPath(path);
                navModel.licenseShowNav = false;
            };

            $this.setGoogleUserCookie = function(isStaff){
                var options = { path: '/'};
                var role = $location.search().role;

                if(isStaff){
                    cookieService.saveCookie(gapiService.googleEntryTypeCookieName, 'staff', options);
                    gapiService.googleEntryUserType = 'staff';
                } else if(role === 'student'){
                    cookieService.saveCookie(gapiService.googleEntryTypeCookieName, 'student', options);
                    gapiService.googleEntryUserType = 'student';
                } else {
                    cookieService.saveCookie(gapiService.googleEntryTypeCookieName, '', options);
                }
            };

            $this.init = function () {
                userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                    if (!!currentUser) { $this.setGoogleUserCookie(currentUser.isStaff); }
                    SessionService.isExtSessionActive(currentUser.sessionContext).then(function(data) {
                        $log.debug('EXT-session response from launch app', data);

                        $this.maxRetryCount = $location.search().maxRetryCount;

                        if (!$this.maxRetryCount) {
                            // this is number of time , the App will reload itself
                            $this.maxRetryCount = 3;
                        }

                        if(!!data.sessionActive) {
                            $log.debug('User signed in ', currentUser);
                            if (userModel.currentUser.student) {
                                studentPortalAppService.getSchoolInfo().then(function (data) {
                                    $scope.studentOrgId = [];

                                    $scope.studentOrgId = data.map(function (item) {
                                       return item.id;
                                    });

                                    $this.entitlementPromises =  data.map(function (item) {
                                        return studentPortalAppService.getAppsforStudent(item.id);
                                    });

                                    $this.entitlementPromisesResolve = Promise.all($this.entitlementPromises).then(function (entitlements) {
                                            if (!!entitlements && entitlements.length > 0) {
                                                var dataValue = [];
                                                entitlements.forEach(function (value) {
                                                    dataValue = value.concat(dataValue);
                                                });
                                                $scope.studentEntitlements = dataValue;
                                                $this.getParameters();
                                            }
                                        }, $this.reloadOnError
                                    );
                                }, $this.reloadOnError);
                            } else {
                                productService.getEntitlement().then(function (data) {
                                    productService.getSubscriptions().then(function () {
                                        $this.newUser = !!data && data.length === 0;

                                        if (
                                          productModel.getCookieForOrgAsTeacher() === undefined &&
                                          !!data &&
                                          data.length !== 0
                                        ) {
                                            productModel.setCookieForOrgAsTeacher(data[0].id)
                                        }

                                        navModel.licenseShowNav = true;
                                        navModel.showNav = false;
                                        navModel.helpPage = false;
                                        $this.getParameters();
                                    }, $this.reloadOnError);
                                }, $this.reloadOnError);
                            }
                        } else {
                            //$log.error('User not signed in ', error);
                            $scope.role = $location.search().role;
                            // $location.path() is not saving the URL variables
                            // so moving to $window.location.hash
                            // but gotta remove the '#' from front of it
                            loginModel.setPostLoginUrl($window.location.hash.substr(1, $window.location.hash.length));

                            // logout user logic goes here. As part of this, update the service to reset the models.
                            $this.resetServicesOnSignout();
                            userService.signoutUser().then(function(res) {
                                $log.debug(res);
                                userModel.checkUser = 1;
                                //Only execute redirect logic after logout success/error
                                $this.redirectOnSignout();
                            }, function(err) {
                                $log.error(err);
                                userModel.checkUser = 1;
                                //Only execute redirect logic after logout success/error
                                $this.redirectOnSignout();
                            });
                        }
                    }).catch(function(err) {
                        $log.error('Error in ext session from launch app',err);
                    });

                }, function (error) {
                    $log.error('User not signed in ', error);
                    $scope.role = $location.search().role;
                    // $location.path() is not saving the URL variables
                    // so moving to $window.location.hash
                    // but gotta remove the '#' from front of it
                    loginModel.setPostLoginUrl($window.location.hash.substr(1, $window.location.hash.length));
                    $this.redirectOnSignout();
                });
            };

            $this.init();
        }
    ]);
})();
