portalModule.service("appService",['$http', 'apiResponse',function($http,apiResponse){
	return{
		getApps: getApps
	};
	
	function getApps() {
		 var request = $http({
	            method: "get",
	            url: PORTAL_API_ENDPOINT + "/composite/staff/apps"
	        });
			 
		 return  apiResponse.makeApiCall(request);
	}
	
}]);