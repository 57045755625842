/**
 * Created by jhouser on 11/13/2015.
 */

portalModule.controller('SubscriptionController', [
  '$scope', '$log', '$routeParams', 'subscriptionModel', 'productService',
    '$location', '$uibModal', 'userModel', 'userService', 'navModel',
    'orgModel', 'teacherModel', 'teacherService', '$filter', 'settingsService',
    'orgService','configService',
    function($scope, $log, $routeParams, subscriptionModel, productService,
             $location, $uibModal, userModel, userService, navModel,
             orgModel, teacherModel, teacherService, $filter, settingsService,
             orgService, configService) {

        var $this = this;

        $scope.subscription = undefined;
        $scope.subscriptionArray = [];
        $scope.hasMultipleContentPacks = false;
        $scope.accessCode = [];
        $scope.teachers = undefined;
        $scope.showAddTeacherLink = false;
        $scope.showAddAllTeachersButton = false;
        $scope.errorRetrievingData = '';
        $scope.addRemoveMessage = '';
        $scope.filterText = '';
        $scope.isClever = false;
        $scope.hideDropDown = true;
        $scope.showLtiInfo = false;
        $scope.showDownloadAccessCodeButton = false;
        $scope.hideDropDown = true;
        $scope.showSiteAccessCode = false;
        $scope.initialized = false;
        $scope.isReactAdminPrograms = false;
        var teachersInSubscription;

        $this.loadAccessCodesandTeachers = loadAccessCodesandTeachers;
        $this.checkNeedShowEndDate = checkNeedShowEndDate;

        $scope.closeAlert = function() {
            $scope.errorRetrievingData = '';
            $scope.addRemoveMessage = '';
        };

        //MB: Remove teacher from Subscription for Clever/Manual
        $scope.removeTeacher = function(teacher) {
            $log.debug(teacher);

            return teacherModel.createRemoveTeacherDialog(teacher, $scope.subscription.id).then(
                function(data) {
                    if (data !== '') {
                    	$scope.subscription.license.remaining++;
                        $this.loadAccessCodesandTeachers(data);
                        isSubscriptionClever();
                        if ($scope.showEntireCleverTeacherList) {
                            $scope.onAddTeachers();
                        }
                        $scope.addRemoveMessage = 'Teacher successfully removed.';
                        
                        if (!!$scope.isReactAdminPrograms){
                            Volume.programHeroSubscriptionsRefreshTrigger();
                        }
                        
                    }
                }
            );
        };

        //MB: Dropdown to switch different subscriptions on both Subscription details page as well as add teacher page for Clever
        $scope.onSubscriptionChange = function(subscription) {
            $scope.subscription = subscription;
            if($scope.subscription.application.applicationCode === $scope.orgAndSubs.sortAppCode){
                $scope.subscriptionArray = $scope.orgAndSubs.subscriptionsMap[$scope.orgAndSubs.sortAppCode];
                $scope.hasMultipleContentPacks = true;
            }else{
                $scope.hasMultipleContentPacks = false;
            }
            $scope.selectedSubscriptionName = ($scope.hasMultipleContentPacks ? $scope.subscription.application.name :$scope.subscription.displayName);
            $scope.selectedSubContentName = ($scope.hasMultipleContentPacks ? $scope.subscription.product.name : "");
            checkNeedShowEndDate();
            isSubscriptionClever();
            $scope.accesCode = [];
            $scope.teachers = [];
            $this.loadAccessCodesandTeachers($scope.subscription);
        };

        $scope.onSubContentChange = function(subContent){
            $scope.subscription = subContent;
            $scope.selectedSubContentName = $scope.subscription.product.name;
            checkNeedShowEndDate();
            isSubscriptionClever();
            $scope.accesCode = [];
            $scope.teachers = [];
            $this.loadAccessCodesandTeachers($scope.subscription);
        };

        //MB: Switch back to normal subscriptions detail view from Clever add teacher view
        $scope.onShowSubscribedList = function() {
        	$scope.showEntireCleverTeacherList = false;
            $this.loadAccessCodesandTeachers($scope.subscription);
            $scope.showAddAllTeachersButton = false;
        };

        //MB: Process the request whether to add/remove teacher from subscription on clever add teacher view
        $scope.processTeacher = function(teacher) {
            if (teacher.inSubscription) {
                $scope.removeTeacher(teacher);
            } else if ($scope.subscription.license.remaining > 0) {
                addTeacher(teacher);
            }
            // else do nothing
        };

        $scope.onAddAllTeachers = function(){

            productService.addAllTeachers($scope.subscription.id).then(function(data) {
                    // decrease the number of remaining licenses
                    // doing this in the UI instead of reloading data for performance purposes
                    $scope.subscription.license.remaining = $scope.subscription.license.remaining - calculateNumberOfTeachersNotInSubscription();

                    // make sure al teachers are in subscription since we got a successful result
                    // done in UI for performance reasons
                    for (var counter = 0; counter < $scope.teachers.length; counter++){
                        $scope.teachers[counter].inSubscription = true;
                    }

                    // this is set to false whenever a single teacher is added; so doing the same here
                    $scope.noExistingTeachers = false;
                    $scope.onShowSubscribedList();
                    $scope.addRemoveMessage = 'Teachers successfully added.';
                },
                function addTeacherAccessErrorHandler(data) {
                    $log.debug('was not able to succesfully add all teachers' + data);
                    $scope.addRemoveMessage = 'We were not able to add all the teacher.';

                });
        };

        //MB: Add teacher to subscription on clever add teacher view
        function addTeacher(teacher) {
            productService.addTeacherAccess($scope.subscription.id, teacher.id).then(function(data) {
                    // decrease the number of remaining licenses
                    // doing this in the UI instead of reloading data for performance purposes
                    $scope.subscription.license.remaining--;
                    // toggle teacher's subscription info for UI; also doing this w/o reloading data for performance reasons
                    teacher.inSubscription = true;
                    $scope.noExistingTeachers = false;
                    $scope.addRemoveMessage = 'Teacher successfully added.';
                },
                function addTeacherAccessErrorHandler(data) {
                    $log.debug('was not able to succesfully add a teacher' + data);
                    $scope.addRemoveMessage = 'We were not able to add the teacher.';

                });
        }

        //MB: Search filter to search teachers only on clever add teacher view
        $scope.onFilterChange = function() {

            $scope.teachers = $scope.teachersMasterList.filter(function(sub, index) {
                // compare $scope.filterText to
                // sub.firstName
                // sub.lastName
                // sub.email
                // if it matches any of them; return true
                // else return false

                var regexName = new RegExp('.*' + $scope.filterText + '.*', 'i');
                return regexName.test(sub.firstName + ' ' + sub.lastName) || regexName.test(sub.email);
            });

        };

        //MB: Load teachers and access codes(for manual) on subscription details page for both clever and manual
        function loadAccessCodesandTeachers(subscription) {
            if (subscription) {
                $scope.subscription.license.remaining = subscription.license.remaining;
                var id = subscription.id;

                if(subscription.rosterSource.toLowerCase() === 'none' || subscription.license.quantity < 1){
                    return;
                }

                $log.debug('About to load access codes and teachers for sub #', id, ' sub: ', $scope.subscription);

                if(subscription.license.type.toLowerCase() === 'universal' || !subscription.teacherDirected){
                    productService.loadAccessCodesForSubscription(id).then(function(codes) {
                        $scope.accessCode = codes;
                        $log.debug('Access codes: ', codes);
                    });
                }

                productService.loadTeachersForSubscription(id).then(function(teachers) {
                    teachersInSubscription = teachers;
                    if (teachers.length === 0 && $scope.isClever) {
                        $scope.noExistingTeachers = true;
                        $scope.onAddTeachers();
                    } else {
                        $log.debug('Teachers: ', teachers);
                        if($scope.isClever){
                        	$scope.showAddTeacherLink = true;
                        }
                        $scope.noExistingTeachers = false;
                        if ($scope.showEntireCleverTeacherList) {
                            $scope.onAddTeachers();
                        }
                        else{
                        	$scope.teachers = teachers;
                        }
                    }
                }, function(error) {
                    $log.error('error loading teachers using subscription');
                    $scope.errorRetrievingData = error.message;
                });
            }

        }

        function resetTeacherSubscriptions() {
            if (!$scope.teachersMasterList) {
                return;
            }

            $scope.teachersMasterList.forEach(function(element, index, array) {
                element.inSubscription = false;
            });

        }

        //MB: Load teachers on clever add teacher view
        function loadActiveTeachers() {


        	resetTeacherSubscriptions();
            //        loop over all teachers $scope.teachersMasterList | and if they are in teachersInSubscription mark them as such somehow
            //        basically conflate the two lists and make them show up in the checkbox UI

            teachersInSubscription.forEach(function(element, index, array) {
                for (var counter = 0; counter < $scope.teachersMasterList.length; counter++) {
                    if (element.id == $scope.teachersMasterList[counter].id) {
                        $scope.teachersMasterList[counter].inSubscription = true;
                        break;
                    }
                }
            });

            $scope.teachers = $scope.teachersMasterList;
        }

        //MB: Show expanded list for teachers when user clicks on add teachers on clever subscription detail page
        $scope.onAddTeachers = function() {
            teacherService.loadTeachersBySchool(userModel.currentUser.staff.id, $scope.orgAndSubs.id).then(function(data) {
            	$log.debug('List of all teachers including inactive teachers',data);
                if($scope.isClever){
                    // remove inactive teachers from add teacher section
                    data = $filter('filter')(data , function(value, index, array){
                        if(!value.active){
                            return false;
                        }
                        if((!value.identifiers.source)  || ((value.identifiers.source) && (value.identifiers.source.toLowerCase() != 'clever'))){
                            return false;
                        }
                        return true ;
                    });
                }
                $scope.teachersMasterList = data;
                loadActiveTeachers();
               // $scope.teachers = data;
                $scope.filterText = '';
                $scope.showEntireCleverTeacherList = true;
                $scope.calculateAddAllTeachersButtonVisibility();
            }, function(error) {
                $log.error('error loading teacher Data');
                $scope.errorRetrievingData = error.message;
            });
        };

        //MB: Check if subscription is clever or not
        function isSubscriptionClever() {
//            if (($scope.subscription.rosterSource === 'Clever') &&
//                (
//                    (($scope.subscription.license.type.toLowerCase() == 'teacher') && ($scope.subscription.license.remaining > 0)) ||
//                    ($scope.subscription.license.type.toLowerCase() == 'student')
//                )
        	if ($scope.subscription.rosterSource === 'Clever'){
                $scope.isClever = true;
               // $scope.showAddTeacherLink = true;
            }
        }

        function checkNeedShowEndDate() {
            if ($scope.subscription.expireDate == null || $scope.subscription.expireDate == "") {
                $scope.needShowEndDate = false;
                return;
            }

            var futureDate = new Date();
            // use now + 3 year in future
            futureDate.setDate(futureDate.getDate() + 1095);
            var expiredDate = Date.parse($scope.subscription.expireDate);
            $scope.needShowEndDate = expiredDate < futureDate.getTime();
        }


        // helper fucntion to calculated the number of displayed teachers who are not in this subscription
        // used as part of function to detemine "add all teachers" button visibility
        function calculateNumberOfTeachersNotInSubscription(){
            var teachersNotInSubscription = 0;
            for(var counter = 0; counter < $scope.teachersMasterList.length; counter++){
                if($scope.teachersMasterList[counter].inSubscription == false){
                    teachersNotInSubscription++;
                }
            }
            return teachersNotInSubscription;
        }

        $scope.calculateAddAllTeachersButtonVisibility = function(){

            // if subscription doesn't use clever rostering return false
            // if showEntireCleverTeacherList is false then we are not on the add teacher scree so return false
            // if all teachers have access return false
            var numOfTeachersNotInSubscription = calculateNumberOfTeachersNotInSubscription();
            if(($scope.isClever == false) || ($scope.showEntireCleverTeacherList == false) || (numOfTeachersNotInSubscription == 0)){
                $scope.showAddAllTeachersButton = false;
                return;
            }

            // if available licenses is >= remaining teachers  return true
            if($scope.subscription.license.remaining >= numOfTeachersNotInSubscription){
                $scope.showAddAllTeachersButton = true;
            }

        };

      function handleSubs() {
        //MB: Add display name property to be used to populate dropdown for subscriptions
        for (var i = 0; i < $scope.orgAndSubs.subscription.length; i++) {
          if ($scope.orgAndSubs.subscription[i].id !== undefined) {
            if ($scope.orgAndSubs.subscription[i].product.name.indexOf($scope.orgAndSubs.subscription[i].application.name) < 0) {
              $scope.orgAndSubs.subscription[i].displayName = $scope.orgAndSubs.subscription[i].application.name + ' - ' + $scope.orgAndSubs.subscription[i].product.name;
            } else {
              $scope.orgAndSubs.subscription[i].displayName = $scope.orgAndSubs.subscription[i].product.name;
            }
          }
        }

        //MB: Get the details of selected subscription based on subscriptionId passed in the url
        if ($routeParams.subscriptionId) {
          var id = $routeParams.subscriptionId;

          $scope.subscription = $scope.orgAndSubs.subscription.filter(function(sub, index) {
            return sub.id === id;
          })[0];

          if ($scope.subscription.application.applicationCode === $scope.orgAndSubs.sortAppCode) {
            $scope.subscriptionArray = $scope.orgAndSubs.subscriptionsMap[$scope.orgAndSubs.sortAppCode];
            $scope.hasMultipleContentPacks = true;
          }

          checkNeedShowEndDate();
        }

        $scope.selectedSubscriptionName = ($scope.hasMultipleContentPacks ? $scope.subscription.application.name : $scope.subscription.displayName);
        $scope.selectedSubContentName = ($scope.hasMultipleContentPacks ? $scope.subscription.product.name : '');
        isSubscriptionClever();

        $log.debug('In SubscriptionController. $routeParams.schoolId', $routeParams.schoolId, '$scope.orgAndSubs:', $scope.orgAndSubs, '$routeParams.subscriptionId:', $routeParams.subscriptionId, '$scope.subscription:', $scope.subscription);
        $this.loadAccessCodesandTeachers($scope.subscription);

        $scope.initialized = true;
      }

      function onInit() {
        userService
          .getCurrentUser()
          .then(function() {
            navModel.showNav = true;
            navModel.helpPage = false;
            $scope.showEntireCleverTeacherList = false;

            navModel.activePage = 'programs';
            Volume.setStoreItem('dpCurrentRole', 'admin');
            
            $scope.isReactAdminPrograms = configService.getCurrentConfigOption('isReactAdminPrograms');

            //MB: Although not necessary to get org data. However on refresh I am losing context which determines the user is school admin or not.
            orgService.getOrgData().then(function(data) {
              $log.debug("getOrgData", data);

              settingsService.getSettings().then(function(settings) {
                $log.debug("Settings: ", settings);

                $scope.showSiteAccessCode = !!settings.showSiteAccessCode;
                $scope.showYOYFeatures = !!settings.showYOYFeatures;
              });

              productService.getEntitlement().then(function(data) {
                $log.debug("Product entitlements", data);
                if (!subscriptionModel.orgAndSubscriptions) {
                  productService
                    .getSubscriptions()
                    .then(function(subs) {
                      //MB: Get org details and subscription list based on schoolId passed in the url
                      if ($routeParams.schoolId) {
                        Volume.setStoreItem('dpOrgId', $routeParams.schoolId);
                        $scope.orgAndSubs = subscriptionModel.getOrgWithSub(
                          $routeParams.schoolId
                        )[0];
                      }
                      handleSubs();
                    })
                    .catch(function(error) {
                      $log.error("error loading subscription Data");
                      $scope.errorRetrievingData = error.message;
                      $location.path("/subscriptions");
                    });
                } else {
                  //MB: Get org details and subscription list based on schoolId passed in the url
                  if ($routeParams.schoolId) {
                    Volume.setStoreItem('dpOrgId', $routeParams.schoolId);
                    $scope.orgAndSubs = subscriptionModel.getOrgWithSub(
                      $routeParams.schoolId
                    )[0];
                  }
                  handleSubs();
                }
              });
            });
          })
          .catch(function() {
            userModel.reset();
            $location.path("/signin/staff");
          });
      }

        onInit();
    }
]);
