portalModule.controller("googleImportConfirmationModal",
    ['$scope', '$uibModalInstance', '$log', 'importedCount', 'numberOfSelectedClasses',
        function ($scope, $uibModalInstance, $log, importedCount, numberOfSelectedClasses) {

            $scope.errorGoogleImport = false;

            $scope.ok = function (message) {
                $uibModalInstance.close({
                    message: message
                });
            };

            function onInit() {
                $scope.importedCount = importedCount;
                $scope.numberOfSelectedClasses = numberOfSelectedClasses;
            }

            onInit();
        }
    ]
);