var gapiLibInit = function() {
    // Globally available var for gapi client initialization
    gapi.load('client:auth2', {
        callback: function () {
            // Handle gapi.client initialization.
            $log.debug('gapi.client initialized!');
        },
        onerror: function () {
            // Handle loading error.
            $log.error('gapi.client failed to load!');
        },
        timeout: 5000, // 5 seconds.
        ontimeout: function () {
            // Handle timeout.
            $log.error('gapi.client could not load in a timely manner!');
        }
    });
};

/**
 * From: https://github.com/angular/angular.js/blob/master/src/Angular.js
 * Return the DOM siblings between the first and last node in the given array.
 * @param {Array} array like object
 * @returns {Array} the inputted object or a jqLite collection containing the nodes
 */
function getBlockNodes(nodes) {
  var node = nodes[0];
  var endNode = nodes[nodes.length - 1];
  var blockNodes;

  for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
    if (blockNodes || nodes[i] !== node) {
      if (!blockNodes) {
        blockNodes = $(slice.call(nodes, 0, i));
      }
      blockNodes.push(node);
    }
  }

  return blockNodes || nodes;
}

var portalModule = angular.module("portalApp", [
  "ngRoute",
  "checklist-model",
  "ui.bootstrap",
  "newStudentGroup",
  "portalAppServices",
  "globalValidationApp",
  "ngCookies",
  "ui.select",
  "ngSanitize",
  "apiResponseServiceModule",
  "settingsServiceModule",
  "monospaced.qrcode",
  "configServiceModule"
]);

var Volume = window.Volume;
var react2angular = window.react2angular.react2angular;

Volume.setStoreItem("dpApiToken", "");
Volume.setStoreItem("dpApiUrlHost", "/");
Volume.setStoreItem(
  "dpApiUrlBase",
  sessionStorage.getItem("localdev") === "true" ? "dp-portal/" : ""
);
Volume.setStoreItem("dpPortalUrl", window.location.href.split("/#/")[0]);

portalModule.component(
  "globalNav",
  react2angular(Volume.GlobalNav, [
    "activePage",
    "onCalendarLinkClick",
    "onChangePasswordClick",
    "onClassesTabClick",
    "onDataTabClick",
    "onHelpLinkClick",
    "onLTILinkClick",
    "onProgramsTabClick",
    "onSignOutClick",
    "onSwitchProfileClick"
  ])
);

portalModule.component(
  "sdmHeader",
  react2angular(Volume.SdmHeader, [
    "activePage",
    "filterByRole",
    "onCalendarLinkClick",
    "onChangePasswordClick",
    "onClassesTabClick",
    "onDataTabClick",
    "onHelpLinkClick",
    "onLTILinkClick",
    "onOrgSelected",
    "onProgramsTabClick",
    "onSignOutClick",
    "onSwitchProfileClick"
  ])
);

portalModule.component(
  "pageHeader",
  react2angular(Volume.PageHeader, ["filterByRole", "onOrgSelected"])
);

portalModule.component(
  "adminPrograms",
  react2angular(Volume.AdminPrograms, [
    "onOrgSelected",
    "onCardClick",
    "onManageClick",
    "setInitialized"
  ])
);

portalModule.component(
  "adminProgramDetails",
  react2angular(Volume.AdminProgramDetails, [
    "subscriptionId",
    "teachers",
    "removeTeacher"
  ])
);

portalModule.component(
  "studentPrograms",
  react2angular(Volume.StudentPrograms, ["onCardClick", "setInitialized"])
);

portalModule.component(
  "teacherPrograms",
  react2angular(Volume.TeacherPrograms, [
    "onOrgSelected",
    "passcode",
    "onCardClick",
    "onSavePassCode",
    "redeemAccess",
    "getSettings",
    "searchOrgsByZip",
    "confirmSubscription",
    "setInitialized",
    "getIsAddNewProgramDisabled"
  ])
);

portalModule.component(
  "teacherClasses",
  react2angular(Volume.TeacherClasses, [
    "changeClass",
    "changeSchoolYear",
    "createUpdateClass",
    "loadClasses",
    "loadEntitlements",
    "loadSchoolCalendars",
    "navigateToAddStudents",
    "onOrgSelected",
    "setActive"
  ])
);

portalModule.component(
  "teacherClassCreate",
  react2angular(Volume.TeacherClassCreate, [
    "calendars",
    "organizations",
    "createClass",
    "addTeacherToClass",
    "removeTeacherFromClass",
    "getCalendarForOrg",
    "loadSelectedClass",
    "classId",
    "updateClass"
  ])
);

portalModule.component(
  "classDetails",
  react2angular(Volume.ClassDetails, [
    "addExistingStudent",
    "addNewStudent",
    "checkEntitlement",
    "classList",
    "createUpdateClass",
    "editStudentId",
    "getEasyLoginInfo",
    "getOptionValue",
    "getSchoolEasyLoginCred",
    "getShowEasyLogin",
    "getStudentsInClassWithAvatars",
    "isAddEditMode",
    "isAppsPage",
    "isEasyLoginPage",
    "loadCalendars",
    "navigateTo",
    "navigateToEditStudents",
    "removeStudentFromClass",
    "school",
    "searchForStudent",
    "selectedClass",
    "selectedSchoolYear",
    "setActive",
    "students",
    "subscriptions",
    "toggleAllEntitlements",
    "toggleEasyLogin",
    "toggleStudentEnrollment",
    "updateStudent"
  ])
);

portalModule.component(
  "studentDetails",
  react2angular(Volume.StudentDetails, [
    "isEditMode",
    "removeStudentFromClass",
    "navigateTo",
    "getEasyLoginInfo",
    "getShowEasyLogin",
    "getStudentsInClassWithAvatars",
    "saveStudent",
    "school",
    "selectedClass",
    "selectedStudentId"
  ])
);

portalModule
  .config([
    "$httpProvider",
    "$logProvider",
    function($httpProvider, $logProvider) {
      var contentType = "application/json; charset=utf-8";
      var accept = contentType + ", text/javascript, text/plain";
      $httpProvider.defaults.headers.post["Accept"] = accept;
      $httpProvider.defaults.headers.post["Content-Type"] = contentType;
      $httpProvider.defaults.headers.patch["Accept"] = accept;
      $httpProvider.defaults.headers.patch["Content-Type"] = contentType;
      $httpProvider.defaults.headers.common["Accept"] = accept;
      $httpProvider.defaults.headers.common["Content-Type"] = contentType;

      var loggingEnabled = false;
      $logProvider.debugEnabled(loggingEnabled);
    }
  ])
  .run([
    function() {
      angular.element(document.querySelector("#initialLoadingGif")).remove();
    }
  ])
  .run([
    "SessionService",
    "SDMAnalyticsService",
    function(SessionService, SDMAnalyticsService) {
      SDMAnalyticsService.registerListener(
        SessionService.analyticsEventListener
      );
    }
  ]);
