angular.module('portalApp')
    .controller('addTeacherButtonController',[
        '$scope','$log','$window','notificationModel','$timeout',
        '$location','$uibModal','$uibModalStack','teacherService','$templateCache',
        '$route',
        function ($scope,$log,$window,notificationModel,$timeout,
                  $location,$uibModal,$uibModalStack,teacherService,$templateCache,
                  $route) {

    $scope.$on('$locationChangeStart', handleLocationChange);
    /** handleLocationChange()
     * Dismisses all modals on location change start,
     * such as when the back button is pressed.
     */
    function handleLocationChange() {
        $uibModalStack.dismissAll();
    }

    $scope.addTeacher = function(){
        var modal = $uibModal.open({
            templateUrl: 'resources/js/Modules/teachers/addTeacherModal/addTeacherModal.html',
            controller: 'addTeacherModalController',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
        }).result.then(function (data) {
            var teacherName = '', className = '';
            if (data.message === 'cancel') {
                return;
            }
            if (data.message === 'addTeacher') {
                teacherName = data.teacher.firstName + " " + data.teacher.lastName;
                className = data.selectedClass.displayName;
                teacherService.addCoTeacherToClass(
                    data.selectedClass.id,
                    data.selectedClass.organizationId,
                    data.teacher.id
                )
                .then(function(response){
                    notificationModel.setMessage(teacherName + ' was successfully added as a co-teacher to ' + className + '!');
                    // TODO: maybe refresh teacher list element instead of page reload (optional future improvement)
                    var currentPageTemplate = $route.current.templateUrl;
                    $templateCache.remove(currentPageTemplate);
                    $route.reload();
                })
                .catch(function(error){
                    $log.error(error);
                });
            }
        });
    };

}]);
