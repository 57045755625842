angular.module('apiResponseServiceModule',[]).service('apiResponse', function($http, $log, $q) {
	return {
		makeApiCall: makeApiCall
	};

	function makeApiCall(request) {
		return request.then(handleSuccess, handleError);
	}

	function handleError(response) {
		// The API response from the server should be returned in a
		// nomralized format. However, if the request was not handled by the
		// server (or what not handles properly - ex. server error), then we
		// may have to normalize it on our end, as best we can.
		if (!angular.isObject(response.data) ||
			!response.data.code
		) {
			return ($q.reject('An unknown error occurred.'));
		}
		// Otherwise, use expected error message.
		return ($q.reject(response.data));
	}
	// I transform the successful response, unwrapping the application data
	// from the API response payload.
	function handleSuccess(response) {
		return (response.data);
	}

    // // TODO: dplocal3 Access code XVTNL2
    // // temporarily add the following code to apiResponse.js
    // function handleSuccess(response) {
    //     if(!!response
    //         && response.data
    //         && response.data.hasOwnProperty("showYOYFeatures")){
    //         response.data.showYOYFeatures = false;
    //         response.data.showLtiInfo = false;
    //         $log.info('showYOYFeatures', response.data.showYOYFeatures);
    //     }
    //     return (response.data);
    // }

});
