portalModule.controller(
    'LtiAppLaunchController',
    [
        '$scope', '$log', '$location', '$routeParams', '$timeout', 'notificationModel', 'navModel', 'userService', 'productService', 'userModel',
        function ($scope, $log, $location, $routeParams, $timeout, notificationModel, navModel, userService, productService, userModel) {

            $scope.expand = false;
            $scope.initialized = false;
            $scope.mustRedeemCode = false;
            $scope.errorFlag  =false;
            $scope.error = '';

            $scope.getForm = function (selector) {
                var form = $(selector);
                return form;
            };

            $scope.submitForm = function (formSelector, launchUrl) {
                $timeout(function () {
                    var form = $scope.getForm(formSelector);
                    form.attr('action', launchUrl);
                    form.submit();
                    $scope.loading = false;
                }, 0);
            };

            $scope.handleError = function () {
                $location.path('/lti/error').search({ code: 'unknown' });
                $location.replace();
            };

            $scope.getUserRole = function () {
                return userModel.isTeacher() ? 'staff' : 'student';
            };

            $scope.redeemAccessCode = function (accessCode) {
                var appId = $routeParams.appId;
                $scope.expand = false;
                $scope.initialized = false;
                productService
                    .redeemAccessCode(accessCode)
                    .then(function (subscriptionArray) {
                        var matchingApps = subscriptionArray.some(function (sub) { return sub.application.id === appId; });

                        Volume.globalNavRefreshTrigger();
                        Volume.organizationSelectRefreshTrigger();

                        if (matchingApps) {
                            $scope.ltiLaunch($routeParams.appId, $routeParams.orgId);
                        } else {
                            $scope.initialized = true;
                            $scope.errorFlag = false;
                            $scope.error = 'Access code was redeemed successfully, but the application requested is not covered in this subscription. Please try another access code.'
                        }
                    })
                    .catch(function (error) {
                        $scope.initialized = true;
                        $scope.errorFlag = true;
                        $scope.error = error.message || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    });
            };

            $scope.ltiLaunch = function (appId, orgId) {
                var role = $scope.getUserRole();
                productService
                    .launchData(appId, orgId, role)
                    .then(function (data) {
                        $log.debug("lti data for app #", appId, " : ", data);
                        $scope.outboundLaunch = data;
                        $scope.ltiParams = data.params;
                        $scope.submitForm('#lti_launch_form', $scope.outboundLaunch.launchUrl);
                    })
                    .catch(function () {
                        $scope.handleError();
                    });
            };

            var onInit = function () {
                userService.getCurrentUser().then(function () {
                    navModel.showNav = true;
                    navModel.helpPage = false;
                    var accessDenied = angular.isDefined($routeParams.accessDenied) ? $routeParams.accessDenied : false;

                    if (accessDenied) {
                        $scope.mustRedeemCode = true;
                        $scope.initialized = true;
                    } else {
                        $scope.ltiLaunch($routeParams.appId, $routeParams.orgId);
                    }

                }).catch(function () {
                    $scope.handleError();
                });
            };

            onInit();
        }
    ]
);
