portalModule.service("sessionModel", [ 'cookieService', '$location', function (cookieService, $location) {

	var sessionTracking = {};
	sessionTracking.lastActivityTime = Date.now();
	sessionTracking.lastReportIn = undefined;
	sessionTracking.pollForActivityEveryMillis = 60 * 1000;
	sessionTracking.noActivityCheckIfSessionActiveThreshold = 5 * 60 * 1000;
	sessionTracking.reportingFunctionExecuted = undefined;

	sessionTracking.reset = function () {
		sessionTracking.lastActivityTime = Date.now();
		sessionTracking.lastReportIn = undefined;
		sessionTracking.pollForActivityEveryMillis = 60 * 1000;
		sessionTracking.noActivityCheckIfSessionActiveThreshold = 5 * 60 * 1000;
		sessionTracking.reportingFunctionExecuted = undefined;
		cookieService.removeCookie('lastActiveExtSessionId');
	};
	
	sessionTracking.setSessionIdCookie = function(sessionId){
	    cookieService.saveCookie('lastActiveExtSessionId', sessionId);
	};
	
	sessionTracking.getSessionIdCookie = function(){
	    return cookieService.getCookie('lastActiveExtSessionId');
	}

	return sessionTracking;

}]);