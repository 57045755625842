(function(){
    
    /**
     * 
     * EasyLoginPasswordLockPopupController
     * 
     * @author Manit Banker
     */
    
angular.module('portalApp').controller('EasyLoginPasswordLockPopupController', EasyLoginPasswordLockPopupController);
    
EasyLoginPasswordLockPopupController.$inject = ['$scope', '$log', 'userModel', 'userService', '$uibModalInstance', 'SDMAnalyticsService', 'sharedConstants', 'chooseUserIconUrl', 'isLocked', '$location'];
    
    function EasyLoginPasswordLockPopupController ($scope, $log, userModel, userService, $uibModalInstance, SDMAnalyticsService, sharedConstants, chooseUserIconUrl, isLocked, $location){
        
        var $this = this;
        $this.chooseUserIconUrl = chooseUserIconUrl;
        $scope.isLocked = isLocked;
        
        $scope.goBack = function(){
            $uibModalInstance.close();
            $location.path($this.chooseUserIconUrl);
        };
    }
})();