angular.module('portalApp')
    .controller('SearchStudentsController', [
        '$scope', '$log', '$location', '$route', '$templateCache', 'filterFilter',
        '$routeParams', 'navModel', 'studentService', 'orgService', 'notificationModel',
        'notificationsService', 'userService', 'userModel', 'classService',
        'classModel', 'csvService', '$uibModal','$uibModalStack', '$q',
        'formValidationService', 'settingsService', 'sessionState', 'productModel', 'productService',
        function (
            $scope, $log, $location, $route, $templateCache, filterFilter,
            $routeParams, navModel, studentService, orgService, notificationModel,
            notificationsService, userService, userModel, classService,
            classModel, csvService, $uibModal, $uibModalStack, $q,
            formValidationService, settingsService, sessionState, productModel, productService
        ) {
            var $this = this;
            /** handleLocationChange()
             * Dismisses all modals on location change start,
             * such as when the back button is pressed.
             */
            function handleLocationChange() {
                $uibModalStack.dismissAll();
            }
            $scope.$on('$locationChangeStart', handleLocationChange);

            navModel.showNav = true;
            navModel.helpPage = false;
            $scope.spinner = true;

            $scope.initStatus = {
                settings: false,
                org: false,
                classSection: false,
                students: false,
                isInitialized: function(){
                    return this.settings && this.org && this.classSection && this.students;
                }
            };
            $scope.initialized = false;
            $scope.$watch('initStatus',function () {
                $scope.initialized = $scope.initStatus.isInitialized();
            },true);

            $scope.searchResponseList = undefined;
            $scope.singleGradeSection = true;
            $scope.orgName = '';
            $scope.sectionName = '';
            $scope.search = { firstName: '', lastName: '', externalStudentId: '', username: '' };
            $scope.studentsArray = csvService.getStudentsArray();
            $scope.importProgressStatus = csvService.getImportProgressStatus();
            $scope.searchAdd = ($location.path().indexOf('searchAdd') > -1);
            $scope.initialOnboarding = ($location.path().indexOf('firstclass') > -1);
            $scope.gradelevels = classModel.gradesArray;
            $this.getNewStudentObj = getNewStudentObj;
            $scope.newStudentsArray = [$this.getNewStudentObj()];
            $scope.newStudentsWithAllRequiredFieldsCount = 0;
            $scope.emptyInputRowCount = 0;
            $scope.invalidInputRowCount = 0;
            $scope.showValidationNotifications = false;

            $scope.messageHeader = 'Are you sure?';
            $scope.messageModalText = 'By leaving this page, you will lose any information that has been entered.  Do you want to continue?';

            $this.currentLoggedInUser = undefined;
            $this.onInit = onInit;
            $this.toggleSpinner = toggleSpinner;
            $this.reloadRedirect = reloadRedirect;
            $this.errorNotification = errorNotification;
            $this.executeSearch = executeSearch;
            $this.defaultMessage = 'Oops. We seem to be experiencing some technical difficulty. ' +
                'Sorry for the inconvenience. Please try again later.';

            $this.assignSelection = assignSelection;
            $this.assignStudents = assignStudents;
            $this.nextImportStudent = nextImportStudent;
            $this.importSkipStudent = importSkipStudent;
            $this.importStudent = importStudent;

            $this.removeFlag = false;
            $this.updateSection = updateSection;
            $scope.addInputRow = addInputRow;
            $scope.removeInputRow = removeInputRow;
            $this.removeEmptyStudentInputRows = removeEmptyStudentInputRows;
            $scope.newStudentSave = newStudentSave;
            $scope.newStudentEdit = newStudentEdit;
            $scope.newStudentConfirm = newStudentConfirm;
            $scope.newStudentCancel = newStudentCancel;
            $this.upsertStudentsSuccessHandler = upsertStudentsSuccessHandler;
            $this.upsertStudentsErrorHandler = upsertStudentsErrorHandler;
            $this.upsertStudents = upsertStudents;
            $this.uploadModalResults = uploadModalResults;
            $this.csvUploadModalResults = csvUploadModalResults;
            $this.assignNewStudentsInCsvUpload = assignNewStudentsInCsvUpload;
            $this.arrayWithStudentRemoved = arrayWithStudentRemoved;
            $this.importRouting = importRouting;
            $this.initOrgData = initOrgData;
            $this.initClassSection = initClassSection;
            $this.initStudentsInClass = initStudentsInClass;
            $this.redirectToStudentsCallBack = redirectToStudentsCallBack;
            $this.checkIfArrayNotEmpty = checkIfArrayNotEmpty;
            $this.clearNewStudentsArrayOnCsvUpload = true;

            $scope.violationsArray = [];

            $scope.currentStudentEntryRow = {};

            $scope.forms = {};

            $scope.closeAlert = function () {
                $scope.errorMessage = "";
                delete $scope.errorMessage;
                $scope.errorFlag = "";
                delete $scope.errorFlag;
            };

            $scope.backToManageRosters = function () {

                var atLeastOneRecordPresent;
                if (!!$scope.newStudentsArray && $scope.newStudentsArray.length > 0) {
                    atLeastOneRecordPresent =  $this.checkIfArrayNotEmpty($scope.newStudentsArray);

                } else if (!!$scope.confirmNewStudentsArray && $scope.confirmNewStudentsArray.length > 0 ) {
                    atLeastOneRecordPresent = $this.checkIfArrayNotEmpty($scope.confirmNewStudentsArray);
                }

                if (atLeastOneRecordPresent) {
                    $this.displayWarningModal(redirectToStudentsCallBack);
                } else {
                    $this.redirectToStudentsCallBack();
                }
            };

            $scope.selectedStudents = function () {
                return filterFilter($scope.searchResponseList, {selected: true});
            };

            $scope.$watch('searchResponseList|filter:{selected:true}', function (newSelection, oldSelection) {
                $this.assignSelection(newSelection, oldSelection);
            }, true);

            $scope.$watch('studentsArray', function (newStudentsArray, oldStudentsArray) {
                $log.debug('studentsArray', newStudentsArray);

                if(!!$scope.studentsArray && $scope.studentsArray.length > 0) {
                    $scope.searchSpinner = true;
                    var studentsRow = $scope.studentsArray[0];
                    $log.debug('studentsRow', studentsRow);
                    $scope.search = {
                        firstName: studentsRow.firstName,
                        lastName: studentsRow.lastName,
                        username: studentsRow.username,
                        externalStudentId: studentsRow.externalStudentId
                    };

                    if (!$scope.errorFlag) {
                        $this.executeSearch();
                    } else {
                        $scope.searchSpinner = false;
                    }
                }
            }, true);

            $scope.$watch('newStudentsArray', function (newStudentsArray, oldStudentsArray) {
                $log.debug('watch newStudentsArray',newStudentsArray);
                formValidationService
                    .parseValidations($scope.newStudentsArray, $scope.violationsArray)
                    .then(function(violationsArray){
                        $scope.violationsArray = violationsArray;
                        if(!!newStudentsArray && newStudentsArray.length > 0) {
                            /** required for valid student firstName, lastName, grade, and no duplicates */
                            $scope.newStudentsWithAllRequiredFieldsCount = newStudentsArray.filter(function(student){
                                return (
                                    !!student.firstName
                                    && !!student.lastName
                                    && !!student.grade
                                    && !(student.sectionDuplicate || student.orgDuplicate)
                                );
                            }).length;
                            /** required for empty student no firstName, no lastName, no studentId  */
                            $scope.emptyInputRowCount = newStudentsArray.filter(function(student){
                                return (!student.firstName && !student.lastName && !student.studentId);
                            }).length;
                            $scope.invalidInputRowCount = newStudentsArray.filter(function (student) {
                                /** invalid form singleGradeSection
                                 * case 1) no firstName, has lastName, has grade
                                 * case 2) no lastName, has firstName, has grade
                                 * case 3) no grade, has firstName, has lastName
                                 * case 4) has duplicate names
                                 * */
                                return (!!$scope.singleGradeSection
                                    && ((!student.firstName && !!student.lastName)
                                        || (!student.lastName && !!student.firstName)
                                        || (!!student.studentId && (!student.firstName || !student.lastName))
                                        || !!(student.sectionDuplicate || student.orgDuplicate)))
                                    /**  invalid form !singleGradeSection (mulitple)
                                     * case 1) no firstName, has lastName, has grade
                                     * case 2) no lastName, has firstName, has grade
                                     * case 3) no grade, has firstName, has lastName
                                     * case 4) has studentId, no firstName, no lastName
                                     * case 5) has duplicate names
                                     * case 6) grade invalid for uploaded student
                                     * */
                                    || (!$scope.singleGradeSection
                                        && ((!student.firstName && (!!student.lastName || !!student.grade))
                                            || (!student.lastName && (!!student.firstName || !!student.grade))
                                            || !student.grade && (!!student.firstName || !!student.lastName)
                                            || (!!student.studentId && (!student.firstName || !student.lastName))
                                            || !!(student.sectionDuplicate || student.orgDuplicate)
                                            || (!!student.gradeInvalid && student.entryType === 'upload')));
                            }).length;

                            if (productModel.isSRCTeacher) {
                                newStudentsArray.forEach(function (student){
                                    if (!!(student.sectionDuplicate || student.orgDuplicate)) {
                                        student.tooltipMessage = "Duplicate students cannot be added " +
                                            "to the same class. Ensure the student name and id fields are unique."
                                    }
                                });
                            }

                            if (!$this.removeFlag
                                && ($scope.newStudentsWithAllRequiredFieldsCount === $scope.newStudentsArray.length)
                                && !$scope.invalidInputRowCount
                            ) {
                                $scope.addInputRow();
                            }
                        }
                    });
            }, true);

            $this.displayWarningModal = function(callBackFn) {
                var modal = $uibModal.open({
                    templateUrl: 'resources/js/Modules/students/studentMessageModal/messageModal.html',
                    controller: 'showMessageController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (response) {
                    if (response === 'CONTINUE') {
                        callBackFn();
                    }
                });
            };

            $this.showInvalidCharactersInNameMessage = function showInvalidCharactersInNameMessage(){
                var message = "You entered at lease one invalid character in a name." +
                    " Please enter only unaccented or accented alphabetical letters, A–Z or a–z.";
                $scope.errorMessage = message;
                $scope.errorFlag = true;
            };

            $scope.$on('invalidCharactersInName', function(event, valid){
                if(!valid && $scope.showValidationNotifications){
                    $this.showInvalidCharactersInNameMessage();
                } else {
                    $scope.closeAlert();
                }
            });

            $this.showInvalidCharactersInStudentIdMessage = function showInvalidCharactersInStudentIdMessage(){
                var message = "You entered at lease one invalid character in a Student ID." +
                    " Please enter only a combination of unaccented alphabetical letters and numbers, A–Z, a–z and/or 0-9.";
                $scope.errorMessage = message;
                $scope.errorFlag = true;
            };

            $scope.$on('invalidCharactersInStudentId', function(event,valid){
                if(!valid && $scope.showValidationNotifications){
                    $this.showInvalidCharactersInStudentIdMessage();
                } else {
                    $scope.closeAlert();
                }
            });

            $scope.enterKeyUp = function (event) {
                // event.target.value
                $log.debug('enterKeyUp event', event);
                $scope.onButtonPress('addStudent');
            };

            $scope.onNewStudentKeyUp = function (event) {
                // event.target.value
                $log.debug('onNewStudentKeyUp event', event);
                $this.removeFlag = false;
            };

            $scope.onNewStudentSelectChange = function (item) {
                $log.debug('onNewStudentSelectChange item',item);
                $this.removeFlag = false;
            };

            $scope.onButtonPress = function (message) {
                $log.debug('message', message);
                switch(message){
                    case 'search':
                        $this.executeSearch();
                        break;
                    case 'addStudentAndContinue':
                        $this.assignStudents('/students/search/'
                            + $routeParams.orgId + '/' + $routeParams.sectionId);
                        break;
                    case 'addStudentAndReturn':
                        $this.assignStudents('/students');
                        break;
                    case 'uploadCsvFile':
                        $this.importRouting();
                        break;
                    case 'createNewStudents':
                        csvService.reset();
                        $location.path('/students/searchAdd/'
                            + $routeParams.orgId + "/" + $routeParams.sectionId);
                        break;
                    case 'importNext':
                        $this.nextImportStudent($scope.initialOnboarding
                            ? '/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId
                            : '/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                        break;
                    case 'importSkip':
                        $this.importSkipStudent($scope.initialOnboarding
                            ? '/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId
                            : '/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                        break;
                    case 'importAdd':
                        $this.importStudent($scope.initialOnboarding
                            ? '/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId
                            : '/students/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                        break;
                }
            };

            function redirectToStudentsCallBack() {
                csvService.reset();
                $location.path('/students');
            }

            function getNewStudentObj(student){
                var newStudent = student || {};
                // randomString may have duplicate after ~46k combinations
                var randomString = Math.random().toString(36).substring(7);
                var newStudentObj = {
                    rowIndex: randomString,
                    firstName: newStudent.firstName || '',
                    lastName: newStudent.lastName || '',
                    studentId: newStudent.studentId || '',
                    grade: newStudent.grade || '',
                    lowerCaseNames: '',
                    getLowerCaseNames: function(){
                        this.lowerCaseNames
                            = (this.firstName + '|' + this.lastName).toLowerCase() + '|' + this.studentId;
                    }
                };
                return newStudentObj;
            }

            function addInputRow(newStudent,entryType){
                $this.removeFlag = false;
                var student = getNewStudentObj(newStudent);
                entryType ? student.entryType = entryType : false;
                student.grade = $scope.singleGradeSection ? $this.selectedClass.lowGrade :
                    student.grade ? student.grade : '';
                $log.debug('student',JSON.stringify(student,null,2));
                $scope.newStudentsArray.push(student);
            }

            function arrayWithStudentRemoved(rowIndex){
                return $q(function (resolve, reject) {
                    var arr = [];
                    $scope.newStudentsArray.forEach(function(student,index){
                        if(student.rowIndex !== rowIndex){
                            arr.push(student);
                        }
                        if(index === $scope.newStudentsArray.length-1){
                            return resolve(arr);
                        }
                    });
                });
            }

            function removeInputRow(rowIndex){
                $this.removeFlag = true;
                $this.arrayWithStudentRemoved(rowIndex).then(function(newStudentsArray){
                    $scope.newStudentsArray = newStudentsArray;
                    formValidationService
                        .parseValidations($scope.newStudentsArray, $scope.violationsArray)
                        .then(function(violationsArray){
                            $scope.violationsArray = violationsArray;
                        });
                });
            }

            function removeEmptyStudentInputRows(arr){
                return arr.filter(function(student){
                    return (!!student.firstName && !!student.lastName && !!student.grade);
                }).slice();
            }

            function newStudentSave(){
                $scope.newStudentsArray = $this.removeEmptyStudentInputRows($scope.newStudentsArray);

                var promise = productModel.isSRCTeacher
                  ? studentService.validateStudents($scope.newStudentsArray, $routeParams.sectionId)
                  : studentService.validateStudentsAgainstOrg($scope.newStudentsArray,$routeParams.sectionId);

                promise
                    .then(function (response) {
                        if (!!response && !!response.violations
                            && !!response.violations.some(function (violations) {
                                return !!violations.recordErrors;
                            })) {
                            formValidationService
                                .parseValidations($scope.newStudentsArray, $scope.violationsArray, response)
                                .then(function (violationsArray) {
                                    $scope.violationsArray = violationsArray;
                                });
                        } else {
                            $scope.disableConfirm = false;
                            $scope.confirmNewStudentsArray = $this.removeEmptyStudentInputRows($scope.newStudentsArray);
                            $scope.newStudentsArray = [];
                        }
                    })
                    .catch(function (error) {
                        $log.info('error', error);
                        /** custom error for when validation returns an api error */
                        $scope.errorMessage = "There was an error trying to validating " +
                            "the new students you wish to add. Please try again or skip " +
                            "this student to continue adding the remaining students.";
                        $scope.errorFlag = true;
                    });
            }

            function newStudentEdit(){
                $this.removeFlag = true;
                $scope.newStudentsArray = $scope.confirmNewStudentsArray.slice();
                $scope.confirmNewStudentsArray = [];
            }

            function newStudentConfirm(){
                $this.upsertStudents($scope.confirmNewStudentsArray);
            }

            function newStudentCancel(msg) {
                var atLeastOneRecordPresent = false;

                if ($scope.newStudentsArray.length > 0) {
                    atLeastOneRecordPresent =  $this.checkIfArrayNotEmpty($scope.newStudentsArray);

                } else if (!!$scope.confirmNewStudentsArray && $scope.confirmNewStudentsArray.length > 0 ) {
                    atLeastOneRecordPresent = $this.checkIfArrayNotEmpty($scope.confirmNewStudentsArray);
                }

                if(msg === 'uploadCsvFile') {
                    if(atLeastOneRecordPresent) {
                        $this.displayWarningModal($this.importRouting);
                        return;
                    }

                    $this.importRouting();
                }

                $log.debug('newStudentCancel msg',msg);
            }

            function upsertStudentsSuccessHandler(students){
                $log.debug('upsertStudentsSuccess',students);
                $scope.disableConfirm = true;
                $scope.spinner = true;
                if(students.code === 'error.not.logged.in'){
                    userModel.reset();
                    $uibModalStack.dismissAll();
                    $location.path('/signin/staff');
                    return;
                }
                var message = "Successfully added "
                    + students.length + " student(s) to " + $scope.sectionName + "!";
                notificationModel.setMessage(message);
                notificationsService.setMessage(true);

                if ($scope.initialOnboarding) {
                    $location.path('/students/firstclass/print/' + $routeParams.sectionId);
                    return;
                }

                $this.reloadRedirect('/students');
            }

            function upsertStudentsErrorHandler(data){
                $scope.disableConfirm = true;
                $scope.spinner = false;
                if( data.students ) {
                    $log.debug('upsertStudentsError',data.students);
                } else {
                    var message = 'There was an error ' +
                        'trying to add these students to your class. Please try again.';
                    $scope.errorFlag = true;
                    $scope.errorMessage = message;
                    $scope.newStudentEdit();
                    /** should redirect to Manage Roster in the case of
                     * an unhandled error or exception (something
                     * not prevented by validations) such as BatchExceptionError
                         var error = data;
                         var message = error.message
                         || error.code || "An error occurred. Please try again.";
                         $this.reloadRedirect('/students');
                     */
                }
            }

            function assignNewStudentsInCsvUpload(data) {
                $log.debug('assignNewStudentsInCsvUpload data', data);
                if(!!data && !!data.records && data.records.length > 0){
                    if ($this.clearNewStudentsArrayOnCsvUpload) {
                        $scope.newStudentsArray = [];
                        $scope.confirmNewStudentsArray = [];
                    }
                    /** if there is a lose info warning message then it is best to reset the entry form */
                    var recordsCount = data.records.length;
                    data.records.forEach(function(importedStudent, index){
                        $scope.addInputRow(importedStudent,'upload');
                        if(index === recordsCount-1){
                            formValidationService
                                .parseValidations(data.records, $scope.violationsArray, data)
                                .then(function (violationsArray) {
                                    $log.debug('violationsArray',JSON.stringify(violationsArray,null,2));
                                    $scope.violationsArray = violationsArray;
                                });
                        }
                    });
                }
            }

            function uploadModalResults(data) {
                $log.debug('uploadModalResults data',data);
                var successMessage = data;
                if(!!successMessage) {
                    notificationModel.setMessage(successMessage);
                    $this.reloadRedirect('/students');
                }
            }

            function csvUploadModalResults(data) {
                $log.debug('csvUploadModalResults data', data);
                if (!!data && data.status === 'cancel') {
                    /** do nothing: this case is only necessary if importCSVPopup modal is used */
                } else if(!!data && !!data.records && data.records.length > 0) {
                    $scope.errorMessage = "Successfully uploaded " + data.records.length + " students." +
                        " Please review the uploaded information below.";
                    $scope.errorFlag = false;
                    $this.assignNewStudentsInCsvUpload(data);
                } else {
                    $scope.errorMessage = "There was an error trying to validating " +
                        "the new students you wish to add. Please try again or skip " +
                        "this student to continue adding the remaining students.";
                    $scope.errorFlag = true;
                }
            }

            function upsertStudents(students) {
                $log.debug("upsertStudents students",students);
                $scope.disableConfirm = true;
                $scope.spinner = true;

                if (productModel.isSRCTeacher) {
                    return studentService.upsertStudents(students, $this.selectedClass.id)
                      .then($this.upsertStudentsSuccessHandler, $this.upsertStudentsErrorHandler);
                }

                return studentService.upsertStudentsToOrg(students, $this.selectedClass.id)
                  .then($this.upsertStudentsSuccessHandler, $this.upsertStudentsErrorHandler);
            }

            function updateSection(section){
                $this.selectedClass = angular.copy(section);
                $scope.initStatus.classSection = !!$this.selectedClass;

                $scope.singleGradeSection = $this.selectedClass.lowGrade === $this.selectedClass.highGrade;
                $scope.sectionName = $this.selectedClass.displayName;
                $scope.gradelevels = classModel.calculateGradeRangeArray($this.selectedClass);
                if(!!$scope.singleGradeSection){
                    $scope.newStudentsArray[0].grade = $this.selectedClass.lowGrade;
                } else {
                    $scope.newStudentsArray[0].grade = '';
                }
            }

            $scope.$watch('orgName', function () {
                $this.toggleSpinner();
            }, true);

            $scope.$watch('sectionName', function () {
                $this.toggleSpinner();
            }, true);

            function executeSearch(){
                $log.debug('getStudentsSearchResults: ' +
                    'staffId', $this.currentLoggedInUser,
                    'orgId', $routeParams.orgId,
                    'params', $scope.search);

                if ($scope.search && !$scope.search.username) {
                    delete $scope.search.username;
                }

                if ($scope.search && !$scope.search.externalStudentId) {
                    delete $scope.search.externalStudentId;
                }

                studentService
                    .getStudentsSearchResults($routeParams.orgId, $scope.search)
                    .then(function (searchResultsList) {
                        $scope.searchResponseList = searchResultsList;
                        $scope.searchSpinner = false;
                    })
                    .catch(function(error){
                        /*
                        // has not been discussed yet but is included when discussion happens
                        /!** custom search error *!/
                        var error = {
                            message: "There was an error trying to search for"
                                + " " + $scope.selection.firstName
                                + " " + $scope.selection.lastName
                                + " Please try again."
                        };
                        */
                        $this.errorNotification(error,'reload page');
                        /** errorNotification ^route must not reload/refresh page or else it will loop */
                    });
            }

            function toggleSpinner(){
                $scope.spinner = !$scope.orgName && !$scope.sectionName;
            }

            function reloadRedirect(route) {
                /** necessary to show success message when page reloaded */
                if (route === '/students') {
                    $location.path('/students');
                } else {
                    var currentPageTemplate = $route.current.templateUrl;
                    $templateCache.remove(currentPageTemplate);
                    $route.reload();
                }
            }

            function assignStudents(route) {
                delete $scope.selection.selected;
                if (!$scope.selection.active) {
                    $scope.selection.active = true;
                }
                $log.debug(
                    'assignStudents sectionId', $routeParams.sectionId,
                    'selection.id',
                    $scope.selection.id,
                    'student', $scope.selection);
                studentService
                    .assignStudents([$routeParams.sectionId], $routeParams.orgId, [$scope.selection.id])
                    .then(function (student) {
                        $log.debug('added student', student);
                        $scope.searchResponseList = undefined;
                        $scope.search = {};
                        var message = 'Successfully added '
                            + $scope.selection.firstName + ' ' + $scope.selection.lastName
                            + ' to ' + $scope.sectionName + '.';
                        $log.debug('message', message);
                        notificationModel.setMessage(message);

                        if ($scope.initialOnboarding) {
                            if (route === '/students') {
                                $location.path('/students/firstclass/print/' + $routeParams.sectionId);
                            } else {
                                $location.path('/students/firstclass/search/' + $routeParams.orgId + '/' + $routeParams.sectionId);
                            }
                            return;
                        }

                        $this.reloadRedirect(route);
                    })
                    .catch(function(error){
                        /** custom error for add or add & new search */
                        var error = {
                            message: "There was an error trying to add"
                                + " " + $scope.selection.firstName
                                + " " + $scope.selection.lastName + " "
                                + "to your class. Please try again or skip this student" +
                                " to continue adding the remaining students."
                        };
                        $this.errorNotification(error,'reload page');
                        /** errorNotification ^route must not reload/refresh page or else it will loop */
                    });
            }

            function nextImportStudent(route) {
                if (!!$scope.selection && $scope.selection.selected) {
                    delete $scope.selection.selected;
                }

                var complete = csvService.shiftSearchEntry();

                if($scope.initialOnboarding && complete) {
                    $location.path('/students/firstclass/print/' + $routeParams.sectionId);
                    return;
                }

                route = complete ? '/students' : route;
                $this.reloadRedirect(route);
            }

            function importSkipStudent(route) {
                if (!!$scope.selection && $scope.selection.selected) {
                    delete $scope.selection.selected;
                }

                var complete = csvService.shiftSearchEntry();

                if($scope.initialOnboarding && complete) {
                    $location.path('/students/firstclass/print/' + $routeParams.sectionId);
                    return;
                }

                route = complete ? '/students' : route;
                $this.reloadRedirect(route);
            }

            function importStudent(route) {
                delete $scope.selection.selected;
                if (!$scope.selection.active) {
                    $scope.selection.active = true;
                }
                $log.debug(
                    'assignStudents sectionId', $routeParams.sectionId,
                    'selection.id',
                    $scope.selection.id,
                    'student', $scope.selection);
                studentService
                    .assignStudents([$routeParams.sectionId], $routeParams.orgId, [$scope.selection.id])
                    .then(function (student) {
                        var complete = csvService.shiftSearchEntry();

                        route = complete ? '/students' : route;
                        $log.debug('added student', student);
                        var message = 'Successfully added '
                            + $scope.selection.firstName + ' ' + $scope.selection.lastName
                            + ' to ' + $scope.sectionName + '.';
                        $log.debug('message', message);
                        notificationModel.setMessage(message);
                        $scope.searchResponseList = undefined;
                        $scope.search = {};

                        if($scope.initialOnboarding && complete) {
                            $location.path('/students/firstclass/print/' + $routeParams.sectionId);
                            return;
                        }

                        $this.reloadRedirect(route);
                    })
                    .catch(function(error){
                        /** custom error for import csv upload search */
                        var error = {
                            message: "There was an error trying to add"
                                + " " + $scope.selection.firstName
                                + " " + $scope.selection.lastName + " "
                                + "to your class. Please try again or skip this student" +
                                " to continue adding the remaining students."
                        };
                        $this.errorNotification(error,'reload page');
                        /** errorNotification ^route must not reload/refresh page or else it will loop */
                    });
            }

            function assignSelection(newSelection, oldSelection) {
                if ($scope.searchResponseList) {
                    if (!!newSelection && newSelection.length === 1) {
                        // if new selection.length === 1 then $scope.selection = newSelection
                        $scope.selection = newSelection[0];
                    } else if (!!newSelection && newSelection.length > 1) {
                        // if new selection.length > 0 then remove oldSelections
                        $scope.selection = newSelection.filter(function (selected) {
                            return selected.id !== oldSelection[0].id;
                        })[0];
                        $scope.searchResponseList.forEach(function (student) {
                            if (student.id !== $scope.selection.id) {
                                delete student.selected;
                            }
                        });
                    }
                } else {
                    return {};
                }
            }

            function errorNotification(error,route){
                $scope.searchSpinner = false;
                $log.info('error', error);
                var message = (error && error.message) ? error.message :
                    (error && error.detailedMessage) ? error.detailedMessage :
                    (error && error.code) ? error.code : $this.defaultMessage;
                var errorFlag = true;
                notificationModel.setMessage(message);
                notificationsService.setMessage(errorFlag);
                if(route){
                    $this.reloadRedirect(route);
                }
            }

            function importRouting() {
                if ($scope.initialOnboarding) {
                    if ($scope.searchAdd) {
                        $location.path('/students/firstclass/addImport/'
                            + $routeParams.orgId + '/' + $routeParams.sectionId);
                    } else {
                        $location.path('/students/firstclass/searchImport/'
                            + $routeParams.orgId + '/' + $routeParams.sectionId);
                    }

                    return;
                }

                if ($scope.searchAdd) {
                    $location.path('/students/addImport/'
                        + $routeParams.orgId + '/' + $routeParams.sectionId);
                } else {
                    $location.path('/students/import/'
                        + $routeParams.orgId + '/' + $routeParams.sectionId);
                }
            }

            function initOrgData() {
                return orgService.getOrgData()
                    .then(function (orgRolesList) {
                        $log.debug('orgService.getOrgData', orgRolesList);
                        var org = $scope.orgName = orgRolesList.filter(function (org) {
                            return org.id === $routeParams.orgId;
                        })[0];

                        if (org) {
                            $scope.orgName = org.name;
                        }

                        $scope.initStatus.org = !!$scope.orgName;
                    });
            }

            function initClassSection() {
                return classService.getClassSection($routeParams.sectionId)
                    .then(function (section) {
                        $log.debug('classService.getClassSection', section);
                        $this.updateSection(section);
                    });
            }

            function initStudentsInClass() {
                if(!!$scope.searchAdd){
                    return studentService.getStudentsInClass($routeParams.sectionId)
                        .then(function (students) {
                            $log.debug('classSstudentService.getStudentsInClassssSection', students);
                            $scope.arrayOfStudentsInSection = students.slice();
                            $scope.initStatus.students = !!$scope.arrayOfStudentsInSection;
                        });
                }

                var emptyPromise = $q.defer();
                emptyPromise.resolve();
                return emptyPromise.promise;
            }

            function checkIfArrayNotEmpty(arrayInput) {
                var atLeastOneRecordPresent;

                for (var i = 0; i < arrayInput.length; i++) {
                    if ((arrayInput[i].firstName != undefined && arrayInput[i].firstName != '')
                        ||
                        (arrayInput[i].lastName != undefined && arrayInput[i].lastName != '')
                    ) {
                        atLeastOneRecordPresent = true;
                        break;
                    }
                }

                return atLeastOneRecordPresent;
            }

            function onInit() {
                $log.debug("In searchStudents.controller.onInit");
                $scope.errorMessage = notificationModel.popMessage();
                $scope.errorFlag = notificationsService.popMessage(); /** will replace notificationModel eventually */

                sessionState.store('$scope',$scope);
                settingsService.setSettings(sessionState).then(function(settings){
                    $log.debug('settings',settings);
                    $scope.initStatus.settings = !!settings;
                });

                userService.getCurrentUser()
                    .then(function (user) {
                        $log.debug('getCurrentUser', user);
                        if (!!userModel && !!userModel.currentUser && !!userModel.currentUser.staff) {
                            $this.currentLoggedInUser = userModel.currentUser.staff.id;
                        }

                        $q.all([$this.initOrgData(), $this.initClassSection(), $this.initStudentsInClass(), productService.getEntitlement()])
                            .catch(function(error){
                                $this.errorNotification(error,'/students');
                                /** errorNotification ^route must not reload/refresh page or else it will loop */
                            })
                            .finally(function () {
                                var validatedResults = csvService.getValidatedResults();
                                if (validatedResults) {
                                    $this.csvUploadModalResults(validatedResults);
                                }

                                if (!$scope.searchAdd && productModel.isSRCTeacher !== undefined && productModel.isSRCTeacher) {
                                    var path = $location.path().replace(/search/gi, 'searchAdd');
                                    $location.path(path);
                                }
                            });
                    })
                ;
            }

            $this.onInit();

        }
    ]);
