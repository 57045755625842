portalModule.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/students/settings/:sectionId/name', {
            templateUrl: 'resources/js/Modules/classes/classSettings/classSettings.html',
            controller: 'ClassSettingsController'
        })
        .when('/students/settings/:sectionId/grade', {
            templateUrl: 'resources/js/Modules/classes/classSettings/classSettings.html',
            controller: 'ClassSettingsController'
        })
        .when('/students/settings/:sectionId/el', {
            templateUrl: 'resources/js/Modules/classes/classSettings/classSettings.html',
            controller: 'ClassSettingsController'
        })
        .when('/students/settings/:sectionId/archive', {
            templateUrl: 'resources/js/Modules/classes/classSettings/classSettings.html',
            controller: 'ClassSettingsController'
        })
        .when('/students/createclass', {
            templateUrl: 'resources/js/Modules/classes/ClassCreateandUpdate/class.html',
            controller: 'CreateClassController'
        })
        .when('/students/createclass/:calendarId', {
            templateUrl: 'resources/js/Modules/classes/ClassCreateandUpdate/class.html',
            controller: 'CreateClassController'
        })
        .when('/students/createclass/:calendarId/edit/:sectionId', {
            templateUrl: 'resources/js/Modules/classes/ClassCreateandUpdate/class.html',
            controller: 'CreateClassController'
        })
        .when('/students/createfirstclass', {
            templateUrl: 'resources/js/Modules/classes/ClassCreateandUpdate/class.html',
            controller: 'CreateClassController'
        })
        .when('/students/createclass/el-pref/:sectionId', {
            templateUrl: 'resources/js/Modules/classes/classEasyLoginPreferences/classEasyLoginPreferences.html',
            controller: 'ClassEasyLoginPreferencesController'
        })
        .when('/students/createfirstclass/el-pref/:sectionId', {
            templateUrl: 'resources/js/Modules/classes/classEasyLoginPreferences/classEasyLoginPreferences.html',
            controller: 'ClassEasyLoginPreferencesController'
        })
        .when('/students/createclass/el-pref/:schoolCalendarId/:sectionId', {
            templateUrl: 'resources/js/Modules/classes/classEasyLoginPreferences/classEasyLoginPreferences.html',
            controller: 'ClassEasyLoginPreferencesController'
        })
        .when('/students/google/import', { // import or on-boarding Google Classroom Courses
            templateUrl: 'resources/js/Modules/google/import-classes-splash/google-import-classes-splash.html',
            controller: 'GoogleImportClassesSplashController'
        })
        .when('/students/google/resync/:sectionId', { // resync of a Google Classroom Course
            templateUrl: 'resources/js/Modules/google/import-classes-splash/google-import-classes-splash.html',
            controller: 'GoogleImportClassesSplashController'
        })
        .when('/students/print/:sectionId', {
            templateUrl: 'resources/js/Modules/students/printInstructions/classPrintInstructions.html',
            controller: 'ClassPrintController'
        })
        .when('/students/firstclass/print/:sectionId', {
            templateUrl: 'resources/js/Modules/students/printInstructions/classPrintInstructions.html',
            controller: 'ClassPrintController'
        })
        .when('/students/easylogin/print/:sectionId', {
            templateUrl: 'resources/js/Modules/students/printInstructions/classPrintInstructions.html',
            controller: 'ClassPrintController'
        })
        .when('/students/firstclass/onboardinginstructions', {
            templateUrl: 'resources/js/Modules/onboardingInstructions/onboarding-instructions.html',
            controller: 'OnboardingInstController'
        })
        .when('/signin', {
            templateUrl: 'resources/js/Modules/login/signin.html',
            controller: 'SignInController'
        })
        .when('/signin/:staff', {
            templateUrl: 'resources/js/Modules/login/signin.html',
            controller: 'SignInController'
        })
        .when('/sz/:orgId/:orgSecret', {
            templateUrl: 'resources/js/Modules/login/easyLogin/schoolEasyLogin/schoolEasyLogin.html',
            controller: 'SchoolEasyLoginController'
        })
        .when('/cz/:classId/:classSecret', {
            templateUrl: 'resources/js/Modules/login/easyLogin/easyLogin.html',
            controller: 'EasyLoginController'
        })
        .when('/cz/pwd/:classId/:classSecret/:studentId', {
            templateUrl: 'resources/js/Modules/login/easyLogin/easyLoginPassword.html',
            controller: 'EasyLoginPasswordController'
        })
        .when('/easylogin/:classId/:classSecret', {
            templateUrl: 'resources/js/Modules/login/easyLogin/easyLogin.html',
            controller: 'EasyLoginController'
        })
        .when('/easylogin/password/:classId/:classSecret/:studentId', {
            templateUrl: 'resources/js/Modules/login/easyLogin/easyLoginPassword.html',
            controller: 'EasyLoginPasswordController'
        })
        .when('/unauthorizeduser', {
            templateUrl: 'resources/js/Modules/login/unauthorizedUser.html'
        })
        .when('/students', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController'
        })
        .when('/students/show-details', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController'
        })
        .when('/students/show-details/easy-login', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController',
            reloadOnUrl: false,
        })
        .when('/students/show-details/apps', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController',
            reloadOnUrl: false,
        })
        .when('/students/:studentId?/edit', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController',
            reloadOnUrl: false,
        })
        .when('/student/:studentId/show', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController',
            reloadOnUrl: false,
        })
        .when('/student/:studentId/edit', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController',
            reloadOnUrl: false,
        })
        .when('/students/:schoolCalendarId/:classSectionId', {
            templateUrl: 'resources/js/Modules/students/studentHome/studentHome.html',
            controller: 'StudentHomeController'
        })
        .when('/students/search/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/search/searchStudents.template.html',
            controller: 'SearchStudentsController'
        })
        .when('/students/searchAdd/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/search/searchStudents.template.html',
            controller: 'SearchStudentsController'
        })
        .when('/students/firstclass/addnewstudents/:sectionId', {
            templateUrl: 'resources/js/Modules/students/firstClass/addNewStudents/addNewStudents.html',
            controller: 'AddNewStudentsController'
        })
        .when('/students/firstclass/search/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/firstClass/searchAddNewStudents/searchAddNewStudents.html',
            controller: 'SearchAddNewStudentsController'
        })
        .when('/students/firstclass/searchAdd/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/firstClass/searchAddNewStudents/searchAddNewStudents.html',
            controller: 'SearchAddNewStudentsController'
        })
        .when('/students/firstclass/searchImport/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/firstClass/importNewStudents/importNewStudents.html',
            controller: 'ImportNewStudentsController'
        })
        .when('/students/firstclass/addImport/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/firstClass/importNewStudents/importNewStudents.html',
            controller: 'ImportNewStudentsController'
        })
        .when('/students/editStudent/:sectionId/:studentId', {
            templateUrl: 'resources/js/Modules/students/editStudent/edit-student.html',
            controller: 'EditStudentController'
        })
        .when('/students/import/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/import/importStudents/importStudents.template.html',
            controller: 'importStudentsController'
        })
        .when('/students/addImport/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/import/importStudents/importStudents.template.html',
            controller: 'importStudentsController'
        })
        .when('/students/add/:orgId/:sectionId', {
            templateUrl: 'resources/js/Modules/students/addStudents/addStudentHomeTemplate/addStudentHome.template.html',
            controller: 'addStudentsHomeController'
        })
        .when('/lti', {
            templateUrl: 'resources/js/Modules/products/productHome/productHome.html',
            controller: 'LtiCardController'
        })
        .when('/lti/signedout', {
            controller: 'LtiSignedOutController',
            templateUrl: 'resources/js/Modules/lti/signedout/ltiSignedOut.html',
        })
        .when('/lti/:orgId/info', {
            templateUrl: 'resources/js/Modules/lti/ltiInformation.html',
            controller: 'LtiInformationController',
            controllerAs: '$ctrl',
            resolve: [
                '$q', 'settingsService', function ($q, settingsService) {
                    return settingsService.getSettings().then(function (settings) {
                        return settings.showLtiInfo ? true : $q.reject('FEATURE_DISABLED');
                    });
                }
            ]
        })
        .when('/lti/appLaunch', {
            templateUrl: 'resources/js/Modules/lti/appLaunch/ltiAppLaunch.html',
            controller: 'LtiAppLaunchController'
        })
        .when('/lti/error', {
            templateUrl: 'resources/js/Modules/lti/error/ltiError.html',
            controller: 'LtiErrorController',
            controllerAs: '$ctrl',
            resolve: [
                '$q', 'settingsService', function ($q, settingsService) {
                    return settingsService.getSettings().then(function (settings) {
                        return settings.showLtiInfo ? true : false;
                    });
                }
            ]
        })
        .when('/products', {
            templateUrl: 'resources/js/Modules/products/productsHome/productsHome.html',
            controller: 'ProductHomeController',
            reloadOnSearch: false
        })
        .when('/products/school/:schoolId', {
            templateUrl: 'resources/js/Modules/products/productsHome/productsHome.html',
            controller: 'ProductHomeController'
        })
        .when('/products/add', {
            templateUrl: 'resources/js/Modules/products/accessCode/accessCode.html',
            controller: 'ProductHomeController'
        })
        .when('/products/selectschool/:accesscode', {
            templateUrl: 'resources/js/Modules/products/selectSchool/selectSchool.html',
            controller: 'ProductSchoolController'
        })
        .when('/subscriptions', {
            templateUrl: 'resources/js/Modules/subscriptions/subscriptionsHome/subscriptionsHome.html',
            controller: 'SubscriptionHomeController'
        })
        .when('/subscriptions/:schoolId/:subscriptionId', {
            templateUrl: 'resources/js/Modules/subscriptions/subscriptionsDetails/subscriptionsDetails.html',
            controller: 'SubscriptionController'
        })
        .when('/schoolyear', {
            templateUrl: 'resources/js/Modules/schoolCalendar/schoolCalendar.template.html',
            controller: 'SchoolCalendarController'
        })
        .when('/subscriptions/editTeachers/clever/:subscriptionId', {
            templateUrl: 'resources/js/Modules/subscriptions/teachersEdit/teachersEdit.html',
            controller: 'TeachersEditController'
        })
        .when('/studentportal', {
            templateUrl: 'resources/js/Modules/studentportal/home/studentPortalHome.html',
            controller: 'StudentPortalController',
            reloadOnSearch:false
        })
        .when('/launchApp', {
            templateUrl: 'resources/js/Modules/launchApp/launchApp.html',
            controller: 'launchAppController'
        })
        .when('/createaccount/:appCode', {
            templateUrl: 'resources/js/Modules/accountCreate/accountCreate.html',
            controller: 'newAccountController'
        })
        .when('/createaccount', {
            templateUrl: 'resources/js/Modules/accountCreate/accountCreate.html',
            controller: 'newAccountController'
        })
        .when('/license/:token', {
            templateUrl: 'resources/js/Modules/licenseAccept/licenseAccept.html',
            controller: 'licenseAcceptController'
        })
        .when('/userlicenseagreement', {
            templateUrl: 'resources/js/Modules/licenseAccept/licenseAcceptIndividual.html',
            controller: 'userLicenseAcceptController'
        })
        .when('/userlicenseagreement/:accesscode', {
            templateUrl: 'resources/js/Modules/licenseAccept/licenseAcceptIndividual.html',
            controller: 'userLicenseAcceptController'
        })
        .when('/licenseconfirmation', {
            templateUrl: 'resources/js/Modules/licenseAccept/confirmation/confirmation.html',
            controller: 'licenseConfirmationController'
        })
        .when('/help', {
            templateUrl: 'resources/js/Modules/help/help.html',
            controller: 'helpController'
        })
        .when('/logout', {
            templateUrl: 'resources/js/Modules/home/home.html',
            controller: 'logoutController'
        })
        .when('/reports', {
            templateUrl: 'resources/js/Modules/reports/reports.html',
            controller: 'reportingController'
        }).when('/sso', {
            templateUrl: 'resources/js/Modules/home/home.html',
            controller: 'HomeController'
        })
        .otherwise({
            url: '/',
            templateUrl: 'resources/js/Modules/home/home.html',
            controller: 'HomeController'
        });
}]);
