(function(){
    
    /**
     * 
     * @author Manit Banker
     */

    angular.module('settingsServiceModule',['apiResponseServiceModule','globalConfigModule'])
        .service('settingsService',['$http','$q','$log','apiResponse','globalConfigModel',
            function($http,$q,$log,apiResponse,globalConfigModel){

        var $this = this;

        return {
            getSettings: getSettings,
            setSettings: setSettings,
            getZoneInfo: getZoneInfo
        };

        function getSettings(){
            var url = globalConfigModel.PORTAL_API_ENDPOINT + '/settings';

            var request = $http({
                method: "get",
                url: url,
                cache: true
            });
            return apiResponse.makeApiCall(request);
        }

        function setSettings(sessionState) {
            var deferred = $q.defer();
            var scope = sessionState.get('$scope');
            if(!!scope) {
                getSettings().then(function(settings){
                    readAllSettings(scope,settings);
                    $this.settings = settings;
                    deferred.resolve(settings);
                }).catch(function(error){
                    deferred.reject(error);
                });
            }
            return deferred.promise;
        }

        function readAllSettings(scope,settingsObject) {
            var tempObj = Object.keys(settingsObject);
            tempObj.forEach(function (item) {
                if(settingsObject.hasOwnProperty(item)) {
                        scope[item] = settingsObject[item];
                }
            });
        }

        function getZoneInfo() {
            var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/public/zone-info';

            var request = $http({
                method: "get",
                url: url,
                cache: true
            });
            return apiResponse.makeApiCall(request);
        }
    }]);

})();
