portalModule.directive('configIfNot', [
  '$animate', '$compile', 'userModel', 'cookieService', 'configService',
  function($animate, $compile, userModel, cookieService, configService) {
    return {
      multiElement: true,
      restrict: 'A',
      transclude: 'element',
      terminal: true,
      link: function($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;

        $scope.hasConfig = configService.getCurrentConfigOption($attr.configIfNot);

        function cloneContent() {
          if (!childScope) {
            $transclude(function(clone, newScope) {
              childScope = newScope;
              clone[clone.length++] = $compile.$$createComment('end configIfNot', $attr.configIfNot);
              block = {
                clone: clone,
              };
              $animate.enter(clone, $element.parent(), $element);
            });
          }
        }

        $scope.$watch(function() {
          return configService.getCurrentConfigOption($attr.configIfNot);
        }, function watchCallback(newValue){
          $scope.hasConfig = newValue;

          if (!$scope.hasConfig) {
            cloneContent();
          } else {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }
            if (block) {
              // getBlockNodes is an Angular function
              previousElements = getBlockNodes(block.clone);
              $animate.leave(previousElements).done(function(response) {
                if (response !== false) previousElements = null;
              });
              block = null;
            }
          }
        });
      },
    };
  }]);
