portalModule.controller(
    'LtiSetGradeForClassModalController',
    [
        '$log',
        '$scope',
        '$uibModalInstance',
        '$uibModalStack',
        'classes',
        'classModel',
        'classService',
        'sharedConstants',
        'SDMAnalyticsService',
        'notificationModel',
        function ($log, $scope, $uibModalInstance, $uibModalStack, classes, classModel, classService, sharedConstants, SDMAnalyticsService, notificationModel) {
            
            var ctrl = this;

            ctrl.grades = classModel.gradesArray;

            ctrl.classes = classes;

            $scope.$on('$locationChangeStart', handleLocationChange);
            /** handleLocationChange()
             * Dismisses all modals on location change start,
             * such as when the back button is pressed.
             */
            function handleLocationChange() {
                $uibModalStack.dismissAll();
            }

            ctrl.save = function (classObj, low, high) {
                classService
                    .updateClassSection(
                        angular.extend(classObj, {
                            lowGrade: low,
                            highGrade: high
                        })
                    ).then(function (updatedSection) {
                        $log.debug('Updated section details', updatedSection);
                        try {
                            SDMAnalyticsService.addEventType(sharedConstants.UPDATE_CLASS_EVENT, 'updatedClass', 'existingClasses', 'orgId');
                            SDMAnalyticsService.emit(sharedConstants.UPDATE_CLASS_EVENT, updatedSection, classModel.classList, updatedSection.organizationId);
                        } catch (e) {
                            $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
                        }

                        ctrl.next(classObj.id)
                        
                    }, function (error) {
                        $scope.error = error.message || error.code || 'Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.';
                    });
            };

            ctrl.next = function (classId) {
                ctrl.classes = ctrl.classes.filter(function (classObj) {
                    return classObj.id !== classId;
                });
                if (!ctrl.classes.length) {
                    notificationModel.setMessage('Successfully updated class grade details.');
                    ctrl.ok();
                } else {
                    ctrl.setSelectedClass(ctrl.classes);
                }
            }

            ctrl.setSelectedClass = function (classes) {
                ctrl.selectedClassObj = classes[0];
                ctrl.gradeSettings = {
                    low: null,
                    high: null
                };
                ctrl.classNumber = ctrl.classNumber + 1;
            };

            ctrl.$onInit = function () {
                ctrl.totalClasses = ctrl.classes.length;
                ctrl.classNumber = 0;
                ctrl.setSelectedClass(ctrl.classes);
            };

            ctrl.ok = function () {
                $uibModalInstance.close();
            };
        }
    ]
);
