portalModule.service('easyLoginModel', ['$log', '$cookies', 'sharedConstants', function($log, $cookies, sharedConstants) {
    var dataObject = {
            getEasyLoginAvatars : getEasyLoginAvatars,
            getSchoolEasyLoginAvatars : getSchoolEasyLoginAvatars,
            getEasyLoginSecrets : getEasyLoginSecrets,
            easyLoginClassDetails : undefined,
            schoolEasyLoginClassDetails : undefined,
            setEasyLoginCookie : setEasyLoginCookie,
            getEasyLoginCookie : getEasyLoginCookie,
            reset : reset
    };
    return dataObject;
    
    function getEasyLoginAvatars(){
        var easyLoginAvatars = [{
            "id": "1",
            "class": "fa-snowflake-o",
            "description": "snowflake",
            "color": "#75d104"
        }, {
            "id": "2",
            "class": "fa-space-shuttle ",
            "description": "space shuttle",
            "color": "#f74b61"
        }, {
            "id": "3",
            "class": "fa-clock-o ",
            "description": "clock",
            "color": "#a678fc"
        }, {
            "id": "4",
            "class": "fa-play ",
            "description": "triangle",
            "color": "#75d104"
        }, {
            "id": "5",
            "class": "fa-paperclip ",
            "description": "paperclip ",
            "color": "#f74b61"
        }, {
            "id": "6",
            "class": "fa-smile-o ",
            "description": "smile",
            "color": "#fcc100"
        }, {
            "id": "7",
            "class": "fa-pagelines ",
            "description": "branch",
            "color": "#fcc100"
        }, {
            "id": "8",
            "class": "fa-suitcase ",
            "description": "suitcase ",
            "color": "#a678fc"
        }, {
            "id": "9",
            "class": "fa-umbrella",
            "description": "umbrella",
            "color": "#f74b61"
        }, {
            "id": "10",
            "class": "fa-envelope ",
            "description": "envelope ",
            "color": "#01c0da"
        }, {
            "id": "11",
            "class": "fa-heart ",
            "description": "heart ",
            "color": "#75d104"
        }, {
            "id": "12",
            "class": "fa-motorcycle ",
            "description": "motorcycle ",
            "color": "#fcc100"
        }, {
            "id": "13",
            "class": "fa-paint-brush ",
            "description": "paint brush",
            "color": "#fcc100"
        }, {
            "id": "14",
            "class": "fa-laptop ",
            "description": "laptop ",
            "color": "#a678fc"
        }, {
            "id": "15",
            "class": "fa-gamepad ",
            "description": "gamepad ",
            "color": "#a678fc"
        }, {
            "id": "16",
            "class": "fa-flag ",
            "description": "flag ",
            "color": "#01c0da"
        }, {
            "id": "17",
            "class": "fa-binoculars ",
            "description": "binoculars ",
            "color": "#fcc100"
        }, {
            "id": "18",
            "class": "fa-square ",
            "description": "square ",
            "color": "#01c0da"
        }, {
            "id": "19",
            "class": "fa-book ",
            "description": "book ",
            "color": "#a678fc"
        }, {
            "id": "20",
            "class": "fa-pencil ",
            "description": "pencil ",
            "color": "#f74b61"
        }, {
            "id": "21",
            "class": "fa-cloud ",
            "description": "cloud ",
            "color": "#01c0da"
        }, {
            "id": "22",
            "class": "fa-cut ",
            "description": "scissors ",
            "color": "#75d104"
        }, {
            "id": "23",
            "class": "fa-cube",
            "description": "cube",
            "color": "#01c0da"
        }, {
            "id": "24",
            "class": "fa-star ",
            "description": "star ",
            "color": "#f74b61"
        }, {
            "id": "25",
            "class": "fa-circle ",
            "description": "circle ",
            "color": "#75d104"
        }];
        return easyLoginAvatars;
    }
    
    function getEasyLoginSecrets(){
        
        var easyLoginSecrets = [{
            "id": "1",
            "class": "fa-camera ",
            "description": "camera ",
            "color": "#75d104"
        }, {
            "id": "2",
            "class": "fa-tree ",
            "description": "tree ",
            "color": "#a678fc"
        }, {
            "id": "3",
            "class": "fa-lightbulb-o",
            "description": "lightbulb ",
            "color": "#fcc100"
        }, {
            "id": "4",
            "class": "fa-truck ",
            "description": "truck ",
            "color": "#01c0da"
        }, {
            "id": "5",
            "class": "fa-music ",
            "description": "music ",
            "color": "#01c0da"
        }, {
            "id": "6",
            "class": "fa-trophy ",
            "description": "trophy ",
            "color": "#f74b61"
        }, {
            "id": "7",
            "class": "fa-bug ",
            "description": "bug ",
            "color": "#fcc100"
        }, {
            "id": "8",
            "class": "fa-subway ",
            "description": "subway ",
            "color": "#f74b61"
        }, {
            "id": "9",
            "class": "fa-puzzle-piece ",
            "description": "puzzle piece",
            "color": "#01c0da"
        }, {
            "id": "10",
            "class": "fa-paw ",
            "description": "paw ",
            "color": "#a678fc"
        }, {
            "id": "11",
            "class": "fa-rocket ",
            "description": "rocket ",
            "color": "#a678fc"
        }, {
            "id": "12",
            "class": "fa-leaf ",
            "description": "leaf ",
            "color": "#fcc100"
        }, {
            "id": "13",
            "class": "fa-moon-o ",
            "description": "moon",
            "color": "#f74b61"
        }, {
            "id": "14",
            "class": "fa-bicycle ",
            "description": "bicycle ",
            "color": "#f74b61"
        }, {
            "id": "15",
            "class": "fa-ship ",
            "description": "ship ",
            "color": "#75d104"
        }, {
            "id": "16",
            "class": "fa-car ",
            "description": "car ",
            "color": "#01c0da"
        }, {
            "id": "17",
            "class": "fa-plane ",
            "description": "plane ",
            "color": "#f74b61"
        }, {
            "id": "18",
            "class": "fa-paper-plane ",
            "description": "paper plane",
            "color": "#75d104"
        }, {
            "id": "19",
            "class": "fa-key ",
            "description": "key ",
            "color": "#a678fc"
        }, {
            "id": "20",
            "class": "fa-anchor",
            "description": "anchor",
            "color": "#75d104"
        }, {
            "id": "21",
            "class": "fa-futbol-o",
            "description": "soccerball",
            "color": "#01c0da"
        }, {
            "id": "22",
            "class": "fa-bus ",
            "description": "bus ",
            "color": "#a678fc"
        }, {
            "id": "23",
            "class": "fa-dribbble ",
            "description": "basketball",
            "color": "#75d104"
        }, {
            "id": "24",
            "class": "fa-sun-o ",
            "description": "sun",
            "color": "#fcc100"
        }, {
            "id": "25",
            "class": "fa-bell ",
            "description": "bell ",
            "color": "#fcc100"
        }];
        
        return easyLoginSecrets;
    }
    
    function getSchoolEasyLoginAvatars() {
        return [{
            "id": "1",
            "name": "Class-Icon-Aqua",
            "description": "Aqua"
        }, {
            "id": "2",
            "name": "Class-Icon-Bright-Blue",
            "description": "Bright-Blue"
        }, {
            "id": "3",
            "name": "Class-Icon-Bright-Green",
            "description": "Bright-Green"
        }, {
            "id": "4",
            "name": "Class-Icon-Dark-Blue",
            "description": "Dark-Blue"
        }, {
            "id": "5",
            "name": "Class-Icon-Green",
            "description": "Green"
        }, {
            "id": "6",
            "name": "Class-Icon-Orange",
            "description": "Orange"
        }, {
            "id": "7",
            "name": "Class-Icon-Pink",
            "description": "Pink"
        }, {
            "id": "8",
            "name": "Class-Icon-Purple",
            "description": "Purple"
        }, {
            "id": "9",
            "name": "Class-Icon-Red",
            "description": "Red"
        }, {
            "id": "10",
            "name": "Class-Icon-Yellow",
            "description": "Yellow"
        }];
    }
    
    function setEasyLoginCookie(classId, classSecret){
        $cookies.remove('easyLogin');
        var date = new Date();
        date.setTime(date.getTime() + sharedConstants.CURRENT_USER_COOKIE_TIMEOUT);
        var expires = date.toGMTString();
        var easyLogin = {};
        easyLogin.classId = classId;
        easyLogin.classSecret = classSecret;
        $cookies.putObject( 'easyLogin', easyLogin , {'expires': expires} );
    }
    
    function getEasyLoginCookie(){
        $log.debug($cookies.getObject('easyLogin'));
        var easyLoginCookie = $cookies.getObject('easyLogin');
        return easyLoginCookie;
    }
    
    function reset(){
        dataObject.easyLoginClassDetails = undefined;
        dataObject.schoolEasyLoginClassDetails = undefined;
        $cookies.remove('easyLogin');
    }
    
}]);