/**
 * Created by jhouser on 9/21/2015.
 *
 * Split out rosterService from dpPortal so it is easy to change the server variable
 * for different dev environments
 *
 * added to portalAppServices instead of main app so studentService could be passed into directives that make the quick entry form work
 */

portalAppServices.service("studentService",
	['$http', 'apiResponse','sharedConstants','$q',
	function($http, apiResponse,sharedConstants, $q) {

	return {
        assignStudents: assignStudents,
				unassignStudents: unassignStudents,
        createStudentInClass: createStudentInClass,
        getStudent: getStudent,
        getStudentsInClass: getStudentsInClass,
        getStudentsSearchResults: getStudentsSearchResults,
				getStudentBySourceId: getStudentBySourceId,
        updateStudent: updateStudent,
        updateStudents: updateStudents,
        updateGoogleStudent: updateGoogleStudent,
        updateGoogleStudents: updateGoogleStudents,
        uploadStudents: uploadStudents,
        updateStudentToOrg: updateStudentToOrg,
        upsertStudents: upsertStudents,
				upsertStudentsToOrg: upsertStudentsToOrg,
        validateStudents: validateStudents,
        validateStudentsAgainstOrg: validateStudentsAgainstOrg,
        validateStudentsCSV: validateStudentsCSV,
        validateStudentsCsvAgainstOrg: validateStudentsCsvAgainstOrg
    };

	function getStudentsSearchResults(orgId, params, sourceId) {
		/** Teacher-Composite-Controller Get students for org
		 * GET /composite/staff/{staffId}/organization/{orgId}/roster/students
		 * request body:
		 * orgId string 3317067
		 * staffId string 3336927 (in header authentication, do not pass in POST)
		 * params include but are not limited to
		 * firstName, lastName, username, studentId
		 * */
		var request = $http({
			method: "get",
			url: PORTAL_API_ENDPOINT + "/composite/staff/organization/" + orgId + "/roster/simplestudents" + (sourceId ? "?sourceId=" + sourceId : ""),
			params: params
		});

		return apiResponse.makeApiCall(request);
	}

	function getStudentBySourceId(sourceId) {
		var request = $http({
			method: "get",
			url: PORTAL_API_ENDPOINT + "/composite/staff/search/students/" + sourceId
		});

		return apiResponse.makeApiCall(request);
	}

	function assignStudents(classSectionIdList, orgId, studentsIdList) {
		/** Teacher-Composite-Controller
		 * POST /composite/staff/{staffId}/organization/{orgId}/roster/students/classes/assign
		 * request body:
		 * classSectionIdList array[string] ["160350"]
		 * orgId string 3317067
		 * staffId string 3336927 (in header authentication, do not pass in POST)
		 * studentsIdList array[string] ["3337044"]
		 */
		var request = $http({
			method: "post",
			url: PORTAL_API_ENDPOINT + "/composite/staff/organization/" + orgId + "/roster/students/classes/assign",
			params: {
				classSectionIdList: classSectionIdList,
				studentsIdList: studentsIdList
			}
		});

		return apiResponse.makeApiCall(request);
	}

	function unassignStudents(classSectionIdList, orgId, studentsIdList) {
		/** POST: /composite/staff/{staffId}/organization/{orgId}/roster/students/classes/unassign */
		var request = $http({
			method: "post",
			url: PORTAL_API_ENDPOINT + "/composite/staff/organization/" + orgId + "/roster/students/classes/unassign",
			params: {
				classSectionIdList: classSectionIdList,
				studentsIdList: studentsIdList
			}
		});

		return apiResponse.makeApiCall(request);
	}

	function getStudentsInClass(sectionId, withPasswords) {

		var request = $http({
			method: "get",
			 url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/"
				 + sectionId + "/students" + (withPasswords ? "/?withPasswords=true" : "/")
		});

		return  apiResponse.makeApiCall(request);
	}

	function getStudent(sectionId, studentId, includePassword) {
		// We are ignoring the classId for now but will be using endpoint with it later on
		var request = $http({
			method: "get",
			url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/"+ sectionId + "/students/" + studentId + (includePassword ? "?includePassword=true" : "")
		});

		return  apiResponse.makeApiCall(request);

	}

	function createStudentInClass(sectionID, student) {
		student.active = true;
		var request = $http({
			method: "post",
		    url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionID + "/students",
			data: student
		});

		return  apiResponse.makeApiCall(request);
	}

	function updateStudentToOrg(sectionId, studentId, student){
		// validates students against organization before import
		return updateStudent(sectionId, studentId, student, "?validateType=organization");
	}

	function updateGoogleStudent(student, sectionId,action) {
        // do some magic to translate all the grades 'kindergarten' to 'K' and 'pre-kindergarten' into 'PK'
        // otherwise the service throws errors because it doesn't understand the kindergarten or pre-kindergarten text

        if (student.grade) {
            if (student.grade.toLowerCase() === sharedConstants.KINDERGARTEN) {
                student.grade = 'K';
            }
            if (student.grade.toLowerCase() === sharedConstants.PREKINDERGARTEN) {
                student.grade = 'PK';
            }
        }

        var request = $http({
            method: "patch",
            url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/students/" + student.id,
            data: student
        });

        return apiResponse.makeApiCall(request);
    }

		function updateGoogleStudents(students, sectionId) {
			// do some magic to translate all the grades 'kindergarten' to 'K' and 'pre-kindergarten' into 'PK'
			// otherwise the service throws errors because it doesn't understand the kindergarten or pre-kindergarten text

			students.forEach(function(student) {
				if (student.grade) {
					if (student.grade.toLowerCase() === sharedConstants.KINDERGARTEN) {
						student.grade = 'K';
					}
					if (student.grade.toLowerCase() === sharedConstants.PREKINDERGARTEN) {
						student.grade = 'PK';
					}
				}
			});

			var request = $http({
				method: "patch",
				url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/students",
				data: students
			});

			return apiResponse.makeApiCall(request);
		}

	function updateStudent(sectionId, studentId, student, action) {
		var studentIdSanitized = studentId || "";
		var actionSanitized = action || "";

    /** updateStudent
     * @param sectionId
     * @param studentId
     * @param student
     * @param action
     * Update a class section (in Class Section Controller)
     * PATCH /roster/sections/{sectionId}/students/{studentId}
     * Path: sectionId, studentId
     * Request Payload: student object
     */
    var request = $http({
      method: studentIdSanitized ? "PATCH" : "POST" ,
      url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/students/" + studentIdSanitized + actionSanitized,
      data: student
    });

    return apiResponse.makeApiCall(request);
  }

	function updateStudents(sectionId, students, action) {
		/** PATCH/PUT: /composite/staff/{staffId}/roster/sections/{sectionId}/students */
		var url = PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/" + sectionId + "/students" +
			(action ? "/" + action : "");

		var request = $http({
			method: "patch",
			url: url,
			data: students
		});

		return apiResponse.makeApiCall(request);
	}

	function validateStudentsCSV(fileName,sectionID){
		var uploadFile = new FormData();
		uploadFile.append('file',fileName);
		var request = $http({
			method: "post",
			url: PORTAL_API_ENDPOINT + "/my/roster/sections/" + sectionID + "/students/csv-validate",
			data: uploadFile,
			transformRequest: angular.identity,
			headers: {'Content-Type': undefined},
			cache: false
		});
		return  apiResponse.makeApiCall(request);
	}

	function uploadStudents(students, sectionId, action) {
		/** uploadStudents
		 * @param students
		 * @param sectionId
		 * @param action
		 * API to create a class student for roster (in Class Section Controller)
		 * POST /roster/sections/{sectionId}/students/import
		 * Path: sectionId
		 * Request Payload: list of students (list of student objects)		 *
		 */
		// do some magic to translate all the grades 'kindergarten' to 'K' and 'pre-kindergarten' into 'PK'
		// otherwise the service throws errors because it doesn't understand the kindergarten or pre-kindergarten text
		for(var counter = 0; counter < students.length; counter++){
			if(students[counter].grade){
				if(students[counter].grade.toLowerCase() ==  sharedConstants.KINDERGARTEN){
					students[counter].grade = 'K';
				}
				if(students[counter].grade.toLowerCase() ==  sharedConstants.PREKINDERGARTEN){
					students[counter].grade = 'PK';
				}
			}
		}

		var request = $http({
			method: "post",
			url: PORTAL_API_ENDPOINT + "/my/roster/sections/" + sectionId + "/students/" + action,
			data: students
		});

		return apiResponse.makeApiCall(request);
	}

	function validateStudents(students, sectionId) {
		// validates students against section returns a JSON of student rows and violations
		return uploadStudents(students, sectionId, "validate");
	}

	function upsertStudents(students, sectionId) {
		// validates students against section before import
		return uploadStudents(students, sectionId, "import");
	}

	function upsertStudentsToOrg(students, sectionId) {
		// validates students against organization before import
		return uploadStudents(students, sectionId, "import?validateType=organization");
	}

	function validateStudentsAgainstOrg(students, sectionId) {
        /** Teacher-Composite-Controller:
		 * endpoint to validate students against organization returns a JSON of student rows and violations
         * POST /composite/staff/{staffId}/roster/sections/{sectionId}/students/validate
         * API to validate a student bulk import
         * sectionId string 3317067
         * staffId string 3336927 (in header authentication, do not pass in POST)
         * students student[] [{
		  "active": true, "credentials": { "easyLoginAvatarId": "string", "easyLoginSecret": "string",
    	  "externalStudentId": "string", "password": "string", "realm": "string", "securityRealm": "string", "studentId": "string",
    	  "username": "string" },
    	  "dob": { "dayOfMonth": 0, "monthOfYear": 0, "year": 0 },
    	  "email": "string", "firstName": "string", "gender": "string", "grade": "string", "guidedReadingLevel": "string",
    	  "id": "string",
    	  "identifiers": { "iamUserId": "string", "source": "string", "sourceDistrictId": "string",
		  "sourceId": "string", "sourceSISId": "string", "sourceSchoolId": "string", "sourceStateId": "string",
		  "staffId": "string", "studentId": "string" },
		  "lastName": "string", "lexileLevel": "string", "middleName": "string", "primaryOrgId": "string",
		  "studentId": "string", "uuid": "string"
		  } ]
         */
		return uploadStudents(students, sectionId, "validate?validateType=organization");
	}

	function validateStudentsCsvAgainstOrg(fileName,sectionID){
		// sends file to server, validates students against organization, returns a JSON of student rows and violations
		var uploadFile = new FormData();
		uploadFile.append('file',fileName);
		var params = '?validateType=organization';
		var request = $http({
			method: "post",
			url: PORTAL_API_ENDPOINT + "/my/roster/sections/" + sectionID + "/students/csv-validate" + params,
			data: uploadFile,
			transformRequest: angular.identity,
			headers: {'Content-Type': undefined},
			cache: false
		});
		return  apiResponse.makeApiCall(request);
	}
}]);
