portalModule.factory('fileReader', ['$log', '$q', function($log,$q){
    return {
        readAsText: readAsText,
        loadHandler: loadHandler,
        errorHandler: errorHandler,
        getReader: getReader
    };

    function readAsText(scope, fileData) {
        $log.debug('file',fileData.file);
        fileData.fileName = (!!fileData.file && !!fileData.file.name) ? fileData.file.name : undefined;
        var deferred = $q.defer();
        var reader = getReader(deferred, scope, fileData);
        reader.readAsText(fileData.file);
        return deferred.promise;
    }

    function loadHandler(reader, deferred, scope, fileData) {
        return function () {
            scope.$apply(function () {
                var fileResults = {
                    fileName: fileData.fileName,
                    fileContents: reader.result
                };
                deferred.resolve(fileResults);
            });
        };
    }

    function errorHandler(reader, deferred, scope, fileData) {
        return function () {
            scope.$apply(function () {
                var fileResults = {
                    fileName: fileData.fileName,
                    fileContent: reader.result
                };
                deferred.reject(fileResults);
            });
        };
    }

    function getReader(deferred, scope, fileData) {
        var reader = new FileReader();
        reader.onload = loadHandler(reader, deferred, scope, fileData);
        reader.onerror = errorHandler(reader, deferred, scope, fileData);
        return reader;
    }

}]);