(function(){
    
    /**
     * 
     * OnboardingInstController
     * 
     * @author Manit Banker
     */
    
    angular.module('portalApp').controller('OnboardingInstController', OnboardingInstController);
    
    OnboardingInstController.$inject = ['$scope', '$rootScope', '$location', 'classService', '$routeParams', '$log', 'orgService', 'userModel', 'orgModel', 'classModel', 'userService', 'navModel', 'sharedConstants', 'SDMAnalyticsService', 'productModel', 'productService','studentService'];
    
    function OnboardingInstController ($scope, $rootScope, $location, classService, $routeParams, $log, orgService, userModel, orgModel, classModel, userService, navModel, sharedConstants, SDMAnalyticsService, productModel, productService, studentService){
        
        var $this = this;
        $this.onInit = onInit;
        $scope.applicationsActivated = '';
        if($location.path().indexOf('firstclass') > -1){
            $scope.initialOnBoarding = true
        }else{
            $scope.initialOnBoarding = false;
        }
        $scope.showElPref = false;
        $this.onInit();
        
        $scope.goBackToDashboard = function(){
            productModel.reset();
            $location.path('/products');
        }
        
        function onInit(){
            
            userService.getCurrentUser().then(function(currentUser){
                $log.debug('User signed in',currentUser);
                
                if(classModel.showElPref){
                    $scope.showElPref = true;
                }
                
                productService.getSubscriptions().then(function(subscriptions){
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                });
                
                productService.getEntitlement().then(function(data){
                    $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                });
                
                if(productModel.redeemedSubscription){
                    $scope.redeemedSubscription = productModel.redeemedSubscription;
                    $scope.applicationsActivated = productModel.getRedeemedApplicationsName(productModel.redeemedSubscription);
                    var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                    if(!isNoStudentAccess){
                        $scope.noStudentAccess = true;
                    }
                }
                
                if(!$scope.redeemedSubscription && $scope.initialOnBoarding){
                    $location.path('/products');
                }
                
                $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);
                
                navModel.showNav = true;
                navModel.helpPage = false;
                
            },function(error){
                $log.error('User not signed in',error);
                $location.path('/signin/staff');
            });
        }
    }
    
})();