/**
 * Created by jhouser on 10/21/2015.
 */

portalModule.service("userModel", [function() {

    var dataObject = {
        // contains the user info of the user who currently signed in
        currentUser : {},
        currentOrg: {},
        teacherId : 0,
        expireTime : 0,
        checkUser : 0,
        overrideUserLicenseAgreement : false,
        fromPromptToAcceptLicense : false,
        isUserLoggedIn : isUserLoggedIn,
        isTeacher : isTeacher,
        isUserClever : isUserClever,
        isUserClasslink : isUserClasslink,
        isUserLTI: isUserLTI,
        isUserSSO: isUserSSO,
        getUserSource: getUserSource,
        isGoogleUser : isGoogleUser,
        getCustomerName : getCustomerName,
        reset : reset
    };
	
    return dataObject;

    function isUserLoggedIn(){
        return dataObject.currentUser && (dataObject.currentUser.staff || dataObject.currentUser.student);
    }

    function getCustomerName(){
        if( !dataObject.currentUser ) {
            return '';
        }
        var person = dataObject.currentUser.student || dataObject.currentUser.staff;
        if( person ) {
        	if(dataObject.currentUser.student){
        		return person.firstName + " " + person.lastName.charAt(0);
        	}
        	else{
        		return person.lastName;
        	}
        }
        return '';
    }

    function isTeacher (){
        if(!dataObject.currentUser){
            return false;
        }
        if (dataObject.currentUser.staff) {
            return true;
        } else {
            return false;
        }
    }

    // encapsulating this for multiple uses
    // modeled after isOrgClever in orgModel
    function getUserSource() {
        var source = null;
        if (dataObject.currentUser && dataObject.currentUser.staff && dataObject.currentUser.staff.identifiers && dataObject.currentUser.staff.identifiers.source) {
            source = dataObject.currentUser.staff.identifiers.source;
            return source.toLowerCase();
        }
        else if (dataObject.currentUser && dataObject.currentUser.student && dataObject.currentUser.student.identifiers && dataObject.currentUser.student.identifiers.source) {
            source = dataObject.currentUser.student.identifiers.source;
            return source.toLowerCase();
        }
        else {
            return null;
        }
    }

    function isUserClever(){
    	return getUserSource() === 'clever';
    }

    function isUserLTI() {
        return getUserSource() === 'lti';
    }

    function isUserClasslink() {
        return getUserSource() === 'classlink';
    }

    function isGoogleUser(){
        return getUserSource() === 'google';
    }

    function isUserSSO() {
        return isUserLTI() || isUserClever() || isUserClasslink();
    }

    function reset(){
        dataObject.currentUser = {};
        dataObject.currentOrg = {};
        dataObject.teacherId = 0;
        dataObject.expireTime = 0;
        dataObject.checkUser = 0;
        dataObject.overrideUserLicenseAgreement = false;
        dataObject.fromPromptToAcceptLicense = false;
    }
	    
}]);
