angular.module('portalApp').directive('noSchools', function () {
  return {
    restrict: 'E',
    scope: {},
    template: '<div class="no-schools-container">\n' +
              '    <div class="no-schools-error">\n' +
              '        <div class="no-schools-error-text">\n' +
              '            <h1>Sorry!</h1>\n' +
              '            <h2>\n' +
              '                It looks like you are not currently enrolled in any schools. Please ask your teacher to enroll you and try again.\n' +
              '            </h2>\n' +
              '        </div>\n' +
              '    </div>\n' +
              '</div>\n'
  }
});
