portalModule.directive('trackMousemovement', ['userModel', 'sessionModel',
    function (userModel, sessionModel) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.on('mousemove touchmove touchstart',
                    function () {
                        if (userModel.currentUser.staff != undefined || userModel.currentUser.student != undefined) {
                            sessionModel.lastActivityTime = new Date();
                        }
                    });
            }
        };
    }
]);