portalModule.controller('googleImportClassesConfirmDetailModalController', [
    '$scope', '$uibModalInstance', '$uibModalStack', '$filter', '$log',
    '$location', '$window', '$cookies', 'filterFilter','$sce', 'gapiService',

    function (
        $scope, $uibModalInstance, $uibModalStack, $filter, $log,
        $location, $window, $cookies, filterFilter, $sce, gapiService
    ) {
        $scope.duplicateClassId = '';
        $scope.getCourseList(); // getCourseList will set sdmClassClassComplete = false

        $log.debug($scope.importtype);
        $log.debug($scope.gradelevels);
        if( !!$scope.gradelevels
            && !!$scope.gradelevels[0]
            && $scope.gradelevels[0].value !== 'multiple'
        ) {
            $scope.gradelevels.unshift({"value": "multiple", "label": "Multiple", "sort": -1, "shortLabel": "multi"});
        }

        $scope.selectedCourses = selectedCourses;
        $scope.importableCourses = importableCourses;

        function selectedCourses() {
            if (!!$scope.courses) {
                return filterFilter($scope.courses, {selected: true});
            }
        }

        function importableCourses() {
            if (!!$scope.courses) {
                return $scope.courses.filter(importableCourse);
            }

            return [];
        }

        function importableCourse(course) {
            var currentOrgId = gapiService.selectedSchoolYear.orgId;

            if (!$scope.sdmClassClassComplete) {
                return false;
            }

            if ($scope.importtype === 'resync' &&
                !!course.sdmClass &&
                course.sdmClass.organizationId === currentOrgId) {
                return true;
            }

            if (!course.students || course.students.length < 1) {
                return false;
            }

            for (var i = 0; i < $scope.schools.length; i++) {
                var school = $scope.schools[i];
                if (school.id === currentOrgId) {
                    course.school = school;
                    break;
                }
            }

            if ($scope.importtype === 'onboarding' || $scope.importtype === 'import') {
                if (!course.imported) {
                    return true;
                }

                if (!course.sdmClass.active && course.sdmClass.organizationId === currentOrgId) {
                    return true;
                }
            }

            return false;

            // removed condition per SDMBF-1531
            // ($scope.importtype === 'import' && !!course.imported && course.courseState === 'ACTIVE')
        }

        $scope.$watch('courses|filter:{selected:true}', function (elementValue) {
            if ($scope.courses) {
                $scope.selection = elementValue.map(function (course) {
                    return course;
                });
            } else {
                return {};
            }
        }, true);

        $scope.$watch("sdmClassCallsRemaining", function(sdmClassCallsRemaining, oldValue) {
            if ((!!$scope.courses && $scope.courses.length > 0)
                && $scope.sdmClassCallsRemaining === 0
                && oldValue === 1) {
                $scope.sdmClassClassComplete = true;
                $scope.courses.forEach(function (course) {
                    if (!!course.sdmClass) {
                        course.imported = !!course.sdmClass;
                        course.school = $scope.schools.find(function (school) {
                            return school.id === course.sdmClass.organizationId;
                        });
                        if(!!$scope.gradelevels){
                            if(course.sdmClass.highGrade === course.sdmClass.lowGrade){
                                course.lowGrade = $scope.gradelevels.find(function(gradeLevel){
                                    return gradeLevel.value === course.sdmClass.lowGrade;
                                });
                            } else {
                                course.lowGrade = $scope.gradelevels.find(function(gradeLevel){
                                    return gradeLevel.value === "multiple";
                                });
                            }
                        }
                    } else if ($scope.importtype === 'onboarding'
                        && !!$scope.schools && $scope.schools.length === 1){
                        course.school = $scope.schools[0];
                    }
                });
            }
        });

        function sanitizeStudentNames(courses){
            if(!!courses && courses.length > 0) {
                courses.map(function(course){
                    // getCourseList sdmClass assignments will not have completed, so course.sdmClass is undefined here
                    if(!!course.students && course.students.length > 0){
                        course.students.map(function (student) {
                            student.profile.name.givenName = $filter('sanitizeNameStr')(student.profile.name.givenName);
                            student.profile.name.familyName = $filter('sanitizeNameStr')(student.profile.name.familyName);
                            student.profile.name.fullName = $filter('sanitizeNameStr')(student.profile.name.fullName);
                            return student
                        });
                    }
                    return course;
                });
            }
        }

        $scope.$watch("courses", function(courses){
            if($scope.importtype === 'resync' && !!$scope.courses && $scope.courses.length > 0){
                $scope.courses[0].selected = true;
                $scope.courses[0].school = $scope.schools.filter(function(school){
                    return $scope.selectedclass.organizationId === school.id;
                })[0];
                if($scope.selectedclass.lowGrade === $scope.selectedclass.highGrade) {
                    $scope.courses[0].highGrade = $scope.courses[0].lowGrade = $scope.gradelevels.filter(function (gradeLevel) {
                        return $scope.selectedclass.lowGrade === gradeLevel.value;
                    })[0];
                } else {
                    $scope.courses[0].lowGrade = {"value": "multiple", "label": "Multiple", "sort": -1, "shortLabel": "multi"};
                }
                $log.debug('resync $scope.courses[0]', $scope.courses[0]);
            }
        });

        $scope.$on('$locationChangeStart', handleLocationChange);
        /** handleLocationChange()
         * Dismisses all modals on location change start,
         * such as when the back button is pressed.
         */
        function handleLocationChange() {
            $uibModalStack.dismissAll();
        }

        $scope.cancel = function (msg) {
            $scope.gradelevels.shift();
            if (!!$scope.courses && $scope.courses.length > 0) {
                $scope.courses.forEach(function (course) {
                    delete course.selected;
                    delete course.school;
                    delete course.lowGrade;
                    delete course.highGrade;
                });
            }
            $uibModalInstance.close({
                message: msg
            });
        };

        $scope.importSelected = function () {
            var currentOrgId = gapiService.selectedSchoolYear.orgId;

            if (!!$scope.courses) {
                var selection = filterFilter($scope.courses, {selected: true});
                $scope.gradelevels.shift();
                if (selection.length > 0) {
                    // logic to verify if the selected class to import has a duplicate class
                    // the list of duplicate classes have been added in the Import modal logic - GetCourceListFunction
                    var duplicateClassName = '';
                    var classId = '';
                    var isDuplicateClassToImport = false;
                    $scope.errorRetrievingData = '';

                    for (var i = 0; i < selection.length; i++) {
                        var selectedClass = selection[i];
                        var classInstances = $scope.classInstanceAllowedForImport[selectedClass.name.toLowerCase().trim()];

                        if (classInstances !== undefined) {
                            var instanceIds = Object.keys(classInstances).filter(function(key) {
                                var classInstance = classInstances[key];
                                var selected = selection.filter(function (selectionItem) {
                                    return selectionItem.id === key;
                                })[0] !== undefined;

                                return (selected || classInstance.imported) && key !== selectedClass.id;
                            });

                            if (instanceIds.length <= 0) {
                                break;
                            }

                            for (var j = 0; j < instanceIds.length; j++) {
                                var id = instanceIds[j];
                                var classInstance = classInstances[id];


                                if ((classInstance.section && !selectedClass.section) ||
                                  (!classInstance.section && selectedClass.section) ||
                                  (classInstance.section &&
                                    selectedClass.section &&
                                    classInstance.section !== selectedClass.section)) {
                                    continue;
                                }

                                if (classInstance.orgId && classInstance.orgId !== currentOrgId) {
                                    continue;
                                }

                                isDuplicateClassToImport = true;
                                classId = selectedClass.id;
                                duplicateClassName = selectedClass.name;
                                break;
                            }

                            if (isDuplicateClassToImport) {
                                break;
                            }
                        }
                    }

                    if (isDuplicateClassToImport) {
                        $scope.errorRetrievingData = $sce.trustAsHtml('Looks like you are trying to import a duplicate class. To proceed with ' +
                            'your import, please deselect <strong style="color:black">' + duplicateClassName + '</strong> or rename the class in your ' +
                            ' Google Classroom account and try again.');
                        $scope.spinner = false;
                        $scope.duplicateClassId = classId;

                    } else {
                        $uibModalInstance.close({
                            message: 'list',
                            selected: selection
                        });
                    }
                }
            }
        };

        $scope.removeInactive = function() {
            $uibModalInstance.close({
                message: 'remove'
            });
        };
    }
]);
