portalModule.controller('assignGradeLevelPopupController',
    ['$scope', '$uibModalInstance', 'grades', function($scope, $uibModalInstance, grades) {
        $scope.grades = grades;
        $scope.selectedGrade = grades[0];

        $scope.select = function (message) {
            if (message === 'cancel') {
                $uibModalInstance.dismiss();
                return;
            }

            if (message === 'assign') {
                $uibModalInstance.close({ message: message, grade: $scope.selectedGrade });
            }

            return false;
        };

        $scope.changeGrade = function(grade) {
            $scope.selectedGrade = grade;
        }
    }]
);