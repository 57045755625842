portalModule.directive('configIf', [
  '$animate', '$compile', 'userModel', 'cookieService', 'configService',
  function($animate, $compile, userModel, cookieService, configService) {
    return {
      multiElement: true,
      restrict: 'A',
      transclude: 'element',
      terminal: true,
      link: function($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;

        $scope.hasConfig = configService.getCurrentConfigOption($attr.configIf);

        function clear() {
          if (previousElements) {
            previousElements.remove();
            previousElements = null;
          }
          if (childScope) {
            childScope.$destroy();
            childScope = null;
          }
          if (block) {
            // getBlockNodes is an Angular function
            previousElements = getBlockNodes(block.clone);
            $animate.leave(previousElements).done(function(response) {
              if (response !== false) previousElements = null;
            });
            block = null;
          }
        }

        $scope.$watch(function() {
          return configService.getCurrentConfigOption($attr.configIf);
        }, function watchCallback(newValue){
          $scope.hasConfig = newValue;

          if ($scope.hasConfig) {
            if (!childScope) {
              $transclude(function(clone, newScope) {
                childScope = newScope;
                clone[clone.length++] = $compile.$$createComment('end configIf', $attr.configIf);
                block = {
                  clone: clone,
                };
                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            clear();
          }
        });
      }
    };
  }]);
