portalModule.controller("NavController", [
	'$scope', '$log', '$location', 'userService', 'orgModel',
	'studentModel', 'userModel', 'classModel', 'appModel', 'subscriptionModel',
	'navModel', '$rootScope', '$uibModal', 'notificationModel', 'productModel',
	'settingsService', '$window', 'cookieService', 'configService', 'calendarService',
	'orgService',
	function($scope, $log, $location, userService, orgModel,
			 studentModel, userModel, classModel, appModel, subscriptionModel,
			 navModel, $rootScope, $uibModal, notificationModel, productModel,
			 settingsService, $window, cookieService, configService, calendarService,
       orgService){
	$scope.navModel = navModel;
	$scope.message = '';
	$scope.showYOYFeatures = false;
	$scope.showBanner = false;

	$rootScope.copyrightYear = (new Date()).getFullYear();

	// GlobalNav props
	$scope.activePage = navModel.activePage;
	$rootScope.isReactGlobalNav = configService.getCurrentConfigOption("isReactGlobalNav");

	$rootScope.isReactBody = false;

	$scope.showNavLinks = showNavLinks;
  $scope.closeBanner = closeBanner;

  // DNA page-viewed event trigger
  $rootScope.$on("$routeChangeSuccess", function() {
    try {
      _satellite.track("page-view");
    } catch (_e) {}
  });

  $rootScope.showFooter = function() {
    return navModel.showFooter;
  };

	$scope.isActive = function(viewLocation){
		return $window.location.href.indexOf(viewLocation) > -1;
	};

	$scope.redirectBasedOnApp = function(urlLocation) {
        $scope.appCode = '';
        if (!!$location.search() && $location.search().app) {
            $scope.appCode = $location.search().app.toUpperCase();
        }

        $location.search({});
        if ($scope.appCode) {
            $location.path(urlLocation).search('app', $scope.appCode);
        } else {
            $location.path(urlLocation);
        }
	}


	$scope.redirectToDashboard = function() {
        $scope.appCode = '';
        if (!!$location.search() && $location.search().app) {
            $scope.appCode = $location.search().app.toUpperCase();
        }
        $location.search({});
		if ($scope.isStaff()) {
			if ($scope.appCode) {
                $location.path('/products').search('app', $scope.appCode);
			} else {
                $location.path('/products');
			}
		} else if (!!userModel && !!userModel.userContext && userModel.userContext.isStudent) {
            if ($scope.appCode) {
                $location.path('/studentportal').search('app', $scope.appCode);
            } else {
                $location.path('/studentportal');
			}

		}
	};

	$scope.logoutUser = function(){
		// clear the update change password dialog
		$scope.message = '';
		$scope.showBanner = false;
		if (!!productModel) {
            cookieService.removeCookie('notificationClosed');
		}
		$location.path('/logout');
	};

	$scope.redirectToSchoolYears = function(organizationId) {
		$location.path('/subscriptions/schoolyears/' + organizationId);
	};

	$scope.changePassword = function(){
		// create popup for changing the password
		$uibModal.open({
				templateUrl: 'resources/js/Modules/changePassword/changePassword.html',
				controller: 'ChangePasswordPopupController',
				resolve : {
					selectedClass : function() { return $scope.selectedClass; }
				}
			})
			.result.then(function(data){
				// display the message alert
				if(data.msg){
					$scope.message = data.msg;
				}
			});
	};

	$scope.onChangeNav = function(){
		$scope.closeAlert();
	};

	$scope.closeAlert = function(){
		$scope.message = '';
		$scope.showBanner = false;
	};

	function closeBanner() {
		$scope.showBanner = false;
		productModel.setBannerNotificationCloseCookie(false);
	}

    $rootScope.$watch(function() {
         return userModel.currentUser;
        },
        function watchCallback(newValue, oldValue) {
            // if value changed then recalculate the customer name
            if(newValue == oldValue){
                return;
            }

            // if the item hasn't changed but is a new object then return nothing and don't recalculate the
            // customer name
            if(newValue.student && oldValue.student){
               if(newValue.student == oldValue.student){
                   return;
               }
            }
            if(newValue.staff && oldValue.staff){
                if(newValue.staff == oldValue.staff){
                    return;
                }
            }

            showNavLinks();

            $scope.customerName = userModel.getCustomerName();
			// code added to show the footer bar
			// it is hidden until the page initializes; and turned in via the signin.js handler;
			// but this line will re-enable it in case a signed in user reloads the app on a page other than the sign in page
			navModel.showFooter = true;
        }
    );

	$rootScope.$watch(function() {
			return subscriptionModel.orgAndSubscriptions;
		},
		function watchCallback(newValue, oldValue) {
			showNavLinks();
		}
	);

	$rootScope.$watch(function() {
			return productModel.entitlementList;
		},
		function watchCallback(newValue, oldValue) {
			showNavLinks();
		}
	);

	// JH DotComIt created as part of user license acceptance form; to show confirmation mesage if user already accepted terms and conditiosn
	// this fires twice becase we have two instance of navController; one for header and one for footer; possibly that is tech debt
	$scope.$on('globalMessageSet', function(){
		$scope.message = notificationModel.popMessage();
	});

    function showNavLinks(){
        navModel.showSubscriptionLink = subscriptionModel.hasSubsAsAdmin();
        navModel.showStudentsLink = $scope.isCleverUser() || productModel.hasAppsAsTeacher();
        navModel.showProductsLink = navModel.showSubscriptionLink || navModel.showStudentsLink;
        if( productModel.hasAppsAsTeacher() || subscriptionModel.hasSubsAsAdmin() ) {
            settingsService.getSettings().then(function(settings) {
                if( settings.showReports ) {
                    navModel.showReportsLink = true;
                }
                else{
                    navModel.showReportsLink = false;
                }
                if (!!settings.showYOYFeatures) {
                    $scope.showYOYFeatures = settings.showYOYFeatures;
                    // $scope.showBanner = productModel.getBannerNotificationCloseCookie();
                }
            });
        }
        else{
            navModel.showReportsLink = false;
        }
	}

	$scope.isCleverUser = function(){
    	return userModel.isUserClever();
	};

	$scope.isLtiUser = function() {
    	return userModel.isUserLTI();
	};

	$scope.isClasslinkUser = function() {
	    return userModel.isUserClasslink();
	};

	$scope.isEasyLoginEnabled = function(){
	    if(userModel.currentUser.sessionContext && userModel.currentUser.sessionContext.easyLogin){
	        return true;
	    }
	};

	$scope.isEasyLoginPage = function(){
        if($location.path().match( /(easylogin|cz|sz)/)){
            return true;
        }
    };

	$scope.isStaff = function() {
		return userModel && userModel.currentUser && userModel.currentUser.staff;
	};

		// GlobalNav Functionality

		function applyChanges() {
			if (!$scope.$$phase) {
				$scope.$apply();
			}
		}

		$rootScope.$on('$locationChangeSuccess', function() {
			$rootScope.currentLocation = $location.path();
		});

		$rootScope.$watch(function() {
			return $location.path();
		}, function watchCallback(newLocation, oldLocation) {
			if (!!newLocation && (newLocation !== oldLocation || $rootScope.currentLocation === newLocation)) {
				handleLocationChange();
			}
		});

		$rootScope.$watch(function() {
				return navModel.activePage;
			},
			function watchCallback(newPage, oldPage) {
				if (!!newPage && newPage !== oldPage) {
					$scope.activePage = newPage;
				}
			}
		);

		$scope.onCalendarLinkClick = function() {
			$location.path('/schoolyear');
			navModel.activePage = 'calendar';
			applyChanges();
		};

		$scope.onChangePasswordClick = function() {
			$location.search('changePassword', true);
			$scope.changePassword();
			applyChanges();
		};

		$scope.onClassesTabClick = function() {
			$location.path('/students');
			navModel.activePage = 'classes';
			applyChanges();
		};

		$scope.onDataTabClick = function() {
			var currentOrgId = Volume.getStoreItem('dpOrgId');
			var literacyDash = productModel.entitlementList.find(function(app) {
				return app.applicationCode === 'literacydash';
			});

			if (literacyDash) {
				$location.path('/lti/appLaunch?orgId=' + currentOrgId + '&appId=' + literacyDash.id);
				navModel.activePage = 'data';
				applyChanges();
			}
		};

		$scope.onHelpLinkClick = function() {
			$window.open('https://help.digital.scholastic.com/');
		};

		$scope.onLTILinkClick = function() {
			var currentOrgId = Volume.getStoreItem('dpOrgId');
			$location.path('/lti/' + currentOrgId + '/info');
			navModel.activePage = 'lti';
			applyChanges();
		};

		$scope.onProgramsTabClick = function() {
			var currentRole = Volume.getStoreItem('dpCurrentRole');

			switch (currentRole) {
				case 'teacher':
					$location.path('/products');
					break;
				case 'admin':
					$location.path('/subscriptions');
					break;
				case 'student':
					$location.path('/studentportal');
					break;
				default:
					$location.path('/');
					break;
			}

			navModel.activePage = 'programs';
			applyChanges();
		};

		$scope.onSignOutClick = function() {
			$location.path('/logout');
			navModel.activePage = undefined;
			applyChanges();
		};

		$scope.onSwitchProfileClick = function(e, role) {
			if (role && role !== 'student') {
				if (role === 'teacher') {
					$location.path('/products');
				} else if (role === 'admin') {
					$location.path('/subscriptions');
				}

				navModel.activePage = 'programs';
				applyChanges();
			}
		};

		$rootScope.onOrgSelected = function(org) {
      if (org && userModel.currentUser) {
        var id;

        if (userModel.currentUser.staff) {
          var role = Volume.getStoreItem("dpCurrentRole");
          id = userModel.currentUser.staff.id;

          if (role === "teacher") {
            var teacherCookie = productModel.getCookieForOrgAsTeacher(),
              classId,
              schoolCalendarId;

            if (!!teacherCookie) {
              classId = teacherCookie.classId;
              schoolCalendarId = teacherCookie.schoolCalendarId;
            }

            orgService.getSchoolYearsYOYForTeacher(org.id).then(function() {
              productModel.setCookieForOrgAsTeacher(
                org.id,
                classId,
                schoolCalendarId
              );
            });
          } else if (role === "admin") {
            calendarService.getSchoolYearInformation(org.id).then(function() {
              subscriptionModel.setCookieForOrgAsAdmin(org.id);
            });
          }
        } else if (userModel.currentUser.student) {
          id = userModel.currentUser.student.id;
        }

        if (id) {
          configService.getConfig(id, org.id).finally(function() {
            $rootScope.$broadcast("orgChanged", org.id);

						$rootScope.isReactGlobalNav = configService.getCurrentConfigOption("isReactGlobalNav");

						if (configService.getCurrentConfigOption("isReactStudentPrograms") ||
							configService.getCurrentConfigOption("isReactTeacherPrograms") ||
							configService.getCurrentConfigOption("isReactAdminPrograms")) {
							$rootScope.isReactBody = true;
						}

						applyChanges();
          });
        }
      }
    };

		function handleLocationChange() {
			if ($location.path().match(/schoolyear/)) {
				navModel.activePage = 'calendar';
				Volume.setStoreItem('dpCurrentRole', 'admin');
				return;
			}

			if (!!$location.search().changePassword) {
				Volume.setStoreItem('dpCurrentRole', 'student');
				return;
			}

			if ($location.path().match(/students/)) {
				navModel.activePage = 'classes';
				Volume.setStoreItem('dpCurrentRole', 'teacher');
				return;
			}

			if ($location.path().match(/lti\/[0-9]+\/info/)) {
				navModel.activePage = 'lti';
				return;
			}

			if ($location.path().match(/help/)) {
				navModel.activePage = 'help';
				return;
			}

			if ($location.path().match(/subscriptions/)) {
				navModel.activePage = 'programs';
				Volume.setStoreItem('dpCurrentRole', 'admin');
				return;
			}

			if ($location.path().match(/subscriptions\/[0-9]+\/[0-9]+/)) {
				navModel.activePage = 'programs';
				Volume.setStoreItem('dpCurrentRole', 'admin');
				return;
			}

			if ($location.path().match(/products/)) {
				navModel.activePage = 'programs';
				Volume.setStoreItem('dpCurrentRole', 'teacher');
				return;
			}

			if ($location.path().match(/studentportal/)) {
				navModel.activePage = 'programs';
				Volume.setStoreItem('dpCurrentRole', 'student');
				return;
			}

			if ($location.path().match(/(logout|signin)/)) {
				configService.reset();

				Volume.deleteStoreItem('dpUserContext');
				Volume.deleteStoreItem('dpCurrentRole');
				Volume.deleteStoreItem('dpOrgId');
			}

			navModel.activePage = undefined;
		}

	}]);
