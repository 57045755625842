(function () {

    angular.module('portalApp')
        .controller('GoogleImportClassesSplashController', GoogleImportClassesSplashController);

    GoogleImportClassesSplashController.$inject = [
        '$scope', '$routeParams', '$location', '$log', '$http',
        'sharedConstants', 'classService', 'classModel', 'orgService', 'userService',
        'navModel', 'productModel', 'productService', '$window', '$cookies',
        'filterFilter', '$uibModal', 'userModel', 'gapiService','settingsService',
        'orgModel','sessionState','notificationModel'
    ];

    function GoogleImportClassesSplashController(
        $scope, $routeParams, $location, $log, $http,
        sharedConstants, classService, classModel, orgService, userService,
        navModel, productModel, productService, $window, $cookies,
        filterFilter, $uibModal, userModel, gapiService,settingsService, orgModel, sessionState,
        notificationModel
    ) {
        var $this = this;
        $scope.googleUser = false;
        $scope.importtype = 'onboarding';
        $scope.resync = false;
        $scope.importbuttonpresscounter = 0;
        $scope.importbuttontext = '';
        $scope.gradelevels = classModel.gradesArray;
        $scope.schools = undefined;
        $scope.importstaffid  = undefined;
        $scope.importsource = undefined;
        $scope.selectedclass = undefined;

        $scope.sectionId = $routeParams.sectionId;
        $scope.initialized = false;
        $scope.spinner = true;

        $scope.error = '';
        $scope.errorFlag = false;

        $scope.closeAlert = function () {
            $scope.error = '';
            $scope.errorFlag = false;
        };

        $this.onInit = onInit;

        $scope.accessToken = undefined;
        $scope.staffId = undefined;
        $scope.school = {name: ''};

        $scope.importConfirmation = false;
        $scope.initialOnBoarding = $location.path().indexOf('students/google/import') > -1;
        $scope.goBackToDashboard = function () {
            productModel.reset();
            $location.path('/products');
        };

        function onInit() {
            sessionState.store('$scope',$scope);
            userService.getCurrentUser().then(function (currentUser) {
                if (gapiService.isGoogleUser(userModel)) {
                    $scope.isGoogleUser = true;
                    $scope.importstaffid  = currentUser.userIdentifiers.staffId;
                    $scope.importsource = currentUser.userIdentifiers.source;
                    $log.debug('Google User signed in', currentUser);
                } else {
                    $log.debug('not a Google User', currentUser);
                }

                settingsService.setSettings(sessionState).then(function(settings) {
                    $scope.showYOYFeatures = settings.showYOYFeatures;
                    if ($scope.showYOYFeatures) {
                        orgService.getSchoolsWithTeacherRole().then(function (data) {
                            $log.debug('Schools found on user profile', data);
                            if (data.length === 0) {
                                $log.debug('No schools on user profile');
                                $location.path('/products');
                                return;
                            }
                            else {
                                $scope.schools = data;
                                if (productModel.getCookieForOrgAsTeacher()) {
                                    var orgId = productModel.getCookieForOrgAsTeacher().orgId;
                                    $scope.schools.forEach(function (school) {
                                        if (school.id === orgId) {
                                            $scope.school = school;
                                        }
                                    });

                                    if ($scope.showYOYFeatures) {
                                        // below service is now modified to call the new GET API for school calendars
                                        orgService.getSchoolYearsYOYForTeacher(orgId).then(function (data) {
                                            if (data.length > 0) {
                                                // Set base year to a variable which
                                                // is the current calendar year
                                                $scope.selectedSchoolYear = orgModel.setBaseCalendarYear(data);
                                                $scope.staffId = userModel.currentUser.userIdentifiers.staffId;
                                                $scope.initialized = true;
                                                $scope.spinner = false;
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                        $scope.staffId = userModel.currentUser.userIdentifiers.staffId;
                        $scope.initialized = true;
                        $scope.spinner = false;
                    }
                });

                var notificationMessage = notificationModel.popMessage();
                try {
                    if (!!notificationMessage) {
                        var message;
                        notificationMessage = JSON.parse(notificationMessage);
                        if (typeof notificationMessage === 'object') {
                            message = notificationMessage.message;
                        }
                        if (message) {
                            $scope.errorFlag = notificationModel.errorFlag;
                            $scope.error = message;
                        }
                    }
                } catch (error) {
                    if (typeof notificationMessage === 'string') {
                        $scope.error = notificationMessage;
                        $scope.errorFlag = notificationModel.errorFlag;
                    }
                }

                productService.getSubscriptions().then(function (subscriptions) {
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh', subscriptions);
                });

                productService.getEntitlement().then(function (data) {
                    $log.debug('Reload entitlement list in order to set the productModel on refresh', data);
                });

                if (productModel.redeemedSubscription) {
                    $scope.redeemedSubscription = productModel.redeemedSubscription;
                    var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                    if(!isNoStudentAccess){
                        $scope.noStudentAccess = true;
                    }
                }

                $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);

                if (!classModel.classList) {
                    classService.getClassesForTeacher().then(function (classList) {
                        $log.debug('Class list', classList);
                    });
                }
                else {
                    $log.debug('Class list', classModel.classList);
                }

                navModel.showNav = true;
                navModel.helpPage = false;

            }, function (error) {
                $log.error('User not signed in', error);
                $scope.error = error.message;
                $scope.errorFlag = true;
                $location.path('/signin/staff');
            });
        }

        $this.onInit();

    }
})();