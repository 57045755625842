portalModule.service("orgModel", ["$log", function($log) {
    var dataObject = {
        // contains the organization list associated to a teacher
        // default it to empty array
        reset : reset,
        isUsersSchoolsClever : isUsersSchoolsClever,
        isOrgClever : isOrgClever,
        isOrgClassLink : isOrgClassLink,
        isUserSchoolAdmin : isUserSchoolAdmin,
        getSchoolsByID : getSchoolsByID,
        isTeacherInSchool : isTeacherInSchool,
        getOrgName: getOrgName,
        setBaseCalendarYear: setBaseCalendarYear,
        getCalendarById: getCalendarById,
        getCalendarsForOrg: getCalendarsForOrg,
        setCalendarsForOrg: setCalendarsForOrg,
        orgName: '',
        orgCalendars: {},
        orgsForAccessCode : [],
    };

    return dataObject;

    function reset(){
        delete dataObject.organizationList;
        dataObject.orgName = '';
        dataObject.orgCalendars = {};
        dataObject.orgsForAccessCode = [];
    }

    function getOrgName(schoolYears, orgId) {
        schoolYears.forEach(function (objYear) {
            if (objYear.id === orgId) {
                dataObject.orgName = objYear.name;
            }
        });

        return dataObject.orgName;
    }

    function setBaseCalendarYear(schoolYears) {
        var isBaseCalendarSet = false;
        var baseCalendarYear = {};
        schoolYears.forEach(function (schoolYear) {
            if (schoolYear.currentCalendar) {
                baseCalendarYear = schoolYear;
                isBaseCalendarSet = true;
            }
        });

        if (!isBaseCalendarSet) {
            baseCalendarYear = schoolYears[0];
        }
        return baseCalendarYear;
    }

    // encapsulating this for multiple uses
    function isOrgClever(org){
        // if no org passed in; treat this as a non-Clever User
        if(!org){
            return false;
        }
        if(
            // if no identifier is defined; then assume the school is not cliever
            (!org.identifiers ) ||

            // if idenfitifer is defined; and the source is not equal to clever
            // assume a not clever school
            (
                (org.identifiers ) &&
                (org.identifiers.source ) &&
                (org.identifiers.source != "Clever")
            ) ||
            // added to cover the condition where the identifier is defined; but there is no source defined
            // in that case we need to return false
            (
                (org.identifiers ) &&
                (!org.identifiers.source )
            )
        ){
                return false;
        }
        return true;
    }

    function isOrgClassLink(org){
        if (!org ||
            !org.identifiers ||
            !org.identifiers.source) {
            return false;
        }

        return org.identifiers.source.toLowerCase() === "classlink";
    }

    // encapsulating function to determine if the user's schools are Clever [or not]
    // this only returns true if all the user's schools are Clever based
    function isUsersSchoolsClever(){
        if(!dataObject.organizationList || dataObject.organizationList.length === 0){
            return false;
        }
        for (var counter=0;counter<dataObject.organizationList.length; counter++){
            if(
                !isOrgClever(dataObject.organizationList[counter])
            ){
                return false;
            }
        }
        // it got here so all the schools where clever schools
        return true;
    }

    function isUserSchoolAdmin(){
    	var schoolAdminList = (dataObject.organizationList||[]).filter(function(org, index) {
            if(((org.orgType === 'school') && (($.inArray("schoolAdmin", org.roles) != -1))) || ((org.orgType === 'district') && (($.inArray('districtAdmin',org.roles) != -1)))){
                return true;
            }
            return false;
        });
        
        if(schoolAdminList.length >0){
            return true;
        } else {
            return false;
        }
        
    }

    // function to retrieve schools based on the school Id
    // used for syncing up a subscription to the school
    // returns an array; in theory with only one item in it
    function getSchoolsByID(schoolId){
        return dataObject.organizationList.filter(function(sub,index){
            return (sub.id == schoolId);
        })
    }
    
    function isTeacherInSchool(orgId) {    	
    	var match = (dataObject.organizationList||[]).filter(function(org, index) {
            if((org.id === orgId) && (($.inArray("teacher", org.roles) != -1))){
                return true;
            }
            return false;
        });
    	$log.debug("Checked if has teacher role for ", orgId, " match=", match, " dataObject.organizationList=", dataObject.organizationList);
    	return match.length > 0;
    }

    function getCalendarById(orgId, calendarId) {
      if (orgId && calendarId) {
        var calendars = dataObject.orgCalendars[orgId];

        if (calendars) {
          return calendars.filter(function(calendar) {
            return calendar.id === calendarId;
          })[0];
        }
      }

      return undefined;
    }

    function getCalendarsForOrg(orgId) {
        if (!dataObject.orgCalendars) {
            dataObject.orgCalendars = {};
        }

        if (!dataObject.orgCalendars[orgId]) {
            dataObject.orgCalendars[orgId] = [];
        }

        return dataObject.orgCalendars[orgId];
    }

    function setCalendarsForOrg(orgId, calendars) {
        if (!dataObject.orgCalendars) {
            dataObject.orgCalendars = {};
        }

        if (orgId) {
            dataObject.orgCalendars[orgId] = calendars ? calendars : [];
        }

        return undefined;
    }

}]);