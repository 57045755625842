portalModule.service('userService', [
    '$http', '$q', '$log', '$location', 'cookieService',
    'apiResponse', 'userModel', 'sharedConstants', 'navModel', 'sessionModel',
    'easyLoginModel', 'configService',
    function (
        $http, $q, $log, $location, cookieService,
        apiResponse, userModel, sharedConstants, navModel, sessionModel,
        easyLoginModel, configService
        ) {
        function rejectAndRedirect(deferred, error) {
            deferred.reject(error);
            $location.path('/signin/staff');
        }

        return {
            getCurrentUser: getCurrentUser,
            signinUser: signinUser,
            signoutUser: signoutUser,
            setCurrentUser: setCurrentUser,
            ensureIsStaffMember: ensureIsStaffMember,
            loginWithCleverUrl: loginWithCleverUrl,
            updateCredentialsTeacher: updateCredentialsTeacher,
            updateCredentialsStudent: updateCredentialsStudent,
            getCurrentUserWithoutRedirect: getCurrentUserWithoutRedirect,
            getEasyLoginClassDetails: getEasyLoginClassDetails,
            getSchoolEasyLoginDetails: getSchoolEasyLoginDetails,
            easyLoginSignInUser: easyLoginSignInUser
        };

        function ensureIsStaffMember() {
            var deferred = $q.defer();
            getCurrentUser().then(function (currentUser) {
                if (currentUser.staff) {
                    $log.debug('In userService.ensureIsStaffMember. Confirmed!');
                    deferred.resolve(currentUser);
                } else {
                    $log.debug('In userService.ensureIsStaffMember. Logged in but not staff. currentUser: ', currentUser);
                    $location.path('/studentportal');
                    deferred.reject('User is not a staff member');
                }
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        //MB: Duplicate function to get current user.
        //TODO: Change the logic in entire application to use only getCurrentUserWithoutRedirect()
        function getCurrentUserWithoutRedirect(ssoLaunch, cleverToken, gapiToken, userType) {
            var deferred = $q.defer();
            if (userModel.currentUser && userModel.expireTime > new Date().getTime()) {
                $log.debug('getCurrentUser from userModel');
                if (sessionModel.reportingFunctionExecuted == undefined) {
                    sessionModel.reportingFunctionExecuted = true;
                }
                return $q.when(userModel.currentUser);
            }

            var params = {};
            if (ssoLaunch) {
                params.ssoLaunch = ssoLaunch;
            }
            if (cleverToken) {
                params.cleverToken = cleverToken;
            }
            if (gapiToken) {
                params.gapiToken = gapiToken;
            }
            if (userType) {
                params.userType = userType;
            }

            var request = $http({
                method: 'get',
                url: PORTAL_API_ENDPOINT + '/user',
                cache: false,
                params: params
            });

            function removeOpenAmSessionCookie() {
                // get the root domain (e.g. scholastic.com)
                var cookieDomain = "." + $location.host().replace(/.*\.([^.]+\.[^.]+)$/, "$1");
                var config = {
                    "path": "/",
                    "domain": cookieDomain
                };
                var openAmCookieName = "iPlanetDirectoryPro";
                $log.debug("Deleting " + openAmCookieName + " cookie using config: ", config);
                cookieService.removeCookie(openAmCookieName);
            }

            apiResponse.makeApiCall(request).then(function (data) {
                if (ssoLaunch) {
                    removeOpenAmSessionCookie();
                }
                $log.debug('getCurrentUser api response', data);
                if (data && data.shingleEnabled === true) {
                    window.location.href = data.shingleTemplate;
                } else if (!data.userContext) {
                    deferred.reject(data.message || data.code || "An unexpected error occurred");
                } else {
                    setCurrentUser(data.userContext);
                    deferred.resolve(data.userContext);
                    if (sessionModel.reportingFunctionExecuted == undefined) {
                        sessionModel.reportingFunctionExecuted = true;
                    }
                }
            }, function (error) {
                if (ssoLaunch) {
                    removeOpenAmSessionCookie();
                }

                rejectAndRedirect(deferred, error);
            });
            return deferred.promise;
        }

        function getCurrentUser() {
            var deferred = $q.defer();
            // Bug was introduced where userModel.currentUser is empty instead of blank
            // Adding userModel.userContext check until we can regress a larger change to set to false or null
            if (userModel.currentUser && userModel.expireTime > new Date().getTime()) {
                $log.debug('getCurrentUser from userModel');
                if (sessionModel.reportingFunctionExecuted == undefined) {
                    sessionModel.reportingFunctionExecuted = true;
                }

                getUserConfig().then(function() {
                    deferred.resolve(userModel.currentUser);
                });

                return deferred.promise;
            }

            var request = $http({
                method: 'get',
                url: PORTAL_API_ENDPOINT + '/user',
                cache: false
            });

            apiResponse.makeApiCall(request).then(function (data) {
                $log.debug('getCurrentUser api response', data);

                if (data) {
                    // bounce users to shingle
                    if (data.shingleEnabled && data.shingleEnabled === true) {
                        window.location.href = data.shingleTemplate;
                        return;
                    }

                    // redirect logged out users
                    if (data.code && data.code === 'error.not.logged.in') {
                        rejectAndRedirect(deferred, new Error(data.message));
                        return;
                    }

                    // log out CSRs and Sales Reps
                    if (
                      data.userContext &&
                      data.userContext.roles &&
                      data.userContext.roles.length === 1 &&
                      ['customer_service_rep', 'sales_rep'].includes(data.userContext.roles[0])
                    ) {
                        rejectAndRedirect(deferred, undefined);
                        return;
                    }

                    // set user and resolve
                    setCurrentUser(data.userContext);
                    getUserConfig().then(function(data) {
                        deferred.resolve(userModel.currentUser);
                        if (sessionModel.reportingFunctionExecuted == undefined) {
                            sessionModel.reportingFunctionExecuted = true;
                        }
                    });
                } else {
                    rejectAndRedirect(deferred, new Error(sharedConstants.ERROR_PROBLEM_UNKNOWN));
                }
            }, function (error) {
                $location.path('/signin/staff');
                deferred.reject(error);
            });
            return deferred.promise;
        }
        
        function getUserType(currentUser) {
          if (currentUser.student) {
            return "student";
          }

          return "staff";
        }

        function setCurrentUser(currentUser) {
            $log.debug('In userService.setCurrentUser. currentUser: ', currentUser);
            userModel.currentUser = currentUser;
            Volume.setStoreObject('dpUserContext', currentUser);
            window.dumbleDataHelpers.updateUser();
            cookieService.saveCookie('userType', getUserType(currentUser));
            if (currentUser.staff && userModel.overrideUserLicenseAgreement) {
                $log.debug('Overriding promptToAcceptLicense flag as EULA has already been accepted');
                userModel.currentUser.staff.promptToAcceptLicense = false;
            }
            userModel.teacherId = currentUser.staff ? currentUser.staff.id : undefined;
            userModel.expireTime = new Date().getTime() + sharedConstants.CURRENT_USER_TIMEOUT; // expire in one minute
            navModel.helpPage = false;
            $log.debug('userModel: ', userModel);
        }

        function setCookie(currentUser) {
            cookieService.removeCookie('user');
            cookieService.saveCookie('user', getUserType(currentUser));
        }

        function signinUser(userEmail, userPassword, token) {
            var params = {
              username: userEmail,
              password: userPassword
            };

            if (!!token) {
              params["g-recaptcha-response"] = token;
            }

            var request = $http({
                method: 'POST',
                url: PORTAL_API_ENDPOINT + '/login/submit',
                data: $.param(params),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return apiResponse.makeApiCall(request).then(function (data) {
                setCurrentUser(data.userContext);
                setCookie(data.userContext);
                sessionModel.reportingFunctionExecuted = true;
                return data.userContext;
            });
        }

        function signoutUser() {
            var request = $http({
                method: 'POST',
                url: PORTAL_API_ENDPOINT + '/logout'
            });
            return apiResponse.makeApiCall(request);
        }

        function loginWithCleverUrl() {
            var request = $http({
                method: 'GET',
                url: PORTAL_API_ENDPOINT + '/clever-login'
            });
            return apiResponse.makeApiCall(request);
        }

        function updateCredentialsTeacher(newCredentials, staffId) {
            var request = $http({
                method: 'POST',
                url: PORTAL_API_ENDPOINT + '/composite/staff/credentials',
                data: newCredentials
            });
            return apiResponse.makeApiCall(request);
        }

        function updateCredentialsStudent(newCredentials, studentId) {
            var request = $http({
                method: 'POST',
                url: PORTAL_API_ENDPOINT + '/composite/student/credentials',
                data: newCredentials
            });
            return apiResponse.makeApiCall(request);
        }

        function getEasyLoginClassDetails(classId, classSecret) {
            var deferred = $q.defer();

            var request = $http({
                method: 'GET',
                url: PORTAL_API_ENDPOINT + '/composite/public/easylogin/class/' + classId + '/' + classSecret
            });

            apiResponse.makeApiCall(request).then(function (data) {
                var easyLoginAvatars = easyLoginModel.getEasyLoginAvatars();

                for (var student in data.students) {
                    for (var avatar in easyLoginAvatars) {
                        if (data.students[student].easyLoginAvatarId == easyLoginAvatars[avatar].id) {
                            data.students[student].avatar = easyLoginAvatars[avatar];
                            break;
                        }
                    }
                }
                easyLoginModel.easyLoginClassDetails = data;
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
        
        function getSchoolEasyLoginDetails(orgId, orgSecret) {
            var deferred = $q.defer();
            var request = $http({
                method: 'GET',
                url: PORTAL_API_ENDPOINT + '/composite/public/easylogin/organizations/' + orgId + '/' + orgSecret
            });
            apiResponse.makeApiCall(request).then(function (data) {
                var schoolEasyLoginAvatars = easyLoginModel.getSchoolEasyLoginAvatars();
                for (var classInfo in data.classes) {
                    for (var avatar in schoolEasyLoginAvatars) {
                        if (data.classes[classInfo].easyLoginClassIcon === schoolEasyLoginAvatars[avatar].id) {
                            data.classes[classInfo].avatar = schoolEasyLoginAvatars[avatar];
                            break;
                        }
                    }
                    data.classes[classInfo].teacherDisplayName = data.classes[classInfo].primaryTeacherName.substr(data.classes[classInfo].primaryTeacherName.indexOf(' ')+1);
                }
                easyLoginModel.schoolEasyLoginClassDetails = data;
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
        
        function easyLoginSignInUser(classId, classSecret, studentId, studentSecret) {

            var params = {};
            params.classId = classId;
            params.classSecret = classSecret;
            params.studentId = studentId;
            params.studentSecret = studentSecret;

            var request = $http({
                method: 'POST',
                url: PORTAL_API_ENDPOINT + '/authenticate/easylogin',
                data: params
            });

            return apiResponse.makeApiCall(request).then(function (data) {
                setCurrentUser(data.userContext);
                setCookie(data.userContext);
                sessionModel.reportingFunctionExecuted = true;
                return data.userContext;
            });
        }

        function getUserConfig() {
          // Fetch config for user if we already have their current org ID
          var orgId = Volume.getStoreItem("dpOrgId");
          var id;

          var deferred = $q.defer();

          if (!orgId || !userModel.currentUser) {
            deferred.resolve();
            return deferred.promise;
          }

          if (userModel.currentUser.staff && userModel.currentUser.staff.id) {
            id = userModel.currentUser.staff.id;
          } else if (
            userModel.currentUser.student &&
            userModel.currentUser.student.id
          ) {
            id = userModel.currentUser.student.id;
          }

          if (id) {
            configService.getConfig(id, orgId).finally(deferred.resolve);
          } else {
            deferred.resolve();
          }

          return deferred.promise;
        }
    }
]);