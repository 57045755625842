(function(){
    
    /**
     * EditStudentEasyLoginController
     * 
     * @author Manit Banker
     */
    
    angular.module('portalApp').controller('EditStudentEasyLoginController', EditStudentEasyLoginController);
    
    EditStudentEasyLoginController.$inject = ['$scope','$log', '$uibModalInstance', 'shapeArray', 'credType','$location'];
    
    function EditStudentEasyLoginController($scope, $log, $uibModalInstance, shapeArray, credType, $location){
        
        $scope.credType = credType;
        $scope.easyLoginShapes = shapeArray;
        
        $scope.cancel = function(shape){
            if(shape !== ''){
                $uibModalInstance.close(shape);
            }
            else{
                $uibModalInstance.close(undefined);
            }
        };
        
        $scope.chooseShape = function(shape){
            $scope.selectedShape = shape;
            $log.debug('Selected shape', $scope.selectedShape);
        };
        
        $scope.save = function(){
            var shape = {};
            shape.credType = $scope.credType;
            shape.selectedShape = $scope.selectedShape;
            $scope.cancel(shape);
        };
        
    }
    
})();