(function (undefined) {
    // 'use strict';

    angular.module('portalApp').service('SessionService', SessionService);

    SessionService.$inject = [
        '$http',
        '$q',
        '$log',
        '$location',
        '$interval',
        'userModel',
        'sharedConstants',
        'apiResponse',
        'sessionModel',
        'SDMAnalyticsService'
    ];

    function SessionService($http, $q, $log, $location, $interval, userModel, sharedConstants, apiResponse, sessionModel, SDMAnalyticsService) {

        var service = this;

        var initialized = false;
        var stop;

        service.getState = function () {
            return {
                oldOrgId: service.oldOrgId,
                newOrgId: service.newOrgId,
                initialized: initialized
            };
        };

        service.initialize = function () {

            $log.debug('Initializing state.');
            var self = this;
            var now = Date.now();

            service.oldOrgId = service.newOrgId;
            SessionService.lastActivityTime = now;
            service.fireWebBeacon();

            stop = $interval(service.fireWebBeacon.bind(service), sessionModel.pollForActivityEveryMillis);
            initialized = true;

            $log.debug('Service state post initialization: ', service.getState());

            //Once the org initialization is done, mark the newOrgId to undefined
            service.newOrgId = undefined;
        };
        service.fireWebBeacon = function () {

            if (userModel.currentUser.sessionContext) {
                $log.debug('Inside fireWebBeacon: ', service.getState());

                var now = Date.now();
                var lastActivityMillisAgo = now - sessionModel.lastActivityTime;
                if ((service.oldOrgId !== service.newOrgId) || sessionModel.lastReportIn === undefined || sessionModel.lastActivityTime > sessionModel.lastReportIn) {
                    $log.debug('Last activity is sooner than last reported time or org has changed.');
                    service.extSession(userModel.currentUser.sessionContext, false, lastActivityMillisAgo, false);
                    sessionModel.lastReportIn = now;
                } else {
                    if ((now - sessionModel.lastReportIn) > sessionModel.noActivityCheckIfSessionActiveThreshold) {
                        $log.debug('Exceeded threshold limit');
                        service.extSession(userModel.currentUser.sessionContext, false, lastActivityMillisAgo, true);
                        sessionModel.lastReportIn = now;
                    } else {
                        $log.debug('No activity since last report in but less than the threshold limit');
                        setTimeout(function () {
                            service.fireWebBeacon.bind(service);
                        }, sessionModel.pollForActivityEveryMillis);
                    }
                }
            } else {
                stopTimer();
            }
        };

        function stopTimer() {
            $log.debug('Stopping the timer.');
            $interval.cancel(stop);
        }

        service.extSession = function (sessionContext, isLogout, lastActivityMillisAgo, noActivitySinceLastReportIn) {

            var data = {
                'sessionId': sessionContext.sessionToken,
                'orgId': service.newOrgId ? service.newOrgId : service.oldOrgId,
                'isLogout': isLogout,
                'appCode': 'sdm',
                'env': sessionContext.env,
                'lastActivityMillisAgo': lastActivityMillisAgo,
                'noActivitySinceLastReportIn': noActivitySinceLastReportIn
            };

            if (service.oldOrgId && service.newOrgId && service.oldOrgId !== service.newOrgId) {
                data.oldOrgId = service.oldOrgId;
            }

            var request = $http({
                method: 'POST',
                url: sessionContext.extSessionEndpoint,
                data: JSON.stringify(data),
                cache: false,
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'cache-control': undefined,
                    'pragma': undefined
                }
            });

            $log.debug('extSession: ', data);

            apiResponse.makeApiCall(request).then(function (data) {
                if ((data != null) && (data.sessionActive === false)) {
                    //TODO: Remove this code once lambda is working fine
                    
                    var lastActiveSessionId = sessionModel.getSessionIdCookie();
                    
                    if(data.code === 'SESSION_NOT_FOUND'){
                        if(sessionContext.sessionToken !== lastActiveSessionId){
                            $log.debug('Session not found. But sessionId cookie does not have match');
                            return;
                        } else{
                            $location.path('/logout');
                        }
                    } else if (sessionContext.sessionToken.toLowerCase().startsWith('disc')) {
                        $log.debug('Session expired but starts with DISC');
                        return;
                    } else {
                        $location.path('/logout');
                    }
                } else {
                    if (data == null || data.sessionActive !== true) {
                        $log.debug('Response from extsession API ', data, ' missing sessionActive flag');
                    } else{
                        sessionModel.setSessionIdCookie(sessionContext.sessionToken);
                    }
                }
            }, function (error) {
                $log.debug('Extended session error response: ', error);
            });
            return false;
        };

        service.isExtSessionActive = function(sessionContext) {
            var deferred = $q.defer();
            var data = {
                'sessionId': sessionContext.sessionToken,
                'orgId': '0',
                'isLogout': false,
                'appCode': 'sdm',
                'env': sessionContext.env
            };

            $http({
                method: 'POST',
                url: sessionContext.extSessionEndpoint,
                data: JSON.stringify(data),
                cache: false,
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'cache-control': undefined,
                    'pragma': undefined
                }
            }).then(function success(res) {
                deferred.resolve(res.data);
            },function error(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.switchOrg = function () {
            $log.debug('Inside switchOrg: ', service.getState());
            var now = Date.now();
            SessionService.lastActivityTime = now;
            service.fireWebBeacon();
            service.oldOrgId = service.newOrgId;
            $log.debug('Service state post switch org: ', service.getState());
            //Once the org switching is done, mark the newOrgId to undefined
            service.newOrgId = undefined;
        };

        service.analyticsEventListener = function (event) {
            try {
                $log.debug('Current service state: ', service.getState());
                service.newOrgId = event.orgId;

                if (service.newOrgId && !initialized) {
                    service.initialize();
                } else if (service.newOrgId !== 0 && service.newOrgId !== service.oldOrgId) {
                    service.switchOrg();
                }
            } catch (error) {
                $log.error(error);
            }
        };
    }

})();
