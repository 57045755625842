portalModule.controller("addTeacherModalController",
    ['$scope', '$uibModalInstance', '$log','teacherService', 'userModel',
        function ($scope, $uibModalInstance, $log, teacherService, userModel) {

            $scope.setfocus = true;
            $scope.staffId = undefined;
            $scope.teacher = undefined;
            $scope.previouslySubmittedEmail = '';

            $scope.enterKeyUp = function(event){
                $scope.onButtonPress('addTeacher');
            };

            $scope.onButtonPress = function (message) {
                var data = {};
                data.message = message;
                if (message==='addTeacher'
                    && !!$scope.email
                    && !!$scope.staffId
                    && !!$scope.school && !!$scope.school.id){
                    teacherService
                        .matchTeacherEmail($scope.staffId, $scope.school.id, $scope.email)
                        .then(function(teacherList){
                            if(!!teacherList && teacherList.length === 1){
                                $scope.teacher = teacherList[0];
                                data.teacher = $scope.teacher;
                                data.selectedClass = $scope.selectedClass;
                                data.staffId = $scope.staffId;
                                if (data.teacher.email === $scope.staffEmail){
                                    $scope.selfEmail = $scope.email;
                                } else {
                                    $uibModalInstance.close(data);
                                }
                            } else {
                                $scope.teacher = undefined;
                                data.teacher = $scope.teacher;
                                data.selectedClass = undefined;
                                data.staffId = undefined;
                                $scope.previouslySubmittedEmail = $scope.email;
                            }
                        })
                        .catch(function(error){
                            $scope.teacher = undefined;
                            $scope.previouslySubmittedEmail = $scope.email;
                            $log.error(error);
                        });
                } if (message==='cancel'){
                    $uibModalInstance.close(data);
                }
            };

            function onInit() {
                if (!!userModel
                    && !!userModel.currentUser
                    && !!userModel.currentUser.staff
                    && !!userModel.currentUser.staff.identifiers
                    && !!userModel.currentUser.staff.identifiers.staffId) {
                    $scope.staffId = userModel.currentUser.staff.identifiers.staffId;
                    $scope.staffEmail = userModel.currentUser.staff.email;
                }
                $log.debug('add teacher modal initialized!');
            }

            onInit();
        }
    ]
);
