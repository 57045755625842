portalModule.controller('LtiInformationController', [
    '$scope', '$q', '$log', 'ltiService', '$location', 'classlinkService', 'settingsService',
    '$uibModal', '$uibModalStack', '$routeParams', 'userService', 'orgService', 'classlinkDataModel',
    'notificationModel', 'productService', 'subscriptionModel', 'ltiDataModel', 'userModel',
    'navModel', 'sharedConstants','$timeout', 'orgModel',
    function ($scope, $q, $log, ltiService, $location, classlinkService, settingsService,
              $uibModal, $uibModalStack, $routeParams, userService, orgService, classlinkDataModel,
              notificationModel, productService, subscriptionModel, ltiDataModel, userModel,
              navModel, sharedConstants, $timeout, orgModel) {

        var $this = this;

        $this.onInit = onInit;
        $this.redirectPath  = redirectPath;

        $scope.initialized = false;
        $scope.userIsAdmin = false;
        $scope.notAllowed = false;
        $scope.alertMessage = '';

        $scope.classLinkEnabled = false;
        $scope.isClassLinkOrg = false;
        $scope.shouldShowClassLinkApiSettings = false;

        $scope.closeAlert = function(){
            $scope.alertMessage = '';
            delete $scope.alertMessage;

            $scope.errorFlag = false;
            delete $scope.errorFlag;
        };

        $scope.cancel = function () {
            if ($routeParams.dashboard) {
                $location.path('/' + $routeParams.dashboard).search({dashboard: null});
                return;
            }

            $scope.userIsAdmin
                ? $location.path('/subscriptions')
                : $location.path('/products');
        };

        $scope.$on('$locationChangeStart', function () {
            $uibModalStack.dismissAll();
            $location.search({dashboard: null});
        });


        function getModalConfiguration(config) {
            return angular.extend({}, {
                controller: 'LtiConfirmationPopupController',
                controllerAs: '$ctrl'
            }, config);
        }

        $scope.getLtiUrl = function (appId) {
            return ltiDataModel.getLtiUrlForApp(appId);
        };

        $scope.loadSubscriptions = function () {
            return productService.getSubscriptions()
                .then(function (subscriptions) {
                    $log.debug('Load Subscriptions success handler', subscriptions);

                    $scope.userIsAdmin = subscriptionModel.isAdminOfOrg($routeParams.orgId);

                    if ($scope.userIsAdmin) {
                        $scope.orgAndSubscriptions = subscriptions;

                        if ($scope.orgAndSubscriptions.length >= 1) {
                            var cookie = subscriptionModel.getCookieForOrgAsAdmin();

                            if (cookie && cookie.orgId === $routeParams.orgId) {
                                $scope.subscriptionsForOrg = subscriptionModel.getOrgWithSub(cookie.orgId)[0];
                            } else {
                                $scope.subscriptionsForOrg = subscriptionModel.getOrgWithSub($routeParams.orgId)[0];
                                subscriptionModel.setCookieForOrgAsAdmin($routeParams.orgId);
                            }
                        }
                    } else {
                        productService.loadEntitlements()
                            .then(function (applications) {
                                $scope.orgAndSubscriptions = applications.filter(function (org) {
                                    return org.id === $routeParams.orgId;
                                });

                                if ($scope.orgAndSubscriptions.length >= 1) {
                                    $scope.subscriptionsForOrg = {subscription: []};

                                    for (var i = 0; i < $scope.orgAndSubscriptions.length; i++) {
                                        var org = $scope.orgAndSubscriptions[i];
                                        for (var j = 0; j < org.applications.length; j++) {
                                            $scope.subscriptionsForOrg.subscription.push({application: org.applications[j]});
                                        }
                                    }
                                }
                            })
                            .catch(handleLoadSubscriptionsError);
                    }
                })
                .catch(handleLoadSubscriptionsError);
        };

        $scope.launchGenerateModal = function () {
            var result = $uibModal.open(getModalConfiguration({
                templateUrl: 'resources/js/Modules/lti/modal/ltiConfirmGenerateModal.html'
            })).result;

            result.then(function (response) {
                return $scope.generate($routeParams.orgId);
            })
        };

        $scope.launchDeactivateModal = function (key) {
            var result = $uibModal.open(getModalConfiguration({
                templateUrl: 'resources/js/Modules/lti/modal/ltiConfirmDeactivateModal.html'
            })).result;

            result.then(function (response) {
                return $scope.deactivate($routeParams.orgId, key);
            })
        };

        $scope.generate = function (orgId) {
            $scope.initialized = false;
            ltiService.generateKey(orgId)
                .then(function () {
                    $scope.getInfo().finally(function () {
                        $scope.initialized = true;
                    });
                })
                .catch(function (error) {
                    $scope.alertMessage = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    $scope.errorFlag = true;
                    $scope.initialized = true;
                });
        };

        $scope.deactivate = function (orgId, key) {
            $scope.initialized = false;
            ltiService.deactivateKey(orgId, key)
                .then(function () {
                    $scope.getInfo().finally(function () {
                        $scope.initialized = true;
                    });
                })
                .catch(function (error) {
                    $scope.alertMessage = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    $scope.errorFlag = true;
                    $scope.initialized = true;
                });
        };

        $scope.getInfo = function () {
            var promise = ltiService.getInfo($routeParams.orgId);
            promise
                .then(function (response) {
                    $scope.ltiData = angular.copy(response);
                })
                .catch(function (error) {
                    if (error.code === 'error.staff.member.does.not.have.access') {
                        $scope.notAllowed = true;
                        return;
                    }

                    $scope.alertMessage = error.message || sharedConstants.ERROR_PROBLEM_UNKNOWN;
                    $scope.errorFlag = true;
                });
            return promise;
        };

        $scope.clSaveKey = function (id, orgId, orgKey, orgSecret, orgUrl) {
            $scope.initialized = false;
            if (id) {
                classlinkService.clChangeKey(id, orgId, orgKey, orgSecret, orgUrl)
                    .then(function () {
                        $scope.clGetInfo().finally(function () {
                            $scope.initialized = true;
                        });
                    })
                    .catch(function (error) {
                        $scope.alertMessage = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                        $scope.errorFlag = true;
                        $scope.initialized = true;
                    });
            }
            else {
                if (!orgId) {
                    orgId = $routeParams.orgId;
                }
                classlinkService.clSaveKey(orgId, orgKey, orgSecret, orgUrl)
                    .then(function () {
                        $scope.clGetInfo().finally(function () {
                            $scope.initialized = true;
                        });
                    })
                    .catch(function (error) {
                        $scope.alertMessage = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                        $scope.errorFlag = true;
                        $scope.initialized = true;
                    });
            }
        };

        $scope.clGetInfo = function () {
            var deferred = $q.defer();

            if ($scope.shouldShowClassLinkApiSettings) {
                classlinkService.clGetInfo($routeParams.orgId)
                  .then(function (response) {
                      $scope.classlinkData = angular.copy(response);
                      deferred.resolve();
                  })
                  .catch(function (error) {
                      $scope.classlinkData = {};
                      if (error.code === 'error.staff.member.does.not.have.access') {
                          $scope.notAllowed = true;
                          return;
                      }

                      $scope.alertMessage = error.message || sharedConstants.ERROR_PROBLEM_UNKNOWN;
                      $scope.errorFlag = true;
                      deferred.reject();
                  });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        $scope.clEnabled = function () {
            return new Promise(function(resolve) {
                settingsService.getSettings().then(function(settings) {
                    var org =  orgModel.getSchoolsByID($routeParams.orgId)[0];

                    $scope.classLinkEnabled = !!settings.showClassLinkFeatures;

                    if (!!org && $scope.classLinkEnabled) {
                        $scope.isClassLinkOrg = orgModel.isOrgClassLink(org);

                        $scope.shouldShowClassLinkApiSettings =
                          $scope.classLinkEnabled &&
                          $scope.isClassLinkOrg &&
                          org.orgType === 'district';
                    }

                    resolve();
                });
            });
        };

        $scope.getDate = function (dateString) {
            return new Date(dateString);
        };

        var handleLoadSubscriptionsError = function (error) {
            if (error.code === 'error.not.logged.in') {
                userModel.reset();
                $location.path('/signin/staff');
            }
            $log.error('Error loading Applications', error);
            $scope.alertMessage = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
            $scope.errorFlag = true;
        };

        $scope.$on('orgChanged', function($event, orgId) {
            if (subscriptionModel.orgAndSubscriptions) {
                $this.redirectPath("/lti/" + orgId + "/info");
            }
        });

        function redirectPath(path) {
            $timeout(function () {
                $location.path(path);
            },1000);
        }

        function onInit() {
            navModel.activePage = 'lti';

            userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                $log.debug('User signed in', currentUser);
                navModel.showNav = true;

                orgService.getOrgData().then(function (data) {
                    $log.debug('Reload org list in order to set the orgModel again on refresh', data);

                    $q.all([
                      $scope.getInfo(),
                      $scope.loadSubscriptions()
                    ]).finally(function() {
                        $scope.clEnabled().finally(function() {
                            $scope.clGetInfo().finally(function() {
                                $scope.initialized = true;
                            });
                        });
                    });
                });
                $scope.error = notificationModel.popMessage();
            }, function (error) {
                $log.error('User not signed in', error);
                $location.path('/signin/staff');
            });
        }

        $this.onInit();

    }
]);
