portalModule.controller('confirmRemoveEntitlements',
    ['$scope', '$uibModalInstance', function($scope, $uibModalInstance){

        $scope.studentDetails = $scope.studentsWithMultipleEntitlements.split(',');

        $scope.select = function (message) {
            $uibModalInstance.close({ message: message })
        };
    }
    ]
);