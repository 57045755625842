portalModule.controller('licenseAcceptController',
	['$scope','$location','$log','$routeParams','userService','notificationModel','licenseAcceptService', 'navModel','loginModel',
    function($scope,$location,$log,$routeParams,userService,notificationModel,licenseAcceptService,navModel,loginModel){
	
	
	function checkToken(){
		$log.debug($routeParams.token);
		if($routeParams){
			licenseAcceptService.checkValidToken($routeParams.token).then(checkValidTokenSuccessHandler,checkValidTokenErrorHandler);
		}
	}
	
	function checkValidTokenSuccessHandler(data){
		$log.debug('Check token success handler',data);
		$scope.orgName = data.administrativeOrg.name;
		if(data.licenseAccepted == true){
			notificationModel.setMessage('The Product Terms and Institutional Customer Agreement are already accepted for <strong>'+ data.administrativeOrg.name +'</strong>.');
			$location.path('/licenseconfirmation');
		}
		else{
			$scope.initialized = true;
		}
	}
	
	function checkValidTokenErrorHandler(error){
		$log.error('Check token error handler',error);
		$scope.initialized = true;
		$scope.invalidToken = true;
		$scope.error = 'The Product Terms and Institutional Customer Agreement link you are attempting to use is invalid or expired. Please contact customer service 1-866-826-8834 for assistance with the agreement.';
	}
	
	function licenseAcceptSuccessHandler(data){
		$log.debug('License accepted',data);
		notificationModel.setMessage('Thank you for accepting Product Terms and Institutional Customer Agreement. The agreement applies to <strong>'+ $scope.orgName + '</strong>\'s current order as well as any future orders.' );
		$location.path('/licenseconfirmation');	
	}
	
	function licenseAcceptErrorHandler(error){
		$log.error('License accept error handler',error);
		$scope.error = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
	}
	
	$scope.acceptLicense = function(){
		licenseAcceptService.licenseAccept($routeParams.token).then(licenseAcceptSuccessHandler,licenseAcceptErrorHandler);
	}
	
	$scope.closeAlert = function(){
		$scope.error = '';
	}
	
	function init(){
		$scope.error = '';
		$scope.invalidToken = false;
		$scope.initialized = false;
		$scope.showOrgInfo = true;
		$log.debug('Url with token is', $location.path());
		userService.getCurrentUserWithoutRedirect().then(function(currentUser){
			$log.debug('User signed in ',currentUser);
			if(currentUser.student)
			{
				$location.path('/studentportal');
			}
			else
			{
				navModel.licenseShowNav = true;
				navModel.showNav = false;
				navModel.helpPage = false;
				$scope.firstName = currentUser.staff.firstName;
				$scope.lastName = currentUser.staff.lastName;
				checkToken();
			}
		},function(error){
		    loginModel.setPostLoginUrl($location.path());
			$log.error('User not signed in ',error);
			$location.path('/signin/staff');
			navModel.licenseShowNav = false;
		});
	}
	init();
	
}]);