angular.module('portalApp').controller('SearchAddNewStudentsController', [
    '$scope', '$location', 'classService', '$routeParams', '$log',
    'orgService', 'classModel', 'userService', 'navModel', 'productModel',
    'productService', 'sessionState', 'settingsService',
    function ($scope, $location, classService, $routeParams, $log,
              orgService, classModel, userService, navModel, productModel,
              productService, sessionState, settingsService) {
        var $this = this;

        $this.onInit = onInit;
        $this.getSelectedClass = getSelectedClass;

        $scope.orgId = '';
        $scope.sectionId = $routeParams.sectionId;
        $scope.initialOnboarding = $location.path().indexOf('firstclass') > -1;
        $scope.showElPref = false;

        $scope.backToAdd = backToAdd;
        $scope.goToPrintInstructions = goToPrintInstructions;

        $this.onInit();

        function getSelectedClass() {
            classService.getClassSection($scope.sectionId).then(function (data) {
                $scope.selectedClass = data;

                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }

                $log.debug('Selected Class', $scope.selectedClass);
            }, function (error) {
                $log.debug('Error getting selected class details', error);
            });
        }

        function backToAdd() {
            $location.path('/students/firstclass/addnewstudents/'+ $scope.sectionId);
        }

        function goToPrintInstructions() {
            $location.path('/students/firstclass/print/' + $scope.sectionId);
        }

        function onInit() {
            // we need to fetch settings here in case the user is SRC only and was directed past the addNewStudents controller
            sessionState.store('$scope',$scope);
            settingsService.setSettings(sessionState)
                .then(function(settings) {
                    userService.getCurrentUser().then(function (currentUser) {
                        $log.debug('User signed in', currentUser);

                        orgService.getSchoolsWithTeacherRole().then(function (data) {
                            $log.debug('Schools found on user profile', data);

                            if (data.length === 0) {
                                $log.debug('No schools on user profile');
                                $location.path('/products');
                                return;
                            } else {
                                $scope.schools = data;
                            }

                            var orgCookie = productModel.getCookieForOrgAsTeacher();

                            if (!orgCookie) {
                                $scope.orgId = $scope.schools[0].id;
                            } else {
                                $scope.orgId = orgCookie.orgId;
                            }
                        });

                        if (classModel.showElPref) {
                            $scope.showElPref = true;
                        }

                        productService.getSubscriptions().then(function (subscriptions) {
                            $log.debug('Reload subscription list in order to set the subscriptionModel on refresh', subscriptions);
                        });

                        productService.getEntitlement().then(function (data) {
                            $log.debug('Reload entitlement list in order to set the productModel on refresh', data);
                        });

                        if (productModel.redeemedSubscription) {
                            $scope.redeemedSubscription = productModel.redeemedSubscription;
                            var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                            if (!isNoStudentAccess) {
                                $scope.noStudentAccess = true;
                            }
                        }

                        if(!$scope.redeemedSubscription && $scope.initialOnboarding){
                            $location.path('/products');
                        }

                        $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);
                        $this.getSelectedClass();

                        navModel.showNav = true;
                        navModel.helpPage = false;
                    }, function (error) {
                        $log.error('User not signed in', error);
                        $location.path('/signin/staff');
                    });
                });
        }
    }
]);
