(function(){

    /**
     * EditStudentController
     *
     * @author Manit Banker
     */

    angular.module('portalApp').controller('EditStudentController', EditStudentController);

    EditStudentController.$inject = [
        '$scope','$log','studentService','$filter','studentModel',
        'userModel','$location', 'userService', 'orgService', 'productService',
        'navModel', '$routeParams', 'classService', 'classModel', 'easyLoginModel',
        '$uibModal', 'sessionState', 'settingsService', '$window', 'productModel',
        'orgModel'
    ];
    function EditStudentController(
        $scope, $log, studentService, $filter, studentModel,
        userModel, $location, userService, orgService, productService,
        navModel, $routeParams, classService, classModel, easyLoginModel,
        $uibModal, sessionState, settingsService, $window, productModel,
        orgModel
    ){

        var $this = this;
        $this.onInit = onInit;
        $this.getSelectedClassDetails = getSelectedClassDetails;
        $this.calculateGradeDropDownDisplay = calculateGradeDropDownDisplay;
        $this.setDeactivateButtonText = setDeactivateButtonText;
        $this.getStudent = getStudent;
        $this.setStudent = setStudent;
        $this.ErrorHandlerOnSaveStudents = ErrorHandlerOnSaveStudents;
        $this.isFutureYear = isFutureYear;
        $this.validate = validate;

        $this.handledErrorCodeList = [
            {
                code: 'error.duplicate.student.name',
                tooltip: "This student exists in your organization. " +
                    "Ensure the student name and id fields are unique, " +
                    "or add the existing student account to your class via search."
            },
            {
                code: 'error.duplicate.student.id',
                tooltip: "Something Went Wrong"
            },
            {
                code : 'Invalid password',
                tooltip : "Password must be at least 7 characters, no more than 30 characters, and must include at least one digit."
            }
        ];
        $scope.tooltipModel = {
            firstName: "",
            lastName: "",
            studentId: "",
            password: ""
        };

        $scope.selectedClassId = $routeParams.sectionId;
        $scope.isCoTeacherForCurrentClass = false;
        $scope.selectedStudentId = $routeParams.studentId;
        $scope.hideGradeDropDown = true;
        $scope.hideIncorrectGradeInDropDown = false;
        $scope.studentDefaultGrade = '';
        $scope.error = '';
        $scope.errorFlag = false;
        $scope.easyLoginAvatars = easyLoginModel.getEasyLoginAvatars();
        $scope.accountSecretArray = easyLoginModel.getEasyLoginSecrets();
        $scope.initialized = false;
        $scope.futureYear = false;
        $scope.srcTeacher = false;

        function ErrorHandlerOnSaveStudents(error){
            var duplicateStudentError, duplicateStudentTooltip;
            /** if there is an error and the error code matches handledErrorCodeList */
            if(!!error) {
                /** then select tooltip message for assignment */
                $log.debug('error.code', error.code, 'error.message', error.message);
                duplicateStudentError = $this.handledErrorCodeList.find(function (err) {
                    return err.code === error.code;
                });
                duplicateStudentTooltip = duplicateStudentError ? duplicateStudentError.tooltip : undefined;
            }
            /** assign or clear tooltips */
            $scope.tooltipModel.password = '';
            $scope.tooltipModel.firstName =
                $scope.tooltipModel.lastName =
                    $scope.tooltipModel.studentId = duplicateStudentTooltip;
            /** clear the top of page notification if there is one and there are tooltips */
            if(!!$scope.error  && ( !!$scope.tooltipModel.firstName
                ||  !!$scope.tooltipModel.firstName ||  !!$scope.tooltipModel.firstName )){
                $scope.closeAlert();
            }
        }

        $scope.$watch('student',function(currStudent,prevStudent){
            $log.debug('currStudent',JSON.stringify(currStudent));
            $scope.showYOYFeatures ? $this.ErrorHandlerOnSaveStudents() : false;
        },true);

        function validate() {
            var reg = /\d/;

            $this.isValidPassword = true;

            if ($scope.student.credentials.password.length < 7 || !reg.test($scope.student.credentials.password)) {
                var invalidPassword = $this.handledErrorCodeList.find(function (err) {
                    return err.code === 'Invalid password';
                });
                $scope.tooltipModel.password = invalidPassword.tooltip;
                $this.isValidPassword = false;
            }

            /** clear the top of page notification if there is one and there are tooltips */
            if(!!$scope.error  && ( !!$scope.tooltipModel.firstName
                ||  !!$scope.tooltipModel.firstName ||  !!$scope.tooltipModel.firstName )){
                $scope.closeAlert();
            }

            return $this.isValidPassword;
        }

        $scope.saveStudentToSection = function() {
            if (validate()) {
                $scope.initialized = false;

                studentService.updateStudentToOrg($scope.selectedClass.id, $scope.student.id, $scope.student)
                  .then(function(data) {
                      $log.debug("Student updated", data);

                      $this.setStudent(data);

                      $scope.error = "Successfully updated student information";
                      $scope.errorFlag = false;
                      $window.scrollTo(0, 0);
                  })
                  .catch(function(error) {
                      $log.debug("Error updating student", error);

                      $scope.error = "Unable to update student information. Please try again with correct values";
                      $scope.errorFlag = true;
                      $window.scrollTo(0, 0);
                      $scope.initialized = true;
                  });
            }
        };

        $scope.saveStudentToOrg = function() {
            if (validate()) {
                $scope.initialized = false;

                studentService.updateStudentToOrg($scope.selectedClass.id, $scope.student.id,$scope.student)
                  .then(function(data) {
                      $log.debug("Student updated", data);

                      $this.setStudent(data);

                      $scope.error = "Successfully updated student information";
                      $scope.errorFlag = false;
                      $window.scrollTo(0, 0);
                  })
                  .catch(function(error) {
                      $log.debug("Error updating student", error);

                      $scope.errorFlag = true;

                      if (!!error && !!error.code && error.code !== "InvalidRequest") {
                          $this.ErrorHandlerOnSaveStudents(error);
                      } else {
                          $scope.error = "Unable to update student information. Please try again with correct values";
                          $window.scrollTo(0, 0);
                      }

                      $scope.initialized = true;
                  });
            }
        };

        $scope.deactivateStudent = function(){
            var newStudent = angular.copy($scope.student);
            newStudent.active = !$scope.student.active;

            $scope.initialized = false;

            studentService.updateStudent($scope.selectedClass.id, newStudent.id, newStudent)
                .then(function(data){
                    $log.debug('Successfully deactivated the student', data);

                    $this.setStudent(data);

                    if ($scope.student.active) {
                        $scope.error = 'Student successfully activated';
                    } else {
                        $scope.error = 'Student successfully deactivated';
                    }

                    $scope.errorFlag = false;
                    $window.scrollTo(0,0);
                })
                .catch(function(error){
                    $log.debug('Error deactivating the student', error);

                    $scope.error = 'Unable to update student information';
                    $scope.errorFlag = true;
                    $window.scrollTo(0,0);
                    $scope.initialized = true;
                });
        };

        $scope.changeGrade = function(grade) {
            $scope.student.grade = grade.value;
        };

        $scope.changeEasyLoginCredentials = function(credType){
            var shapeArray;
            if(credType === 'User Icon'){
                shapeArray = $scope.easyLoginAvatars;
            }
            else{
                shapeArray = $scope.accountSecretArray;
            }
            $uibModal.open({
                templateUrl: 'resources/js/Modules/students/editStudent/easyLoginCred/edit-easy-login-cred.html',
                controller: 'EditStudentEasyLoginController',
                resolve: {
                    shapeArray: function () {
                        return shapeArray;
                    },
                    credType : function (){
                        return credType;
                    }
                }
            })
            .result.then(function (data) {
                if(data){
                    if(data.credType === 'User Icon'){
                        $scope.student.credentials.easyLoginAvatarId = data.selectedShape.id;
                        $scope.student.avatar = data.selectedShape;
                    }
                    else{
                        $scope.student.credentials.easyLoginSecret = data.selectedShape.id;
                        $scope.student.secret = data.selectedShape;
                    }
                    $scope.saveStudentToOrg();
                }
            });
        };

        $scope.closeAlert = function(){
            $scope.error = '';
            $scope.errorFlag = false;
        };

        $scope.cancel = function(){
            $location.path('/students');
        };

        function getSelectedClassDetails(){
            classService.getClassSection($scope.selectedClassId).then(function (sectionData) {
                $log.debug('Get section details of student', sectionData);
                $scope.selectedClass = sectionData;
                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }
                // Verify if the teacher is a co-Teacher
                if ((!!userModel && !!userModel.currentUser && !!userModel.currentUser.staff) &&
                (userModel.currentUser.staff.id !== sectionData.staff.primaryTeacherId)) {
                    $scope.isCoTeacherForCurrentClass = true;
                }
                $scope.grades = $scope.selectedClass.gradeArray;
                $this.getStudent();
            }, function(error){
                $log.debug('Error getting section details for student', error);
                $location.path('/students');
            });
        }

        function getStudent(){
            studentService.getStudent($scope.selectedClass.id, $scope.selectedStudentId, true)
                .then(function(data) {
                    $this.setStudent(data);
                })
                .catch(function(error) {
                    $log.debug('Error getting students in the class', error);
                    $location.path('/students');
                });
        }

        function setStudent(student) {
            $log.debug("Student data", student);

            $scope.student = student;

            if ($scope.selectedClass.easyLogin.enabled) {
                for (var i = 0; i < $scope.easyLoginAvatars.length; i++) {
                    if ($scope.student.credentials.easyLoginAvatarId === $scope.easyLoginAvatars[i].id) {
                        $scope.student.avatar = $scope.easyLoginAvatars[i];
                        break;
                    }
                }

                for (var j = 0; j < $scope.accountSecretArray.length; j++) {
                    if ($scope.student.credentials.easyLoginSecret === $scope.accountSecretArray[j].id) {
                        $scope.student.secret = $scope.accountSecretArray[j];
                        break;
                    }
                }
            }

            $scope.isStudentLTI = $scope.student && $scope.student.identifiers.source === "LTI";
            $scope.isStudentClasslink = $scope.student && $scope.student.identifiers.source && $scope.student.identifiers.source.toLowerCase() === "classlink";
            $scope.studentDefaultGrade = $scope.student.grade;

            $this.calculateGradeDropDownDisplay();
            $this.setDeactivateButtonText();

            $scope.initialized = true;

            $log.debug("Selected student", $scope.student);
        }

        function calculateGradeDropDownDisplay() {

            if ($scope.isStudentClasslink) {
                $scope.hideGradeDropDown = true;
                return;
            }

            // if only 1 item in grades
            // and student's grade is equal to that one item
            // then don't display drop down
            if ($scope.grades.length === 1) {
                if(!$scope.grades[0]) {
                    $scope.hideGradeDropDown = true;
                    return;
                }
                if ($scope.grades[0] && $scope.grades[0].value.toLowerCase() === $scope.student.grade.toLowerCase()) {
                    $scope.hideGradeDropDown = true;
                    return;
                }
            }

            // if we got here we know we are going to display the drop down
            $scope.hideGradeDropDown = false;

            // if the student's current grade is not in the selected drop down
            // then show the selected item, unselectable, in the drop down
            for (var counter = 0; counter < $scope.grades.length; counter++) {
                if ($scope.grades[counter] && $scope.grades[counter].value.toLowerCase() !== $scope.student.grade.toLowerCase()) {
                    // if we find it hide the incorrect grade item with this flag
                    $scope.hideIncorrectGradeInDropDown = true;
                    return;
                }
            }
        }

        function setDeactivateButtonText(){
            if($scope.student.active == true){
                $scope.deactivateButtonText = 'DEACTIVATE STUDENT';
            } else {
                $scope.deactivateButtonText = 'ACTIVATE STUDENT';
            }
        }

        function isFutureYear(selectedSchoolYear, currentSchoolYear) {
            return (moment(selectedSchoolYear.endDate).year() - moment(currentSchoolYear.endDate).year()) > 0;
        }

        function onInit(){
            sessionState.store('$scope',$scope);
            userService.getCurrentUserWithoutRedirect().then(function(currentUser){
                $log.debug('User signed in',currentUser);

                settingsService.setSettings(sessionState).then(function(settings) {
                    $log.debug('Settings: ', settings);
                    if(settings.showEasyLogin && !userModel.isUserLTI()) {
                        $scope.showEasyLogin = true;
                    }

                    $scope.showYOYFeatures = settings.showYOYFeatures;

                    orgService.getSchoolsWithTeacherRole().then(function(schools){
                        if ($scope.showYOYFeatures) {
                            var orgProdCookie;
                            var orgId;

                            orgProdCookie = productModel.getCookieForOrgAsTeacher();
                            if (!!orgProdCookie) {
                                orgId = orgProdCookie.orgId;
                            } else {
                                orgId = schools[0].id;
                            }

                            orgService.getSchoolYearsYOYForTeacher(orgId).then(function (years) {
                                var selectedSchoolYear;

                                if (years.length > 0) {
                                    var currentSchoolYear = orgModel.setBaseCalendarYear(years);

                                    if (!!orgProdCookie && orgProdCookie.schoolCalendarId) {
                                        selectedSchoolYear = years.find(function (schoolYear) {
                                            return schoolYear.id === orgProdCookie.schoolCalendarId;
                                        });
                                    } else {
                                        selectedSchoolYear = currentSchoolYear;
                                    }

                                    $scope.futureYear = $this.isFutureYear(selectedSchoolYear, currentSchoolYear);
                                    $this.getSelectedClassDetails();
                                }
                            });
                        } else {
                            $this.getSelectedClassDetails();
                        }
                    });
                });

                productService.getSubscriptions().then(function(subscriptions){
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                });

                productService.getEntitlement().then(function(data){
                    $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                    $scope.srcTeacher = productModel.isSRCTeacher;
                });

                navModel.showNav = true;
                navModel.helpPage = false;
            },function(error){
                $log.error('User not signed in',error);
                $location.path('/signin/staff');
            });

        }
        $this.onInit();
    }

})();