/**
 * Created by jhouser on 9/21/2015.
 *
 * Split out rosterService from dpPortal so it is easy to change the server variable
 * for different dev environments
 */

portalModule.service("productService",['$http', '$log', 'apiResponse', 'userModel', 'productModel', '$q', 'subscriptionModel', function($http, $log, apiResponse, userModel, productModel, $q, subscriptionModel) {
	return {
		loadSubscriptions: loadSubscriptions,
		loadApplicationsForTeacher: loadApplicationsForTeacher,
		launchData: launchData,
		loadAccessCodesForSubscription: loadAccessCodesForSubscription,
		loadTeachersForSubscription: loadTeachersForSubscription,
		redeemAccessCode: redeemAccessCode,
		revokeTeacherAccess: revokeTeacherAccess,
		addTeacherAccess : addTeacherAccess,
		addAllTeachers : addAllTeachers,
		loadEntitlements : loadEntitlements,
		loadSubscriptionsForOrgId : loadSubscriptionsForOrgId,
		getEntitlement : getEntitlement,
		getSubscriptions : getSubscriptions,
		launchDataByAppCode : launchDataByAppCode,
        getAlternateUrl: getAlternateUrl
	};

	function get(url) {
		var request = $http({
            method: "get",
            url: url
        });

        return  apiResponse.makeApiCall(request);
	}

	function loadSubscriptionsForOrgId(schoolId) {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/admin-for-org/" + schoolId + "/subscriptions";
		return get(url).then(function(data) {
			 var subs = [];
			 for(var i=0; i<data.length; i++) {
				 subs[i] = data[i].subscription;
				 subs[i].product = data[i].product;
				 subs[i].application = data[i].application;
			 }
			 return subs;
		 });
	}

	function loadSubscriptions() {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/subscriptions";
		return get(url).then(function (subscriptions) {

			var orgAndSubData = [];

			for (var i = 0; i < subscriptions.length; i++) {
				orgAndSubData[i] = subscriptions[i].organization;
				var subData = [];
				for (var j = 0; j < subscriptions[i].subscriptionAndProduct.length; j++) {
					if (subscriptions[i].subscriptionAndProduct[j].subscription != undefined) {
						subData[j] = subscriptions[i].subscriptionAndProduct[j].subscription;
						subData[j].product = subscriptions[i].subscriptionAndProduct[j].product;
					} else {
						subData[j] = {};
						orgAndSubData[i].district = true;
					}
					subData[j].application = subscriptions[i].subscriptionAndProduct[j].application;
				}
				orgAndSubData[i].subscription = subData;
			}

			orgAndSubData.sort(function (a, b) {
				if (a.district && b.district) {
					return 0;
				} else if (a.district && !b.district) {
					return -1;
				} else if (!a.district && b.district) {
					return 1;
				}
			});
			subscriptionModel.orgAndSubscriptions = orgAndSubData;

			return orgAndSubData;
		});
	}

	function getSubscriptions(){
		if( subscriptionModel.orgAndSubscriptions != undefined ){
			return $q.when(subscriptionModel.orgAndSubscriptions);
		}
		return loadSubscriptions();
	}

	function loadApplicationsForTeacher(schoolId) {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/entitlements/apps-for-org/" + schoolId;
		return get(url);
	}

	function loadEntitlements() {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/entitlements";
		return get(url).then(function(data){
			var apps = [];
			for(var i=0; i<data.length; i++) {
				apps[i] = data[i].org;
				apps[i].applications = data[i].applications;
			}
			apps.sort(function(a,b){
				if(a.demoAccount && b.demoAccount) {
					return 0;
				} else if(a.demoAccount && !b.demoAccount){
					return 1;
				} else if (!a.demoAcount && b.demoAccount){
					return -1;
				}
			});

			productModel.entitlementList = apps;
			productModel.setSRCTeacher();

			return apps;
		});
	}

	function getEntitlement() {
		if( productModel.entitlementList !== undefined ) {
			productModel.setSRCTeacher();
			return $q.when(productModel.entitlementList);
		}

		return loadEntitlements();
	}

	// Originally 'lti' was the only supported launch type. In order to avoid making breaking changes,
	// if no launchType is passed, default to 'lti' for launchData and launchDataByAppCode calls

	function launchData(appId, orgId, userType, isAdmin, launchType) {
		if (!launchType) {
			launchType = 'lti';
		}

		return get(PORTAL_API_ENDPOINT + '/composite/' + userType + '/' + launchType + '/' + orgId + '/' + appId + (isAdmin ? '?isAdmin=true' : ''));
	}

	function getAlternateUrl(appCode) {

		////composite/staff/{staffId}/{launch_type}/{appCode}

        var request = $http({
            method: 'get',
            url: PORTAL_API_ENDPOINT + '/composite/staff/lti/' + appCode
        });
        return apiResponse.makeApiCall(request);
    }

	function launchDataByAppCode(appCode, orgId, userType, params, launchType) {
		if (!launchType) {
			launchType = 'lti';
		}

		var request = $http({
			method: 'get',
			url: PORTAL_API_ENDPOINT + '/composite/' + userType + '/' + launchType + '/' + orgId + '/' + appCode,
			params: params,
		});
		return apiResponse.makeApiCall(request);
	}

	function loadAccessCodesForSubscription(subId) {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/subscriptions/" + subId + "/access-codes";
		return get(url);
	}


	function loadTeachersForSubscription(subId) {
		var url = PORTAL_API_ENDPOINT + "/composite/staff/subscriptions/" + subId + "/staff";
		return get(url);
	}

	function redeemAccessCode(accessCode, ucn, firstProduct, emit) {
    $log.debug("In productService.redeemAccessCode. accessCode: ", accessCode);
    var url =
      PORTAL_API_ENDPOINT + "/composite/staff/access-codes/" + accessCode;
    var params = {};
    var deferred = $q.defer();
    var event = "access-code-submitted";

    if (ucn) {
      params.ucn = ucn;
      event = "access-code-redeemed";
    }

    var request = $http({
      method: "post",
      url: url,
      params: params
    });

    apiResponse
      .makeApiCall(request)
      .then(function(response) {
        deferred.resolve(response);

        var subscription = {};
        if (!!response && response.length > 0) {
          subscription = response[0];
        } else {
          subscription = response;
        }

        if (emit && subscription && subscription.application) {
          var analyticsData = {
            product: subscription.application.applicationCode,
            productType: subscription.application.rosterSupport,
            firstProduct: firstProduct
          };

          if (ucn) {
            analyticsData.orgId = subscription.schools[0].id;
          }

          try {
            _satellite.track(event, analyticsData);
          } catch (_e) {}
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  }

	function revokeOrAddTeacher(subId, teacherId, method){
		var request = $http({
			method: method,
			url: PORTAL_API_ENDPOINT + "/composite/staff/subscriptions/" + subId +  "/staff/" + teacherId
		});

		return  apiResponse.makeApiCall(request);

	}

	function revokeTeacherAccess(subId,teacherId){
		return revokeOrAddTeacher(subId,teacherId,"delete");
		/*
		var request = $http({
            method: "delete",
            url: PORTAL_API_ENDPOINT + "/composite/staff/subscriptions/" + subId +  "/staff/" + teacherId
        });

        return  apiResponse.makeApiCall(request);*/
	}

	function addTeacherAccess(subId,teacherId) {
		return revokeOrAddTeacher(subId, teacherId, "put");
	}

	function addAllTeachers(subId){
		/*
		 PUT: composite/staff/{staffId}/subscriptions/{subId}/staff

		 StaffId - school admin Id
		 SubId - subscription id

		 As result, you will get list of TeacherSubscriptionAssociation for those, who has been added
		 */

		var request = $http({
			method: "put",
//			url: PORTAL_API_ENDPOINT + "/composite/staff/" +  staffId + "/subscriptions/" + subId +  "/staff/"
			url: PORTAL_API_ENDPOINT + "/composite/staff/subscriptions/" + subId +  "/staff"
		});

		return  apiResponse.makeApiCall(request);

	}

}]);
