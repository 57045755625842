portalModule.controller(
    'LtiErrorController',
    ['$scope', '$log', '$routeParams', 'navModel',
        function ($scope, $log, $routeParams, navModel) {
            $scope.initialized = false;

            var onInit = function () {
                $scope.errorCode = $routeParams.code;
                $scope.initialized = true;
                navModel.showFooter = true;
            };
            onInit();
        }
    ]);
