portalModule.service("calendarService",
    [
      '$http', '$q', 'apiResponse', 'subscriptionModel', 'orgModel',
      function($http, $q, apiResponse, subscriptionModel, orgModel) {

        return {
          getSchoolYearInformation: getSchoolYearInformation,
          saveSchoolYear: saveSchoolYear,
          updateSchoolYear: updateSchoolYear,
          validateOverrideChildCalendarOnParent: validateOverrideChildCalendarOnParent
        };

        function getSchoolYearInformation(organizationId) {
          //    /composite/staff/{staffId}/organization/{orgId}/school-calendars
          var request = $http({
            method: "GET",
            url:
              PORTAL_API_ENDPOINT +
              "/composite/staff/organization/" +
              organizationId +
              "/school-calendars"
          });

          var deferred = $q.defer();

          apiResponse
            .makeApiCall(request)
            .then(function(calendars) {
              // On success set the current calendar year in dumbleData
              orgModel.setCalendarsForOrg(organizationId, calendars);

              var currentCalendar = calendars.filter(function(calendar) {
                return calendar.currentCalendar;
              })[0];

              if (currentCalendar) {
                window.dumbleDataHelpers.updateSchoolYear(currentCalendar);
                window.dumbleDataHelpers.updateCurrentSchoolYear(currentCalendar);
              }

              deferred.resolve(calendars);
            })
            .catch(deferred.reject);

          return deferred.promise;
        }

        function  saveSchoolYear(calendar) {
            ///composite/staff/{staffId}/organization/{orgId}/school-calendars
            var params = {
                startDate: calendar.displayStartDate,
                endDate: calendar.displayEndDate,
                currentCalendar: calendar.currentCalendar,
                description: calendar.description,
                orgId: calendar.organizationId,
                markingPeriods: []
            };

            var request = $http({
                method: "POST",
                url: PORTAL_API_ENDPOINT + "/composite/staff/organization/" + calendar.organizationId + "/school-calendars",
                data: params
            });

            return  apiResponse.makeApiCall(request);
        }

        function validateOverrideChildCalendarOnParent(orgId) {
            /// /composite/staff/{staffId}/organization/{orgId}/validate/school-calendars
            var request = $http({
                method: "GET",
                url: PORTAL_API_ENDPOINT + "/composite/staff/organization/" + orgId + "/validate/school-calendars"
            });

            return  apiResponse.makeApiCall(request);
        }

        function updateSchoolYear(calendar) {
            //composite/staff/{staffId}/organization/{orgId}/school-calendars/{schoolCalendarId}
            var params = {
                id: calendar.id,
                startDate: calendar.displayStartDate,
                endDate: calendar.displayEndDate,
                currentCalendar: calendar.currentCalendar,
                description: calendar.description,
                orgId: calendar.organizationId,
                markingPeriods: []
            };

            var request = $http({
                method: "PUT",
                url: PORTAL_API_ENDPOINT + "/composite/staff/organization/"
                    + calendar.organizationId + "/school-calendars/" + calendar.id,
                data: params
            });

            return  apiResponse.makeApiCall(request);
        }

    }]);
