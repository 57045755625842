(function() {

    angular.module('portalApp').service('gapiService', [
        '$http', '$log', '$location', 'cookieService', '$q',
        'apiResponse', 'notificationModel', 'sharedConstants', 'settingsService',
        'classService', 'studentService', '$route', '$templateCache', 'productModel',
        '$rootScope', 'userModel', 'classModel', 'orgModel', gapiServiceFunction]);

    function gapiServiceFunction(
        $http, $log, $location, cookieService, $q,
        apiResponse, notificationModel, sharedConstants, settingsService,
        classService, studentService, $route, $templateCache, productModel,
        $rootScope, userModel, classModel, orgModel
        ) {
        var $this = this;
        $this.PORTAL_API_ENDPOINT = PORTAL_API_ENDPOINT;
        $this.PORTAL_SETTINGS_URL = '/settings';
        $this.PORTAL_GOOGLE_REDIRECT_URL = '/composite/public/google/redirect';
        $this.PORTAL_GOOGLE_TOKEN_URL = '/composite/staff/google/access-token';
        $this.GOOGLE_LOGIN_ERROR = sharedConstants.GOOGLE_LOGIN_ERROR;
        $this.gapiEnabled = false;
        $this.gapiUrl = '';
        $this.gapiTokenCookieName = 'gapiToken';
        $this.googleEntryTypeCookieName = 'userType';
        $this.googleEntryUserType = '';
        $this.accessToken = '';
        $this.gapiTokenRenewalCount = 0;
        $this.gapiScopes = [];
        $this.googleOauthUrl = undefined;
        $this.access_type = undefined;
        $this.client_id = undefined;
        $this.redirect_uri = undefined;
        $this.response_type = undefined;
        $this.gapiScopesString = undefined;
        $this.prompt = undefined;

        $this.selectedSchoolYear = {};
        $this.showYOYFeatures = '';
        // Important: discoveryDocs and studentListFields are two configs which are not passed by the backend!
        // TODO: pass all configurations in environmental variables or from the backend
        $this.discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest"];
        // $this.discoveryDocs = ["https://classroom.googleapis.com/$discovery/rest?version=v1"]
        $this.studentsListFields = 'students/courseId,' +
                                'students/userId,' +
                                'students/profile/id,' +
                                'students/profile/name,' +
                                'nextPageToken';

        $this.getUrlSearchParams = function (name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec($this.gapiUrl);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };

        $this.gapiError = function(error) {
            var notificationError;

            notificationError = {};
            $log.error('Google API Service Error on "Sign in with Google":', error);
            if (!!error && !!error.message) {
                notificationError['message'] = $this.GOOGLE_LOGIN_ERROR + '  Details: ' +  error.message;
            } else {
                notificationError['message'] = $this.GOOGLE_LOGIN_ERROR;
            }

            notificationModel.setMessage(JSON.stringify(notificationError));
        };

        $this.parseConsentUrl = function(){
            var url = $this.gapiUrl;
            if(!!url) {
                $this.gapiScopes = url.scopes;
                // $this.googleOauthUrl = undefined;
                $this.access_type = $this.getUrlSearchParams('access_type');
                $this.client_id = $this.getUrlSearchParams('client_id');
                $this.redirect_uri = $this.getUrlSearchParams('redirect_uri');
                $this.response_type = $this.getUrlSearchParams('response_type');
                $this.gapiScopesString = $this.getUrlSearchParams('scope');
                $this.prompt = $this.getUrlSearchParams('prompt');
            }
        };

        $this.getConsentUrl = function(){
            return new Promise(function(resolve) {
                apiResponse.makeApiCall($http({
                    method: "get",
                    url: $this.PORTAL_API_ENDPOINT + $this.PORTAL_GOOGLE_REDIRECT_URL,
                    params: {[$this.googleEntryTypeCookieName]: $this.googleEntryUserType}
                })).then(function (res) {
                    if (!res || !res.url) {
                        $this.gapiUrl = '';
                        $this.reset();
                        resolve('');
                    }

                    $this.gapiUrl = res.url;
                    $this.parseConsentUrl();
                    resolve(res.url);
                }).catch(function(error){
                    $this.reset();
                    $this.gapiError(error);
                    resolve('');
                });
            });
        };


        $this.getSdmClassesForGoogleTeacherPromise = function () {
                return classService.getClassesForTeacher();
        };
        $this.getSdmStudentsForGoogleTeachersSdmClassesPromise = function (sdmClass, withPasswordFlag) {
            return studentService.getStudentsInClass(sdmClass.id, false);
        };

        /** updateSdmGoogleStudentPromise
         * Update a class section (in Class Section Controller)
         * PATCH /roster/sections/{sectionId}/students/{studentId}
         * Path: sectionId, studentId
         * Request Payload: student object
         * @param course
         * @param student
         * @returns Promise studentService.updateStudent
         */
        $this.updateSdmGoogleStudentPromise = function(course, student) {
            // http://localhost:8080/dp-api/swagger-ui.html#/class-section-controller/Update%20an%20existing%20class%20section_3
            $log.debug('updateSdmGoogleStudentPromise', course, student);
            return studentService.updateStudent(course.id, student.id, student)
                .then(function(studentsList){
                    $log.debug('updateSdmGoogleStudentPromise response', studentsList);
                    return studentsList;
                    // $scope.error = 'Successfully updated student information';
            }, function(error){
                $log.debug('updateSdmGoogleStudentPromise error', error);
                // $scope.error = 'Unable to update student information. Please try again with correct values';
            });
        };

        /** upsertSdmGoogleStudentsPromise
         * API to create a class student for roster (in Class Section Controller)
         * POST /roster/sections/{sectionId}/students/import
         * Path: sectionId
         * Request Payload: list of students (list of student objects)
         * @param sdmClass
         * @param students
         * @returns Promise studentService.upsertStudents
         * upsertStudents is an 'uploadStudent' where action is "import"
         * PATCH /my/roster/sections/" + sectionId + "/students/" + action
         */
        $this.upsertSdmGoogleStudentsPromise = function(sdmClass, student) {
            var studentsToImport = [];
            studentsToImport.push(student);
            return studentService.upsertStudents(studentsToImport, sdmClass.id);
        };

        $this.updateSdmGoogleStudentsRosterPromise = function(sdmClass, students) {
            return studentService.updateGoogleStudent(students, sdmClass.id);
        };

        $this.createOrUpdateSdmGoogleStudentsPromise = function (importtype, googleCourse, studentsToBeModified, assignStudents, unassignStudents) {
            $log.debug('students', studentsToBeModified);

            var promises = [];

            if (assignStudents && assignStudents.length) {
                promises.push(studentService.assignStudents([googleCourse.sdmClass.id], $this.selectedSchoolYear.orgId, assignStudents));
            }

            if (unassignStudents && unassignStudents.length) {
                promises.push(studentService.unassignStudents([googleCourse.sdmClass.id], $this.selectedSchoolYear.orgId, unassignStudents));
            }

            return new Promise(function (resolve, reject) {
                Promise.all(promises)
                    .then(function () {
                        Promise.all(studentsToBeModified.map(function (student) {
                            delete student.credentials;
                            if (student.active && student.id !== "-1") {
                                // if they are in sdmCass then updateStudent (create in SDM)
                                return $this.updateSdmGoogleStudentsRosterPromise(googleCourse.sdmClass, student);
                            } else if (student.id === "-1") {
                                // this is a NEW students, we are uploading his information in the system
                                delete student.id;
                                return $this.upsertSdmGoogleStudentsPromise(googleCourse.sdmClass, student);
                            } else if (!student.active && importtype !== 'onboarding') {
                                student.active = false;
                                student.credentials ? delete student.credentials : false;
                                student.email ? delete student.email : false;
                                student.uuid ? delete student.uuid : false;
                                !!student.grade.value ? student.grade = student.grade.value : false;
                                return $this.updateSdmGoogleStudentsRosterPromise(googleCourse.sdmClass, student);
                            }
                        })).then(function(createOrUpdateSdmStudentPromises){
                            resolve(createOrUpdateSdmStudentPromises);
                        }).catch(reject);
                }).catch(reject);
            });
        };

        $this.assignStudent = function(classIds, studentId) {
            var promises = [];

            if (classIds && classIds.length && studentId) {
                promises.push(studentService.assignStudents(classIds, $this.selectedSchoolYear.orgId, [studentId]));
            }

            return Promise.all(promises);
        };

        $this.assignStudents = function(classId, studentIds) {
            var promises = [];

            if (studentIds && studentIds.length && classId) {
                promises.push(studentService.assignStudents([classId], $this.selectedSchoolYear.orgId, studentIds));
            }

            return Promise.all(promises);
        };

        $this.unassignStudent = function(classIds, studentId) {
            var promises = [];

            if (classIds && classIds.length && studentId) {
                promises.push(studentService.unassignStudents(classIds, $this.selectedSchoolYear.orgId, [studentId]));
            }

            return Promise.all(promises);
        };

        $this.unassignStudents = function(classId, studentIds) {
            var promises = [];

            if (studentIds && studentIds.length && classId) {
                promises.push(studentService.unassignStudents([classId], $this.selectedSchoolYear.orgId, studentIds));
            }

            return Promise.all(promises);
        };

        $this.deactivateStudent = function(student, sdmClass) {
            student.active = false;
            student.credentials ? delete student.credentials : false;
            student.email ? delete student.email : false;
            student.uuid ? delete student.uuid : false;
            !!student.grade.value ? student.grade = student.grade.value : false;
            return $this.updateSdmGoogleStudentsRosterPromise(sdmClass, student);
        };

        $this.createStudents = function(students, sdmClass, importType) {
            var deactivatePromises = [];
            var studentsToCreate = [];

            students.forEach(function(student) {
                delete student.credentials;
                delete student.id;
                if (!student.active && importType !== 'onboarding') {
                    deactivatePromises.push($this.deactivateStudent(sdmClass, student));
                } else {
                    studentsToCreate.push(student);
                }
            });

            return Promise.all([].concat(
              deactivatePromises,
              studentService.upsertStudents(studentsToCreate, sdmClass.id)));
        };

        $this.updateStudents = function(students, sdmClass, importType) {
            var deactivatePromises = [];
            var studentsToUpdate = [];

            students.forEach(function(student) {
                delete student.credentials;
                if (!student.active && importType !== 'onboarding') {
                    deactivatePromises.push($this.deactivateStudent(sdmClass, student));
                } else {
                    studentsToUpdate.push(student);
                }
            });

            return Promise.all([].concat(
              deactivatePromises,
              studentService.updateGoogleStudents(studentsToUpdate, sdmClass.id))
            );
        };

        $this.updateSdmClassForGoogleTeacher = function (course) {
            var primaryTeacherId = course.sdmClass.staff.primaryTeacherId;
            var source = course.sdmClass.identifiers.source;
            var classSection = {
                "id": course.sdmClass.id, // unique key by teacher's classes
                "highGrade": course.highGrade.value,
                "identifiers": {
                    "source": source,
                    "sourceId": course.id
                },
                "lowGrade": course.lowGrade.value,
                "nickname": course.name, // unique key by teacher's classes
                "organizationId": course.orgId,
                "staff": {
                    "primaryTeacherId": primaryTeacherId
                }
            };
            $log.debug('classSection', classSection);
            classSection.active = course.courseState.toUpperCase() === 'ACTIVE' && !!course.students && course.students.length !== 0;

            if ($this.showYOYFeatures) {
                classSection.schoolCalendarId =  $this.selectedSchoolYear.id;
            }

            return classService.updateClassSection(classSection);
        };

        $this.createSdmGoogleCoursePromise = function (course, primaryTeacherId, source) {
            var params = {
                "highGrade": course.highGrade.value,
                "identifiers": {
                    "source": source,
                    "sourceId": course.id
                },
                "lowGrade": course.lowGrade.value,
                "nickname": course.name, // course name must be unique
                "organizationId": course.orgId,
                "staff": {
                    "primaryTeacherId": primaryTeacherId
                }
            };
            params.active = course.courseState.toUpperCase() === 'ACTIVE' && !!course.students && course.students.length !== 0;

            if ($this.showYOYFeatures) {
                params.schoolCalendarId =  $this.selectedSchoolYear.id;
            }

            $log.debug('params', params);
            return classService.createClassSection(params, 'google class import');
        };

        $this.createOrUpdateSdmGoogleCoursePromise = function (course, primaryTeacherId, source) {
            if(!!course.sdmClass) {
                $log.debug('$this.updateSdmClassForGoogleTeacher', course);
                return $this.updateSdmClassForGoogleTeacher(course);
            } else {
                $log.debug('$this.createSdmGoogleCoursePromise', course, primaryTeacherId, source);
                return $this.createSdmGoogleCoursePromise(course, primaryTeacherId, source);
            }
        };

        $this.refreshGapiCourseListPromise = function(teacherId){
            var _apiCallerFunction = 'refreshGapiCourseListPromise';
            var _thisAPIRequest = this;
            return gapi.client.init({
                discoveryDocs: $this.discoveryDocs
            }).then(function () {
                $this.accessToken = $this.getGapiTokenCookie();
                gapi.client.setToken({access_token: $this.accessToken});
                return gapi.client.classroom.courses.list(teacherId ? {teacherId: teacherId} : {});
            }, function (error) {
                if((!!error && error.status === 401)
                    || (!!error && !!error.results && error.results.status === 401)
                    || (!!error && !!error.body && !!error.body.error && error.body.error.code === 401)){
                    $log.debug('renew token, gapi client error', error);
                    $this.renewToken(_apiCallerFunction, _thisAPIRequest);
                } else{
                    $log.error('gapi init error', error);
                }
            });
        };

        $this.assignGapiStudentsListPromise = function(course){
            var _apiCallerFunction = 'assignGapiStudentsListPromise';
            var _thisAPIRequest = this;
            return gapi.client.init({
                discoveryDocs: $this.discoveryDocs
            }).then(function () {
                $this.accessToken = $this.getGapiTokenCookie();
                gapi.client.setToken({access_token: $this.accessToken});
                return new Promise(function (resolve) {
                    function getStudents(pageToken) {
                        var request = gapi.client.classroom.courses.students.list({
                            courseId: course.id,
                            pageSize: 30,
                            pageToken: pageToken,
                            fields: $this.studentsListFields
                        });

                        request.execute(function (results) {
                            $log.debug('assignGapiStudentsListPromise results.students', 'page ' + pageToken, results.students);

                            if (!course.students) {
                                course.students = [];
                            }

                            if (results.students) {
                                if (results.students.length) {
                                    course.students = course.students.concat(results.students);
                                }

                                results.nextPageToken ? getStudents(results.nextPageToken) : resolve();
                            } else {
                                resolve();
                            }
                        });
                    }

                    getStudents(null);
                });
            }, function (error) {
                if((!!error && error.status === 401)
                    || (!!error && !!error.results && error.results.status === 401)
                    || (!!error && !!error.body && !!error.body.error && error.body.error.code === 401)){
                    $log.debug('renew token, gapi client error', error);
                    $this.renewToken(_apiCallerFunction, _thisAPIRequest, course);
                } else{
                    $log.error('gapi init error', error);
                }
            });
        };

        $this.refreshGapiCourseTeachersListPromise = function(googleCourse){
            var _apiCallerFunction = 'refreshGapiCourseTeachersListPromise';
            var _thisAPIRequest = this;
            return gapi.client.init({
                discoveryDocs: $this.discoveryDocs
            }).then(function () {
                $this.accessToken = $this.getGapiTokenCookie();
                gapi.client.setToken({access_token: $this.accessToken});
                return gapi.client.classroom.courses.teachers.list({'courseId': googleCourse.id});
            }, function (error) {
                if((!!error && error.status === 401)
                    || (!!error && !!error.results && error.results.status === 401)
                    || (!!error && !!error.body && !!error.body.error && error.body.error.code === 401)){
                    $log.debug('renew token, gapi client error', error);
                    $this.renewToken(_apiCallerFunction, _thisAPIRequest, googleCourse);
                } else{
                    $log.error('gapi init error', error);
                }
            });
        };

        $this.renewToken = function(apiCallerFunction, apiRequest, apiRequestParams){
            $this.gapiTokenRenewalCount++;
            $http({
                method: "get",
                url: $this.PORTAL_API_ENDPOINT + $this.PORTAL_GOOGLE_TOKEN_URL
            }).then(function(newToken){
                if(!!newToken.data && !!newToken.data.access_token) {
                    $this.accessToken = newToken.data.access_token;
                    $this.setGapiTokenCookie($this.accessToken);
                }
                if (!!apiRequest) {
                    if(typeof apiRequest === 'function'){
                        apiRequestParams ? apiRequest(apiRequestParams) : apiRequest();
                    } else {
                        apiRequestParams ? apiRequest[apiCallerFunction](apiRequestParams) : apiRequest[apiCallerFunction]();
                    }
                }
            }, function(error){
                $log.debug('renew token failure error:', error);
            });
        };

        $this.setGapiTokenCookie = function(token){
            cookieService.removeCookie($this.gapiTokenCookieName);
            if(!token) {
                cookieService.saveCookie($this.gapiTokenCookieName, $this.accessToken);
            } else {
                cookieService.saveCookie($this.gapiTokenCookieName, token);
            }
        };

        $this.getGapiTokenCookie = function(){
            return cookieService.getCookie($this.gapiTokenCookieName);
        };

        $this.setGoogleUserCookie = function(){
            var cookieValue = '';
            if($location.path().indexOf('/signin/staff') > -1){
                cookieValue = "staff";
                $this.googleEntryUserType = 'staff';
            } else if ($location.path().indexOf('/createaccount') > -1) {
                cookieValue = "staff";
                $this.googleEntryUserType = 'staff';
            } else if($location.path().indexOf('/signin') > -1
                || $location.path().indexOf('classpasscode') > -1 ){
                cookieValue = "student";
                $this.googleEntryUserType = 'student';
            }

            cookieService.saveCookie($this.googleEntryTypeCookieName, cookieValue);
        };

        $this.getGoogleUserCookie = function(){
            return cookieService.getCookie($this.googleEntryTypeCookieName);
        };

        $this.disconnectGapi = function(){
            // TODO: investigate implementation of GoogleAuth.disconnect() and/or GoogleAuth.signOut();
            // TODO: if logged out of google, SDM flow should disconnect too. user cannot be a manual user.
            // TODO: revisit stateful service with the $scope.gapiEnable, $scope.gapiUrl, $scope.client_id
            try {
                $this.parseConsentUrl();
                if (!!gapi && !!gapi.auth2 && !!$this.client_id) {
                    var GoogleAuth = gapi.auth2.init({
                        client_id: $this.client_id
                    });
                    GoogleAuth.signOut();
                    // GoogleAuth.disconnect();
                }
            } catch (e) {
                // fail gapi check silently
            }
        };

        $this.reset = function(){
            // deletes the cookie
            cookieService.removeCookie($this.gapiTokenCookieName, { path: '/' });
            $log.debug("'Google User' reset.");
        };

        $this.enableGoogleLogin = function() {
          return new Promise(function(resolve) {
            settingsService
              .getSettings()
              .then(function(settings) {
                $log.debug("Settings: ", settings);
                if (!!settings.googleLoginEnabled) {
                  $this.gapiEnabled = true;
                  $this.setGoogleUserCookie();
                  resolve(true);
                } else {
                  $this.gapiEnabled = false;
                  $this.reset();
                  resolve(false);
                }
              })
              .catch(function(error) {
                $this.gapiError(error);
                $this.reset();
                resolve(false);
              });
          });
        };

        $this.isGoogleUser = function (userModel) {
            var isGoogleUser = false;
            // if enableGoogleLogin has not been injected in the
            // controller calling it gapiService.isGoogleUser(userModel)
            // returns false (unless it's Google User sessionType)
            try {
                if (!!userModel
                    && ($this.getGoogleUserCookie() === 'staff'
                        || $this.getGoogleUserCookie() === 'student')) {
                    isGoogleUser = userModel.isGoogleUser();
                    if (isGoogleUser) {
                        $log.debug('Is Google User');
                        var currentToken = $this.getGapiTokenCookie();
                        if(!!currentToken && currentToken.indexOf('DISC') === 0){
                            $this.renewToken();
                        } else {
                            $this.setGapiTokenCookie(userModel.currentUser.sessionContext.sessionToken);
                        }
                    } else {
                        $log.debug('Is not Google User');
                    }
                } else {
                    $log.debug('Is not Google User');
                }
            } catch (error) {
                error.message = 'isGoogleUser error: ' + error.message;
                $this.gapiError(error);
            }
            return isGoogleUser;
        };

        $this.refreshPage = function(targetPage, selectedClass){
            switch (targetPage) {
                case 'onboarding':
                    $location.path('/students/google/import');
                    break;
                case 'dashboard':
                    $location.path('/products');
                    break;
                case 'manage access':
                    $location.path('/students');
                    break;
                case 'students':
                    if (selectedClass) {
                        // the next class data could be a GC course with attached sdmClass data, or just an SDM class
                        var nextClass = {
                            orgId: selectedClass.orgId
                              ? selectedClass.orgId
                              : selectedClass.organizationId,
                            id: selectedClass.sdmClass && selectedClass.sdmClass.id
                              ? selectedClass.sdmClass.id
                              : selectedClass.id,
                            schoolCalendarId: selectedClass.sdmClass && selectedClass.sdmClass.schoolCalendarId
                              ? selectedClass.sdmClass.schoolCalendarId
                              : selectedClass.schoolCalendarId
                        };

                        if (nextClass.orgId && nextClass.id && nextClass.schoolCalendarId) {
                            productModel.setCookieForOrgAsTeacher(nextClass.orgId, nextClass.id, nextClass.schoolCalendarId);
                            userModel.currentOrg = classModel.getClassAssociation(
                              orgModel.organizationList,
                              selectedClass.sdmClass ? selectedClass.sdmClass : selectedClass);
                        }
                    }

                    $location.path().indexOf('students') > -1 ? $route.reload() : $location.path('/students');
                    break;
                default:
                    var currentPageTemplate = $route.current.templateUrl;
                    $templateCache.remove(currentPageTemplate);
                    $route.reload();
                    break;
            }

            if (!$rootScope.$$phase) {
                $rootScope.$apply();
            }
        };

        $this.initGapi = function (resolve,reject) {
            $log.debug('initialized gapi');
            gapi.load('client:auth2', {
                callback: function () {
                    // Handle gapi.client initialization.
                    resolve();
                    $log.debug('gapi.client initialized!');
                },
                onerror: function () {
                    // Handle loading error.
                    reject();
                    $log.error('gapi.client failed to load!');
                },
                timeout: 5000, // 5 seconds.
                ontimeout: function () {
                    // Handle timeout.
                    $log.error('gapi.client could not load in a timely manner!');
                }
            });
        };
    }

})();
