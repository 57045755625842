portalModule.controller("messageModalController",[
    '$scope','$uibModalInstance','$log',
    function($scope,$uibModalInstance, $log){
        $log.debug("messageModalController ");

        $scope.closePopup = function(response){
            $uibModalInstance.close(response);
        };

        $scope.continueSave = function(){
            $scope.closePopup('CONTINUE');
        };
    }]);