/**
 * Created by jhouser on 9/18/2015.
 */


portalModule.service("studentModel", ['$filter',function($filter) {

    var dataObject = {
        copyStudent : copyStudent,
        // contains the student list based on the selected class
        // default it to empty array
        studentList : [],
        filterActiveStudents : filterActiveStudents,
        replaceStudent : replaceStudent,
        reset : reset
    };


    return dataObject;

    // copied from EditStudentPopup.js
    // used to duplicate a student record before sending it to the server for mods
    // just in case the server fails; the UI display is not also ruined
    function copyStudent(oldStudent){
        var newStudent = {};

        newStudent.active = oldStudent.active;
        /* we removed birthday from UI; so no need to send a dead object to the remote service when updating student
         if(oldStudent.birthdayDisplay){
         newStudent.dob = {};
         newStudent.dob.monthOfYear = $filter('date')(oldStudent.birthdayDisplay, 'M') ;
         newStudent.dob.dayOfMonth = $filter('date')(oldStudent.birthdayDisplay, 'd') ;
         newStudent.dob.year = $filter('date')(oldStudent.birthdayDisplay, 'yyyy') ;
         }
         */

        newStudent.credentials = {};
        newStudent.credentials.password = oldStudent.credentials.password ;
        newStudent.credentials.realm = oldStudent.credentials.realm;
        newStudent.credentials.username = oldStudent.credentials.username;

        newStudent.firstName = oldStudent.firstName;
        // Is gender still part of student object?  Remove this since it is no longer part of UI
//        newStudent.gender = oldStudent.gender;
        newStudent.grade = oldStudent.grade;
        newStudent.id = oldStudent.id;

        newStudent.lastName = oldStudent.lastName;
        newStudent.studentId = oldStudent.studentId;

        return newStudent;
    }



    function filterActiveStudents(activeState){

        return $filter('filter')(dataObject.studentList, function(value, index, array){
            return (value.active == activeState);
        });
    }

    // encapsulated from EditStudentPopup.js
    // since the functionality was to be used in both the edit student popup and the entitlements home (/students)
    function replaceStudent(student){
        for (var index = 0; index < dataObject.studentList.length; index++){
            if(dataObject.studentList[index].id == student.id){
                dataObject.studentList[index] = student;
                break;
            }
        }
    }



    function reset(){
        dataObject.studentList = [];
    }

}]);
