portalModule.service("classService",[
	'$http', '$log','apiResponse', 'userService', 'classModel', '$q',
	function($http,$log, apiResponse, userService, classModel, $q){

	return{
		createClassSection: createClassSection,
		getClassesForTeacher: getClassesForTeacher,
		updateClassSection: updateClassSection,
		getClassSection: getClassSection,
		httpAction : httpAction,
		getClassPasscode : getClassPasscode,
		updateClassPasscode : updateClassPasscode,
		getSchoolEasyLoginCred: getSchoolEasyLoginCred,
		getEasyLoginStatus: getEasyLoginStatus,
		loadClassSectionsForACalendar: loadClassSectionsForACalendar
	};
	
	function httpAction(method, uri, requestData, params, preprocessor) {
		var promise  = userService.ensureIsStaffMember().then(function(currentUser) {
			var request = $http({
				method: method,
				url: uri,
				data: requestData,
				params: params
	        });
			return apiResponse.makeApiCall(request).then(function(responseData) {
				$log.debug("Got response for ", method, " ", uri, " responseData: ", responseData);
				return preprocessor ? preprocessor(responseData) : responseData;
			});
		}, function(error) {
			$q.reject(error);
			return error;
		});
		return promise;
	}

	function loadClassSectionsForACalendar(selectedSchoolYear, includeStudents) {
		$log.debug("Retrieving class list");
		var params = {
			includePrimaryTeacher: true,
			includeTeachers: true,
			schoolCalendarId: selectedSchoolYear.id
		};

		if (!!includeStudents) {
			params.includeStudents = true;
		}

		var request = $http({
			method: "GET",
			url: PORTAL_API_ENDPOINT + "/composite/staff/roster/sections",
			params: params
		});

		return apiResponse.makeApiCall(request);
	}

	function createClassSection(classSection, method) {
    /** createClassSection
     * @param classSection
     * @returns {*}
     * CREATE Creates a class section (in Teacher Composite Controller)
     * POST /composite/staff/{staffId}/roster/sections
     * Request Payload: section object
     */

    var deferred = $q.defer();
    var createMethod = !!method ? method : 'manual';

    httpAction(
      "post",
      PORTAL_API_ENDPOINT + "/composite/staff/roster/sections",
      classSection
    )
      .then(function(response) {
        deferred.resolve(response);

        try {
          _satellite.track("class-created", {
            classID: response.id,
            minGrade: response.lowGrade,
            maxGrade: response.highGrade,
            createMethod: createMethod,
            schoolYear: response.schoolYear,
            orgID: response.organizationId
          });
        } catch (_e) {}
      })
      .catch(deferred.reject);

    return deferred.promise;
  }
	
	function getClassesForTeacher(schoolYear) {
		return userService.ensureIsStaffMember().then(function(currentUser) {
		    $log.debug("Retrieving class list");
		    var params = {};
			if(schoolYear){
			    params.startYear = schoolYear;
			}

			return httpAction("get", PORTAL_API_ENDPOINT + "/composite/staff/roster/sections", undefined, params).then(function(data){
			    data.sort(function(a,b){
			        return a.organizationId - b.organizationId;
				});
				classModel.classList = data;
                return data;
			});
		});
	}

	function updateClassSection(classSection) {
		/** updateClassSection
		 * @param classSection
		 * @returns {*}
		 * API Update a class section as Staff (in Teacher Composite Controller)
		 * PUT/composite/staff/{staffId}/roster/sections/{sectionId}
		 * Path: sectionId
		 * Request Payload: classSection object
		 */
		return httpAction("put", PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/"+classSection.id, classSection);
	}
	
	function getClassSection(sectionId) {
		return httpAction("get", PORTAL_API_ENDPOINT + "/composite/staff/roster/sections/"+sectionId);
	}
	
	function getClassPasscode(orgId){
	    return httpAction('get', PORTAL_API_ENDPOINT + '/composite/staff/classroom/organization/' + orgId);
	}
	
	function updateClassPasscode(updatedPasscode){
	    return httpAction('put', PORTAL_API_ENDPOINT + '/composite/staff/classroom', updatedPasscode);
	}
	
	function getSchoolEasyLoginCred(orgId) {
	    return httpAction('get', PORTAL_API_ENDPOINT + '/composite/staff/organizations/' + orgId + '/easylogin');
	}
	
	function getEasyLoginStatus(orgId) {
        return httpAction('get', PORTAL_API_ENDPOINT + '/composite/staff/organizations/' + orgId + '/easylogin/status');
    }
	
}]);
