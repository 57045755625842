portalModule
    .controller('addStudentsHomeController', [
        '$scope', '$log', '$location', '$routeParams', 'navModel',
        'userService', 'userModel', 'classService',
        function ($scope, $log, $location, $routeParams, navModel,
                  userService, userModel, classService) {
            // TODO : api call timeout will turn off spinner and add notification message

            var $this = this;

            navModel.showNav = true;
            navModel.helpPage = false;
            $scope.sectionName = '';
            $this.onInit = onInit;
            $scope.spinner = true;
            $scope.orgId = $routeParams.orgId;
            $scope.sectionId = $routeParams.sectionId;

            $scope.backToManageRosters = function () {
                $location.path('/students');
            };

            function onInit() {
                $log.debug("In addStudentHome.controller.onInit");
                userService.getCurrentUser()
                    .then(function (user) {
                        $log.debug('getCurrentUser', user);
                        if (!!userModel && !!userModel.currentUser && !!userModel.currentUser.staff) {
                            $this.currentLoggedInUser = userModel.currentUser.staff.id;
                        }
                        classService.getClassSection($routeParams.sectionId)
                            .then(function (section) {
                                $log.debug('getClassSection', section);
                                $scope.sectionName = section.displayName;
                                $scope.spinner = false;
                            });
                    });

            }

            $this.onInit();

        }
    ]);