portalModule.service("studentPortalAppService", [
  "$http",
  "$log",
  "$q",
  "apiResponse",
  function($http, $log, $q, apiResponse) {
    return {
      getSchoolInfo: getSchoolInfo,
      getAppsforStudent: getAppsforStudent,
      getClassSections: getClassSections
    };

    function getSchoolInfo() {
      var request = $http({
        method: "get",
        url: PORTAL_API_ENDPOINT + "/composite/student/schools"
      });

      return apiResponse.makeApiCall(request);
    }

    function getAppsforStudent(orgId) {
      var request = $http({
        method: "get",
        url:
          PORTAL_API_ENDPOINT + "/composite/student/schools/" + orgId + "/apps"
      });

      return apiResponse.makeApiCall(request);
    }

    function getClassSections() {
      var request = $http({
        method: "get",
        url: PORTAL_API_ENDPOINT + "/composite/student/roster/sections"
      });

      var deferred = $q.defer();

      return apiResponse
        .makeApiCall(request)
        .then(function(classSections) {
          window.dumbleDataHelpers.updateStudentClassData(classSections);
          deferred.resolve(classSections);
        })
        .catch(deferred.reject);
    }
  }
]);
