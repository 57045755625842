/**
 * Created by jhouser on 9/18/2015.
 */


portalModule.service("classModel",['$filter', function($filter) {

    var dataObject = {
        // contains the class list for teachers
        // default it to blank
        reset : reset,
        getClassAssociation : getClassAssociation,
        calculateGradeRangeString : calculateGradeRangeString,
        calculateGradeRangeArray:calculateGradeRangeArray,
        gradesArray : [],
        showElPref: undefined
    };

    dataObject.gradesArray = [
        {"value": "pk", "label": "Pre-Kindergarten", "sort": 1, "shortLabel":"pk"},
        {"value": "k", "label": "Kindergarten", "sort": 2, "shortLabel":"k"},
        {"value": "1", "label": "Grade 1", "sort": 3, "shortLabel":"1"},
        {"value": "2", "label": "Grade 2", "sort": 4, "shortLabel":"2"},
        {"value": "3", "label": "Grade 3", "sort": 5, "shortLabel":"3"},
        {"value": "4", "label": "Grade 4", "sort": 6, "shortLabel":"4"},
        {"value": "5", "label": "Grade 5", "sort": 7, "shortLabel":"5"},
        {"value": "6", "label": "Grade 6", "sort": 8, "shortLabel":"6"},
        {"value": "7", "label": "Grade 7", "sort": 9, "shortLabel":"7"},
        {"value": "8", "label": "Grade 8", "sort": 10, "shortLabel":"8"},
        {"value": "9", "label": "Grade 9", "sort": 11, "shortLabel":"9"},
        {"value": "10", "label": "Grade 10", "sort": 12, "shortLabel":"10"},
        {"value": "11", "label": "Grade 11", "sort": 13, "shortLabel":"11"},
        {"value": "12", "label": "Grade 12", "sort": 14, "shortLabel":"12"}
    ];

    return dataObject;

    function reset(){
        delete dataObject.classList;
        dataObject.adminClassList = [];
        delete dataObject.selectedClassId;
        dataObject.showElPref = undefined;
    }


    // function to determine the grade range text; used for display purposes on Class List Pages
    // Essentially, a loop from low grade to high grade
    function calculateGradeRangeString(section){
        var result ='';
        if(!section){
            return result;
        }

        var startIndex = -1;
        var endIndex = -1;
        for(var i=0;i<dataObject.gradesArray.length;i++)
        {
        	if(section.lowGrade == dataObject.gradesArray[i].value.toLowerCase())
        	{
        		startIndex = i;
        	}
        	if(section.highGrade == dataObject.gradesArray[i].value.toLowerCase())
        	{
        		endIndex = i;
        	}
            // this is a quick check to prevent the loop from looping once we found both indexes
            if((startIndex != -1) && (endIndex != -1)){
                break;
            }
        }
        for(var counter=startIndex; counter <= endIndex; counter++)
        {
        	result += dataObject.gradesArray[counter].shortLabel + ",";
        }

        // remove last comma
        result = result.substr(0,result.length-1);
        return result;

    }

    // function to determine the grade range array; used for grade selection drop down purposes on Student Edit / Create Pages
    function calculateGradeRangeArray(section){
        var result =[];

        if(!section){
            return result;
        }

        var startIndex = -1;
        var endIndex = -1;
        for(var i=0;i<dataObject.gradesArray.length;i++)
        {
        	if(section.lowGrade && section.lowGrade.toLowerCase() == dataObject.gradesArray[i].value.toLowerCase())
        	{
        		startIndex = i;
        	}
        	if(section.highGrade && section.highGrade.toLowerCase() == dataObject.gradesArray[i].value.toLowerCase())
        	{
        		endIndex = i;
        	}
            // this is a quick check to prevent the loop from looping once we found both indexes
            if((startIndex != -1) && (endIndex != -1)){
                break;
            }
        }
        for(var counter=startIndex; counter <= endIndex; counter++)
        {
        	result.push(dataObject.gradesArray[counter]);
        }

        return result;

    }


    function getClassAssociation(organizationList, selectedClass){
        // default schoolName result to empty string
        var result  = "";

        // if the teacher has no classes; the selectedClass will be undefined and this will prevent an error from being thrown
        if(!selectedClass){
            return '';
        }

        for(var i=0;i<organizationList.length;i++)
        {
            if(organizationList[i].id == selectedClass.organizationId)
            {
                result  = organizationList[i];
                // if we find the school associated with the selected class; then break out of the loop
                break;
            }
        }
        // is the result better returned in the
        return result;
    }
    
}]);
