portalModule.service("accountService",['$http','$log','$q','apiResponse',function($http,$log,$q,apiResponse){
	return{
		registerUser : registerUser
	};
	
	function registerUser(newUser){
		 var request = $http({
	    	    method: 'POST',
	    	    url: PORTAL_API_ENDPOINT +"/register",
	    	    data: newUser
	    	});

		 return  apiResponse.makeApiCall(request);
	}
}]);