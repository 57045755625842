portalModule.controller('googleImportNoClassMessageModalController', [
    '$scope', '$uibModalInstance', '$uibModalStack', '$log',

    function (
        $scope, $uibModalInstance, $uibModalStack, $log
    ) {
        $scope.spinner = false;

        $scope.$on('$locationChangeStart', handleLocationChange);
        /** handleLocationChange()
         * Dismisses all modals on location change start,
         * such as when the back button is pressed.
         */
        function handleLocationChange() {
            $uibModalStack.dismissAll();
        }

        $scope.close = function (msg) {
            $log.debug('modal close');
            $uibModalInstance.close({
                message: msg
            });
            $uibModalStack.dismissAll('close_no_student_for_class');
        };

    }
]);
