portalModule.controller("googleAssignGradeLevelsModal",
    ['$scope', '$uibModalInstance', '$log', 'studentService', 'studentModel',
        '$window', 'sharedConstants', 'userModel', '$location', '$uibModalStack',
        'classModel', 'googleAssignMultipleGradesModal', 'selectedClassesArray', 'currentClass', 'currentClassIndex',
        '$filter', 'nextClass', 'nextClassIndex',
        function ($scope, $uibModalInstance, $log, studentService, studentModel,
                  $window, sharedConstants, userModel, $location, $uibModalStack,
                  classModel, googleAssignMultipleGradesModal, selectedClassesArray, currentClass, currentClassIndex,
                  $filter, nextClass, nextClassIndex) {

            var $this = this;
            $scope.studentsUploaded = false;
            $scope.errorMessage = "";
            $scope.duplicateErrorMessage = "";
            $scope.disableUpload = false;
            $scope.loader = {loading: false};
            $scope.editMode = true;
            $scope.grades = currentClass.gradeArray;
            $scope.gradelevels = classModel.gradesArray;
            $scope.confirmCancel = false;
            $scope.disableConfirm = false;

            $scope.googleAssignMultipleGradesModal = googleAssignMultipleGradesModal;
            $scope.selectedClassesArray = selectedClassesArray;
            $scope.currentClass = currentClass;
            $scope.currentClassIndex = currentClassIndex;
            $scope.nextClass = selectedClassesArray[currentClassIndex + 1];
            $scope.nextClassIndex = selectedClassesArray[currentClassIndex + 1] ? currentClassIndex + 1 : undefined;
            $this.onInit = onInit;
            $this.setGradeRange = setGradeRange;
            $this.transformGoogleStudents = transformGoogleStudents;

            $scope.$on('$locationChangeStart', handleLocationChange);
            /** handleLocationChange()
             * Dismisses all modals on location change start,
             * such as when the back button is pressed.
             */
            function handleLocationChange() {
                $uibModalStack.dismissAll();
            }

            $scope.cancel = function (message) {
                if (!!$scope.currentClass) {
                    $scope.currentClass = undefined;
                }
                $uibModalInstance.close({
                    message: message,
                    selected: currentClass,
                    modalCounter: $scope.modalCounter
                });
            };

            $scope.save = function (currentClass) {
                var range = $this.setGradeRange($scope.studentList);
                currentClass.lowGrade = range.lowGrade;
                currentClass.highGrade = range.highGrade;

                currentClass.students = $scope.studentList;
                if (!!currentClass.students && currentClass.students.length > 0) {
                    $uibModalInstance.close({
                        message: 'save',
                        selected: currentClass,
                        modalCounter: $scope.modalCounter
                    });
                }
            };

            $scope.importSelected = function (currentClass) {
                var range = $this.setGradeRange($scope.studentList);
                currentClass.lowGrade = range.lowGrade;
                currentClass.highGrade = range.highGrade;

                currentClass.students = $scope.studentList;
                if (!!currentClass.students && currentClass.students.length > 0) {
                    $uibModalInstance.close({
                        message: 'importSelected',
                        selected: currentClass,
                        modalCounter: $scope.modalCounter
                    });
                }
            };

            function transformGoogleStudents (students) {
                students.map(function (student) {
                    $log.debug('$scope.selection student', student);
                    student.studentId = student.userId;
                    student.firstName = student.profile.name.givenName;
                    student.lastName = student.profile.name.familyName;
                    /** Name sanitization deprecated so allowed Google NAme Characters can be sent to the BE */
                    // student.firstName = $filter('sanitizeNameStr')(student.profile.name.givenName);
                    // student.lastName = $filter('sanitizeNameStr')(student.profile.name.familyName);
                    student.grade = '';
                    return student;
                });
                return students;
            }


            function setGradeRange(students) {
                var tempArray = [];
                students.forEach(function (student) {
                    tempArray.push(student.grade);
                });
                tempArray.sort(function (a, b) {
                    return a.sort - b.sort;
                });
                var lowest = tempArray[0];
                var highest = tempArray[tempArray.length - 1];

                return {
                    lowGrade: lowest,
                    highGrade: highest
                };
            }

            function onInit() {
                $scope.studentList = $this.transformGoogleStudents(currentClass.students);
            }

            $this.onInit();
        }]);