portalModule.service('classlinkService',
    ['$http', '$q', '$log', 'apiResponse', 'globalConfigModel', 'classlinkDataModel',
        function ($http, $q, $log, apiResponse, globalConfigModel, classlinkDataModel) {

            return {
                clGetInfo: clGetInfo,
                clSaveKey: clSaveKey,
                clChangeKey: clChangeKey
            };

            function clGetInfo(orgId) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/extconfig/classlink/organization/' + orgId;
                var request = $http({
                    method: 'get',
                    url: url,
                    cache: false
                });
                return apiResponse.makeApiCall(request).then(function (data) {
                    classlinkDataModel.reset();
                    classlinkDataModel.updateDataModel(data);
                    return data;
                });
            }

            function clSaveKey(orgId, clKey, clSecret, clUrl) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/extconfig/classlink/organization/' + orgId;
                var request = $http({
                    method: 'POST',
                    url: url,
                    data: {
                        orgId: orgId,
                        orgKey: clKey,
                        orgSecret: clSecret,
                        orgUrl: clUrl,
                        orgSourceId: 'NotUsed'
                    }
                });
                return apiResponse.makeApiCall(request);
            }

            function clChangeKey(id, orgId, clKey, clSecret, clUrl) {
                var url = globalConfigModel.PORTAL_API_ENDPOINT + '/composite/extconfig/classlink/organization/' + orgId;
                var request = $http({
                    method: 'PUT',
                    url: url,
                    data: {
                        id: id,
                        orgId: orgId,
                        orgKey: clKey,
                        orgSecret: clSecret,
                        orgUrl: clUrl,
                        // param required but not used, will be removed
                        orgSourceId: 'NotUsed'
                    }
                });
                return apiResponse.makeApiCall(request);
            }
        }
    ]
);