portalModule.component('gradeSettings', {
    bindings: {
        allowRange: '<?',
        options: '<gradeOptions'
    },
    require: {
        ngModel: 'ngModel'
    },
    templateUrl: 'resources/js/Modules/lti/gradeSetting/gradeSettingComponent.html',
    controller: [
        '$scope',
        function ($scope) {
            var ctrl = this;
            ctrl.values = {
                low: null,
                high: null
            };

            ctrl.isRange = false;

            ctrl.$onInit = function () {
                ctrl.ngModel.$render = function () {
                    angular.extend(ctrl.values, ctrl.ngModel.$viewValue);
                };
            };

            ctrl.$onChanges = function (changes) {
                if (changes.options) {
                    ctrl.lastGrade = ctrl.options[ctrl.options.length - 1];
                }
            };

            $scope.$watch(
                '$ctrl.values.low',
                function (value) {
                    if (!ctrl.isRange || (ctrl.values.high && value.sort > ctrl.values.high.sort)) {
                        ctrl.values.high = value;
                    }
                    if (value && value.sort === ctrl.lastGrade.sort) {
                        ctrl.isRange = false;
                    }
                    ctrl.ngModel.$setViewValue(ctrl.values);
                    ctrl.ngModel.$render();
                },
                true
            );

            $scope.$watch(
                '$ctrl.values.high',
                function (value) {
                    ctrl.ngModel.$setViewValue(ctrl.values);
                    ctrl.ngModel.$render();
                },
                true
            );

            $scope.$watch(
                '$ctrl.isRange',
                function (isRange) {
                    if (!isRange) {
                        ctrl.values.high = ctrl.values.low;
                    }
                }
            );
        }
    ],
    controllerAs: '$ctrl'
});
