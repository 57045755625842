portalModule.controller("SchoolCalendarController", [
    '$scope', '$log', '$location', 'productService', 'orgService',
    'navModel', '$uibModal', '$timeout', 'notificationModel', 'calendarService',
    '$route', '$templateCache', 'userService', 'notificationsService', 'sharedConstants',
    '$uibModalStack', 'orgModel', 'configService', 'subscriptionModel', 'userModel',
    'settingsService',
    function (
        $scope, $log, $location, productService, orgService,
        navModel, $uibModal, $timeout, notificationModel, calendarService,
        $route, $templateCache, userService, notificationsService, sharedConstants,
        $uibModalStack, orgModel, configService, subscriptionModel, userModel,
        settingsService
    ) {

        $log.debug("In school Calendar Controller init");
        var $this = this;
        $this.onInit = onInit;
        $scope.errorRetrievingData = '';
        $scope.calendarInitialized = false;
        $scope.showDownloadAccessCodeButton = false;
        $scope.showLtiInfo = false;
        $scope.hideDropDown = true;
        $scope.showSiteAccessCode = false;
        $scope.showYOYFeatures = false;
        $scope.initialized = false;
        $scope.isReactGlobalNav = configService.getCurrentConfigOption('isReactGlobalNav');
        $scope.useDistrictCalendarYearSettings = configService.getCurrentConfigOption('useDistrictCalendarYearSettings');

        $this.loadCalendarInformation = loadCalendarInformation;
        $this.validationsBeforeSave = validationsBeforeSave;
        $this.sortCalendarArray = sortCalendarArray;
        $this.saveApiCall = saveApiCall;
        $this.errorNotification = errorNotification;
        $this.disableSchoolCalendarIfUsingParent = disableSchoolCalendarIfUsingParent;
        $this.reCheckOverrideFlagAfterValidations = reCheckOverrideFlagAfterValidations;
        $this.checkEndDate = checkEndDate;
        $this.templateUrl = 'resources/js/Modules/schoolCalendar/messageModal/showMessageModal.html';
        $scope.schoolYearInformationArray = [];
        $scope.setEditMode = setEditMode;
        $scope.addSchoolYear = addSchoolYear;
        $scope.saveSchoolYear = saveSchoolYear;
        $scope.enableEdit = enableEdit;
        $scope.cancelEdit = cancelEdit;
        $scope.enableControl = enableControl;
        $scope.displayOverrideSchoolYearWarning = displayOverrideSchoolYearWarning;
        $scope.disableEdits = false;
        $scope.fromDate = {
            opened: false
        };

        $scope.$on('setInitialized', function($event, initialized) {
            $scope.initialized = initialized;
        });

        $scope.fromDateOpen = function (calendar) {
            $scope.fromDate.opened = true;

            // this code will reset the calendar value, if it has been cleared by the date picker clear button event
            if (!calendar.startDate) {
                calendar.startDate = $this.startDate;
                calendar.displayStartDate = $this.displayStartDate;
            }
        };

        $scope.fromDateChanged = function(calendar) {
            $this.checkEndDate(calendar);
        };

        $scope.toDate = {
            opened: false
        };

        $scope.toDateOpen = function (calendar) {
            var startDate = moment(calendar.startDate || $this.startDate);
            var targetYear = startDate.year() + 1;

            // set the min and max date's allowed for the end date to be any day in the target year
            var minDate = moment().year(targetYear).month(0).date(1);
            var maxDate = moment().year(targetYear).month(11).date(31);

            // ensure minDate is not in the past
            var today = moment();
            if (moment(minDate).isBefore(today)) {
                minDate = today;
            }

            $scope.toDateOptions.minDate = minDate.toDate();
            $scope.toDateOptions.maxDate = maxDate.toDate();

            $scope.toDate.opened = true;

            $this.checkEndDate(calendar);

            // this code will reset the calendar value, if it has been cleared by the date picker clear button event
            if (!calendar.endDate && calendar.mode !== 'add') {
                calendar.endDate = $this.endDate;
                calendar.displayEndDate = $this.displayEndDate;
            } else if (!calendar.endDate && calendar.mode === 'add') {
                calendar.endDate = startDate.add(1, 'years').toDate();
            }
        };

        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            showWeeks: false
        };

        $scope.toDateOptions = {
            formatYear: 'yy',
            startingDay: 1,
            showWeeks: false
        };

        $scope.$on('$locationChangeStart', handleLocationChange);

        /** handleLocationChange()
         * Dismisses all modals on location change start,
         * such as when the back button is pressed.
         */
        function handleLocationChange() {
            $uibModalStack.dismissAll();
        }

        // This handles switching from a legacy UX org to a new UX org
        $scope.$on('subscriptionsForOrg', function($event, subscriptionsForOrg) {
            $log.debug('on subscriptionsForOrg subscriptionsForOrg', subscriptionsForOrg);
            $scope.subscriptionsForOrg = subscriptionsForOrg;
        });

        // This handles initial page load, switching from a new UX org to a legacy UX org, and
        // switching between legacy UX orgs
        $scope.$watch('subscriptionsForOrg', function (newValue, oldValue) {
            $log.debug('watch subscriptionsForOrg newValue', newValue);

            if (!!newValue && !oldValue) {
                $scope.errorRetrievingData = '';
                $this.loadCalendarInformation();
            } else if (!!newValue && newValue.id !== oldValue.id) {
                $scope.errorRetrievingData = '';
                $this.loadCalendarInformation();
            }
        });

        // This handles switching between new UX orgs
        $scope.$on("orgChanged", function($event, orgId) {
          if (
            subscriptionModel.orgAndSubscriptions &&
            $scope.subscriptionsForOrg &&
            $scope.subscriptionsForOrg.id !== orgId
          ) {
            $scope.subscriptionsForOrg = subscriptionModel.getOrgWithSub(
              orgId
            )[0];
          }
        });

        $scope.closeAlert = function () {
            delete $scope.successMessage;
            delete $scope.errorRetrievingData;
        };

        function checkEndDate(calendar) {
            // check if end date year is one year past the start date, and if not set it to that
            var startDate = moment(calendar.startDate || $this.startDate);
            var endDate = moment(calendar.endDate || $this.endDate);
            var targetYear = startDate.year() + 1;

            if (endDate.year() !== targetYear) {
                calendar.endDate = endDate.year(targetYear).toDate();
            }
        }

        function errorNotification(error, route) {
            $log.debug('errorNotification error', error);
            var message = (error && error.message) ? error.message :
                (error && error.detailedMessage) ? error.detailedMessage :
                    (error && error.code) ? error.code : $this.defaultMessage;
            var errorFlag = error.errorFlag;
            notificationModel.setMessage(message);
            notificationsService.setMessage(errorFlag);

            $scope.errorRetrievingData = message;
            $scope.errorFlag = errorFlag;
            if (route) {
                $this.reloadRedirect(route);
            }
        }

        function loadCalendarInformation() {
            if (!!$scope.subscriptionsForOrg) {
                $log.debug('loadCalendarInformation orgId',$scope.subscriptionsForOrg.id);

                if (
                  userModel.currentUser &&
                  userModel.currentUser.staff &&
                  userModel.currentUser.staff.id &&
                  $scope.subscriptionsForOrg.id
                ) {
                    configService.getConfig(userModel.currentUser.staff.id, $scope.subscriptionsForOrg.id)
                      .then(function() {
                          $scope.isReactGlobalNav = configService.getCurrentConfigOption('isReactGlobalNav');
                          $scope.useDistrictCalendarYearSettings = configService.getCurrentConfigOption('useDistrictCalendarYearSettings');
                      })
                      .catch(function(error) {
                          $log.debug('error', error);
                          error.errorFlag = true;
                          $this.errorNotification(error, '');
                          $scope.isReactGlobalNav = false;
                          $scope.useDistrictCalendarYearSettings = false;
                      });
                }

                calendarService
                    .getSchoolYearInformation($scope.subscriptionsForOrg.id)
                    .then(function (schoolYearArray) {
                        $this.schoolCurrentCalenderYear = -1;
                        delete $this.startDate;
                        delete $this.endDate;
                        delete $this.displayStartDate;
                        delete $this.displayEndDate;
                        delete $this.currentSchoolCalendar;

                        if (!!schoolYearArray && schoolYearArray.length > 0) {
                            schoolYearArray.sort($this.sortCalendarArray);

                            var orderNumber = 0;
                            schoolYearArray.forEach(function (item) {
                                item.startDate = moment(item.startDate).toDate();
                                item.endDate = moment(item.endDate).toDate();
                                item.displayStartDate = moment(item.startDate).format('MMM Do YYYY');
                                item.displayEndDate = moment(item.endDate).format('MMM Do YYYY');
                                item.endYear = moment(item.endDate).year();
                                item.mode = '';
                                item.baseYear = -1;
                                item.organizationId = item.orgId;

                                // this logic is written to capture the year when the currentCalender is true
                                // after that all calenders should have EDIT functionality
                                // $this.schoolCurrentCalenderYear set a global value of the calendar year
                                // when it is the current calendar
                                if (item.currentCalendar && $this.schoolCurrentCalenderYear === -1) {
                                    item.baseYear = item.endYear;
                                    $this.schoolCurrentCalenderYear = item.endYear;
                                    $this.currentSchoolCalendar = item;
                                } else if (!item.currentCalendar && $this.schoolCurrentCalenderYear !== -1) {
                                    item.baseYear = item.endYear;
                                }
                                item.orderNumber = orderNumber;
                                orderNumber++;
                            });

                            $scope.schoolYearInformationArray = schoolYearArray;
                            $scope.calendarInitialized = true;
                            navModel.showNav = true;
                            $scope.disableEdits = false;
                            $this.disableSchoolCalendarIfUsingParent();
                        } else {
                            $scope.calendarInitialized = true;
                            $scope.disableEdits = false;
                            navModel.showNav = true;
                            $scope.errorRetrievingData = 'There is no school calendar being setup for this school.';
                            $scope.errorFlag = true;
                            var error = {};
                            error.code = "warning";
                            error.message = 'There is no school calendar being setup for this school.';
                            error.errorFlag = true;
                            $this.errorNotification(error, '');
                        }
                    })
                  .catch(function (error) {
                        $log.debug('error', error);
                        data.errorFlag = true;
                        $this.errorNotification(error, '');
                        $scope.calendarInitialized = true;
                    })
                  .finally(function() {
                      $scope.initialized = true;
                  });
            } else {
                $scope.initialized = true;
            }
        }

        function disableSchoolCalendarIfUsingParent() {
            if (!!$scope.subscriptionsForOrg && $scope.subscriptionsForOrg.parent && !$scope.subscriptionsForOrg.overrideSchoolYearStart) {
                $scope.disableEdits = true;
            }
        }

        function addSchoolYear() {
            var schoolYear;
            var getlargestPositionInSchoolArray;
            var previousObject;

            if ($scope.schoolYearInformationArray === undefined) {
                $scope.schoolYearInformationArray = [];
            }
            getlargestPositionInSchoolArray = $scope.schoolYearInformationArray.length;
            previousObject = $scope.schoolYearInformationArray[getlargestPositionInSchoolArray - 1];

            schoolYear = new Object();
            schoolYear.currentSchoolYear = false;
            schoolYear.mode = 'add';
            schoolYear.startDate = moment(previousObject.endDate).add(1, 'days').toDate();
            schoolYear.displayStartDate = moment(schoolYear.startDate).format('MMM Do YYYY');
            schoolYear.endDate = moment(previousObject.endDate).add(1, 'years').toDate();
            schoolYear.displayEndDate = moment(schoolYear.endDate).format('MMM Do YYYY');
            schoolYear.endYear = moment(schoolYear.endDate).year();
            schoolYear.baseYear = schoolYear.endYear;
            schoolYear.description = (parseInt(schoolYear.endYear) - 1) + '-' + schoolYear.endYear + ' School Year';
            schoolYear.organizationId = previousObject.orgId;
            schoolYear.orderNumber = previousObject.orderNumber + 1;

            // this code holds the value of the date in a temp object
            // if the clear button is pressed, then the date picker sets the date as undefined.
            // while accessing the date control again, the date picker will be reset.
            $this.endDate = schoolYear.endDate;
            $this.startDate = schoolYear.startDate;
            $this.displayStartDate = schoolYear.displayStartDate;
            $this.displayEndDate = schoolYear.displayEndDate;

            $scope.schoolYearInformationArray.push(schoolYear);

            $scope.dateOptions.minDate = moment(schoolYear.startDate).toDate();
            $scope.dateOptions.maxDate = moment(schoolYear.startDate).toDate();

            $scope.toDateOptions.minDate = moment(schoolYear.startDate).toDate();
            $scope.toDateOptions.maxDate = '';

            $scope.disableEdits = true;
        }

        function sortCalendarArray(a, b) {

            if (moment(a.startDate).year() < moment(b.startDate).year())
                return -1;
            if (moment(a.startDate).year() > moment(b.startDate).year())
                return 1;
            return 0;
        }

        function setEditMode(calendar) {
            var orderNumber;

            calendar.mode = 'update';
            orderNumber = calendar.orderNumber;

            $this.endDate = calendar.endDate;
            $this.startDate = calendar.startDate;
            $this.displayStartDate = calendar.displayStartDate;
            $this.displayEndDate = calendar.displayEndDate;
            $this.description = calendar.description;
            $scope.disableEdits = true;

            delete  $scope.dateOptions.minDate;
            delete  $scope.toDateOptions.minDate;
            delete  $scope.toDateOptions.maxDate;

            if (orderNumber > 0) {
                // the minimal date of the calendar should be a day greater than previous calendar end date

                $scope.dateOptions.minDate = moment($scope.schoolYearInformationArray[orderNumber - 1].endDate).add(1, 'days').toDate();
            } else {
                $scope.dateOptions.minDate = moment(calendar.startDate).toDate();
            }

            var startYear  = moment(calendar.startDate).year();
            var maxDate = moment().year(startYear).month(11).date(31);
            $scope.dateOptions.maxDate = maxDate;

            // the minimal date of the calendar control should be a day greater than the start date
            $scope.toDateOptions.minDate = moment(calendar.startDate).add(1, 'days').toDate();

            if (orderNumber !== $scope.schoolYearInformationArray.length - 1) {
                // max date of the calendar control should not be more than min date of the next school calendar year start date
                $scope.toDateOptions.maxDate = moment($scope.schoolYearInformationArray[orderNumber + 1].startDate).add(-1, 'days').toDate();
            } else {
                $scope.toDateOptions.maxDate = moment($scope.schoolYearInformationArray[orderNumber].startDate).add(365, 'days').toDate();
            }
        }

        function cancelEdit(calendar) {
            if (calendar.mode === 'add') {
                $scope.schoolYearInformationArray.pop();
            } else if (calendar.mode === 'update') {
                calendar.endDate = $this.endDate;
                calendar.startDate = $this.startDate;
                calendar.displayStartDate = $this.displayStartDate;
                calendar.displayEndDate = $this.displayEndDate;
                calendar.description = $this.description;
            }
            calendar.mode = '';
            $scope.disableEdits = false;
        }

        function enableControl(calendar) {
            return (calendar.mode === 'update' || calendar.mode === 'add');
        }

        /** enableEdit
         * @param calendar
         * @returns {boolean}
         * if calendar.mode is 'add' or 'update
         * OR
         * if calendar.mode is '' AND calendar.baseYear is not -1 AND calendar.baseYear is less than endYear
         */
        function enableEdit(calendar) {
            $log.debug('enableEdit calendar', calendar);
            var retValue = false;

            if (calendar.mode === '' && calendar.baseYear !== -1 && calendar.endYear >= calendar.baseYear) {
                retValue = true;
            } else if (calendar.mode === 'update' || calendar.mode === 'add') {
                retValue = true;
            }
            return retValue;
        }

        function validationsBeforeSave(calendar) {
            var orderNumber = calendar.orderNumber;
            var previousDate;
            var totalData = $scope.schoolYearInformationArray.length;
            var nextDate;
            var returnMessage;

            returnMessage = {};
            returnMessage.type = 'valid';
            if (totalData > 1) {
                $scope.schoolYearInformationArray.forEach(function (item) {
                    if (item.orderNumber === orderNumber - 1) {
                        previousDate = item.endDate;
                    } else if ((item.orderNumber === orderNumber + 1)) {
                        // this field is taken for comparing the next school year calendar with the
                        // current to check for gaps
                        nextDate = item.startDate;
                    }
                });

                var result = 0;

                // previous school year comparison for gap
                if (!!previousDate) {
                    result = new Date(calendar.startDate) - new Date(previousDate);
                    result = Math.floor(result / 86400000);
                }

                if (result > 1) {
                    returnMessage.type = 'modal';
                    returnMessage.previousYearGap = true;
                    returnMessage.previousYearOrderNo = orderNumber -1;
                    if (calendar.mode === 'add') {
                        returnMessage.message = sharedConstants.VALIDATION_GAP_MESSAGE;
                    } else {
                        returnMessage.message = sharedConstants.VALIDATION_GAP_PREVIOUS_EDIT_MESSAGE;
                        returnMessage.message = returnMessage.message.replace('#PREVIOUSMODIFIEDYEAR#',$scope.schoolYearInformationArray[returnMessage.previousYearOrderNo].description);
                        returnMessage.message = returnMessage.message.replace('#CURRENTMODIFIEDYEAR#',$scope.schoolYearInformationArray[orderNumber].description);
                    }
                }

                // next school year comparison for gap
                if (!!nextDate && calendar.mode === 'update') {
                    result = new Date(nextDate) - new Date(calendar.endDate);
                    result = Math.floor(result / 86400000);

                    if (result > 1) {
                        if (returnMessage.type === 'valid') {
                            returnMessage = {};
                            returnMessage.type = 'modal';
                        }
                        if (!!returnMessage) {
                            returnMessage.nextYearGap = true;
                            returnMessage.nextYearOrderNumber = orderNumber + 1;
                            returnMessage.message = sharedConstants.VALIDATION_GAP_FOLLOWING_EDIT_MESSAGE;
                            returnMessage.message = returnMessage.message.replace('#NEXTMODIFIEDYEAR#',$scope.schoolYearInformationArray[returnMessage.nextYearOrderNumber].description);
                            returnMessage.message = returnMessage.message.replace('#CURRENTMODIFIEDYEAR#',$scope.schoolYearInformationArray[orderNumber].description);
                        }
                    }
                }
            }

            return returnMessage;
        }

        function saveSchoolYear(calendar) {
            var returnMessage;
            var tempObj;
            var syncCalendar;
            // sync up calendar
            var prevCalendarToBeSyncedUp, nextCalendarToBeSyncUp;
            calendar.displayStartDate = moment(calendar.startDate).format('YYYY-MM-DD');
            calendar.displayEndDate = moment(calendar.endDate).format('YYYY-MM-DD');
            $scope.errorRetrievingData = '';
            $scope.successMessage = '';

            returnMessage = $this.validationsBeforeSave(calendar);
            syncCalendar = false;
            if (!!returnMessage && typeof returnMessage === "object") {
                if (returnMessage.type === 'modal') {
                    $scope.messageModalText = returnMessage.message;
                    $scope.showHeader = true;
                    var modal = $uibModal.open({
                        templateUrl: $this.templateUrl,
                        controller: 'showMessageController',
                        scope: $scope,
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function (response) {
                        if (response === 'CONTINUE') {
                            if (returnMessage.previousYearGap) {
                                tempObj = $scope.schoolYearInformationArray[returnMessage.previousYearOrderNo];
                                prevCalendarToBeSyncedUp = Object.assign({}, tempObj);
                                prevCalendarToBeSyncedUp.endDate = moment(calendar.startDate).add('-1','days');
                                prevCalendarToBeSyncedUp.mode= 'update';
                                prevCalendarToBeSyncedUp.displayStartDate = moment(prevCalendarToBeSyncedUp.startDate).format('YYYY-MM-DD');
                                prevCalendarToBeSyncedUp.displayEndDate = moment(prevCalendarToBeSyncedUp.endDate).format('YYYY-MM-DD');
                                syncCalendar = true;

                            }

                            if (returnMessage.nextYearGap) {
                                tempObj = $scope.schoolYearInformationArray[returnMessage.nextYearOrderNumber];
                                nextCalendarToBeSyncUp = Object.assign({}, tempObj);
                                nextCalendarToBeSyncUp.startDate = moment(calendar.endDate).add('1','days');
                                nextCalendarToBeSyncUp.mode= 'update';
                                nextCalendarToBeSyncUp.displayStartDate = moment(nextCalendarToBeSyncUp.startDate).format('YYYY-MM-DD');
                                nextCalendarToBeSyncUp.displayEndDate = moment(nextCalendarToBeSyncUp.endDate).format('YYYY-MM-DD');
                                syncCalendar = true;
                            }


                            $this.saveApiCall(calendar,prevCalendarToBeSyncedUp,nextCalendarToBeSyncUp, syncCalendar);

                        } else {
                            $scope.cancelEdit(calendar);
                        }
                    });

                } else if (!!returnMessage && returnMessage.type === 'warning') {
                    alert(returnMessage.message);
                } else if (!!returnMessage && returnMessage.type === 'valid') {
                    $this.saveApiCall(calendar);
                }
            }
        }

        function displayOverrideSchoolYearWarning(previousCheckedFlag) {
            var overrideFlag = !previousCheckedFlag;

            // this field will hide the header
            $scope.showHeader = false;
            $scope.messageModalText = sharedConstants.OVERRIDE_DISTRICT_SCHOOL_CALENDAR_MESSAGE;

            // we are checking the overrideFlag value because the ng-model dual binding has
            // already set the flag to false when it was true / checked
            if (overrideFlag) {
                var modal = $uibModal.open({
                    templateUrl: $this.templateUrl,
                    controller: 'showMessageController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (response) {
                    if (response === 'CONTINUE') {
                        $scope.disableEdits = false;
                        var orgId = $scope.schoolYearInformationArray[0].orgId;
                        var startDate = {};
                        startDate.monthOfYear = moment($this.currentSchoolCalendar.startDate).month() + 1;
                        startDate.dayOfMonth = moment($this.currentSchoolCalendar.startDate).date();
                        orgService.UpdateOverrideStartDateForChildOrg(orgId, overrideFlag, startDate).then(function (data) {
                            $scope.errorRetrievingData = '';
                            $scope.errorFlag = false;
                            $scope.subscriptionsForOrg.overrideSchoolYearStart = overrideFlag;
                            $scope.errorRetrievingData = sharedConstants.OVERRIDE_DISTRICT_SCHOOL_CALENDAR_SUCCESS_MESSAGE;
                        });
                    } else {
                        $scope.subscriptionsForOrg.overrideSchoolYearStart = previousCheckedFlag;
                        $scope.disableEdits = true;
                    }
                });
            } else {
                $this.reCheckOverrideFlagAfterValidations(overrideFlag);
            }

        }

        function reCheckOverrideFlagAfterValidations(overrideFlag) {
            var orgId = $scope.schoolYearInformationArray[0].orgId;

            calendarService.validateOverrideChildCalendarOnParent(orgId).then(function (response) {
                if (!!response && !!response.violations && response.violations.length === 0) {
                    $scope.messageModalText = sharedConstants.VALIDATE_SYNC_CHILD_TO_PARENT_SCHOOL_CALENDAR_MESSAGE;
                    var modal = $uibModal.open({
                        templateUrl: $this.templateUrl,
                        controller: 'showMessageController',
                        scope: $scope,
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function (response) {
                        if (response == 'CONTINUE') {
                            orgService.OverrideChildSchoolToDistrictCalendar(orgId).then(function (data) {
                                if (!!data) {
                                    $scope.disableEdits = true;
                                    $scope.subscriptionsForOrg.overrideSchoolYearStart = overrideFlag;
                                    $scope.errorRetrievingData = sharedConstants.VALIDATION_SUCCESS_SYNC_TO_DISTRICT_CALENDAR;
                                    $scope.errorFlag = false;
                                    $this.loadCalendarInformation();
                                }
                            });
                        } else {
                            $scope.subscriptionsForOrg.overrideSchoolYearStart = !overrideFlag;
                            $scope.disableEdits = false;
                        }
                    });
                } else if (!!response && !!response.violations && response.violations.length !== 0) {
                    $scope.errorRetrievingData = sharedConstants.VALIDATION_UNABLE_TO_SYNC_TO_DISTRICT_CALENDAR;
                    $scope.errorFlag = true;
                    $scope.subscriptionsForOrg.overrideSchoolYearStart = !overrideFlag;
                    $scope.disableEdits = false;
                }
            });
        }

        function saveApiCall(calendar, prevCalendarSync, nextCalendarSync, isCalendarSycRequired) {
            var notifyMessage;
            var message = calendar.description + " was successfully saved for " + $scope.subscriptionsForOrg.name + '.';

            notifyMessage = {};
            notifyMessage.message = message;
            if (calendar.mode.toLowerCase() === 'add') {
                calendarService.saveSchoolYear(calendar).then(function (response) {
                    notifyMessage.errorFlag = false;
                    $this.errorNotification(notifyMessage, '');
                    $this.loadCalendarInformation();
                }, function (data) {
                    data.errorFlag = true;
                    $this.errorNotification(data, '');
                    $scope.cancelEdit(calendar);

                    if (data.code === 'error.school.calendar.not.found.for.child.org') {
                        // Reload calendar information if parent calendar is updated but child org fails.
                        // This is a workaround until resolved by: https://jira.sts.scholastic.com/browse/SDMBF-6589
                        $this.loadCalendarInformation();
                    }
                });
            } else if (calendar.mode.toLowerCase() === 'update') {
                calendarService.updateSchoolYear(calendar).then(
                    function (response) {
                        if (!isCalendarSycRequired) {
                            notifyMessage.errorFlag = false;
                            $this.errorNotification(notifyMessage, '');
                            calendar.mode = '';
                            $this.loadCalendarInformation();
                        } else {
                            var promises = [];
                            var promise;

                            if (prevCalendarSync) {
                                promise = new Promise(function (resolve, reject) {
                                    calendarService.updateSchoolYear(prevCalendarSync).then(
                                        function (response) {
                                            resolve(response);
                                        },
                                        function (error) {
                                            reject(error);
                                        });
                                });
                                promises.push(promise);
                            }

                            if (nextCalendarSync) {
                                promise = new Promise(function (resolve, reject) {
                                    calendarService.updateSchoolYear(nextCalendarSync).then(
                                        function (response) {
                                            resolve(response);
                                        },
                                        function (error) {
                                            reject(error);
                                        });
                                });
                                promises.push(promise);
                            }

                            Promise.all(promises).then(function() {
                                notifyMessage.errorFlag = false;
                                $this.errorNotification(notifyMessage, '');
                                $this.loadCalendarInformation();
                            });
                        }
                    },
                    function (data) {
                        data.errorFlag = true;
                        $this.errorNotification(data, '');
                        $scope.cancelEdit(calendar);

                        if (data.code === 'error.school.calendar.not.found.for.child.org') {
                            // Reload calendar information if parent calendar is updated but child org fails.
                            // This is a workaround until resolved by: https://jira.sts.scholastic.com/browse/SDMBF-6589
                            $this.loadCalendarInformation();
                        }
                    }
                );
            }
        }

        function loadSubscriptions() {
            productService.getSubscriptions()
              .then(function (subscriptions) {
                  $log.debug('Load Subscriptions success handler', subscriptions);
                  if (subscriptionModel.lacksSubsAsAdmin()) {
                      $location.path('/products');
                  }

                  $scope.orgAndSubscriptions = subscriptions;
                  if ($scope.orgAndSubscriptions.length >= 1) {
                      if (subscriptionModel.getCookieForOrgAsAdmin()) {
                          var currentOrg = subscriptionModel.getOrgWithSub(subscriptionModel.getCookieForOrgAsAdmin().orgId)[0];
                          $scope.subscriptionsForOrg = currentOrg;
                      } else {
                          $scope.subscriptionsForOrg = $scope.orgAndSubscriptions[0];
                          subscriptionModel.setCookieForOrgAsAdmin($scope.orgAndSubscriptions[0].id);
                      }
                      // setting up user model
                      userModel.currentOrg = $scope.subscriptionsForOrg;
                  }

                  $this.loadCalendarInformation();
              })
              .catch(function (error) {
                  if (error.code == 'error.not.logged.in') {
                      userModel.reset();
                      $location.path('/signin/staff');
                  }
                  $log.error('Error loading Subscriptions', error);
                  $scope.errorRetrievingData = error.message || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
              });
        }

        function onInit() {
            userService.getCurrentUser()
              .then(function() {
                  $scope.schoolYearInformationArray = [];

                  navModel.activePage = 'calendar';
                  Volume.setStoreItem('dpCurrentRole', 'admin');

                  //MB: Although not necessary to get org data. However on refresh I am losing context which determines the user is school admin or not.
                  orgService.getOrgData().then(function(data) {
                      $log.debug('getOrgData', data);

                      settingsService.getSettings().then(function(settings) {
                          $log.debug('Settings: ', settings);
                          $scope.showYOYFeatures = !!settings.showYOYFeatures;

                          productService.getEntitlement().then(function(data) {
                              $log.debug('Product entitlements', data);
                              loadSubscriptions();
                          });
                      });
                  });
              })
              .catch(function(error) {
                  $log.error('User not signed in', error);
                  $location.path('/signin/staff');
              });
        }
        onInit();
    }
]);
