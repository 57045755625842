/**
 * Created by jhouser on 10/21/2015.
 */


portalModule.service("appModel", function(cookieService, sharedConstants, userModel) {

    var dataObject = {
        appList : [],
        appMap : {},
        subscriptionList : [],
        createAppMap : createAppMap,
        setCurrentApp : setCurrentApp,
        getCurrentApp : getCurrentApp,
        reset : reset
    };

    return dataObject;

    function reset(){
        dataObject.appList = [];
        dataObject.appMap = {};
        dataObject.subscriptionList = [];
    };

    /*
     this creates an application mapping for easy access later
     key is subscriptionProductId
     appMap[subscriptionProductId]
     appMap[subscriptionProductId] = subscription product from /subscription-products  service
                                       {
                                            "id": "1",
                                                "name": "2nd Grade Maths3",
                                                "applicationId": "2",
                                                "active": true
                                        },
     appMap[subscriptionProductId].application = application object (from /apps service )
                                                 {
                                                 "id": "1",
                                                 "name": "StoriaApp",
                                                 "description": "Storia Application",
                                                 "url": "www.storia.com",
                                                 "active": true
                                                 }
*/

    function createAppMap(){
        dataObject.appMap = {};
        for (var productSubscriptionIndex = 0; productSubscriptionIndex < dataObject.subscriptionList.length; productSubscriptionIndex++){
            // save the subscription object in the app Map with a key related to its ID
            dataObject.appMap[dataObject.subscriptionList[productSubscriptionIndex].id] = dataObject.subscriptionList[productSubscriptionIndex];
            // now find the application object that relates to this subscription item
            for (var appIndex = 0; appIndex<dataObject.appList.length; appIndex++){
                if(dataObject.appList[appIndex].id == dataObject.subscriptionList[productSubscriptionIndex].applicationId){
                    dataObject.appMap[dataObject.subscriptionList[productSubscriptionIndex].id].application = dataObject.appList[appIndex];
                    break;
                }
            }
        }

    };
    
    function setCurrentApp(appId){
        var currentApp = {};
        currentApp.staffId = userModel.teacherId;
        currentApp.appId = appId;
        cookieService.saveCookie('currentApp', currentApp);
    }
    
    function getCurrentApp(){
        var currentAppCookie = cookieService.getCookie('currentApp');
        if(!!currentAppCookie){
            if(userModel.teacherId == currentAppCookie.staffId){
                return currentAppCookie;
            }
            else{
                return undefined;
            }
        }
        return undefined;
    }

});
