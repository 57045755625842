portalModule.service("notificationModel", [ "$log", function($log) {
	var dataObject = {
			setMessage: setMessage,
			popMessage: popMessage,
			errorFlag: false,
	};

	return dataObject;
	
	var message;
	function setMessage(msg, errorFlag) {
		message = msg;
		dataObject.errorFlag = !!errorFlag;
	}
	
	function popMessage() {
		var retVal = message;
		message = undefined;
		return retVal;
	}
} ]);