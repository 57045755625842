/**
 * Created by jhouser on 9/16/2015.
 */

portalModule.controller('newStudentGroupPopupController',['$scope','$uibModalInstance','$filter','$log','selectedClass','studentService','studentModel', function($scope, $uibModalInstance,$filter, $log, selectedClass, studentService, studentModel){

    $scope.selectedClass = selectedClass;
    $scope.editMode = true;
    $scope.confirmCancel = false;
    $scope.errorValidating = '';
    $scope.newStudentGroupControl = {};
    $scope.uploadUsingCSV = false;

    $scope.closeAlert = function(){
        $scope.errorValidating = '';
    };

    $scope.$on('showGrade',function(event,showGrade){
    	$scope.showGrade = showGrade.show;
    });

    // defining the student array
    // and setting a default empty student object
    $scope.studentArray = [
        {student: {grade:'Select Grade'}}
    ];

    $scope.cancel = function(msg){
    		var atLeastOneRecordPresent = false;
        	for(var i=0; i< $scope.studentArray.length; i++){
        		if(($scope.studentArray[i].student.firstName != undefined && $scope.studentArray[i].student.firstName != '')
        				||
        			($scope.studentArray[i].student.lastName != undefined && $scope.studentArray[i].student.lastName != '')
        		){
        			atLeastOneRecordPresent = true;
        			break;
        		}
        	}
        	if(atLeastOneRecordPresent == true){
        		$scope.confirmCancel = true;
            	$scope.editMode = false;
            	if(msg == 'csv'){
            		$scope.uploadUsingCSV = true;
            	}
        	}
        	else{
        		if(msg == 'csv'){
        			$scope.uploadUsingCSV = true;
        			$scope.closePopup('');
        		}
        		else{
        			$scope.closePopup('');
        		}  		
        	}	
    };
    
    $scope.closePopup = function(msg, students){
    	if($scope.uploadUsingCSV == true){
    		$scope.uploadUsingCSV = false;
    		$uibModalInstance.close({message:'csv', students:students});
    	}
    	else{
    		$uibModalInstance.close({message:msg, students:students});
    	}
    	
    }

    $scope.validateStudents = function(){
        $scope.newStudentGroupControl.validate();
   };


    $scope.addStudents = function() {
        $scope.newStudentGroupControl.addStudents();
    };

    $scope.toggleEditMode = function(){
    	$scope.editMode = true;
    }
    
    $scope.goBacktoEdit = function(){
    	$scope.editMode = true;
    	$scope.confirmCancel = false;
    	$scope.uploadUsingCSV = false;
    }

    $scope.upsertStudentsSuccessHandler = function(students) {

        var num;
        if($scope.studentArray.length == 1)
        {
            num = "student";
        }
        else
        {
            num = "students";
        }
        var addedStudentmsg = "Successfully added " + $scope.studentArray.length + " " + num;

        $scope.closePopup(addedStudentmsg,students);
    };

}]);