portalModule.controller('appLaunchController', [
  '$scope', 'productService', '$location', '$routeParams', '$log',
  '$timeout', 'userService', '$window',
  function($scope, productService, $location, $routeParams, $log,
           $timeout, userService, $window) {
    var appId = $routeParams.appId;
    var orgId = $routeParams.orgId;
    var user = $routeParams.user;
    var launchType = $routeParams.launchType;
    $scope.loading = true;

    function launchApp() {
      productService.launchData(appId, orgId, user, launchType)
        .then(function(data) {
          $log.debug('Launch data for app #', appId, ' : ', data);
          $scope.outboundLaunch = data;
          $scope.launchParams = data.params;

          $timeout(function() {
            if (launchType === 'oauth') {
              $window.location.href = $scope.outboundLaunch.launchUrl;
              return;
            }

            var form = $('#launchForm');
            form.attr('action', $scope.outboundLaunch.launchUrl);
            form.submit();
            $scope.loading = false;
          }, 0);

        }, function(error) {
          $log.error('In error handler', error);
        });
    }

    function init() {
      userService.getCurrentUser().then(function(data) {
        $log.debug('User signed in', data);
        launchApp();
      }, function(error) {
        $log.debug('User is not signed in', error);
        $location.path('/signin');
      });

    }

    init();

  }]);