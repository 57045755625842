portalModule.controller(
    'LtiConfirmationPopupController',
    [
        '$scope',
        '$uibModalInstance',
        '$timeout',
        function($scope, $uibModalInstance, $timeout) {
            
            var ctrl = this;
            ctrl.ok = function () {
                $uibModalInstance.close();
            };

            ctrl.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $timeout(function () {
                $('#ltiConfirmation').find('.cancelbtn').focus();
            }, 1);

        }
    ]
);
