(function(){

    /**
     * CreateClassController
     *
     * @author Manit Banker
     *
     */

    angular.module('portalApp').controller('CreateClassController', CreateClassController);

    CreateClassController.$inject = [
        '$scope', '$rootScope', '$location', 'classService', '$routeParams',
        '$log', '$http', 'orgService', 'userModel', 'orgModel',
        'classModel','userService', 'navModel', 'sharedConstants', 'SDMAnalyticsService',
        'productModel', 'productService', 'settingsService', '$sce',  "teacherService", '$filter',
    ];

    function CreateClassController(
        $scope, $rootScope, $location, classService, $routeParams,
        $log, $http, orgService, userModel, orgModel,
        classModel, userService, navModel, sharedConstants, SDMAnalyticsService,
        productModel, productService, settingsService, $sce, teacherService, $filter) {

        var $this = this;
        $this.onInit = onInit;
        $this.initSchoolDropDown = initSchoolDropDown;
        $this.getErrors = getErrors;
        $this.preInitialize  = preInitialize;
        $this.readAllSettings = readAllSettings;
        $this.getCalendarForOrgAndSetDropDown = getCalendarForOrgAndSetDropDown;
        $this.getSchoolsWithRoster = getSchoolsWithRoster;

        $scope.showGradeDropdown = true;
        $scope.grades = classModel.gradesArray;
        $scope.initialized = false;
        $scope.showSchoolCalendar = true;
        $scope.error = '';
        $scope.errorFlag = false;
        $scope.gradeLabelLow = '---Select Grade---';
        $scope.gradeLabelHigh = '---Select Grade---';
        $scope.classSection = {
          lowGrade: "---Select Grade---",
          nickname: "",
          easyLogin: { enabled: false }
        };
        $scope.classList = [];

        $scope.initialOnBoarding = $location.path().indexOf('createfirstclass') > -1;
        $scope.isMultipleGradesEnabled = { value: false };

        $scope.sectionId = $routeParams.sectionId;
        $scope.isEditMode = !!$scope.sectionId;

        $scope.selectedSchoolCalendar = {};
        $this.preInitialize();

        function handleError(error) {
          $log.error("Error creating new class", error);

          if (error.code === "error.not.logged.in") {
            userModel.reset();

            $location.path("/signin/staff");
          }

          throw error;
        }

        $scope.cancel = function () {
            productModel.reset();

            try {
            SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_ABORT_EVENT, 'initialOnBoarding');
            SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_ABORT_EVENT, $scope.initialOnBoarding);
            } catch (e){
                $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_ABORT_EVENT, e);
            }

            if (classModel.classList == undefined || classModel.classList.length === 0) {
                $location.path('/products');
            }
            else{
                $location.path('/students');
            }
        };

        $scope.changeGradeLow = function (gradeLow) {
            $scope.classSection.lowGrade = gradeLow.value;
            $scope.gradeLabelLow = gradeLow.label;
            $scope.gradeSortLow = gradeLow.sort;
            $scope.classSection.highGrade = '---Select Grade---';
            $scope.gradeLabelHigh = "---Select Grade---";
            //If lower grade value is maximum, disable multiple grade option
            if($scope.gradeSortLow === 14){
                $scope.isMultipleGradesEnabled.value = false;
            }
        };

        $scope.changeGradeHigh = function (gradeHigh) {
            if($scope.classSection.lowGrade === '---Select Grade---'){
                $scope.error = sharedConstants.CLASS_SELECT_LOWER_GRADE_ERROR;
                $scope.errorFlag = true;
                return;
            }
            $scope.classSection.highGrade = gradeHigh.value;
            $scope.gradeLabelHigh = gradeHigh.label;
            $scope.gradeSortHigh = gradeHigh.sort;
        };

        $scope.changeSchool = function (selectedSchool) {
            $scope.classSection.organizationId = selectedSchool.id;
            $scope.selectedSchool = selectedSchool;
            if ($scope.showYOYFeatures) {
                $scope.showSchoolCalendar = false;
                $scope.initialized = false;
                $this.getCalendarForOrgAndSetDropDown($scope.selectedSchool);
            } else {
                $scope.initialized = true;
            }
        };

        $scope.onMultipleGradesToggle = function () {
            // if the teacher is saying that the class has multiple grades
            // add the second upper limit grade to the classSection grades array
            if ($scope.isMultipleGradesEnabled.value) {
                $scope.classSection.highGrade = '---Select Grade---';
                $scope.gradeLabelHigh = '---Select Grade---';
            } else {
                // we are saying this class doesn't have multiple grades
                // so if the length is greater than 1 pop off the top item
                // leaving the grades array with a single item
                if ($scope.classSection.highGrade) {
                    $scope.classSection.highGrade = $scope.classSection.lowGrade;
                }
            }
        };

        $scope.submitClassSection = function () {
            $scope.error = $this.getErrors();

            if (!$scope.error && $scope.classSection.nickname != undefined) {

                if (!$scope.isMultipleGradesEnabled.value) {
                    $scope.classSection.highGrade = $scope.classSection.lowGrade;
                }

                $scope.classSection.staff = {
                    primaryTeacherId: userModel.teacherId
                };

                $log.debug('Class section', $scope.classSection);

                classService.createClassSection($scope.classSection).then( function(newSection){
                    $log.debug('Successfully created new class', newSection);

                    try {
                        if (classModel.classList == undefined) {
                            classModel.classList = [];
                        }

                        SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_EVENT, 'newClass', 'existingClasses', 'orgId', 'initialOnBoarding');
                        SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_EVENT, newSection, classModel.classList, newSection.organizationId, $scope.initialOnBoarding);

                    } catch (e) {
                        $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
                    }

                    classModel.reset();
                    classModel.selectedClassId = newSection.id;
                    productModel.setCookieForOrgAsTeacher(newSection.organizationId, newSection.id);
                    userModel.currentOrg = classModel.getClassAssociation(orgModel.organizationList, newSection);

                    if($scope.initialOnBoarding){
                        if($scope.gradeSortLow < 5 && $scope.showEasyLogin && !$scope.noStudentAccess) {
                            classModel.showElPref = true;
                            $location.path('/students/createfirstclass/el-pref/' + classModel.selectedClassId);
                        } else {
                            if (productModel.isSRCTeacher) {
                                $location.path('/students/firstclass/searchAdd/' + newSection.organizationId + "/" + newSection.id);
                            } else {
                                $location.path('/students/firstclass/addnewstudents/' + classModel.selectedClassId);
                            }
                        }
                    } else{
                        if($scope.gradeSortLow < 5 && $scope.showEasyLogin) {
                            if ($scope.showYOYFeatures) {
                                $location.path('/students/createclass/el-pref/' + $scope.classSection.schoolCalendarId + '/' + classModel.selectedClassId);
                            } else {
                                $location.path('/students/createclass/el-pref/' + classModel.selectedClassId);
                            }
                        } else {
                            if ($scope.showYOYFeatures) {
                                $location.path('/students/' + $scope.classSection.schoolCalendarId + '/' + newSection.id);
                            } else {
                                $location.path('/students');
                            }
                        }
                    }
                }, function(error){
                    $log.error('Error creating new class', error);
                    if (error.code == 'error.not.logged.in') {
                        userModel.reset();
                        $location.path('/signin/staff');
                    }
                    $scope.errorFlag = true;
                    $scope.error = error.message || error.code || 'An error occurred. Please try again.';
                });
            } else if (!!$scope.error && $scope.error.length > 0) {
                $scope.error = $sce.trustAsHtml($scope.error);
                $scope.errorFlag = true;
            }
        };

        $scope.volCreateClass = function(classData) {
          return classService.createClassSection(classData).then(
            function(newSection) {
              $log.debug("Successfully created new class", newSection);

              try {
                if (classModel.classList === undefined) {
                  classModel.classList = [];
                }

                SDMAnalyticsService.addEventType(
                  sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_EVENT,
                  "newClass",
                  "existingClasses",
                  "orgId",
                  "initialOnBoarding"
                );

                SDMAnalyticsService.emit(
                  sharedConstants.SDM_ANALYTICS_EVENTS.CREATE_CLASS_EVENT,
                  newSection,
                  classModel.classList,
                  newSection.organizationId,
                  $scope.initialOnBoarding
                );
              } catch (e) {
                $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
              }

              classModel.reset();
              classModel.selectedClassId = newSection.id;

              productModel.setCookieForOrgAsTeacher(
                newSection.organizationId,
                newSection.id
              );

              userModel.currentOrg = classModel.getClassAssociation(
                orgModel.organizationList,
                newSection
              );

              Volume.setStoreItem("dpClassId", newSection.id);
              Volume.setStoreItem("dpCalendarId", classData.schoolCalendarId);

              return newSection;
            },
            handleError
          );
        };

        $scope.volAddTeacherToClass = function(
          classSectionId,
          orgId,
          teacherId
        ) {
          return teacherService
            .addCoTeacherToClass(classSectionId, orgId, teacherId)
            .catch(handleError);
        };

        $scope.volRemoveTeacherFromClass = function(
          classSectionId,
          orgId,
          teacherId
        ) {
          var params = {
            classSectionId: classSectionId,
            organizationId: orgId,
            id: teacherId
          };

          return teacherService.removeCoTeacher(params).catch(handleError);
        };

        $scope.closeAlert = function(){
            $scope.error = '';
            $scope.errorFlag = false;
        };

        $scope.goBackToDashboard = function(){
            $location.path('/products');
        };

        $scope.changeSchoolCalendar = function (calendar) {
            $scope.classSection.schoolCalendarId = calendar.id;
            $scope.selectedSchoolCalendar = calendar;
        };

        function getErrors() {
            var errors = [];
            if (!$scope.classSection.organizationId) {
                errors.push(sharedConstants.CLASS_CHOOSE_SCHOOL_ERROR);
            }

            $log.debug("ClassSection nickname", $scope.classSection.nickname);
            if ($scope.classSection.nickname == "") {
                errors.push(sharedConstants.CLASS_ENTER_TITLE_ERROR);
            }

            if (!$scope.classSection.lowGrade || $scope.classSection.lowGrade == '---Select Grade---') {
                if (!$scope.isMultipleGradesEnabled.value) {
                    errors.push(sharedConstants.CLASS_SELECT_GRADE_ERROR);
                } else {
                    errors.push(sharedConstants.CLASS_SELECT_LOWER_GRADE_ERROR);
                }
            }
            if (!$scope.classSection.lowGrade || $scope.isMultipleGradesEnabled.value && $scope.classSection.highGrade == '---Select Grade---') {
                errors.push(sharedConstants.CLASS_SELECT_HIGHER_GRADE_ERROR);
            }

            return errors.length === 0 ? undefined : errors.join("<br>");
        }

        function initSchoolDropDown() {
            $scope.showSchoolDropdown = false;
            $scope.showSchoolCalendar = false;
            if ($scope.schools.length == 1) {
                $scope.classSection.organizationId = $scope.schools[0].id;
                $scope.selectedSchool = $scope.schools[0];
            } else {
                if (productModel.getCookieForOrgAsTeacher()) {
                    var lastOrgId = productModel.getCookieForOrgAsTeacher().orgId;
                    var lastSchool = $scope.schools.filter(function (school, index) {
                        return school.id == lastOrgId;
                    })[0];
                    $scope.classSection.organizationId = lastSchool.id;
                    $scope.selectedSchool = lastSchool;
                } else {
                    $scope.classSection.organizationId = $scope.schools[0].id;
                    $scope.selectedSchool = $scope.schools[0];
                }
                $scope.showSchoolDropdown = true;
            }
            if ($scope.showYOYFeatures) {
                $scope.showSchoolCalendar = true;
                $scope.showSchoolYearDropdown = false;
                // below service is now modified to call the new GET API for school calendars
                $this.calendarId = $routeParams.calendarId;
                $this.getCalendarForOrgAndSetDropDown($scope.selectedSchool, $this.calendarId);
                $this.getSchoolsWithRoster($scope.schools, productModel.entitlementList);

            } else {
                $scope.initialized = true;
            }
        }

        function getSchoolsWithRoster(arrSchools, entitlements) {

            // this logic is written to remove the schools who do not have any roster support
            // code parses through the entitlement object which is fetched from the Product model
            // if all the applications entitled to a school has roster support as none, it means
            // they do not have roster support and need not have ADD CLASS functionality

            var data;
            if (!!entitlements) {
                var schoolsWithNoRoster = {};
                entitlements.forEach(function (element) {
                    var isAllNoRoster = false;
                    var noRosterCount = 0;
                    element.applications.forEach(function (value) {
                        if (value.rosterSupport.toLowerCase() === "none") {
                            noRosterCount++;
                        }
                    });

                    if (noRosterCount === element.applications.length) {
                        isAllNoRoster = true;
                    }

                    if (isAllNoRoster) {
                        schoolsWithNoRoster[element.id] = element.name;
                    }
                });

                // set the schools array with the value of school which roster support
                data = arrSchools.filter(function (school) {
                    if (!schoolsWithNoRoster[school.id]) {
                        return school;
                    }
                });

                delete $scope.schools;
                $scope.schools = data;
                if ($scope.schools.length === 1) {
                    $scope.showSchoolDropdown = false;
                }
            }
        }

        function getCalendarForOrgAndSetDropDown(selectedSchool, calendarId) {

            delete  $scope.selectedSchoolCalendar;
            delete  $scope.schoolCalendars;
            delete  $this.currentSchoolYear;

            return orgService.getSchoolYearsYOYForTeacher(selectedSchool.id).then(function (data) {
                // this code is to set the selected calendar in the drop down
                // this code will set the current calendar to a variable
                data.forEach(function(calendar) {
                  if (!!calendarId && calendarId == calendar.id) {
                    $scope.selectedSchoolCalendar = calendar;
                  }

                  if (calendar.currentCalendar) {
                    $this.currentSchoolYear = calendar;
                  }
                });

                // Setting a default school Calendar, this logic fires when the user changes the ORG
                if (!$scope.selectedSchoolCalendar) {
                    $scope.selectedSchoolCalendar = $this.currentSchoolYear;
                }

                $scope.classSection.schoolCalendarId =  $scope.selectedSchoolCalendar.id;

                // filter school years for current and above
                data = data.filter(function (item) {
                    if (item.currentCalendar) {
                        return item;
                    } else {
                        var isSchoolYearGreater;

                        isSchoolYearGreater = (moment(item.endDate).year() - moment($this.currentSchoolYear.endDate).year()) > 0 ? true : false;
                        if (isSchoolYearGreater) {
                            return item;
                        }
                    }
                });

                $scope.schoolCalendars = data;
                if (!$scope.$$phase) {
                    $scope.$apply();
                }

                if ($scope.schoolCalendars.length > 1) {
                    $scope.showSchoolYearDropdown = true;
                } else {
                    $scope.showSchoolYearDropdown = false;
                }

                $scope.initialized = true;
                $scope.showSchoolCalendar = true;

                return data;
            });
        }

        $scope.volGetCalendarForOrgAndSetDropDown = getCalendarForOrgAndSetDropDown;

        function volLoadSelectedClass(schoolYear) {
          var promise = $scope.showYOYFeatures
            ? classService.loadClassSectionsForACalendar(schoolYear)
            : classService.getClassesForTeacher(schoolYear);

          return promise.then(function(classList) {
            $log.debug("classList", classList);

            return $filter("filter")(classList, function(value) {
              return value.id === $scope.sectionId;
            })[0];
          });
        }

        $scope.volLoadSelectedClass = volLoadSelectedClass;

        function updateClass(classSection) {
          return classService
            .updateClassSection(classSection)
            .then(function(updatedSection) {
              try {
                if (classModel.classList == undefined) {
                  classModel.classList = [];
                }

                SDMAnalyticsService.addEventType(
                  sharedConstants.UPDATE_CLASS_EVENT,
                  "updatedClass",
                  "existingClasses",
                  "orgId"
                );

                SDMAnalyticsService.emit(
                  sharedConstants.UPDATE_CLASS_EVENT,
                  updatedSection,
                  classModel.classList,
                  updatedSection.organizationId
                );
              } catch (e) {
                $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
              }

              return updatedSection;
            });
        }

        $scope.volUpdateClass = updateClass;

        function readAllSettings(settingsObject) {
            var tempObj = Object.keys(settingsObject);
            tempObj.forEach(function (item) {
                $scope[item] = settingsObject[item];
            });
        }

        function preInitialize() {
            settingsService.getSettings().then(function (settings) {
                // setting up all the settings values
                $this.readAllSettings(settings);
                $this.onInit();
            });
        }

        function onInit(){

            userService.getCurrentUser().then(function(currentUser){
                $log.debug('User signed in',currentUser);
                orgService.getSchoolsWithTeacherRole().then(function(data){
                    $log.debug('Schools found on user profile', data);
                    if(data.length === 0){
                        $log.debug('No schools on user profile');
                        $location.path('/products');
                        return;
                    }
                    else {
                        $scope.schools = data;
                        $this.initSchoolDropDown();
                    }
                });

                productService.getSubscriptions().then(function(subscriptions){
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                });

                if ($scope.initialOnBoarding) {
                    // reload entitlements since the user just redeemed their first access code
                    productService.loadEntitlements().then(function(data){
                        $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                    });
                } else {
                    productService.getEntitlement().then(function(data){
                        $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                    });
                }

                if(productModel.redeemedSubscription){
                    $scope.redeemedSubscription = productModel.redeemedSubscription;
                    var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                    if(!isNoStudentAccess){
                        $scope.noStudentAccess = true;
                    }
                }

                if(!$scope.redeemedSubscription && $scope.initialOnBoarding){
                    $location.path('/products');
                }

                $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);

                if(!classModel.classList){
                    classService.getClassesForTeacher().then(function (classList) {
                        $log.debug('Class list', classList);
                    });
                }
                else{
                    $log.debug('Class list', classModel.classList);
                }
                navModel.showNav = true;
                navModel.helpPage = false;
            },function(error){
                $log.error('User not signed in',error);
                $location.path('/signin/staff');
            });
        }
    }
})();
