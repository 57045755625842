portalModule.controller("ImportCSVPopupController",
		['$scope','$uibModalInstance','$log','selectedClass','studentService',
			'studentModel','$window','sharedConstants','userModel','$location',
			'$uibModalStack','classModel','sessionState',
		 function($scope,$uibModalInstance,$log,selectedClass,studentService,
				  studentModel,$window,sharedConstants,userModel,$location,
				  $uibModalStack,classModel,sessionState){

	var studentRecords;
	var namePattern = /^[a-zA-Z]+([- ][a-zA-Z]+)*$/;
	var gradePattern = /first|second|third|fourth|fifth|sixth|seventh|eighth|ninth|tenth|eleventh|twelfth|1|2|3|4|5|6|7|8|9|10|11|12/;
	var studentIdPattern = /^[a-zA-Z0-9]*$/;
	$scope.patterns={"firstName": namePattern, "lastName":namePattern, "grade": gradePattern, "studentId": studentIdPattern};
	$scope.errorUploadingCSV = "";
	$scope.studentsUploaded = false;
	$scope.errorFlag = false;
	$scope.errorMessage = "";
	$scope.duplicateErrorMessage = "";
	$scope.disableUpload = false;
	$scope.loader={loading:false};
	$scope.editMode = false;
	$scope.grades = selectedClass.gradeArray;
	$scope.confirmCancel = false;
	$scope.disableConfirm = false;
	
	function onInit(){
		if(selectedClass.lowGrade === selectedClass.highGrade){
			$scope.sampleImage = sharedConstants.SAMPLE_STUDENT_UPLOAD_SCREENSHOT_NO_GRADE;
			$scope.sampleHref = sharedConstants.SAMPLE_STUDENT_UPLOAD_FILE_NO_GRADE;
		} else {
			$scope.sampleImage = sharedConstants.SAMPLE_STUDENT_UPLOAD_SCREENSHOT_WITH_GRADE;
			$scope.sampleHref = sharedConstants.SAMPLE_STUDENT_UPLOAD_FILE_WITH_GRADE;
		}
	}
	onInit();

	 var validateStudentsSuccessHandler = function(data){
     	$log.debug("In import Success Handler",data);
     	if(data.code === 'error.not.logged.in'){
     		userModel.reset();
			$uibModalStack.dismissAll();
			$location.path('/signin/staff');
			return;
		}
     	$log.debug("Records: ", data.records);
     	var students = data.records;
     	var tempArray = [];
     	for (var i=0; i< students.length; i++) {

     		if(students[i].grade && students[i].grade.toLowerCase() === 'pk'){
     			tempArray.push(1);
     		}
     		else if(students[i].grade && students[i].grade.toLowerCase() === 'k'){
     			tempArray.push(2);
     		}
     		else{
     			tempArray.push(Number(students[i].grade) + 2);
     		}	
     	}
     	for(var j=0; j<$scope.grades.length; j++){
     		tempArray.push($scope.grades[j].sort);
     	}
     	$log.debug('Superset array',tempArray);
     	var lowest = Number.POSITIVE_INFINITY;
     	var highest = Number.NEGATIVE_INFINITY;
     	for (var z=tempArray.length-1; z>=0; z--) {
     	    if (tempArray[z] < lowest){
     	    	lowest = tempArray[z];
     	    }
     	    if (tempArray[z] > highest){
     	    	highest = tempArray[z];
     	    }
     	}
     	$log.debug('Lowest and highest grades',lowest, highest);
     	
     	if(lowest !== highest){
     		$scope.grades = classModel.gradesArray.slice(lowest - 1,highest);
     	}
     	
   		for(var vi=0; vi<(data.violations||[]).length; vi++) {
   			var violation = data.violations[vi];
   			var student = students[violation.index];
   			violation.student = student;
   			for(var fei=0; fei<(violation.fieldErrors||[]).length; fei++) {
   				var fieldError = violation.fieldErrors[fei];
   				var fieldName = fieldError.field;
   				violation[fieldName] = {invalid: true, invalidValue: student[fieldName]};  
   				$log.debug("violation: ", violation);
   				$log.debug("violation[", fieldName, "]: ", violation[fieldName]);
     		}
   			if( (violation.recordErrors||[]).length > 0 ) {
   				$log.debug("Record violation for index :", violation.index, ": ", violation.recordErrors[0]);
   				var dupErrorCode = violation.recordErrors[0].code;
   				var dupField = dupErrorCode === "error.duplicate.student.name" ? "firstName" : "studentId";
   				violation[dupField] = {invalid: true, invalidValue: student[dupField]};
   				$log.debug("violation[", dupField, "]: ", violation[dupField]);
   			}
     	} 
     	studentRecords = data;
     	if(data.records.length !== 0)
     		{
     			window.violations = data.violations;
     			window.studentsList = data.records;
     			$scope.studentsUploaded = true;
     			$scope.studentList = data.records;
     			$scope.violations = data.violations;
     			$scope.editMode = false;
     			$scope.loader.loading = false;
     		}
     };
     
	var csvUploadValidateSuccessHandler = function(data){
		$log.debug("In import Success Handler",data);
		if(data.code === 'error.not.logged.in'){
			userModel.reset();
			$uibModalStack.dismissAll();
			$location.path('/signin/staff');
			return;
		}
		$log.debug("Records: ", data.records);
		$scope.disableConfirm = false;
		$scope.loader.loading = false;
		$scope.csvClosePopup(data);
	};

    var validateStudentsErrorHandler = function(error){
    	$scope.loader.loading = false;
    	$scope.disableUpload = false;
    	//MB: Needed to include this as change event was not getting triggered if same file was uploaded twice.
    	angular.forEach(
			    angular.element("input[type='file']"),
			    function(inputElem) {
			      angular.element(inputElem).val(null);
			    });
     	$log.error("In import error handler",error);
     	$scope.errorUploadingCSV = error.message || error.code || "An error occurred. Please try again.";
     	$scope.errorFlag = true;
     };
     
   
     var upsertStudentsSuccessHandler = function(students) {
    	 if(students.code === 'error.not.logged.in'){
				userModel.reset();
				$uibModalStack.dismissAll();
				$location.path('/signin/staff');
				return;
			}
    	 var importedStudents = "Successfully imported " + students.length + " students";
    	 $scope.disableConfirm = false;
    	 $scope.loader.loading = false;
    	 $scope.csvClosePopup(importedStudents);
     };
     
     var upsertStudentsErrorHandler = function(data) {
    	 if( data.students ) {
    		 validateStudentsSuccessHandler(data);
    	 }
    	 else {
    		 var error = data;
             $scope.errorFlag = true;
             $scope.errorUploadingCSV = error.message || error.code || "An error occurred. Please try again.";
    	 }
    	 $scope.disableConfirm = false;
    	 $scope.loader.loading = false;
     };
     
	$scope.validateCSV = function(value){
		$scope.loader.loading = true;
		var contextScope = sessionState.get('$scope');
        if (!!contextScope && contextScope.showYOYFeatures) {
            studentService.validateStudentsCsvAgainstOrg(value, selectedClass.id).then(csvUploadValidateSuccessHandler, validateStudentsErrorHandler);
        } else {
            studentService.validateStudentsCSV(value, selectedClass.id).then(validateStudentsSuccessHandler, validateStudentsErrorHandler);
        }
    };
	
	$scope.validateStudents = function(students) {
		$log.debug("In validateStudents. students: ", students);
		studentService.validateStudents(students, selectedClass.id).then(validateStudentsSuccessHandler, validateStudentsErrorHandler );
	};
	
	$scope.upsertStudents = function(students) {
		$scope.editMode = false;
		$log.debug("In upsertStudents. students: ", students);
		$scope.disableConfirm = true;
		$scope.loader.loading = true;
		studentService.upsertStudents(students, selectedClass.id).then(upsertStudentsSuccessHandler, upsertStudentsErrorHandler);
	};
	
	$scope.csvClosePopup = function(msg){
		$log.debug("In csvClosePop: ", msg);
        var contextScope = sessionState.get('$scope');
        if (!!contextScope && contextScope.showYOYFeatures) {
        	msg = !msg ? { status: 'cancel' } : msg;
        }
        $uibModalInstance.close(msg);
    };
	
	$scope.toggleEditMode = function(){
		$scope.editMode = !$scope.editMode;
		if( $scope.editMode ){
			$scope.editStudentList = angular.copy($scope.studentList);
			$log.debug('In editmode: Copied new list: ', $scope.editStudentList);
		}
	};
	
	$scope.closeAlert = function(){
		$scope.errorUploadingCSV = "";
        $scope.errorFlag = false;
    };
	
	$scope.toggleConfirmPopup = function(){
		$scope.confirmCancel = !$scope.confirmCancel;
	}
	
}]);
