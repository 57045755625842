angular.module('portalApp').directive('subMenuBarDirective', function () {
    return {
        restrict: 'EA',
        scope: {
            orgAndSubscriptions: "=",
            subscriptionsForOrg: "=",
            hideDropDown: "=?",
        },
        controller: 'SubMenuBarController',
        templateUrl: 'resources/js/Modules/subscriptions/subscriptionSubMenuBar/subMenuBar.template.html'
    };
});