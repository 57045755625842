/**
 * AddNewStudentsController
 *
 * @author Manit Banker
 */

angular.module('portalApp').controller('AddNewStudentsController', [
    '$scope', '$location', 'classService', '$routeParams', '$log',
    'orgService', 'classModel', 'userService', 'navModel', 'productModel',
    'productService', '$uibModal', 'SDMAnalyticsService', 'sharedConstants',
    'sessionState', 'settingsService',
    function ($scope, $location, classService, $routeParams, $log,
              orgService, classModel, userService, navModel, productModel,
              productService, $uibModal, SDMAnalyticsService, sharedConstants,
              sessionState, settingsService) {
        var $this = this;

        $this.onInit = onInit;
        $this.getSelectedClass = getSelectedClass;

        $scope.newStudentGroupControl = {};
        $scope.editMode = true;
        $scope.classId = $routeParams.sectionId;

        $scope.orgId = '';
        $scope.sectionId = $routeParams.sectionId;
        $scope.initialOnboarding = $location.path().indexOf('firstclass') > -1;
        $scope.showElPref = false;
        $scope.showAddStudentGroup = false;

        $this.onInit();

        $scope.validateStudents = function () {
            $scope.newStudentGroupControl.validate();
        };

        $scope.addStudents = function () {
            $scope.newStudentGroupControl.addStudents();
        };

        $scope.backToEditMode = function () {
            $scope.editMode = true;
            $scope.editmode = true;
            $scope.$broadcast('editmode', true);
        };

        $scope.importCSVWindow = function () {
            $uibModal.open({
                templateUrl: 'resources/js/Modules/students/importCSVPopup/importCSVPopup.html',
                controller: 'ImportCSVPopupController',
                resolve: {
                    selectedClass: function () {
                        return $scope.selectedClass;
                    }
                }
            }).result.then(function (data) {
                if (data !== '') {
                    if (!$scope.noStudentAccess) {
                        $location.path('/students/firstclass/print/' + $scope.selectedClass.id);
                    } else {
                        $location.path('/students/firstclass/onboardinginstructions');
                    }
                }
            });
        };

        $scope.upsertStudentsSuccessHandler = function (students) {
            try {
                SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_EVENT, 'initialOnBoarding', 'selectedClass');
                SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_EVENT, $scope.initialOnBoarding, $scope.selectedClass);
            } catch (e) {
                $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_EVENT, e);
            }
            if (!$scope.noStudentAccess) {
                $location.path('/students/firstclass/print/' + $scope.selectedClass.id);
            } else {
                $location.path('/students/firstclass/onboardinginstructions');
            }
        };

        $scope.goToDashboard = function() {
            productModel.reset();

            try {
                SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_ABORT_EVENT, 'initialOnBoarding', 'selectedClass');
                SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_ABORT_EVENT, $scope.initialOnBoarding, $scope.selectedClass);
            } catch (e) {
                $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.ADD_NEW_STUDENT_ABORT_EVENT, e);
            }

            $location.path('/products');
        };

        function getSelectedClass() {
            classService.getClassSection($scope.sectionId).then(function (data) {
                $scope.selectedClass = data;

                if (!$scope.selectedClass.gradeArray) {
                    $scope.selectedClass.gradeArray = classModel.calculateGradeRangeArray($scope.selectedClass);
                }

                $scope.studentArray = [{
                    student: {
                        grade: 'Select Grade'
                    }
                }];
                $scope.showAddStudentGroup = true;

                $log.debug('Selected Class', $scope.selectedClass);
            }, function (error) {
                $log.debug('Error getting selected class details', error);
            });
        }

        function onInit() {
            sessionState.store('$scope',$scope);
            settingsService.setSettings(sessionState)
                .then(function(settings) {
                    userService.getCurrentUser().then(function (currentUser) {
                        $log.debug('User signed in', currentUser);

                        orgService.getSchoolsWithTeacherRole().then(function (data) {
                            $log.debug('Schools found on user profile', data);

                            if (data.length === 0) {
                                $log.debug('No schools on user profile');
                                $location.path('/products');
                                return;
                            } else {
                                $scope.schools = data;
                            }

                            var orgCookie = productModel.getCookieForOrgAsTeacher();

                            if (!orgCookie) {
                                $scope.orgId = $scope.schools[0].id;
                            } else {
                                $scope.orgId = orgCookie.orgId;
                            }

                        });

                        if (classModel.showElPref) {
                            $scope.showElPref = true;
                        }

                        productService.getSubscriptions().then(function (subscriptions) {
                            $log.debug('Reload subscription list in order to set the subscriptionModel on refresh', subscriptions);
                        });

                        productService.getEntitlement().then(function (data) {
                            $log.debug('Reload entitlement list in order to set the productModel on refresh', data);
                        });

                        if (productModel.redeemedSubscription) {
                            $scope.redeemedSubscription = productModel.redeemedSubscription;
                            var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                            if (!isNoStudentAccess) {
                                $scope.noStudentAccess = true;
                            }
                        }

                        if (!$scope.redeemedSubscription && $scope.initialOnboarding) {
                            $location.path('/products');
                        }

                        $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);
                        $this.getSelectedClass();

                        navModel.showNav = true;
                        navModel.helpPage = false;
                    }, function (error) {
                        $log.error('User not signed in', error);
                        $location.path('/signin/staff');
                    });
                });
        }
    }
]);
