angular.module('portalApp').directive('googleImportModalButton', [
    '$log','gapiService','$uibModal','$window','notificationModel',
    '$timeout','$uibModalStack','$location',
    function ($log,gapiService,$uibModal,$window,notificationModel,
              $timeout,$uibModalStack,$location) {
        return {
            restrict: 'E',
            scope: {
                importtype: '=',
                importbuttonpresscounter: '=',
                importbuttontext: "=",
                courses: "=",
                gradelevels: "=",
                schools: "=",
                importstaffid: '=',
                importsource: "=",
                selectedclass: "=?",
                selectedschoolyear: "=",
                showyoyfeatures: "=",
                classlist: "=",
                error: "=",
                errorFlag: "=?",
            },
            template: '\n'+
                '<div ng-if="importtype !== \'onboarding\'" class="google-manage-access-import-from-google-buttons-container">\n' +
                '    <div class="google-manage-access-import-from-google-button" ng-click="beginImport()">\n' +
                '        <img class="import-courses-from-google-button-img" alt="{{importbuttontext}}"\n' +
                '             src="resources/images/google/s2c_square_36x36_yellow_stroke_icon@1x.png"/>\n' +
                '        <span class="import-courses-from-google-button-text">{{importbuttontext}}</span>\n' +
                '    </div>\n' +
                '</div>\n' +
                '\n' +
                '<div ng-if="importtype === \'onboarding\'" class="import-courses-from-google-buttons-container">\n' +
                '    <div id="import-courses-from-google-button" ng-click="beginImport()">\n' +
                '        <img class="import-courses-from-google-button-img" alt="{{importbuttontext}}"\n' +
                '            src="resources/images/google/s2c_square_36x36_yellow_stroke_icon@1x.png"/>\n' +
                '        <span class="import-courses-from-google-button-text">{{importbuttontext}}</span>\n' +
                '    </div>\n' +
                '</div>\n'+
                '\n' +
                '<div ng-if="spinner" class="loaderGIF">\n' +
                '    <img src="resources/images/loading_blue.gif"/>\n' +
                '</div>\n',
            controller: 'importButtonController'
        }
    }
]);