portalModule.service("orgService",[
	'$http', '$q', 'apiResponse', 'userService', 'orgModel',
	'userModel', '$log', 'productModel',
	function(
		$http, $q, apiResponse, userService, orgModel,
		userModel, $log, productModel){
	return{
		getOrgData: getOrgData,
		getSchoolsWithTeacherRole: getSchoolsWithTeacherRole,
		getStaffAssociations : getStaffAssociations,
		reloadIfMissingTeacherRoleForSchool : reloadIfMissingTeacherRoleForSchool,
		getSchoolYearsForTeacher : getSchoolYearsForTeacher,
		getSchoolYearsYOYForTeacher : getSchoolYearsYOYForTeacher,
		searchOrgsByZip : searchOrgsByZip,
		UpdateOverrideStartDateForChildOrg : UpdateOverrideStartDateForChildOrg,
		OverrideChildSchoolToDistrictCalendar: OverrideChildSchoolToDistrictCalendar
	};
	
	function loadOrgRoles() {
		var request = $http({
            method: "get",
            url: PORTAL_API_ENDPOINT + "/composite/staff/org-roles"
        });		 
	 
		return  apiResponse.makeApiCall(request).then(function(orgRoles) {
			
				var orgs= [];
				for(var i=0; i<orgRoles.length; i++) {
					orgs[i] = orgRoles[i].org;
					orgs[i].roles = orgRoles[i].roles;
				}
				orgModel.organizationList = orgs;
				return orgs;
			}
		);
	}
	
	function getOrgData() {
		if( orgModel.organizationList !== undefined ) {
			return $q.when(orgModel.organizationList);
		}
		return userService.ensureIsStaffMember().then(loadOrgRoles);
	}

	function getStaffAssociations() {
		if( orgModel.organizationList !== undefined ) {
			return $q.when(orgModel.organizationList);
		}

		var request = $http({
			method: "get",
			url: PORTAL_API_ENDPOINT + "/composite/roster/organizations/" + orgModel.organizationList.id + "/staff-associations/" + userModel.currentUser.staff.id
		});

		return apiResponse.makeApiCall(request).then(function(response){

		});
	}


	function getSchoolsWithTeacherRole() {
		return getOrgData().then(function(orgs) {
			return (orgs||[]).filter(function(orgWithRole, index) {
				//return orgWithRole.orgType === 'school' &&  $.inArray("teacher", orgWithRole.roles) >= 0 ;
				return $.inArray("teacher", orgWithRole.roles) >= 0 ;
			});
		});
	}
	
	function reloadIfMissingTeacherRoleForSchool(schoolId) {
		if( orgModel.isTeacherInSchool(schoolId) ) {
			$log.debug("Already has teacher role for ", schoolId, " in model");
			return $q.when(false);
		}
		$log.debug("Already does not have teacher role for ", schoolId, " in model. Reloading");
		orgModel.reset();
		return getOrgData();				
	}

	function getSchoolYearsForTeacher(){
		var url = PORTAL_API_ENDPOINT + '/composite/staff/school-calendars';

		var request = $http({
			method: "get",
			url: url
		});
		return apiResponse.makeApiCall(request);
	}

	function getSchoolYearsYOYForTeacher(organizationId) {
    var url =
      PORTAL_API_ENDPOINT +
      "/composite/staff/organization/" +
      organizationId +
      "/school-calendars";

    var request = $http({
      method: "get",
      url: url
    });

    var deferred = $q.defer();

    apiResponse
      .makeApiCall(request)
      .then(function(calendars) {
        // On success set the current calendar year in dumbleData
        var teacherCookie = productModel.getCookieForOrgAsTeacher();
        var calendarId;

        orgModel.setCalendarsForOrg(organizationId, calendars);

        if (!!teacherCookie) {
          calendarId = teacherCookie.schoolCalendarId;
        }

        var selectedCalendar = orgModel.getCalendarById(organizationId, calendarId);
        if (selectedCalendar) {
          window.dumbleDataHelpers.updateSchoolYear(selectedCalendar);
        }

        var currentCalendar = calendars.filter(function(calendar) {
          return calendar.currentCalendar;
        })[0];

        if (currentCalendar) {
          window.dumbleDataHelpers.updateCurrentSchoolYear(currentCalendar);
        }

        deferred.resolve(calendars);
      })
      .catch(deferred.reject);

    return deferred.promise;
  }
	
	function searchOrgsByZip(zip){
	    var url = PORTAL_API_ENDPOINT + '/composite/staff/organizations/byzip/' + zip;
        
        var request = $http({
            method: "get",
            url: url
        });
        return apiResponse.makeApiCall(request);
	}
	
	function UpdateOverrideStartDateForChildOrg(orgId, overrideSchoolYear, schoolStart) {
        var urlEndPoint = PORTAL_API_ENDPOINT + '/composite/staff/organizations/';
        var params = {
            overrideSchoolYearStart: overrideSchoolYear,
            schoolStart: schoolStart
        };

        var request = $http({
            method: "PATCH",
            url: urlEndPoint + orgId,
            data: params
        });

        return  apiResponse.makeApiCall(request);
    }

    function OverrideChildSchoolToDistrictCalendar(orgId) {
        ///composite/staff/{staffId}/organization/{orgId}/map/school-calendars
        var urlEndPoint = PORTAL_API_ENDPOINT + '/composite/staff/organization/' + orgId + '/map/school-calendars';

        var request = $http({
            method: "PUT",
            url: urlEndPoint
        });

        return  apiResponse.makeApiCall(request);

    }
    
}]);