portalModule.controller("revokeEntitlementPopupController",['$scope','teacher','subscriptionId','$uibModalInstance','productService', '$log',function($scope,teacher,subscriptionId,$uibModalInstance,productService, $log){
	$log.debug("revokeEntitlementPopupController Teacher:", teacher, " subscriptionId: ", subscriptionId);
	$scope.teacher = teacher;
	$scope.errorRemovingTeacher = '';
	
	$scope.closePopup = function(response){
		$uibModalInstance.close(response);
	}
	
	$scope.revokeAccess = function(){
		productService.revokeTeacherAccess(subscriptionId,$scope.teacher.id).then(revokeTeacherAccessSuccessHandler,revokeTeacherAccessErrorHandler);
	}
	
	$scope.closeAlert = function(){
		$scope.errorRemovingTeacher = '';
	}

	function revokeTeacherAccessSuccessHandler(data){
		$log.debug("In revoke teacher success handler",data);
		$scope.errorRemovingTeacher = '';
		$scope.closePopup(data);
	}
	
	function revokeTeacherAccessErrorHandler(error){
		$log.debug("In revoke teacher error handler",error);
		$scope.errorRemovingTeacher = error.message || error.code || "An error occurred. Please try again.";
	}
	
}]);