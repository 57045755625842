(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule.service('reportsService',
            ['$http', '$q', '$log', 'apiResponse', 
            function($http, $q, $log, apiResponse){
        
                return{
                   getSectionReportForTeacher :  getSectionReportForTeacher,
                   getReportByTeacher : getReportByTeacher
                }
                
                function getSectionReportForTeacher(params){
                    var url = PORTAL_API_ENDPOINT + '/composite/staff/reports/students';
                    
                    var request = $http({
                        method: "get",
                        url: url,
                        params: params
                    });
                    return apiResponse.makeApiCall(request);
                }
                
                function getReportByTeacher(params,orgId,orgType){
                    var url = PORTAL_API_ENDPOINT + '/composite/staff/reports/'+ orgType + '/' + orgId ;
                    
                    var request = $http({
                        method: "get",
                        url: url,
                        params: params
                    });
                    return apiResponse.makeApiCall(request);
                }
        
    }]);
    
})();