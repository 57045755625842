(function (window, document, undefined) {
    portalModule.service("SDMAnalyticsService", SDMAnalyticsService);

    SDMAnalyticsService.$inject = ['$log'];

    function SDMAnalyticsService($log) {

        var argNamesByType = {};
        var globalListeners = [];
        var queuedEvents = [];

        return {
            emit: emit,
            registerListener: registerListener,
            addEventType: addEventType
        };

        function addEventType(eventName) {
            argNamesByType[eventName] = Array.prototype.slice.call(arguments, 1);
        }

        function publishEvent(event) {
            var callbackStartTime, callbackEndTime, elapsedTime;
            var ANALYTICS_TOO_LONG_THRESHOLD_MILLIS = 50;
            $log.debug("Emitting event ", event, " to " + globalListeners.length, " listeners");
            globalListeners.forEach(function (callback) {
                callbackStartTime = new Date().getTime();
                try {
                    callback(event);
                } catch (e) {
                    $log.error("Error occurred when invoking analytics function. Error: ", e, " Callback: ", callback);
                }
                callbackEndTime = new Date().getTime();
                elapsedTime = callbackEndTime - callbackStartTime;
                if (elapsedTime > ANALYTICS_TOO_LONG_THRESHOLD_MILLIS) {
                    $log.warn("Callback handler took a while. " + elapsedTime + " milliseconds");
                }
            });
        }

        function emit(eventName) {
            var argNames = argNamesByType[eventName];
            if (!argNames) {
                $log.error("SDMAnalyts: Invalid event name ", eventName);
                return;
            }
            var event = {
                eventTime: new Date().getTime(),
                eventName: eventName
            };
            for (var i = 0; i < argNames.length && i < arguments.length - 1; i++) {
                event[argNames[i]] = arguments[i + 1];
            }
            if (arguments.length > argNames.length + 1) {
                event.extraArgs = Array.prototype.slice.call(arguments, argNames.length + 1);
            }
            if (globalListeners.length > 0) {
                publishEvent(event);
            } else {
                $log.warn("No listeners for events. Queueing in case one gets added later. Event: ", event);
                queuedEvents.push(event);
            }
        }

        function registerListener(listener) {
            globalListeners.push(listener);
            if (queuedEvents.length > 0) {
                $log.debug("Publishing the queued events ", queuedEvents, " to the first subscriber");
                for (var i = 0; i < queuedEvents.length; i++) {
                    publishEvent(queuedEvents[i]);
                }
                queuedEvents = [];
            }
        }
    }
})(window, document);