(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule.controller("reportingController",
            ['$scope', '$log', '$location', 'productModel', 'userModel', 'subscriptionModel', 'userService', 'navModel', 'reportsService', 'productService', 'classService', 'sharedConstants', 'orgService', 'orgModel', 'classModel', 'enrollmentService', '$filter', '$window', 'settingsService', 'appModel',
             function($scope, $log, $location, productModel, userModel, subscriptionModel, userService, navModel, reportsService, productService, classService, sharedConstants, orgService, orgModel, classModel, enrollmentService, $filter, $window, settingsService, appModel){
                
                var $this = this;
                $scope.isCleverUser = false;
                $scope.showAppDropdown = true;
                $scope.hideReports = false;
                $scope.fromDate = {
                        opened : false
                };
                $scope.fromDateOpen = function() {$scope.fromDate.opened = true;};
                $scope.toDate = {
                        opened : false
                };
                $scope.toDateOpen = function() {$scope.toDate.opened = true;};
                $scope.dateOptions = {
                        formatYear: 'yy',
                        startingDay: 1,
                        showWeeks : false
                };
                
                $scope.userTypeList = ['all','staff','student'];
                $scope.userType = $scope.userTypeList[0];
                $scope.userRoles = [];
                var allProducts = { 'application': {'id': 0, 'applicationCode': 'allproducts', 'name': 'All' }};
                
                function stringToDate(s) {
                    var m = $window.moment(s);
                    return new Date(m.year(), m.month(), m.date());
                }   
                
                function dateToString(d) {
                    return $window.moment(d).format('YYYYMMDD');
                }
                
                $this.getReportsForTeacher = function(){
                    $scope.error = '';
                    var params = {};
                    
                    if($scope.classSectionId){
                        params.sectionId = $scope.classSectionId;
                    }
                    
                    if($scope.selectedFromDate){
                        var fromDate = dateToString($scope.selectedFromDate);
                        params.startDate = fromDate;
                    }
                    
                    if($scope.selectedToDate){
                        var toDate = dateToString($scope.selectedToDate);
                        params.endDate = toDate;
                    }
                    
                    if($scope.selectedAppCode){
                        params.app = $scope.selectedAppCode;
                    }
                    
                    params.groupBy = 'student';
                    
                    reportsService.getSectionReportForTeacher(params).then(function(data){
                        $log.debug('Section reports for teacher',data);
                        $scope.hideReports = false;
                        $scope.selectedFromDate = stringToDate(data.criteria.filters.startDate);
                        $scope.selectedToDate = stringToDate(data.criteria.filters.endDate);
                        $scope.reportHeaders = data.headers;
                        $scope.reportRows = data.rows;
                        $scope.reportTotals = data.totals;
                    },function(error){
                        $log.debug('Error while getting section reports',error);
                        $scope.hideReports = true;
                        $scope.error = error.message  || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    });
                }
                
                $this.getReportsForAdmin = function(){
                    $scope.error = '';
                    var params = {};
                    var orgId = $scope.subscriptionsForOrg.id;
                    var orgType;
                    
                    if($scope.userType){
                        params.userType = $scope.userType;
                    }
                    
                    if($scope.selectedFromDate){
                        var fromDate = dateToString($scope.selectedFromDate);
                        params.startDate = fromDate;
                    }
                    
                    if($scope.selectedToDate){
                        var toDate = dateToString($scope.selectedToDate);
                        params.endDate = toDate;
                    }
                    
                    if($scope.selectedAppCode){
                        params.app = $scope.selectedAppCode;
                    }
                    
                    if($scope.userRole == 'districtAdmin'){
                        orgType = 'district'
                        params.groupBy = 'org';
                    }
                    else{
                        orgType = 'org'
                        params.groupBy = 'teacher'; 
                    }
                    
                    reportsService.getReportByTeacher(params,orgId,orgType).then(function(data){
                        $log.debug('Admin reports by teacher',data);
                        $scope.hideReports = false;
                        $scope.selectedFromDate = stringToDate(data.criteria.filters.startDate);
                        $scope.selectedToDate = stringToDate(data.criteria.filters.endDate);
                        $scope.reportHeaders = data.headers;
                        $scope.reportRows = data.rows;
                        $scope.reportTotals = data.totals;
                    },function(error){
                        $log.debug('Error while getting section reports',error);
                        $scope.hideReports = true;
                        $scope.error = error.message  || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    });
                }
                
                $this.setAppDropdown = function(subscriptionList){
                    var subscriptionList = subscriptionList;
                    for(var i=0; i<subscriptionList.length; i++){
                        for(var j=i+1; j<subscriptionList.length; j++){
                            if(subscriptionList[i].application.id == subscriptionList[j].application.id){
                                subscriptionList.splice(j,1);
                                j--;
                            }
                        }
                    }
                    $log.debug('Unique app list',subscriptionList);
                    if(subscriptionList.length !=0){
                        $scope.showAppDropdown = true;
                        var tempApps = angular.copy(subscriptionList);
                        $scope.apps = tempApps;
                        if($scope.apps.length > 1){
                            
                           if($scope.apps[0].application.id !== 0){
                               $scope.apps.unshift(allProducts);
                           }
                           
                           if(appModel.getCurrentApp()){
                               var currentApp = $scope.apps.filter(function(app,index){
                                   return app.application.id == appModel.getCurrentApp().appId;
                               })[0];
                               
                               if(!currentApp){
                                  $scope.selectedApp = $scope.apps[0].application;
                               }else{
                                  $scope.selectedApp = currentApp.application; 
                               }
                           }
                           else{
                               $scope.selectedApp = $scope.apps[0].application;
                           }
                        }
                        else{
                            $scope.selectedApp = $scope.apps[0].application;
                            appModel.setCurrentApp($scope.selectedApp.id);
                        }
                        
                        $scope.selectedAppId = $scope.selectedApp.id;
                        $scope.selectedAppCode = $scope.selectedApp.applicationCode;
                        $scope.selectedAppName = $scope.selectedApp.name;
                    }
                    else{
                        $scope.showAppDropdown = false;
                    }
                    $scope.loadReports();
                }

                $this.loadEntitlements = function(){
                    
                    if(!$scope.selectedClass){
                        return;
                    }
                   
                    enrollmentService.getStudentEnrollment($scope.selectedClass.id).then(function(data){
                        $log.debug('Entitlements for class',data);
                        $this.setAppDropdown(data.subscriptions);
                    },function(error){
                        $log.debug('Error getting entitlements',error);
                        $scope.error = error.message  || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";
                    });
                }; 
                
                $this.setDefaultClass = function(classList){
                    
                    if($scope.isCleverUser){
                        classList = $filter('filter')(classList, function(value, index, array){
                            return (value.active == true);
                        })
                    }
                    
                    $scope.classList = classModel.classList = classList;
                    var selectedClass;
                    
                    if( productModel.getCookieForOrgAsTeacher() ){
                        if( productModel.getCookieForOrgAsTeacher().classId === undefined){
                            //MB: If classId is not present in orgAsTeacher cookie, then default to the first class of the current org
                            var currentOrgId = productModel.getCookieForOrgAsTeacher().orgId;
                            selectedClass = classList.filter(function(section, index){
                                return section.organizationId == currentOrgId;
                            })[0];
                            if(!selectedClass){
                                selectedClass = classList[0]; 
                            }
                        }
                        else{
                            //MB: If classId is present in orgAsTeacher cookie, then default to that class
                            var currentClassId = productModel.getCookieForOrgAsTeacher().classId;
                            selectedClass = classList.filter(function(section, index){
                                return section.id == currentClassId;
                            })[0];
                        }
                    }
                    else{
                        //MB: If orgAsTeacher cookie is not set or returns undefined, default to the first class in the list
                        selectedClass = classList[0];
                    } 
                    
                    $scope.selectedClass = selectedClass;
                    $scope.className = selectedClass.displayName;
                    $scope.classSectionId = selectedClass.id;
                    var org = classModel.getClassAssociation(orgModel.organizationList,$scope.selectedClass);
                    $scope.orgId = org.id
                    $scope.orgName = org.name;
                    $log.debug('Selected class',$scope.selectedClass,'Org',org);
                    $this.loadEntitlements();
                };
                
                $this.setDefaultSchoolForAdmin = function(){
                    if($scope.userRole === 'districtAdmin'){
                       $scope.adminOrgAndSubs = subscriptionModel.filterDistrictAdminOrgs();
                    }
                    else{
                        $scope.adminOrgAndSubs = subscriptionModel.filterSchoolAdminOrgs(); 
                    }
                    
                    if ($scope.adminOrgAndSubs.length > 1) {
                        if (subscriptionModel.getCookieForOrgAsAdmin()) {
                            if($scope.userRole === 'districtAdmin'){
                                var currentOrg = subscriptionModel.getDistrictAdminOrgWithSub(subscriptionModel.getCookieForOrgAsAdmin().orgId)[0]; 
                            }
                            else{
                                var currentOrg = subscriptionModel.getSchoolAdminOrgWithSub(subscriptionModel.getCookieForOrgAsAdmin().orgId)[0];  
                            }
                            
                            if(!currentOrg){
                                $scope.subscriptionsForOrg = $scope.adminOrgAndSubs[0];
                                subscriptionModel.setCookieForOrgAsAdmin($scope.adminOrgAndSubs[0].id); 
                            }
                            else{
                                $scope.subscriptionsForOrg = currentOrg;
                            }
                        } else {
                            $scope.subscriptionsForOrg = $scope.adminOrgAndSubs[0];
                            subscriptionModel.setCookieForOrgAsAdmin($scope.adminOrgAndSubs[0].id);
                        }
                    }
                    else{
                        $scope.subscriptionsForOrg = $scope.adminOrgAndSubs[0];
                        subscriptionModel.setCookieForOrgAsAdmin($scope.adminOrgAndSubs[0].id);  
                    }
                    
                    $this.setAppDropdown($scope.subscriptionsForOrg.subscription);
                }
                
                $this.getClassListForTeacher = function(){
                    classService.getClassesForTeacher().then(function(classList) {
                        if( !classList || classList.length === 0 ) {
                            $scope.hideReports = true;
                            if($scope.isCleverUser){
                                $scope.error = sharedConstants.CLEVER_NO_CLASS_MESSAGE;
                            }
                            else{
                                $scope.error = sharedConstants.NON_CLEVER_NO_CLASS_MESSAGE;
                            }
                            return;
                        }
                        else {
                            $this.setDefaultClass(classList);
                        }
                    },function(error){
                        $log.debug('Error getting sections for teacher',error);
                        if(error.code == 'error.not.logged.in'){
                            userModel.reset();
                            $location.path('/signin/staff');
                        }
                        $scope.error = error.message  || error.code || "Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.";   
                    });
                };
                
                
                $scope.changeClass = function (selectedClass) {
                    $log.debug("Selected Class value:", selectedClass);
                    $scope.selectedClass = selectedClass;
                    $scope.className = $scope.selectedClass.displayName;
                    $scope.classSectionId = $scope.selectedClass.id;
                    var org = classModel.getClassAssociation(orgModel.organizationList, $scope.selectedClass);
                    $scope.orgId = org.id;
                    $scope.orgName = org.name;
                    $this.loadEntitlements();
                    productModel.setCookieForOrgAsTeacher($scope.orgId, $scope.classSectionId);
                    userModel.currentOrg = org;
                };
                
                $scope.loadReports = function(){
                    if($scope.userRole == 'districtAdmin' || $scope.userRole == 'schoolAdmin'){
                        $this.getReportsForAdmin();
                    }
                    else{
                        $this.getReportsForTeacher();
                    } 
                }
                
                $scope.changeRole = function(user,index){
                    $scope.userRole = $scope.userRoles[index];
                    if(user === 'teacher'){
                        $scope.viewAdminReport = false;
                        $this.getClassListForTeacher();
                    }
                    else{
                        $scope.viewAdminReport = true;
                        $this.setDefaultSchoolForAdmin();  
                    }
                  
                }
                
                $scope.changeOrg = function(org){
                    $scope.subscriptionsForOrg = org;
                    subscriptionModel.setCookieForOrgAsAdmin(org.id);  
                    $this.setAppDropdown(org.subscription);
                };
                
                $scope.changeUserType = function(user){
                    $scope.userType = user;
                    $scope.loadReports(); 
                };
                
                $scope.changeApp = function (selectedApp){
                    $scope.selectedApp = selectedApp;
                    $scope.selectedAppId = $scope.selectedApp.id;
                    $scope.selectedAppCode = $scope.selectedApp.applicationCode;
                    $scope.selectedAppName = $scope.selectedApp.name;
                    $scope.loadReports();
                    appModel.setCurrentApp($scope.selectedAppId);
                };
                
                $scope.changeFilters = function(){
                    $scope.loadReports();
                };
                
                $scope.closeAlert = function () {
                    $scope.error = '';
                };
                
                $this.onInit = function(){
                    
                    userService.getCurrentUser().then(function(currentUser){
                        $log.debug('User signed in',currentUser);
                        
                        settingsService.getSettings().then(function(settings) {
                            if( !settings.showReports ) {
                                $location.path('/');
                                return;
                            }
                        });
                        
                        if(userModel.currentUser.staff && userModel.currentUser.staff.identifiers.source && userModel.currentUser.staff.identifiers.source.toLowerCase() == 'clever'){
                            $scope.isCleverUser = true;
                        }
                        
                        orgService.getSchoolsWithTeacherRole().then(function(data){
                            $log.debug('Reload org list');
                        });
                        
                        productService.getSubscriptions().then(function(subscriptions){
                            $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                            
                            productService.getEntitlement().then(function(data){
                                $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                                
                                if(subscriptionModel.hasSubsAsAdmin() && productModel.hasAppsAsTeacher()){
                                    if(subscriptionModel.filterDistrictAdminOrgs().length !== 0 && subscriptionModel.filterSchoolAdminOrgs().length !== 0){
                                        $scope.userRoles = ['districtAdmin','schoolAdmin','teacher'];
                                    }
                                    else if(subscriptionModel.filterDistrictAdminOrgs().length !== 0 && !subscriptionModel.filterSchoolAdminOrgs().length !== 0){
                                        $scope.userRoles = ['districtAdmin','teacher']; 
                                    }
                                    else{
                                        $scope.userRoles = ['schoolAdmin','teacher']; 
                                    }
                                    
                                    $scope.userRole = $scope.userRoles[0];
                                    $scope.viewAdminReport = true;
                                    $this.setDefaultSchoolForAdmin();
                                }
                                else if(subscriptionModel.hasSubsAsAdmin() && !productModel.hasAppsAsTeacher()){
                                    if(subscriptionModel.filterDistrictAdminOrgs().length !== 0 && subscriptionModel.filterSchoolAdminOrgs().length !== 0){
                                        $scope.userRoles = ['districtAdmin','schoolAdmin'];
                                    }
                                    else if(subscriptionModel.filterDistrictAdminOrgs().length !== 0 && !subscriptionModel.filterSchoolAdminOrgs().length !== 0){
                                        $scope.userRoles = ['districtAdmin'];
                                    }
                                    else{
                                        $scope.userRoles = ['schoolAdmin'];
                                    }
                                    $scope.userRole = $scope.userRoles[0];
                                    $scope.viewAdminReport = true;
                                    $this.setDefaultSchoolForAdmin();
                                }
                                else if(!subscriptionModel.hasSubsAsAdmin() && productModel.hasAppsAsTeacher()){
                                    $scope.userRoles = ['teacher'];
                                    $scope.viewAdminReport = false;
                                    $this.getClassListForTeacher();
                                }
                                else{
                                    $location.path('/');
                                }
                            });
                        });
                        
                        navModel.showNav = true;
                        navModel.helpPage = false;

                    },function(error){
                        $log.error('User not signed in',error);
                        $location.path('/signin/staff');
                    });
                    
                };
                $this.onInit();
                  
            }]);
    
})();