portalModule.component('redeemAccessCode', {
    bindings: {
        handleSubmit: '&',
        handleHelpLink: '&',
        handleNewHelpLink : '&',
        accessCode: '<'
    },
    templateUrl: ['$attrs', function ($attrs) {
        if ($attrs.hasOwnProperty('template') && $attrs.template === 'banner') {
            return 'resources/js/Modules/accessCode/redeemAccessCodeBanner.html'; 
        }
        return 'resources/js/Modules/accessCode/redeemAccessCodeComponent.html';
    }],
    controller: [function () {}],
    controllerAs: '$ctrl'
});
