portalModule.service("cookieService",
    ['$cookies', 'sharedConstants', '$location', function($cookies, sharedConstants, $location) {

        return {
            getCookie: getCookie,
            saveCookie: saveCookie,
            removeCookie :removeCookie,
            config: getConfig
        };

        function getHostName() {
          var hostname =
            window.location.hostname.indexOf("://") > -1
              ? window.location.hostname.split("/")[2]
              : window.location.hostname.split("/")[0];

          return hostname.split(":")[0].split("?")[0];
        }

        function getConfig() {
          var path = "/";
          var now = Date.now();
          var futureDate = now + sharedConstants.CURRENT_USER_COOKIE_TIMEOUT;
          var expires = new Date(futureDate).toUTCString();

          var hostname = getHostName(),
            domain = hostname,
            splitArr = hostname.split("."),
            arrLen = splitArr.length;

          if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
          }

          var isLocalHost = sessionStorage.getItem("localdev");
          var isSecure = !(!!isLocalHost && isLocalHost === "true");

          return {
            path: path,
            expires: expires,
            domain: domain,
            secure: isSecure
          };
        }

        function saveCookie(cookieName, value) {
            var options = getConfig();

            removeCookie(cookieName);

            if (typeof (value) === "object") {
                $cookies.putObject(cookieName,value,options);
            } else {
                if (options.secure) {
                    removeCookie(cookieName);
                    document.cookie = cookieName + "=" + value + ";expires=" + options.expires + ";path=/;Secure;domain=" + options.domain + ";";
                } else {
                    document.cookie = cookieName + "=" + value + ";expires=" + options.expires + ";path=/;domain=" + options.domain + ";";
                }
            }
        }

        function getCookie(cookieName) {

            var cookieValue = $cookies.get(cookieName);
            var retValue;

            if (!!cookieValue) {
                try {
                    // some cookies are saved as JSON objects
                    retValue = JSON.parse(cookieValue);
                } catch (err) {
                    retValue = cookieValue;
                }
            } else {
                retValue = cookieValue;
            }

            return retValue;
        }

        function removeCookie(cookieName) {
            var config = getConfig();
            delete config.expires;

            var hostConfig = getConfig();
            hostConfig.domain = document.location.hostname;
            delete hostConfig.expires;

            // delete from root and current domain
            $cookies.remove(cookieName, config);
            $cookies.remove(cookieName, hostConfig);
        }
    }]);