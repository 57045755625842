angular.module('portalApp').controller('ClassPrintController', [
    '$scope', '$rootScope', '$location', 'classService', '$routeParams',
    '$log', 'orgService', 'userModel', 'orgModel', 'classModel',
    'userService', 'navModel', 'sharedConstants', 'SDMAnalyticsService', 'productModel',
    'productService','studentService', '$window', '$timeout', 'easyLoginModel',
    'settingsService', 'studentModel',
    function($scope, $rootScope, $location, classService, $routeParams,
             $log, orgService, userModel, orgModel, classModel,
             userService, navModel, sharedConstants, SDMAnalyticsService, productModel,
             productService, studentService, $window, $timeout, easyLoginModel,
             settingsService, studentModel){

        var $this = this;
        $this.onInit = onInit;
        $this.getSelectedClass = getSelectedClass;
        $this.getStudentsInClass = getStudentsInClass;
        $this.generateEasyLoginUrl = generateEasyLoginUrl;
        $scope.classId = $routeParams.sectionId;
        $scope.initialOnBoarding = $location.path().indexOf('firstclass') > -1;
        $scope.fromEasyLoginInst = $location.path().indexOf('easylogin') > -1 || false;
        $scope.easyLoginAvatars = easyLoginModel.getEasyLoginAvatars();
        $scope.accountSecretArray = easyLoginModel.getEasyLoginSecrets();
        $scope.schoolEasyLoginAvatars = easyLoginModel.getSchoolEasyLoginAvatars();
        $scope.printList = true;
        $scope.showElPref = false;

        var host = $location.host();
        $scope.finalHostUrl;
        if (host === 'localhost' || host === 'l.scholastic-labs.io') {
            $scope.finalHostUrl = location.host + '/dp-portal';
        } else {
            $scope.finalHostUrl = host;
        }

        $this.onInit();

        function generateEasyLoginUrl() {
            if($scope.selectedClass.easyLogin && $scope.selectedClass.easyLogin.enabled){
                if ($scope.selectedClass.easyLogin.showInEasyLoginSchool) {
                    classService.getSchoolEasyLoginCred($scope.selectedClass.organizationId).then(function(data){
                        $scope.schoolEasyLoginCred = data;
                        $scope.easyLoginUrl = $scope.finalHostUrl + '/#/sz/' + $scope.schoolEasyLoginCred.orgId + '/' + $scope.schoolEasyLoginCred.token;
                        $scope.schoolEasyLoginEnabled = true;
                    }, function(error){
                        $scope.easyLoginUrl = $scope.finalHostUrl + '/#/cz/' + $scope.selectedClass.id + '/' + $scope.selectedClass.easyLogin.token;
                        $scope.schoolEasyLoginEnabled = false;
                    });
                } else {
                    $scope.easyLoginUrl = $scope.finalHostUrl + '/#/cz/' + $scope.selectedClass.id + '/' + $scope.selectedClass.easyLogin.token;
                    $scope.schoolEasyLoginEnabled = false;
                }
            }
        }

        function getSelectedClass(){
            classService.getClassSection($scope.classId).then(function(data){
                $scope.selectedClass = data;
                if($scope.selectedClass.easyLogin && $scope.selectedClass.easyLogin.enabled){
                    $scope.easyLoginEnabled = true;
                    for (var avatar in $scope.schoolEasyLoginAvatars) {
                        if ($scope.selectedClass.easyLogin.iconIndex == $scope.schoolEasyLoginAvatars[avatar].id) {
                            $scope.selectedClass.avatar = $scope.schoolEasyLoginAvatars[avatar];
                            break;
                        }
                    }
                    $scope.selectedClass.teacherDisplayName = userModel.currentUser.staff.lastName;
                    $this.generateEasyLoginUrl();
                }
                else{
                    $scope.easyLoginEnabled = false;
                }
                $this.getStudentsInClass();
            }, function(error){
                $log.debug('Error getting selected class details', error);
            });
        }

        function getStudentsInClass(){
            studentService.getStudentsInClass($scope.selectedClass.id, true).then(function(data){
                $log.debug('Students belonging to the class', data);
                studentModel.studentList = data;
                $scope.studentList = studentModel.filterActiveStudents(true);

                if ($scope.initialOnBoarding && !$scope.studentList.length) {
                    $scope.goToInstructions();
                }

                if($scope.selectedClass.easyLogin.enabled){
                    for(var student in $scope.studentList){
                        for(var avatar in $scope.easyLoginAvatars){
                            if($scope.studentList[student].credentials.easyLoginAvatarId == $scope.easyLoginAvatars[avatar].id){
                                $scope.studentList[student].avatar = $scope.easyLoginAvatars[avatar];
                                break;
                            }
                        }
                        for(var secret in $scope.accountSecretArray){
                            if($scope.studentList[student].credentials.easyLoginSecret == $scope.accountSecretArray[secret].id){
                                $scope.studentList[student].secret = $scope.accountSecretArray[secret];
                                break;
                            }
                        }
                    }
                }
            }, function(error){
                $log.debug('Error getting students in class', error);
            });
        }

        $scope.downloadAsCsv = function() {
            var csvString = '';

            try {
                SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, 'selectedClass','initialOnBoarding');
                SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, $scope.selectedClass, $scope.initialOnBoarding);
            } catch (e) {
                $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, e);
            }

            $log.debug("Downloading student information");

            csvString = 'First Name,Last Name,Student ID,Grade,Username,Password';
            csvString = csvString + "\n";

            if (!!$scope.studentList && $scope.studentList.length > 0  && !!$scope.selectedClass) {
                $scope.studentList.forEach(function (student) {
                    var studentId = student.studentId === undefined ? '' : student.studentId;
                    csvString = csvString + student.firstName +  ',' + student.lastName + ',' + studentId + ',' +
                        student.grade + ',' + student.credentials.username + ',' + student.credentials.password;
                    csvString = csvString + "\n";
                });
                csvString = csvString.substring(0, csvString.length - 1);
                csvString = csvString + "\n";

                var exportedFilenmae = $scope.selectedClass.displayName + '_studentInformation.csv';

                if (navigator.msSaveBlob) { // IE 10+
                    var blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var element = document.createElement('a');
                    element.style = "display:none";
                    element.href = 'data:application/octet-stream;base64,' + btoa(csvString);
                    element.download = exportedFilenmae;
                    element.target = "_blank";
                    element.click();
                }
            }
        }

        $scope.printInstructions = function(instructionType){

            try {
                SDMAnalyticsService.addEventType(sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, 'selectedClass','initialOnBoarding');
                SDMAnalyticsService.emit(sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, $scope.selectedClass, $scope.initialOnBoarding);
            } catch (e) {
                $log.error('Error while emitting event: ' + sharedConstants.SDM_ANALYTICS_EVENTS.PRINT_INSTRUCTIONS_EVENT, e);
            }

            $log.debug("Print Instruction type", instructionType);
            var type = instructionType;
            if (type == 'pageBreak') {
                $scope.printList = false;
                $scope.printStyle = true;
            } else if (type == 'pageTearOff') {
                $scope.printList = false;
                $scope.printStyle = false;
            } else {
                $scope.printList = true;
            }

            $timeout(function () {
                $scope.$apply();
                $window.print();
            }, 0);
        };

        $scope.goToInstructions = function(){
            $location.path('/students/firstclass/onboardinginstructions');
        };

        $scope.goBackToDashboard = function(){
            $location.path('/products');
        };

        $scope.backToAdd = function() {
            $location.path('/students/firstclass/addnewstudents/'+ $scope.classId);
        };

        $scope.cancel = function(){
            var route = $scope.fromEasyLoginInst ? '/students/settings/' + $scope.selectedClass.id + '/el' : '/students';
            $location.path(route);
        };

        function onInit(){

            userService.getCurrentUser().then(function(currentUser){
                $log.debug('User signed in',currentUser);

                if(classModel.showElPref){
                    $scope.showElPref = true;
                }

                settingsService.getSettings().then(function(settings) {
                    $log.debug('Settings: ', settings);
                    if(settings.showEasyLogin){
                        $scope.showEasyLogin = true;
                    }
                });

                productService.getSubscriptions().then(function(subscriptions){
                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                });

                productService.getEntitlement().then(function(data){
                    $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                });

                if(productModel.redeemedSubscription){
                    $scope.redeemedSubscription = productModel.redeemedSubscription;
                    var isNoStudentAccess = productModel.checkIfNoStudentAccess(productModel.redeemedSubscription);
                    if(!isNoStudentAccess){
                        $scope.noStudentAccess = true;
                    }
                }

                if(!$scope.redeemedSubscription && $scope.initialOnBoarding){
                    $location.path('/products');
                }

                $log.debug('Redeemed subscription: ', $scope.redeemedSubscription);
                $this.getSelectedClass();
                navModel.showNav = true;
                navModel.helpPage = false;
            },function(error){
                $log.error('User not signed in',error);
                $location.path('/signin/staff');
            });
        }
    }
]);