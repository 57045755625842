(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule.controller("EasyLoginController",
                            ['$scope',
                             '$http',
                             '$location',
                             '$rootScope',
                             'userService',
                             'orgService',
                             'userModel',
                             '$log',
                             'navModel',
                             'orgModel',
                             '$routeParams',
                             'loginModel',
                             '$cookies',
                             'sharedConstants',
                             'SDMAnalyticsService',
                             'appModel',
                             'classModel',
                             'subscriptionModel',
                             'studentModel',
                             'sessionModel',
                             'productModel',
                             'notificationModel',
                             'easyLoginModel',
                             '$document',
                             function($scope, $http, $location, $rootScope, userService, 
                                      orgService, userModel, $log, navModel, orgModel, 
                                      $routeParams, loginModel, $cookies, sharedConstants, 
                                      SDMAnalyticsService, appModel, classModel, subscriptionModel, 
                                      studentModel, sessionModel, productModel, notificationModel, 
                                      easyLoginModel, $document){
                                
                                var $this = this;
                                $this.onInit = onInit;
                                $this.getEasyLoginDetails = getEasyLoginDetails;
                                $scope.classId = $routeParams.classId;
                                $scope.classSecret = $routeParams.classSecret;
                                $scope.error = false;
                                $scope.showErrorPage = false;
                                $scope.schoolEasyLoginEnabled = false;
                                $scope.initialized = false;
                                $this.onInit();
                                
                                $scope.chooseAccount = function(student){
                                    $log.debug('Selected student: ', student);
                                    $location.path('/cz/pwd/' + $scope.classId + '/' + $scope.classSecret + '/' + student.id);
                                }
                                
                                $scope.redirectToLoginPage = function(){
                                    $location.path('/signin');
                                };
                                
                                $scope.goBack = function(){
                                    $location.path('/sz/' + $scope.easyLoginDetails.schoolSettings.orgId + '/' + $scope.easyLoginDetails.schoolSettings.token);
                                };
                                
                                function getEasyLoginDetails(){
                                    userService.getEasyLoginClassDetails($scope.classId, $scope.classSecret).then(function(data){
                                        $log.debug('Easy login class details', data);
                                        $scope.easyLoginDetails = data;
                                        if($scope.easyLoginDetails.schoolSettings) {
                                            $scope.schoolEasyLoginEnabled = true;
                                        }
                                        if($scope.easyLoginDetails.students.length < 1) {
                                            if($scope.schoolEasyLoginEnabled) {
                                                $location.path('/sz/' + $scope.easyLoginDetails.schoolSettings.orgId + '/' + $scope.easyLoginDetails.schoolSettings.token);
                                                notificationModel.setMessage('Sorry, this class is not available. Please contact your teacher for assistance.');
                                            } else {
                                                $scope.showErrorPage = true;
                                            }
                                        }
                                        $scope.initialized = true;
                                    },function(error){
                                        $log.debug('Error getting easy login class details', error);
                                        $scope.error = true;
                                        $scope.initialized = true;
                                    });
                                }
                                
                                function onInit() {
                                    
                                    userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                                        $log.debug("User already logged in", currentUser);
                                        $location.path('/');
                                    }, function (error) {
                                        appModel.reset();
                                        classModel.reset();
                                        navModel.reset();
                                        orgModel.reset();
                                        subscriptionModel.reset();
                                        studentModel.reset();
                                        userModel.reset();
                                        sessionModel.reset();
                                        productModel.reset();
                                        easyLoginModel.reset();
                                        $log.debug("User not signed in", error);
                                        $this.getEasyLoginDetails();
                                    });
                                    
                                    navModel.showFooter = true;
                                }
                                
                            }]);
})();