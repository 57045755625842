(function(){
    
    /**
     * 
     * @author Manit Banker
     */
    
    portalModule
        .controller('ClassEasyLoginPreferencesController',
                    ['$scope', '$location', 'classService', '$log', '$routeParams', 'orgService', 'userModel', 'orgModel', 'classModel', 'userService', 'productService', 'navModel', 'sharedConstants', 'SDMAnalyticsService', 'productModel', 'notificationModel', 'settingsService','sessionState',
                     function($scope, $location, classService, $log, $routeParams, orgService, userModel, orgModel, classModel, userService, productService, navModel, sharedConstants, SDMAnalyticsService, productModel, notificationModel, settingsService,sessionState) {
                        
                        var $this = this;
                        $this.onInit = onInit;
                        $this.loadClassDetails = loadClassDetails;
                        $this.getEasyLoginStatus = getEasyLoginStatus;
                        $scope.currentClassId = $routeParams.sectionId;
                        if($location.path().indexOf('createfirstclass') > -1){
                            $scope.initialOnBoarding = true
                        }else{
                            $scope.initialOnBoarding = false;
                        }
                        $scope.classEasyLoginToggle = false;
                        $scope.schoolEasyLoginAllowed = false;
                        $scope.errorMessage = '';
                        
                        function loadClassDetails(){
                            classService.getClassSection($scope.currentClassId).then(function (sectionData) {
                                $log.debug("In class.loadSection(" + $scope.selectedClassId + "). sectionData=", sectionData);
                                $scope.classSection = sectionData;
                                $this.getEasyLoginStatus();
                                $scope.classEasyLoginToggle = $scope.classSection.easyLogin.enabled;
                                if($scope.classSection.easyLogin.showInEasyLoginSchool) {
                                    $scope.classEasyLoginToggle = false;
                                }
                            }, function(error){
                                $scope.errorMessage = error.message || error.code || 'Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.';
                            });
                        }
                        
                        function getEasyLoginStatus() {
                            classService.getEasyLoginStatus($scope.classSection.organizationId).then(function(data){
                                if(data.status && (data.status.toLowerCase() === 'enabled' || data.status.toLowerCase() === 'disabled')) {
                                    $scope.schoolEasyLoginAllowed = true;
                                }
                            }, function(error){
                                if(error.code && error.code === 'error.easylogin.org.no.subs') {
                                    $scope.schoolEasyLoginAllowed = false;
                                }
                            });
                        }
                        
                        $scope.closeAlert = function(){
                            $scope.errorMessage = '';
                        }
                        
                        $scope.goBackToDashboard = function(){
                            $location.path('/products');
                        }
                        
                        $scope.toggleEasyLogin = function(){
                            if($scope.classSection.easyLogin.enabled && !$scope.classEasyLoginToggle) {
                                $scope.classEasyLoginToggle = true;
                                $scope.classSection.easyLogin.showInEasyLoginSchool = false;
                            } else {
                                $scope.classSection.easyLogin.enabled = !$scope.classSection.easyLogin.enabled;
                                $scope.classEasyLoginToggle = $scope.classSection.easyLogin.enabled;
                            }
                        };
                        
                        $scope.toggleSchoolEasyLogin = function(){
                            $scope.classSection.easyLogin.showInEasyLoginSchool = !$scope.classSection.easyLogin.showInEasyLoginSchool;
                            if($scope.classSection.easyLogin.showInEasyLoginSchool) {
                                $scope.classSection.easyLogin.enabled = true;
                                $scope.classEasyLoginToggle = false;
                            } else {
                                $scope.classSection.easyLogin.enabled = false;
                            }
                        };
                        
                        $scope.updateClassSection = function(){
                            classService.updateClassSection($scope.classSection).then(function(updatedSection){
                                $log.debug('Updated section details', updatedSection);
                                try{
                                    if (classModel.classList == undefined) {
                                        classModel.classList = [];
                                    }
                                    SDMAnalyticsService.addEventType(sharedConstants.UPDATE_CLASS_EVENT, 'updatedClass', 'existingClasses', 'orgId');
                                    SDMAnalyticsService.emit(sharedConstants.UPDATE_CLASS_EVENT, updatedSection, classModel.classList, updatedSection.organizationId);
                                } catch (e) {
                                    $log.debug(sharedConstants.CREATE_UPDATE_CLASS_EVENT_ERROR);
                                }
                                classModel.reset();
                                classModel.selectedClassId = updatedSection.id;
                                if($scope.initialOnBoarding){
                                    if(productModel.isSRCTeacher) {
                                        $location.path('/students/firstclass/searchAdd/' + updatedSection.organizationId + "/" + updatedSection.id);
                                    } else {
                                        $location.path('/students/firstclass/addnewstudents/' + updatedSection.id);
                                    }
                                } else{
                                    if ($scope.showYOYFeatures) {
                                        $location.path('/students/' + $routeParams.schoolCalendarId + "/" +  $scope.currentClassId);
                                    } else {
                                        $location.path('/students');
                                    }
                                }
                            },function(error){
                                $scope.errorMessage = error.message || error.code || 'Oops. We seem to be experiencing some technical difficulty. Sorry for the inconvenience. Please try again later.';
                            });
                        };
                        
                        function onInit(){
                            sessionState.store('$scope',$scope);
                            userService.getCurrentUser().then(function(currentUser){
                                $log.debug('User signed in',currentUser);

                                settingsService.setSettings(sessionState).then(function(settings) {
                                    $log.debug('Settings: ', settings);
                                    if(!settings.showEasyLogin){
                                        $location.path('/products');
                                    }
                                });
                                
                                orgService.getSchoolsWithTeacherRole().then(function(data){
                                    $log.debug('Reload org list');
                                });
                                
                                productService.getSubscriptions().then(function(subscriptions){
                                    $log.debug('Reload subscription list in order to set the subscriptionModel on refresh',subscriptions);
                                });
                                
                                productService.getEntitlement().then(function(data){
                                    $log.debug('Reload entitlement list in order to set the productModel on refresh',data);
                                });
                                
                                navModel.showNav = true;
                                navModel.helpPage = false;
                                $this.loadClassDetails();
                            },function(error){
                                $log.error('User not signed in',error);
                                $location.path('/signin/staff');
                            });
                        }
                        $this.onInit();
                    }
                    ]);
})();