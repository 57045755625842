portalModule.component('gradeSettingInput', {
    bindings: {
        options: '<gradeOptions',
        minimum: '<min'
    },
    require: {
        ngModel: 'ngModel'
    },
    templateUrl: 'resources/js/Modules/lti/gradeSetting/gradeSettingInput.html',
    controller: [
        function () {
            var ctrl = this;

            ctrl.minimum = 0;

            ctrl.$onInit = function () {
                ctrl.ngModel.$render = function () {
                    ctrl.grade = ctrl.ngModel.$viewValue;
                };
            };

            ctrl.changeGrade = function (grade) {
                ctrl.ngModel.$setViewValue(grade);
                ctrl.ngModel.$render();
            };
        }
    ],
    controllerAs: '$ctrl'
});