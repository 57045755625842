angular.module('portalApp').controller('importButtonController',[
    '$scope', '$log', 'gapiService', '$uibModal', '$window',
    'notificationModel', '$timeout', '$uibModalStack', '$location', 'orgService',
    'orgModel', 'studentService','sharedConstants','userModel','enrollmentService',
    'classService', '$route',
    function ($scope, $log, gapiService, $uibModal, $window,
              notificationModel, $timeout, $uibModalStack, $location, orgService,
              orgModel, studentService, sharedConstants, userModel, enrollmentService,
              classService, $route) {
            var $this = this;

            $this.isNoClassGcModalOpen = false;
            $this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR = 'To get started using Scholastic resources in your classroom' +
                ', you will need to import your classes and students from Google Classroom.';
            $this.GOOGLE_IMPORT_ERROR_MESSAGE =
                "Something went wrong and your class could not be imported. Go to Google Classroom and check that the " +
                "class name and section do not include emojis, special characters, or special fonts, and that all " +
                "students are set up with a first name and last name. Then, try importing or resyncing your class in " +
                "SDM again.";

            $this.gapiRequests = [];
            $this.gapiRequestsPromise = undefined;

            $this.importClassPromises = undefined;
            $this.searchPromises = undefined;
            $this.unassignPromises = undefined;
            $this.updateAssignmentPromises = undefined;
            $this.createAssignmentPromises = undefined;
            $this.importStudentPromises = undefined;
            $this.autoEntitlementPromises = undefined;

            $this.openTeachersWithNoStudentsModal = openTeachersWithNoStudentsModal;
            $this.cancelGapiTimeOut = cancelGapiTimeOut;
            $this.deselectClass = deselectClass;
            $this.verifyIfDuplicatePresent = verifyIfDuplicatePresent;
            $this.handleGapiRequests = handleGapiRequests;
            $this.checkCourseRemaining = checkCourseRemaining;
            $this.importCourseHandler = importCourseHandler;
            $this.importCourse = importCourse;
            $this.importStudentHandler = importStudentHandler;
            $this.importStudentDataHandler = importStudentDataHandler;
            $this.importErrorHandler = importErrorHandler;
            $this.handleSelectedCourseData = handleSelectedCourseData;
            $this.importCourses = importCourses;
            $this.autoUpdateStudentEntitlements = autoUpdateStudentEntitlements;

            $scope.errorRetrievingData = '';
            $scope.classInstanceAllowedForImport = {};

            $scope.teacherHasStudents = false;
            $scope.selectedGoogleClasses = [];
            $scope.sdmClassCallsRemaining = 0;
            $scope.sdmClassClassComplete = false;

            $scope.fetchedCourses = [];
            $scope.inactiveClasses = [];

            /**
             * Dismisses all modals on location change start,
             * such as when the back button is pressed.
             */
            $scope.$on('$locationChangeStart', function () {
                $uibModalStack.dismissAll();
            });

            $scope.setError = function(message, errorFlag) {
              notificationModel.setMessage(message, errorFlag);
              $scope.error = message;
              $scope.errorFlag = errorFlag;
            };

            $scope.beginImport = function(){
                /** onboarding (default), import, resync **/
                $scope.spinner = true;
                $scope.importbuttonpresscounter++;
                switch ($scope.importtype) {
                    case 'resync':
                    case 'import':
                        $scope.googleImportClassesConfirmDetailModal($scope.selectedGoogleClasses, $scope.importtype);
                        break;
                    default: // 'onboarding'
                        $scope.googleImportClassesConfirmDetailModal($scope.selectedGoogleClasses);
                }
            };

            function openTeachersWithNoStudentsModal() {
                $log.debug('no active courses with students');
                $scope.spinner = false;
                $this.isNoClassGcModalOpen = true;
                $uibModal.open({
                    controller: 'googleImportNoClassMessageModalController',
                    templateUrl: 'resources/js/Modules/google/import-no-class-message-modal/google-import-no-class-message-modal.template.html',
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (msg) {
                    $log.debug('modal result msg', msg);
                });
            }

            $scope.startGapiTimeout = function(duration){
                $scope.gapiTimer = $timeout(function(){
                    $log.debug('gapi timeout, $scope.courses', $scope.courses);
                    if ($this.isNoClassGcModalOpen) {
                        $this.isNoClassGcModalOpen = false;
                        $this.cancelGapiTimeOut();
                    } else if(!$scope.courses || (!!$scope.courses && $scope.courses.length <=0)){
                        $scope.spinner = false;
                        $uibModalStack.dismissAll();
                        $log.debug('setMessage', $this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR);
                        $scope.setError($this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR, true);
                        $log.debug('$scope.importtype', $scope.importtype);
                        gapiService.refreshPage('dashboard');
                    }
                }, duration);
            };

            function verifyIfDuplicatePresent(googleCourseList, sdmCourseList) {
                $scope.classInstanceAllowedForImport = {};

                sdmCourseList.forEach(function (sdmClass) {
                    if ($scope.classInstanceAllowedForImport[sdmClass.displayName.toLowerCase().trim()] === undefined) {
                      $scope.classInstanceAllowedForImport[sdmClass.displayName.toLowerCase().trim()] = {};
                    }

                    $scope.classInstanceAllowedForImport[sdmClass.displayName.toLowerCase().trim()][sdmClass.identifiers.sourceId] = {
                      orgId: sdmClass.organizationId,
                      imported: true
                    };
                });

                googleCourseList.forEach(function (googleClass) {
                    if ($scope.classInstanceAllowedForImport[googleClass.name.toLowerCase().trim()] === undefined) {
                        $scope.classInstanceAllowedForImport[googleClass.name.toLowerCase().trim()] = {};
                    }

                    if ($scope.classInstanceAllowedForImport[googleClass.name.toLowerCase().trim()][googleClass.id] === undefined) {
                      $scope.classInstanceAllowedForImport[googleClass.name.toLowerCase().trim()][googleClass.id] = {};
                    }

                    $scope.classInstanceAllowedForImport[googleClass.name.toLowerCase().trim()][googleClass.id].section = googleClass.section;
                });
            }

            function cancelGapiTimeOut() {
                $log.debug('cancel gapi timeout');
                $timeout.cancel($scope.gapiTimer);
                $scope.spinner = false;
            }

            function checkCourseRemaining(totalNumberOfCourses) {
                if (totalNumberOfCourses === 0) {
                    $scope.sdmClassCallsRemaining = 0;
                    $this.cancelGapiTimeOut();
                }
            }

            function handleGapiRequests(activeCoursesList) {
                var totalNumberOfCourses = 0;

                $scope.inactiveClasses = [];

                $this.gapiRequests = activeCoursesList.map(function (course) {
                    return gapiService.assignGapiStudentsListPromise(course);
                });

                $this.gapiRequestsPromise = Promise.all($this.gapiRequests);
                $this.gapiRequestsPromise.then(function () {
                    $log.debug('Promises all activeCoursesList', activeCoursesList);
                    $scope.courses = activeCoursesList.filter(function (course) {
                        $log.debug('$scope.selectedclass', $scope.selectedclass);
                        $log.debug('course.students ', course.students);
                        $log.debug('$scope.teacherHasStudents ', $scope.teacherHasStudents);

                        // returns all the students for the selected class if import type = resysc and course has more than 0 student
                        // else returns all the courses which more than 0 student
                        if ($scope.importtype === 'resync'
                            && !!$scope.selectedclass
                            && $scope.selectedclass.identifiers) {
                            !(!!course.students && course.students.length > 0) ? course.students = [] : $scope.teacherHasStudents = true;
                            return (course.id === $scope.selectedclass.identifiers.sourceId)
                        } else {
                            !(!!course.students && course.students.length > 0) ? course.students = [] : $scope.teacherHasStudents = true;
                            return (!!course.students && course.students.length > 0);
                        }
                    });

                    $scope.$apply();

                    totalNumberOfCourses = $scope.courses.length;
                    if (!$scope.teacherHasStudents && $scope.importType !== 'resync') {
                        $scope.spinner = false;
                        $this.openTeachersWithNoStudentsModal();
                    } else {
                        $scope.spinner = true;
                        $scope.sdmClassCallsRemaining = 1;
                        gapiService.getSdmClassesForGoogleTeacherPromise().then(function (data) {
                            var sdmClasses = data;
                            $log.debug('getSdmClassesForGoogleTeacherPromise sdmClasses', sdmClasses);
                            if (!!sdmClasses && sdmClasses.length > 0) {
                                // Check if there are duplicate class names in the google API result
                                // Add the duplicate classess in an Array
                                $this.verifyIfDuplicatePresent($scope.fetchedCourses, sdmClasses);

                                sdmClasses.forEach(function (sdmClass) {
                                    // set studentsList for each sdmClass on the sdmClass b4 next set
                                    $scope.spinner = true;

                                    // check if an active SDM class's corresponding GC Course was deleted
                                    if (sdmClass.active) {
                                        var googleCourse = $scope.fetchedCourses.find(function(course) {
                                            return course.id === sdmClass.identifiers.sourceId;
                                        });

                                        if (!googleCourse) {
                                            // we have an active SDM class where the GC course was deleted
                                            // we need to set this class as inactive
                                            var inactiveClass = angular.copy(sdmClass);
                                            $scope.inactiveClasses.push(inactiveClass);
                                        }
                                    }

                                    gapiService.getSdmStudentsForGoogleTeachersSdmClassesPromise(sdmClass)
                                        .then(function (sdmSectionStudents) {
                                            $log.debug('getSdmStudentsForGoogleTeachersSdmClassesPromise ' +
                                                'sdmSectionStudents', sdmSectionStudents, 'sdmClass', sdmClass);
                                            sdmClass.students = sdmSectionStudents;
                                        });
                                });

                                // if there are no active courses to process but we have inactive classes
                                // go ahead bypass courses remaining
                                if (!totalNumberOfCourses && $scope.inactiveClasses.length) {
                                    $this.checkCourseRemaining(0);
                                }

                                // set each sdmClass on it's respective googleCourse
                                activeCoursesList.forEach(function (googleCourse, googleCourseIndex) {
                                    sdmClasses.forEach(function (sdmClass) {
                                        if (!!sdmClass.identifiers
                                            && !!sdmClass.identifiers.sourceId
                                            && googleCourse.id === sdmClass.identifiers.sourceId) {
                                            activeCoursesList[googleCourseIndex].sdmClass = sdmClass;
                                            $log.debug('sdmClass exists', activeCoursesList[googleCourseIndex]);
                                            // grab teachers list for sdmClass which match googleCourse
                                            $scope.spinner = true;
                                            gapiService.refreshGapiCourseTeachersListPromise(googleCourse)
                                                .then(function (response) {
                                                    activeCoursesList[googleCourseIndex].teachers = response.result.teachers;
                                                    $log.debug('teachers exist', activeCoursesList[googleCourseIndex]);
                                                    $scope.spinner = false;
                                                });
                                        }
                                    });
                                    $this.checkCourseRemaining(--totalNumberOfCourses);
                                });
                            } else {
                                // there are no SDM classes to fetch
                                $log.debug('no sdmClasses', sdmClasses);
                                if (!!$scope.courses && $scope.courses.length === 0) {
                                    // there are no active or inactive google courses, so there is no data for this user
                                    $log.debug('no active courses with students');
                                    $scope.setError($this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR, true);
                                    $log.debug('$scope.importtype', $scope.importtype);
                                    gapiService.refreshPage('dashboard');
                                    $scope.spinner = false;
                                } else {
                                    // there are active GC courses that may be imported, bypass courses remaining
                                    $log.debug('has active courses with students stay on page');
                                    $this.checkCourseRemaining(0);
                                }
                            }
                        });
                    }
                }).catch(function(error) {
                  // disable the spinner, set the notification message, and refresh the page
                  var message = (error && error.message) ? error.message : sharedConstants.ERROR_PROBLEM_UNKNOWN;

                  $scope.spinner = false;

                  $scope.setError(message, true);
                  gapiService.refreshPage('students');
                });
            }

            $scope.getCourseList = function () {
                var _apiCallerFunction = 'getCourseList';
                var _thisAPIRequest = this;
                var courseState = 'ACTIVE';

                $scope.teacherHasStudents = false;
                $scope.spinner = true;
                $scope.sdmClassClassComplete = false;
                $scope.sdmClassCallsRemaining = 0;
                $scope.fetchedCourses = [];

                $scope.startGapiTimeout(15000);

                gapiService.refreshGapiCourseListPromise(userModel.currentUser.userIdentifiers.sourceId)
                    .then(function (response) {
                        $log.debug('refreshGapiCourseList response', response);

                        // this object response contains the list of all the courses
                        // from google classroom for that GC logged in teacher
                        var activeCoursesList;
                        // setup value of the objects
                        gapiService.showYOYFeatures = $scope.showyoyfeatures;

                        if ($scope.showyoyfeatures) {
                            gapiService.selectedSchoolYear = $scope.selectedschoolyear;
                        }

                        if (!!response.result.courses && response.result.courses.length > 0) {
                            $scope.fetchedCourses = response.result.courses;

                            // below will return only the record matching the currently selected class if import type is resync
                            // or will return only the Active courses from google if import type is not Resync
                            $scope.courses = activeCoursesList = $scope.fetchedCourses.filter(function (course) {
                                if ($scope.importtype === 'resync'
                                    && !!$scope.selectedclass
                                    && $scope.selectedclass.identifiers) {
                                    return (course.id === $scope.selectedclass.identifiers.sourceId)
                                } else {
                                    return (course.courseState === courseState);
                                }
                            });
                        } else {
                            $scope.setError($this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR, true);
                            $log.debug('$scope.importtype', $scope.importtype);
                            gapiService.refreshPage('dashboard');
                        }

                        $scope.spinner = true;

                        if (!!activeCoursesList && activeCoursesList.length > 0) {
                            $this.handleGapiRequests(activeCoursesList);
                        } else {
                            if ($scope.importtype === 'resync') {
                                if ($scope.selectedclass) {
                                    // we have no active classes but there is a selected class, so the selected class may have
                                    // been deleted in GC
                                    $this.handleGapiRequests([]);
                                    return;
                                }

                                // we have no active classes and no selected class, so the user must not have any data to resync
                                $scope.spinner = false;
                                $scope.setError($this.GOOGLE_NO_STUDENTS_IN_COURSES_ERROR, true);
                                $log.debug('$scope.importtype', $scope.importtype);
                                gapiService.refreshPage('dashboard');
                                return;
                            }

                            $scope.spinner = false;
                            $this.openTeachersWithNoStudentsModal();
                        }
                    })
                    .catch(function (error) {
                        if ((!!error && error.status === 401)
                            || (!!error && !!error.results && error.results.status === 401)
                            || (!!error && !!error.body && !!error.body.error && error.body.error.code === 401)) {
                            $log.debug('renew token, gapi client error', error);
                            $this.cancelGapiTimeOut();
                            gapiService.renewToken(_apiCallerFunction, _thisAPIRequest);
                        } else {
                            $scope.spinner = false;
                            $log.error('gapi init error', error);
                            // $this.getErrors(error);
                            $scope.setError(error.message, true);
                            $log.debug('$scope.importtype', $scope.importtype);
                            gapiService.refreshPage();
                        }
                    });
            };

            $scope.googleAssignMultipleGradesModal = function (currentClass, currentClassIndex, selectedClassesArray) {
                $scope.selectedClassesArray = selectedClassesArray;
                $scope.currentClass = currentClass;
                $scope.currentClassIndex = currentClassIndex;
                $scope.nextClass = selectedClassesArray[currentClassIndex + 1];
                $scope.nextClassIndex = currentClassIndex + 1;
                $scope.currentClass.highGrade = currentClass.lowGrade = '';
                $scope.currentClass.gradeArray = currentClass.gradesArray;
                // Note - 'Multi' and single student then highGrade and lowGrade will be the same, aka not 'Multi'
                var modal = $uibModal.open({
                    templateUrl: 'resources/js/Modules/google/import-assign-grade-levels-modal/google-assign-grade-levels-modal.html',
                    controller: 'googleAssignGradeLevelsModal',
                    resolve: {
                        googleAssignMultipleGradesModal: function() {
                            return $scope.googleAssignMultipleGradesModal;
                        },
                        selectedClassesArray: function () {
                            return $scope.selectedClassesArray;
                        },
                        currentClass: function () {
                            return $scope.currentClass;
                        },
                        currentClassIndex: function () {
                            return $scope.currentClassIndex;
                        },
                        nextClass: function() {
                            return $scope.nextClass;
                        },
                        nextClassIndex: function() {
                            return $scope.nextClassIndex;
                        }
                    },
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function (data) {
                    var clearCourseSelections = function () {
                        $scope.courses.forEach(function (course) {
                            delete course.selected;
                            delete course.school;
                            delete course.lowGrade;
                            delete course.highGrade;
                        });
                        $scope.selectedGoogleClasses = undefined;
                    };
                    if (data.message === 'cancelAll') {
                        clearCourseSelections();
                        return;
                    }
                    if (data.message === 'save') {
                        $scope.selectedGoogleClasses.forEach(function (course, index) {
                            if (!!data.selected && data.selected.id === course.id) {
                                $scope.selectedGoogleClasses[index] = data.selected;
                            }
                        });
                    }
                    if (data.message === 'importSelected') {
                        $scope.selectedGoogleClasses.forEach(function (course, index) {
                            if (!!data.selected && data.selected.id === course.id) {
                                $scope.selectedGoogleClasses[index] = data.selected;
                            }
                        });
                      $this.importCourses();
                    }
                });
            };

            function importCourseHandler(selectedClass) {
              // this code will get the correct calendar id for the school
              // if the school is a new school then it will push against the current org
              if (selectedClass.school &&
                $scope.selectedschoolyear &&
                $scope.selectedschoolyear.orgId &&
                selectedClass.school.id !== $scope.selectedschoolyear.orgId) {
                // below service is now modified to call the new GET API for school calendars
                return orgService.getSchoolYearsYOYForTeacher(selectedClass.school.id)
                  .then(function (data) {
                    if (data.length > 0) {
                      // Set base year to a variable which
                      // is the current calendar year
                      gapiService.selectedSchoolYear = orgModel.setBaseCalendarYear(data);
                      return $this.importCourse(selectedClass);
                    }
                  }).catch(function(error) { $this.importErrorHandler(error); });
              }

              return $this.importCourse(selectedClass);
            }

            function importCourse(selectedClass) {
              // return the promise to create/update the selected class
              return gapiService
                .createOrUpdateSdmGoogleCoursePromise(selectedClass, $scope.importstaffid, $scope.importsource)
                .then(function (sdmClass) {
                  return studentService.getStudentsInClass(sdmClass.id).then(function(sdmStudents) {
                    sdmClass.students = sdmStudents;
                    return sdmClass;
                  });
                }).catch(function(error) { $this.importErrorHandler(error); });
            }

            function importStudentHandler(googleStudent, sdmStudent, courses) {
              // Map out the student data before importing
              var studentObj = {};

              // if the backend returns null it may be casted to a string, so we need to check for that
              if (!!sdmStudent && sdmStudent !== "null") {
                studentObj = sdmStudent;
              }

              if (googleStudent.grade) {
                // if student is in multiple grade class, use the specified grade value
                studentObj.grade = googleStudent.grade.value;
              } else {
                // if student is not in multiple grade class, get the highest grade from all of
                // the courses they belong to
                var grades = [];
                courses.forEach(function(course) {
                  var studentIds = course.students.map(function(student) {
                    return student.userId;
                  });

                  if (studentIds.includes(googleStudent.userId)) {
                    grades.push(course.lowGrade.value);
                  }
                });

                // we sort all of the course grades and take the highest grade value
                grades.sort(function (a, b) {
                  if (a === 'pk' && b === 'k' ||
                    !isNaN(a) && isNaN(b)) {
                    return true;
                  }

                  if (a === 'k' && b === 'pk' ||
                    !isNaN(b) && isNaN(a)) {
                    return false;
                  }

                  return b - a;
                });

                studentObj.grade = grades[0];
              }

              studentObj.firstName = googleStudent.profile.name.givenName;
              studentObj.lastName = googleStudent.profile.name.familyName;

              if (!studentObj.identifiers) {
                studentObj.identifiers = {
                  source: "Google",
                  sourceId: googleStudent.userId
                };
              }

              if (!studentObj.id) {
                studentObj.id = -1;
              }

              studentObj.active = true;

              return {
                sdmStudent: studentObj,
                sourceId: googleStudent.userId,
                courses: []
              };
            }

            function importStudentDataHandler(studentsMap, googleStudent, sdmStudent, courses, courseMap) {
              // Handle the mapped student data before importing
              var data = $this.importStudentHandler(googleStudent, sdmStudent, courses);
              var studentData = studentsMap[data.sourceId];

              // create a new map value for the student if one does not exist
              if (!studentData) {
                studentData = studentsMap[data.sourceId] = {
                  sdmStudent: data.sdmStudent,
                  sourceId: data.sourceId,
                  courses: []
                };
              }

              // only include the appropriate courses for the student
              Object.keys(courseMap).forEach(function (courseId) {
                if (courseMap[courseId].length && courseMap[courseId].includes(data.sourceId)) {
                  studentData.courses.push(courseId);
                }
              });

              return data;
            }

            function importErrorHandler(error) {
              // disable the spinner, deselect all classes, set the notification message, and refresh the page

              var message = error.message ? error.message : $this.GOOGLE_IMPORT_ERROR_MESSAGE;

              $scope.spinner = false;
              $scope.selectedGoogleClasses.map(function(selectedClass) {
                $this.deselectClass(selectedClass);
              });

              $scope.setError(message, true);

              switch ($scope.importtype) {
                case "resync":
                case "import":
                  gapiService.refreshPage("students");
                  break;
                default:
                  // 'onboarding'
                  gapiService.refreshPage("onboarding");
              }
            }

            function checkForSpecialCharacters(toTest) {
              return /[^\u0020-\u007e\u00a0-\u00ff]/g.test(toTest);
            }

            function handleSelectedCourseData() {
              var errorMessage = '';

              // make a deep copy of the selected courses to refer to later
              var selectedCourses = $scope.selectedGoogleClasses.map(function(course) {
                return Object.assign({}, course);
              });

              for (var i = 0; i < selectedCourses.length; i++) {
                var course = selectedCourses[i];
                var students = [];

                if (checkForSpecialCharacters(course.name) ||
                    checkForSpecialCharacters(course.section)) {
                  errorMessage = $this.GOOGLE_IMPORT_ERROR_MESSAGE;
                }

                if (!!errorMessage) {
                  break;
                }

                for (var j = 0; j < course.students.length; j++) {
                  var student = course.students[j];

                  // filter out orphaned user data to treat them like students removed from a course
                  if (student.profile.name.fullName.toLowerCase() === 'unknown user') {
                    continue;
                  }

                  // ensure the student has a first name
                  if (!student.profile.name.givenName) {
                    errorMessage = student.profile.name.fullName + ' is missing a first name. Please make sure the student is set up with a first name and try again.';
                  }

                  // ensure the student has a last name
                  if (!student.profile.name.familyName) {
                    // in this case the student has no first or last name, no need to check for other errors
                    if (!!errorMessage) {
                      errorMessage = 'A student is missing both a first and last name. Please make sure the student is set up with a first and last name and try again.';
                      break;
                    } else {
                      errorMessage = student.profile.name.fullName + ' is missing a last name. Please make sure the student is set up with a last name and try again.';
                    }
                  }

                  // ensure the students name does not include any special characters/non latin-1 characters
                  if (checkForSpecialCharacters(student.profile.name.givenName) ||
                      checkForSpecialCharacters(student.profile.name.familyName)) {
                    if (!!errorMessage) {
                      errorMessage += " Additionally, the student's name contains a special character, please make sure their name does not include emojis, special characters, or special fonts and try again.";
                    } else {
                      errorMessage = student.profile.name.fullName + ' contains a special character, please make sure their name does not include emojis, special characters, or special fonts and try again.';
                    }
                  }

                  // if no errors add the student to the course
                  if (!errorMessage) {
                    students.push(student);
                  }
                }

                // reset the students for the course with orphaned users filtered out and no errors found
                selectedCourses[i].students = students;
              }

              return {
                errorMessage: errorMessage,
                selectedCourses: selectedCourses
              };
            }

            function importCourses() {
              // import all the selected courses
              $scope.spinner = true;

              // setup value of the objects
              gapiService.selectedSchoolYear = $scope.selectedschoolyear;
              gapiService.showYOYFeatures = $scope.showyoyfeatures;

              var students = [];
              var studentsMap = {};
              var courseMap = {};

              // check for student data errors and filter deleted students
              var selectedCourseData = handleSelectedCourseData();
              var errorMessage = selectedCourseData.errorMessage;
              var selectedCourses = selectedCourseData.selectedCourses;

              // show an error messsage and abort if there is an issue with the student data
              if (!!errorMessage) {
                $this.importErrorHandler({ message: errorMessage });
                return;
              }

              // are we currently resyncing an archived class?
              var archiving = $scope.importType === 'resync' && selectedCourses[0].courseState === 'ARCHIVED';

              var importClassPromises = selectedCourses.map(function(course) {
                if (!course) {
                  return;
                }

                // set the course orgId to the course school's id
                if (course.school && course.school.id) {
                  course.orgId = course.school.id;
                }

                // map out the students for each course by source id
                // we use this to know which classes students belong to in importStudentDataHandler
                if (course.students) {
                  courseMap[course.id] = course.students.map(function(student) {
                    return student.userId;
                  });
                  students = students.concat(course.students);
                }

                return importCourseHandler(course);
              });

              $this.importClassPromises = Promise.all(importClassPromises).then(function(sdmClasses) {
                  var importedClasses = sdmClasses;

                  // set the imported class data to each selected course
                  selectedCourses.forEach(function(selectedCourse) {
                    importedClasses.forEach(function(sdmClass) {
                      if (selectedCourse.id === sdmClass.identifiers.sourceId) {
                        selectedCourse.sdmClass = sdmClass;
                      }
                    });
                  });

                  // deselect all the courses
                  for (var i = 0; i < $scope.selectedGoogleClasses.length; i++) {
                    deselectClass($scope.selectedGoogleClasses[i]);
                  }

                  // remove duplicate students to reduce search calls
                  var duplicateStudentMap  = {};
                  students = students.filter(function(student) {
                    if (duplicateStudentMap[student.userId]) {
                      return false;
                    }

                    duplicateStudentMap[student.userId] = student;
                    return true;
                  });

                  var searchPromises = students.map(function(student) {
                    return studentService.getStudentBySourceId(student.userId)
                      .then(function(sdmStudent) {
                        // map out the student data with the search results
                        return importStudentDataHandler(studentsMap, student, sdmStudent, selectedCourses, courseMap);
                      }).catch(function(error) { $this.importErrorHandler(error); });
                  });

                  $this.searchPromises = Promise.all(searchPromises).then(function() {
                    var studentIds = Object.keys(studentsMap);
                    var unassignMap = {};

                    // check if there are any students that need to be unassigned from the class
                    selectedCourses.forEach(function(course) {
                      // do we have a valid sdm class?
                      if (course && course.sdmClass && course.sdmClass.students) {
                        // we should unassign a student if:
                        //  -  we can't find the student in the map, it is no longer in GC and needs to be unassigned
                        //  - the student is in the course's sdm class and we can't find the course in the student's courses,
                        //    it is no longer in the course and needs to be unassigned
                        var studentsToUnassign = [];

                        course.sdmClass.students.forEach(function(sdmStudent) {
                          var student = studentsMap[sdmStudent.identifiers.sourceId];
                          if (!student || (student && !student.courses.includes(course.id))) {
                            studentsToUnassign.push(sdmStudent.id);
                          }
                        });

                        unassignMap[course.sdmClass.id] = studentsToUnassign;
                      }
                    });

                    $this.unassignPromises = Promise.all(Object.keys(unassignMap)
                      .map(function(key) {
                        return gapiService.unassignStudents(key, unassignMap[key]);
                      }))
                      .then(function() {
                        var importedMap = {};
                        var createMap = {};
                        var createAssignMap = {};
                        var updateMap = {};
                        var updateAssignMap = {};

                        importedClasses.forEach(function(importedClass) {
                          importedMap[importedClass.id] = importedClass;
                          updateAssignMap[importedClass.id] = [];
                          createAssignMap[importedClass.id] = [];
                          createMap[importedClass.id] = [];
                          updateMap[importedClass.id] = [];
                        });

                        studentIds.forEach(function(studentId) {
                          var student = studentsMap[studentId];
                          var newStudent = student.sdmStudent.id === -1;

                          // get the student's sdm classes by sourceId
                          student.sdmStudent.classes = importedClasses.filter(function(sdmClass) {
                            return student.courses.includes(sdmClass.identifiers.sourceId);
                          });

                          if (archiving) {
                            // if we are archiving the class, do not create/update and assign the student
                            return;
                          }

                          // map the student's sdm classes to an object key with a value of an array containing the student's id
                          if (newStudent) {
                            // if this is a new student and we are not archiving the class, assign the student to its
                            // sdm classes after creating the student in the first class
                            createMap[student.sdmStudent.classes[0].id].push(student.sdmStudent);
                            student.sdmStudent.classes.forEach(function(sdmClass, index) {
                              if (index !== 0) {
                                createAssignMap[sdmClass.id].push(studentId);
                              }
                            });
                          } else {
                            // if this is not a new student and we are not archiving the class, assign the student to its
                            // sdm classes before updating
                            updateMap[student.sdmStudent.classes[0].id].push(student.sdmStudent);
                            student.sdmStudent.classes.forEach(function(sdmClass) {
                              updateAssignMap[sdmClass.id].push(student.sdmStudent.id);
                            });
                          }
                        });

                        $this.updateAssignmentPromises = Promise.all(Object.keys(updateAssignMap)
                          .map(function(key) {
                            return gapiService.assignStudents(key, updateAssignMap[key]);
                          }))
                          .then(function() {

                            var createStudentsPromises = Object.keys(createMap).map(function(key) {
                              return gapiService.createStudents( createMap[key], importedMap[key],  $scope.importType)
                                .then()
                                .catch(function(error) { $this.importErrorHandler(error); });
                            });

                            var updateStudentPromises = Object.keys(updateMap).map(function(key) {
                              return gapiService.updateStudents( updateMap[key], importedMap[key], $scope.importType)
                                .then()
                                .catch(function(error) { $this.importErrorHandler(error); });
                            });

                            $this.importStudentPromises = Promise.all([].concat(createStudentsPromises, updateStudentPromises))
                              .then(function(importedStudents) {
                                var createdStudents = [];

                                for (var i = 0; i< importedStudents.length; i++) {
                                  var studentArray = importedStudents[i];

                                  if (!studentArray) {
                                    $scope.setError($this.GOOGLE_IMPORT_ERROR_MESSAGE, true);
                                    return;
                                  } else {
                                    createdStudents = createdStudents.concat(studentArray[0]);
                                  }
                                }

                                $this.createAssignmentPromises = Promise.all(Object.keys(createAssignMap)
                                  .map(function(key) {
                                    var studentsToAssign = createAssignMap[key].map(function(sourceId) {
                                      var createdStudent = createdStudents.find(function(student) {
                                        return student.identifiers.sourceId === sourceId;
                                      });

                                      if (createdStudent && createdStudent.id) {
                                        return createdStudent.id;
                                      }
                                    });
                                    return gapiService.assignStudents(key, studentsToAssign);
                                  }))
                                  .then(function() {
                                    var autoEntitlementPromises = [];

                                    if ($scope.importType !== 'resync') {
                                      // do not auto entitle on resync
                                      autoEntitlementPromises = importedClasses.map(function(sdmClass) {
                                        return $this.autoUpdateStudentEntitlements(sdmClass.id);
                                      });
                                    }

                                    $this.autoEntitlementPromises = Promise.all(autoEntitlementPromises).then(function() {
                                      // hide the spinner, pop a success message, and refresh the page
                                      var action = $scope.importType === 'resync' ? 'updated' : 'imported';

                                      $scope.spinner = false;
                                      $scope.setError('Successfully ' + action + ' ' + selectedCourses.length + ' class(es) from your Google Classroom account!');

                                      if ($location.path().indexOf('import') > -1) {
                                        gapiService.refreshPage('dashboard');
                                        return;
                                      }

                                      if ($location.path().indexOf('students') > -1 && $scope.showyoyfeatures) {
                                        gapiService.refreshPage('students', selectedCourses ? selectedCourses[0] : undefined);
                                        return;
                                      }

                                      gapiService.refreshPage();
                                    }).catch(function(error) { $this.importErrorHandler(error); });
                                  }).catch(function(error) { $this.importErrorHandler(error); });
                              }).catch(function(error) { $this.importErrorHandler(error); });
                          }).catch(function(error) { $this.importErrorHandler(error); });
                      }).catch(function(error) { $this.importErrorHandler(error); });
                  }).catch(function(error) { $this.importErrorHandler(error); });
              }).catch(function(error) { $this.importErrorHandler(error); });
            }

            function autoUpdateStudentEntitlements(sectionId){
                return new Promise(function (resolve, reject) {
                    enrollmentService.getStudentEnrollment(sectionId)
                        .then(function success(data) {
                            $log.debug('data', data);
                            if (!!data
                                // && !!data.students && data.students.length // first student will not get entitled
                                && !!data.subscriptions && data.subscriptions.length) {
                                // doesn't seem to select entitlements for the first student for access code/application
                                data.subscriptions.forEach(function (subs) {
                                    enrollmentService
                                        .addAllStudentsSubscription(sectionId, subs.subscription.id)
                                        .then(function (res) {
                                            $log.debug('enrollmentService.addStudentSubscription res', res);
                                            resolve(res);
                                        })
                                        .catch(function (err) {
                                            $log.info('error', err);
                                            reject(err);
                                        });
                                });
                            }
                        })
                        .catch(function failure(error) {
                            $log.info('error', error);
                            reject(error);
                        });
                });
            }

            $scope.googleImportClassesConfirmDetailModal = function (selectedCourses, importType) {
                // $scope.spinner = true; // spinner off TODO: $on, $emit, event, data spinnerState logic best in interceptor
                $scope.selectedGoogleClasses = selectedCourses;
                $scope.importType = importType;

                $uibModal.open({
                    templateUrl: 'resources/js/Modules/google/import-classes-confirm-detail-modal/google-import-classes-confirm-detail-modal.html',
                    controller: 'googleImportClassesConfirmDetailModalController',
                    scope: $scope,
                    backdrop: 'static',
                    keyboard: true
                }).result.then(function (data) {
                    function setGradeRange(students) {
                        var tempArray = [];
                        students.forEach(function (student) {
                            tempArray.push(student.grade);
                        });
                        tempArray.sort(function (a, b) {
                            return a.sort - b.sort;
                        });
                        var range = {};

                        $scope.gradelevels.forEach(function(gradeLevel){
                            if(gradeLevel.value === tempArray[0]){
                                range.lowGrade = gradeLevel;
                            }
                            if(gradeLevel.value === tempArray[tempArray.length - 1]) {
                                range.highGrade = gradeLevel;
                            }
                        });

                        $log.debug('range', range);
                        return range;
                    }

                    if(data.message === 'cancel' || data.message === 'close'){
                        $scope.spinner = false;
                    }

                    if(data.message === 'remove'){
                        // the user opted to remove the resync class with no corresponding GC course data
                        $scope.spinner = true;

                        // make a copy of the data and set it to inactive
                        var selectedClass = angular.copy($scope.selectedclass);
                        selectedClass.active = false;

                        // updated the inactive class to remove it from the class list
                        classService.updateClassSection(selectedClass)
                            .then(function () {
                              $scope.setError('Successfully updated a class from your Google Classroom account!');

                                if ($scope.classlist && $scope.classlist.length) {
                                    var otherClasses = $scope.classlist.filter(function(classItem) {
                                        return classItem.id !== selectedClass.id;
                                    });

                                    if (otherClasses && otherClasses.length) {
                                        // the user has other classes, let's refresh the page with one of them selected
                                        gapiService.refreshPage('students', otherClasses[0]);
                                        return;
                                    }
                                }

                                // the teacher does not have other classes, redirect them to the products dashboard
                                gapiService.refreshPage('dashboard');
                            })
                            .catch(function(error) {
                                var message = (error && error.message) ? error.message : sharedConstants.ERROR_PROBLEM_UNKNOWN;

                                $scope.selectedGoogleClasses.map(function (selectedClass) {
                                    $this.deselectClass(selectedClass);
                                });

                                $scope.setError(message, true);
                                gapiService.refreshPage();
                            })
                            .finally(function() {
                                $scope.spinner = false;
                            });
                    }

                    if (data && data.message === 'list' && !!data.selected) {
                        $scope.spinner = false;
                        $scope.selectedGoogleClasses = data.selected;

                        $scope.selectedGoogleClasses
                            .filter(function (course) {
                                return course.courseState === 'ARCHIVED'
                                    && course.lowGrade.value.toUpperCase() === 'MULTIPLE';
                            }).forEach(function (selectedClass) {
                            if(!!selectedClass.sdmClass
                                && !!selectedClass.sdmClass.students
                                && selectedClass.sdmClass.students.length > 1) {
                                var range = setGradeRange(selectedClass.sdmClass.students);
                                selectedClass.lowGrade = range.lowGrade;
                                selectedClass.highGrade = range.highGrade;
                            } else if(!!selectedClass.sdmClass
                                && !!selectedClass.sdmClass.students
                                && selectedClass.sdmClass.students.length === 1) {
                                var range = setGradeRange(selectedClass.sdmClass.students);
                                selectedClass.lowGrade = selectedClass.highGrade = range.highGrade;
                            } else {
                                $log.debug('something went wrong with selectedClass', selectedClass);
                            }
                        });

                        $scope.selectedGoogleClasses
                            .filter(function (course) {
                                return course.lowGrade.value.toUpperCase() !== 'MULTIPLE';
                            }).forEach(function (selectedClass) {
                            selectedClass.highGrade = selectedClass.lowGrade;
                        });

                        var selectedSingleGradeLevelClasses = $scope.selectedGoogleClasses
                            .filter(function (course) {
                                return course.lowGrade.value.toUpperCase() !== 'MULTIPLE'
                                    || course.courseState === 'ARCHIVED';
                            });

                        var selectedMultipleGradeLevelClasses = $scope.selectedGoogleClasses
                            .filter(function (course) {
                                return course.lowGrade.value.toUpperCase() === 'MULTIPLE'
                                    && course.courseState !== 'ARCHIVED';
                            });

                        // open fist modal of series (it's a mistake to use promises here)
                        if(!!selectedMultipleGradeLevelClasses
                            && selectedMultipleGradeLevelClasses.length > 0) {
                            var i = 0;
                            $scope.googleAssignMultipleGradesModal(
                                selectedMultipleGradeLevelClasses[i],
                                i,
                                selectedMultipleGradeLevelClasses
                            );
                        } else if(!!selectedSingleGradeLevelClasses
                            && selectedSingleGradeLevelClasses.length > 0) {
                          $this.importCourses();
                        }
                    }
                }).catch(function(error) {
                    if (!!error) {
                        if(!!error.message){
                            $log.debug('error', error, error.message);
                            // investigate why Timeout notification message is overwritten by the notification below
                            // notificationModel.setMessage(error.message);
                        } else {
                            $log.debug('error', error);
                            // investigate why Timeout notification message is overwritten by the notification below
                            // notificationModel.setMessage(error);
                        }
                    } else {
                        $log.debug('error undefined');
                    }

                    if (!(!!error && error === 'close_no_student_for_class')) {
                        gapiService.refreshPage('dashboard');
                    }
                });
            };

            function deselectClass(selectedClass) {
                delete selectedClass.selected;
                delete selectedClass.school;
                delete selectedClass.lowGrade;
                delete selectedClass.highGrade;
            }
    }
    ]);


