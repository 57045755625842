(function(){
    angular.module('portalApp').service('sessionState', [
            function sessionState() {
                var storeObj = {};
                return {
                    store: function (key, value) {
                        storeObj[key] = value;
                    },
                    get: function (key) {
                        return storeObj[key];
                    }
                };
            }]
        );
})();