(function() {

	var MILLIS_IN_DAY = 24 * 60 * 60 * 1000;

portalModule.filter("gradeIDToString",function(){
	return function(input)
	{
	var gradeMapping = {
		"pk": "Pre-Kindergarten",
		"k": "Kindergarten",
		"1": "Grade 1",
		"2": "Grade 2",
		"3": "Grade 3",
		"4": "Grade 4",
		"5": "Grade 5",
		"6": "Grade 6",
		"7": "Grade 7",
		"8": "Grade 8",
		"9": "Grade 9",
		"10": "Grade 10",
		"11": "Grade 11",
		"12": "Grade 12"
	};

	if(!input){
		return '';
	}

	return gradeMapping[input.toLowerCase()] || input;
	};	
});


portalModule.filter("unsafe",function($sce){
	return $sce.trustAsHtml;
});

portalModule.filter('parseAndRoundDate', parseAndRoundDateFilter);	
parseAndRoundDateFilter.$inject = ['$filter', '$window'];	
function parseAndRoundDateFilter($filter, $window){
	return function(input) {
		// Adjusts to eastern time (ignoring DST) and then rounds
		var m = $window.moment(input);
		m.minute(m.minute() - m.utcOffset());
		return new Date(m.year(), m.month(), m.date());
	}
}

portalModule.filter('formatStartDate', formatStartDateFilter);	
formatStartDateFilter.$inject = ['$filter'];
function formatStartDateFilter($filter){
	return function(input) {
		var d = $filter('parseAndRoundDate')(input);
		return $filter('date')(d, 'longDate');	
	}
}

portalModule.filter('formatEndDate', formatEndDateFilter);	
formatEndDateFilter.$inject = ['$filter'];
function formatEndDateFilter($filter){
	return function(input) {
		var d = $filter('parseAndRoundDate')(input);
		d.setTime(d.getTime()-MILLIS_IN_DAY);
		return $filter('date')(d, 'longDate');	
	}
}

portalModule.filter('titleCase', function() {
    return function(input) {
        input = input || '';
        return input.replace(/\w\S*/g, function(txt){
        	return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };
});

portalModule.filter('sanitizeNameStr', function() {
	// NOTE: We've removed sanitize so that Google Allows Chars are passed directly to the backend.
    // The rules for first, middle, last name, max length is 50 for each.
	// First and last name are required, the middle name is not.
    //
    //    Example Pattern for name: "[A-Za-z]([ '-][A-Za-z])*"
	//
	// Names can consist of one or more words separated by spaces or dashes
	// e.g. John-John and Mary Sue are both valid but M@ry or M
    return function(input) {
        input = input || '';
        return input.replace(/([^A-Za-z0-9 '-]+)/gi, '');
    };
});

    portalModule.filter('csvToArrayOfObjects', function () {
        return function (csv, headersArray, lineDelimiter) {
            var resultsArray = [];
            var lines = csv.split(lineDelimiter);
            var contentsArray = lines.map(function (line) {
                return line.split(',');
            });
            var headers = contentsArray.shift();
            if (headersArray.length && headers.length) {
                headers.forEach(function (header, headerIndex) {
                    header = header.replace(/[\W_]+/g, "").toLowerCase();
                    headersArray.forEach(function (propName, propIndex) {
                        propName = propName.toLowerCase();
                        if (header.indexOf(propName) > -1 || propName.indexOf(header) > -1) {
                            headers[headerIndex] = headersArray[propIndex];
                        }
                    });
                });
            }
            contentsArray.forEach(function (item) {
                var row = {};
                headers.forEach(function (header, index) {
                    row[header] = (index < item.length) ? item[index] : null;
                });
                resultsArray.push(row);
            });
            return resultsArray;
        };
    });

}());
