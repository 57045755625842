portalModule
    .controller('logoutController', [
        '$scope', '$log', '$location', '$routeParams', 'userService', 'appModel',
        'classModel', 'navModel', 'orgModel', 'subscriptionModel', 'studentModel',
        'userModel', 'sessionModel', 'productModel', 'notificationModel', '$rootScope',
        'SessionService', 'loginModel', 'easyLoginModel', 'gapiService', 'csvService', 'configService',

        function (
            $scope, $log, $location, $routeParams, userService, appModel,
            classModel, navModel, orgModel, subscriptionModel, studentModel,
            userModel, sessionModel, productModel, notificationModel, $rootScope,
            SessionService, loginModel, easyLoginModel, gapiService, csvService, configService
            ) {

            var user;
            var easyLoginCred;
            var source;
            var ltiLogoutPath = 'lti/signedout';
            onInit();

            function onInit() {
                source = $routeParams.source;
                navModel.showNav = false;
                notificationModel.setMessage('');
                $rootScope.$broadcast('globalMessageSet');
                userService.getCurrentUserWithoutRedirect().then(function (currentUser) {
                    $log.debug('Current signed in user', currentUser);
                    user = currentUser;
                    easyLoginCred = easyLoginModel.getEasyLoginCookie();
                    signoutUser();
                }, function (error) {
                    $log.debug('No user signed in', error);
                    var logoutPath = (source && (source.toLowerCase() === 'lti' || source.toLowerCase() === 'classlink')) ? ltiLogoutPath : '/';
                    $location.path(logoutPath);
                });
            }

            function signoutUser() {
                appModel.reset();
                classModel.reset();
                navModel.reset();
                orgModel.reset();
                subscriptionModel.reset();
                studentModel.reset();
                userModel.reset();
                sessionModel.reset();
                productModel.reset();
                loginModel.reset();
                easyLoginModel.reset();
                gapiService.reset();
                csvService.reset();
                configService.reset();

                Object.keys(localStorage).forEach(function(key) {
                    if (key.substring(0, 2) === 'dp' || key.substring(0, 3) === 'sdm') {
                        localStorage.removeItem(key);
                    }
                });

                Volume.setStoreItem("dpApiToken", "");
                Volume.setStoreItem("dpApiUrlHost", "/");
                Volume.setStoreItem(
                  "dpApiUrlBase",
                  sessionStorage.getItem("localdev") === "true" ? "dp-portal/" : ""
                );

                Volume.setStoreItem("dpPortalUrl", window.location.href.split("/#/")[0]);

                userService.signoutUser().then(function () {
                    if (
                        (user.staff && user.staff.identifiers.source && user.staff.identifiers.source.toLowerCase() == 'clever') ||
                        (user.student && user.student.identifiers.source && user.student.identifiers.source.toLowerCase() == 'clever')
                    ) {
                        window.location.href = 'https://clever.com/login';
                    } else if (
                        (user.staff && user.staff.identifiers.source && user.staff.identifiers.source.toLowerCase() == 'lti') ||
                        (user.student && user.student.identifiers.source && user.student.identifiers.source.toLowerCase() == 'lti') ||
                        source && source.toLowerCase() === 'lti'
                    ) {
                        $location.path(ltiLogoutPath)
                    } else if (
                        (user.staff && user.staff.identifiers.source && user.staff.identifiers.source.toLowerCase() == 'classlink') ||
                        (user.student && user.student.identifiers.source && user.student.identifiers.source.toLowerCase() == 'classlink') ||
                        source && source.toLowerCase() === 'classlink'
                    ) {
                        $location.path(ltiLogoutPath)
                    } else {
                        userModel.checkUser = 1;
                        if (user.student != undefined) {
                            if(user.sessionContext.easyLogin && easyLoginCred){
                                $location.path('/easylogin/' + easyLoginCred.classId + '/' + easyLoginCred.classSecret);
                            }
                            else{
                                $location.path('/signin');
                            }
                        } else {
                            $location.path('/signin/staff');
                        }
                    }
                }, function () {
                    SessionService.extSession(user.sessionContext, true, 0, false);
                    $log.error('Error logging out');
                    $location.path('/signin');
                });
            }
        }
    ]);
